import * as StorageService from "./StorageService";
import heic2any from "heic2any";
import Constants from "../constants/APIConstants";
import Compressor from "compressorjs";

export function setToken(jwtResponse) {
    StorageService.setToken(jwtResponse);
}

export function getUser() {
    let token = StorageService.getToken();
    return token === undefined || token === null ? null : token;
}

export function getUserType() {
    let user = getUser();
    if (user === null) {
        return 'ROLE_USER';
    }
    let roles = user.roles;
    if (roles.includes('ROLE_ADMIN')) {
        return 'ROLE_ADMIN';
    }
    if (roles.includes('ROLE_LAWYER')) {
        return 'ROLE_LAWYER';
    }
    return 'ROLE_USER';
}

function getAuthorization() {
    let Auth = StorageService.getToken();
    return (Auth === undefined || Auth === null) ? undefined : Auth.type + ' ' + Auth.token;
}

async function doRequest(method = 'GET', url = "/", data = {}, file) {
    let headers = {
        'x-apigateway-event': '{"test" : "Test"}',
        'x-apigateway-context': '{"test" : "Test"}'
    }
    headers.contentType = false;

    let Auth = StorageService.getToken();

    if (Auth !== undefined) {
        headers['Authorization'] = getAuthorization();
    }

    if (file === undefined) {
        headers['Content-Type'] = 'application/json';
    }

    let fetchParams = {
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    if (method !== 'GET') {
        if (file !== undefined) {
            let formData = new FormData()
            formData.append('file', file)
            if (data != undefined) {
                formData.append('body', JSON.stringify(data));
            }
            fetchParams.body = formData;
        } else {
            fetchParams.body = JSON.stringify(data)
        }
    }

    let response = await fetch(url, fetchParams);
    if (response.status >= 400) {
        let body = await response.json();
        throw new Error(JSON.stringify({
            responseBody: body,
            code: response.status,
            message: body.message,
            method: method,
            url: url,
            data: data,
            headers: headers
        }))
    }
    return response.json();
}
async function doPost(url = '', data = {}, file) {
    return doRequest('POST', url, data, file);
}

async function doPut(url = '', data = {}, file) {
    return doRequest('PUT', url, data, file);
}

async function doGet(url) {
    return doRequest('GET', url);
}

async function doDelete(url) {
    return doRequest('DELETE', url);
}

export async function placeOrder(message) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.PLACE;
    return doPost(apiURL, {
        ...message,
        origin: "CAZIERE_RO"
    });
}
export async function placeFingerprint(message) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.FINGERPRINTS.PLACE;
    return doPost(apiURL, {
        ...message,
        origin: "CAZIERE_RO"
    });
}

export async function addInvoiceDetails(message) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.INVOICE_DETAILS;
    return doPut(apiURL, message);
}

export async function sendMessage(message) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE.SEND;
    return doPost(apiURL, message);
}

export async function sendMessageWithFile(message, file, unknownDocType) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE.SEND_WITH_ATTACHMENT;
    if (unknownDocType === true) {
        apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE.SEND_WITH_ATTACHMENT_UNKNOWN_DOC;
    }
    if (file == null) {
        apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE.SEND_WITH_ATTACHMENT_DUMB;
    }
    return doPost(apiURL, message, file);
}
export async function sendMessageForClientWithFile(message, file) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE.SEND_WITH_ATTACHMENT_TO_CLIENT;
    return doPost(apiURL, message, file);
}

export async function getOrders(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName, autoGenerated) {
    if (autoGenerated === undefined || autoGenerated === null) {
        autoGenerated = false;
    }
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ALL(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName, autoGenerated);
    return doGet(apiURL);
}

export async function getCommunicationLogs(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COMMUNICATION_LOG.ALL(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName);
    return doGet(apiURL);
}

export async function getFingerprints(pageNo, pageSize, sortBy, sortDirection) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.FINGERPRINTS.ALL(pageNo, pageSize, sortBy, sortDirection);
    return doGet(apiURL);
}

export async function getEFacturaMessages(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.E_FACTURA.ALL(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName);
    return doGet(apiURL);
}

export async function getEFacturaProblematicOrders() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.E_FACTURA.PROBLEMATIC_ORDERS;
    return doGet(apiURL);
}
export async function forceSendOrders() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.E_FACTURA.FORCE_SEND;
    return doPost(apiURL);
}

export async function forceRefresh() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.E_FACTURA.FORCE_REFRESH;
    return doPost(apiURL);
}

export async function getAnafCompany(cui) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ANAF.DETAILS(cui);
    return doGet(apiURL);
}

export async function getStatsLastSevenDays() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.STATS_LAST_SEVEN_DAYS;
    return doGet(apiURL);
}

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

export async function addDocumentToOrder(orderId, documentType, file) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ADD_DOCUMENT(orderId, documentType);

    let fileNameLowercase = file != undefined && file.name != undefined ? file.name.toLowerCase() : "file.jpg";
    if (fileNameLowercase.endsWith('.jfif')) {
        file = renameFile(file, file.name + ".jpeg")
    }

    if (fileNameLowercase.endsWith('.heic')) {
        convertHEICToJPG(file, apiURL, (newFile) => {
            return doPost(apiURL, undefined, newFile);
        });
    } else {
        return doPost(apiURL, undefined, file);
    }
}
export async function error(orderId, error, ls) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ERROR(orderId);
    return doPost(apiURL, {
        message: error,
        ls: ls
    });
}

function convertHEICToJPG(file, apiURL, callback) {
    heic2any({
        blob: file,
        toType: "image/png",
    }).then(function (resultBlob) {
        new Compressor(resultBlob, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                let newFileCompressed = new File([compressedResult], file.name + ".png", {
                    type: "image/png",
                    lastModified: new Date().getTime()
                });
                return callback(newFileCompressed);
            },
        });

    }).catch(function (x) {
        console.error(x);
        return callback(file);
    });
}

export async function login(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.AUTH.LOGIN;
    return doPost(apiURL, body);
}

export async function register(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.AUTH.REGISTER;
    return doPost(apiURL, body);
}

export async function getOrderById(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.GET_BY_ID(id);
    return doGet(apiURL);
}
export async function getOrderWithFullDetailsById(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.GET_BY_ID_WITH_FULL_DETAILS(id);
    return doGet(apiURL);
}

export async function addRegistrationNumber(id, regNumber) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ADD_REGISTRATION_NUMBER(id, regNumber);
    return doPut(apiURL);
}

export async function updateOrderById(id, reason) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.UPDATE(id);
    return doPut(apiURL, {reason: reason});
}

export async function addLaterDelivery(id, obj) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ADD_LATER_DELIVERY(id);
    return doPut(apiURL, obj);
}

export async function refundOrder(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.REFUND(id);
    return doPut(apiURL);
}
export async function editOrder(id, body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.EDIT(id);
    return doPut(apiURL, body);
}
export async function changeOrderAccess(orderId, operation, userId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.CHANGE_ACCESS(orderId, operation, userId);
    return doPut(apiURL);
}

export async function getAllUsers() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.AUTH.GET_USERS;
    return doGet(apiURL);
}

export async function getAllLawyers() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.AUTH.GET_LAWYERS;
    return doGet(apiURL);
}

export async function getAllStatuses() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.GET_STATUSES;
    return doGet(apiURL);
}
export async function getMessageLogs(orderId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.MESSAGE_LOGS(orderId);
    return doGet(apiURL);
}

export async function getOrderByAuthDetails(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.GET;
    return doPost(apiURL, body);
}

export async function requestNewAuthCode(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.REQUEST_NEW_AUTH_CODE;
    return doPut(apiURL, body);
}
export async function requestNewAuthCodeAsAdmin(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.REQUEST_NEW_AUTH_CODE_AS_ADMIN;
    return doPut(apiURL, body);
}
export async function markLaterDeliveryDone(id, laterDeliveryState) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.MARK_LATER_DELIVERY_DONE(id, laterDeliveryState);
    return doPut(apiURL);
}

export async function changeRole(body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.AUTH.ROLE_CHANGE;
    return doPost(apiURL, body);
}

export async function generateReport(params) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.REPORT.ORDER(params);
    return downloadFile(apiURL);
}

export async function generateInvoices(params) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.INVOICES.GENERATE(params);
    return downloadFile(apiURL);
}

export async function generatePartners(params) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.INVOICES.GENERATE_PARTNERS(params);
    return downloadFile(apiURL);
}

export async function confirmPayment(orderId, orderCode) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.CONFIRM_PAYMENT(orderId, orderCode);
    return doPut(apiURL);
}

export async function alertForDocument(orderId, documentId, body) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.ALERT_DOCUMENT(orderId, documentId);
    return doPost(apiURL, body);
}

export async function changeStatusForOrder(orderId, newStatus) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.CHANGE_STATUS(orderId, newStatus);
    return doPut(apiURL);
}

export async function changeTransactionStatusForOrder(orderId, newStatus) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.CHANGE_TRANSACTION_STATUS(orderId, newStatus);
    return doPut(apiURL);
}

export async function getStreetsFor(judet, localitate) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COURIER.STREETS;
    return doPost(apiURL, {
        judet: judet,
        localitate: localitate
    });
}

export async function getDriveScheduler() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DRIVE.SCHEDULE;
    return doGet(apiURL);
}

export async function addDocumentToDrive(file, categoryName, year, month) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DRIVE.UPLOAD_DOCUMENT(categoryName, year, month);
    return doPost(apiURL, undefined, file);
}

export async function addCategoryToDrive(categoryName) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DRIVE.ADD_CATEGORY(categoryName);
    return doPost(apiURL);
}

export async function deleteDocumentToDrive(documentId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DRIVE.DELETE_DOCUMENT(documentId);
    return doDelete(apiURL);
}

export async function paymentLinkCreate(price) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.PAYMENT_LINKS.CREATE;
    return doPost(apiURL, {price: price});
}

export async function paymentLinkOrder(orderId, code) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.PAYMENT_LINKS.ORDER(orderId, code);
    return doGet(apiURL);
}

export async function getAllFeatureSwitches() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.FEATURE_SWITCHES.ALL;
    return doGet(apiURL);
}

export async function getAllPublicFeatureSwitches() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.FEATURE_SWITCHES.ALL_PUBLIC;
    return doGet(apiURL);
}

export async function getAllPublicPrices() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.PRICES.ALL_PUBLIC;
    return doGet(apiURL);
}

export async function updateFeatureSwitch(id, value) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.FEATURE_SWITCHES.UPDATE(id);
    return doPost(apiURL, {id, value});
}

export async function getAllPrices(isCountryPrice= false) {
    let subpath = isCountryPrice ? Constants.API.PATHS.PRICES_COUNTRY.ALL : Constants.API.PATHS.PRICES.ALL;
    let apiURL = Constants.API.HOST_AND_PORT + subpath;
    return doGet(apiURL);
}

export async function updatePrice(id, value, isCountryPrice= false) {
    let subpath = isCountryPrice ? Constants.API.PATHS.PRICES_COUNTRY.UPDATE(id) : Constants.API.PATHS.PRICES.UPDATE(id);
    let apiURL = Constants.API.HOST_AND_PORT + subpath;
    return doPost(apiURL, {id, value});
}

export async function updatePriceBulk(prices, isCountryPrice= false) {
    let subpath = isCountryPrice ? Constants.API.PATHS.PRICES_COUNTRY.UPDATE_BULK : Constants.API.PATHS.PRICES.UPDATE_BULK;
    let apiURL = Constants.API.HOST_AND_PORT + subpath;
    return doPost(apiURL, {prices});
}

export async function getAllDefaults() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DEFAULTS.ALL;
    return doGet(apiURL);
}

export async function getAllBlacklist() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BLACKLIST.ALL;
    return doGet(apiURL);
}

export async function createBlacklistItem(obj) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BLACKLIST.CREATE;
    return doPost(apiURL, obj);
}
export async function deleteBlacklistItem(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BLACKLIST.DELETE(id);
    return doDelete(apiURL);
}

export async function getAllMessagesFromQueue() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE_QUEUE.ALL;
    return doGet(apiURL);
}


export async function deleteMessageQueueItem(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.MESSAGE_QUEUE.DELETE(id);
    return doDelete(apiURL);
}

export async function updateDefault(id, value) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DEFAULTS.UPDATE(id);
    return doPost(apiURL, {id, value});
}

export async function updateDefaultBulk(defaults) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DEFAULTS.UPDATE_BULK;
    return doPost(apiURL, {defaults});
}

export async function getCompanyInUse() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DEFAULTS.COMPANY_IN_USE;
    return doGet(apiURL);
}

export async function generateDriveArchive(categoryName, year, month) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.DRIVE.ARCHIVE(categoryName, year, month);
    return downloadFile(apiURL);
}

export async function addCompany(company) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COMPANY.ADD;
    return doPost(apiURL, company);
}

export async function deleteCompany(companyID) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COMPANY.DELETE(companyID);
    return doDelete(apiURL);
}

export async function getCompanies() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COMPANY.GET;
    return doGet(apiURL);
}

export async function addBanner(banner) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BANNER.ADD;
    return doPost(apiURL, banner);
}

export async function getBanners() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BANNER.GET;
    return doGet(apiURL);
}

export async function getBannersAsAdmin() {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BANNER.GET_AS_ADMIN;
    return doGet(apiURL);
}

export async function updateBannerStatus(id, status) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BANNER.UPDATE_STATUS(id, status);
    return doPut(apiURL);
}
export async function deleteBanner(id) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.BANNER.DELETE(id);
    return doDelete(apiURL);
}

export async function generateAWB(orderId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COURIER.GENERATE_AWB_FOR(orderId);
    return downloadFile(apiURL);
}

export async function generateAWBForAll(params) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.COURIER.GENERATE_AWB_FOR_ALL(params);
    return downloadFile(apiURL);
}

export async function downloadInvoice(orderId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.INVOICE_DOWNLOAD(orderId);
    return downloadFile(apiURL);
}

export async function downloadInvoiceAndSend(orderId) {
    let apiURL = Constants.API.HOST_AND_PORT + Constants.API.PATHS.ORDER.INVOICE_SEND(orderId);
    return downloadFile(apiURL);
}

export async function downloadFile(url) {
    let headers = {}
    headers.contentType = false;

    let Auth = StorageService.getToken();
    if (Auth !== undefined) {
        headers['Authorization'] = getAuthorization();
    }

    let fetchParams = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };


    let response = await fetch(url, fetchParams);
    if (response.status >= 400) {
        let body = await response.json();
        throw new Error(JSON.stringify({
            code: response.status,
            message: body.message
        }))
    }
    return response;
}