import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import {Checkbox, FormControlLabel, InputLabel, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";

export default function AddBlacklistItemModal({open, handleConfirm, handleClose}) {

    let fieldTypes = ['EMAIL', 'PHONE', 'IP_ADDRESS'];
    let [field, setField] = useState(null);
    let [value, setValue] = useState(null);

    const returnCompany = () => {
        handleConfirm({
            field: field,
            value: value
        });
        setField(null);
        setValue(null);
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {"Adăugare item Blacklist"}
            </DialogTitle>
            <DialogContent sx={{marginTop: "10px"}}>

                <FormControl fullWidth sx={{marginTop: "10px"}}>
                    <InputLabel id="demo-simple-select-label">Field</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={field}
                        label="Age"
                        onChange={(event) => setField(event.target.value)}
                    >
                        {
                            fieldTypes.map(field => <MenuItem value={field}>{field}</MenuItem>)
                        }
                    </Select>
                </FormControl>


                <TextField label={"Value"} variant="standard"
                           value={value} fullWidth sx={{marginTop: "30px"}}
                           onChange={(event) => setValue(event.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => returnCompany()} color={"success"}>
                    Adăugare
                </Button>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
