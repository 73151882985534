import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import {changeStatusForOrder, downloadInvoice} from "../../../services/CommunicationService";
import {STATUSES} from "../../../services/LogicService";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import {Icon} from "@iconify/react";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";

export default function OrderDocumentsModal({row, open, handleClose}) {

    let downloadFileFunc = (doc) => {
        CommunicationService.downloadFile(doc.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc.name;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    let showDocumentDetails = (order, doc) => {
        if (doc === undefined || doc === null || doc.internalName === undefined || doc.internalName === null) {
            alert("Error: call Cosmin")
            return;
        }
        let name = doc.internalName;
        let obj = {
            message: name + " - Copied to clipboard.",
            key: 'document_upload',
            severity: 'info'
        }

        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.clipboard.writeText(name).then(
                        () => {
                            LogicService.setAlertMessageAndSeverity(obj);
                        },
                        () => {
                            LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CANNOT_COPY_TO_CLIPBOARD);
                        }
                    );
                }
            });

    }

    const documentIsDeleted = (document) => {
        if (document === undefined || document === null
            || document.uploadedDateTime === undefined || document.uploadedDateTime === null) {
            return false;
        }
        let daysBetweenNowAndThen = ((new Date()).getTime() - (new Date(document.uploadedDateTime)).getTime()) / (1000 * 3600 * 24);
        return daysBetweenNowAndThen >= 30;
    }

    let getExtension = (filename) => {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
    }
    let isPhoto = (documentName) => {
        if (documentName == undefined || documentName == null) {
            return false;
        }
        let ext = getExtension(documentName);
        if (ext == undefined) {
            return false;
        }
        return ext[0].match(/\.(jpg|jpeg|png|gif)$/i);
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Documentele orderului #" + row.id}
            </DialogTitle>
            <DialogContent sx={{padding: "20px 5px"}}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {
                        row.documents.map(doc => {
                            let documentIsDel = documentIsDeleted(doc);
                            return <ListItem sx={{border: "1px solid transparent"}}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {
                                            isPhoto(doc.internalName) ?
                                            <ImageIcon /> : <DescriptionIcon/>
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={doc.internalName}
                                              secondary={
                                                  <React.Fragment>
                                                      <Typography
                                                          sx={{ display: 'inline' }}
                                                          component="span"
                                                          variant="body2"
                                                          color="text.primary"
                                                      >
                                                         <strong> {doc.type} </strong>
                                                      </Typography>
                                                      {" — " + doc.uploadedDateTime}
                                                  </React.Fragment>
                                              }
                                />
                                <IconButton
                                    sx={doc !== undefined && doc.hasProblems ? {
                                        background: "red",
                                        color: "white"
                                    } : null}
                                    onClick={() => documentIsDel ? showDocumentDetails(row.id, doc) : downloadFileFunc(doc)}
                                >
                                    <Icon icon={documentIsDel ? deletedFileOutlined : downloadOutlined} width={24} height={24}/>
                                </IconButton>
                            </ListItem>;
                        })
                    }
                </List>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}
