import PropTypes from 'prop-types';
// material
import {Box, Button, Card, CardContent, Grid, Typography} from '@mui/material';

// utils
//
import OrderFormFinished from "../../authentication/order/OrderFormFinished";
import * as React from "react";
import {useState} from "react";
import * as LogicService from "../../../services/LogicService";
import {DOCUMENTS_DETAILS, STATUSES} from "../../../services/LogicService";
import * as CommunicationService from "../../../services/CommunicationService";
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone';
import SubjectIcon from '@mui/icons-material/Subject';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaymentIcon from '@mui/icons-material/Payment';
import AlbumIcon from '@mui/icons-material/Album';
import * as AlertMapper from "../../../services/AlertMapper";
import {useNavigate} from "react-router-dom";

OrderDetailsPostCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number
};

export default function OrderDetailsPostCard({order}) {
    let {
        id,
        fiscalDone,
        type,
        status,
        translationLanguage,
        transactionVerified,
        paymentCode,
        price,
        createdAt,
        documents
    } = order;

    let orderFromMemory = LogicService.getOrder();
    let nextState = orderFromMemory == undefined || orderFromMemory == null ? "" : orderFromMemory.nextState;

    const navigate = useNavigate();

    if (status === undefined || status === null) {
        status = "";
    }
    let policeRecord = documents.find((document) => DOCUMENTS_DETAILS.isPoliceRecord(document.type));
    let policeRecordTranslated = documents.find((document) => DOCUMENTS_DETAILS.isPoliceRecordTranslated(document.type));

    let existingDetails = DOCUMENTS_DETAILS[type === undefined ? '' : type.toUpperCase()] || {}

    let cazierIsReady = policeRecord !== undefined;
    const [cazierIsReadyToDownload, setCazierIsReadyToDownload] = useState(cazierIsReady);
    const [cazierTranslatedIsReadyToDownload, setCazierTranslatedIsReadyToDownload] = useState(policeRecordTranslated !== undefined);
    const [invoiceIsReadyToDownload, setInvoiceIsReadyToDownload] = useState(fiscalDone === true);
    function redirectToPayment() {

        if (order.fiscalDone) {
            window.location.assign(paymentCode);
        } else {
            let docKey = DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key;
            LogicService.addUploadedDocuments({key: docKey, uploaded: true});

            LogicService.setOrder(order.id, order.price, true,
                order.paymentCode, [], order.user.type.toUpperCase(), null, true, order.active, order.fiscalDone, 'FISCAL');
            navigate('/');
        }
    }

    function downloadInvoice() {
        return response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Invoice-" + Date.now() + ".pdf";
                a.click();

                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                handleClose();
            });
        };
    }

    const handleDownloadInvoice = () => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.INVOICE_REPORT_BEING_CREATED);
        setInvoiceIsReadyToDownload(false);
        setTimeout(() => {
            setInvoiceIsReadyToDownload(true);
        }, 10000)
        CommunicationService.downloadInvoice(id)
            .then(downloadInvoice())
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    function downloadDocument(doc, docName) {
        if (docName === 'policeRecordTranslated') {
            setCazierTranslatedIsReadyToDownload(false);
            setTimeout(() => {
                setCazierTranslatedIsReadyToDownload(true);
            }, 10000)
        } else {
            setCazierIsReadyToDownload(false);
            setTimeout(() => {
                setCazierIsReadyToDownload(true);
            }, 10000)
        }

        CommunicationService.downloadFile(doc.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc.name;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_UPLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    let createdDateParsed = new Date(createdAt);
    let createdDate = createdDateParsed.toLocaleDateString() + ' ' + createdDateParsed.getHours() + ':' + createdDateParsed.getMinutes();

    let getGroupWithImgAndText = (icon, text, color, onClick) => {
        return <Button variant="outlined" size="large"
                       color={color}
                       disableElevation
                       disableFocusRipple
                       disableRipple
                       fullWidth
                       iconSizeLarge
                       onClick={onClick}
                       sx={{height: "50px", display: "flex", justifyContent: "flex-start"}}
                       startIcon={icon}>
            {text}
        </Button>
    }
    let tranzactionStatus = transactionVerified ? 'Tranzactie verificată ' : 'Tranzacție neverificată';
    let existingStatus = STATUSES[status.toUpperCase()] || {};
    return [
        <Grid item xs={12} sm={8.5} md={8.5}>
            <Box dir="ltr">
                <Card sx={{position: 'relative'}}>

                    <CardContent
                        sx={{
                            pt: 4
                        }}
                    >
                        <OrderFormFinished order={order}/>

                    </CardContent>
                </Card>
            </Box>
        </Grid>,
        <Grid item xs={12} sm={3.5} md={3.5}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card sx={{position: 'relative'}}>
                        <CardContent sx={{textAlign: "center", display: "grid", rowGap: "10px"}}>
                            {
                                getGroupWithImgAndText(
                                    <SubjectIcon/>, 'Tip document: ' + existingDetails.title, "primary")
                            }
                            {
                                getGroupWithImgAndText(
                                    <AlbumIcon/>, 'Status order: ' + existingStatus.title, "primary")
                            }
                            {
                                getGroupWithImgAndText(
                                    <PaymentIcon/>, tranzactionStatus, transactionVerified ? "primary" : "error")
                            }
                            {
                                getGroupWithImgAndText(
                                    <AttachMoneyTwoToneIcon/>, 'Preț: ' + price + ' lei', transactionVerified ? "primary" : "error")
                            }
                            {
                                getGroupWithImgAndText(<DateRangeIcon/>, 'Creat la: ' + createdDate, "primary")
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card sx={{position: 'relative'}}>
                        {
                            transactionVerified ? <CardContent sx={{pt: 4}}>
                                    {
                                        cazierIsReady ? null :
                                            <Typography gutterBottom variant="body2"
                                                        sx={{
                                                            color: 'text.disabled',
                                                            display: 'block',
                                                            textAlign: "center"
                                                        }}>
                                                Cazierul tau nu a fost inca ridicat. Iti vom trimite un SMS cand acesta va
                                                fi
                                                disponibil pentru descarcare.
                                            </Typography>
                                    }
                                    <Button variant="contained"
                                            fullWidth
                                            disabled={!cazierIsReadyToDownload}
                                            onClick={() => downloadDocument(policeRecord, 'policeRecord')}
                                            sx={{margin: "5px 0"}}
                                            component="span">
                                        Descarcă cazier
                                    </Button>

                                {
                                    translationLanguage === undefined || translationLanguage === null || translationLanguage === "ROMÂNĂ" || ['RO', 'ro'].includes(translationLanguage.code) ? null :
                                        <Button variant="contained"
                                                fullWidth
                                                disabled={!cazierTranslatedIsReadyToDownload}
                                                onClick={() => downloadDocument(policeRecordTranslated, 'policeRecordTranslated')}
                                                sx={{margin: "5px 0"}}
                                                component="span">
                                            Descarcă cazier tradus
                                        </Button>
                                }
                                {
                                    fiscalDone === true ?
                                        <Button variant="contained"
                                                fullWidth
                                                onClick={() => handleDownloadInvoice()}
                                                disabled={!invoiceIsReadyToDownload}
                                                sx={{margin: "5px 0"}}
                                                component="span">
                                            Descarcă factură fiscală
                                        </Button> : null
                                }

                                </CardContent>
                                : <CardContent sx={{pt: 4}}>
                                    <Typography gutterBottom variant="body2"
                                                sx={{color: 'text.disabled', display: 'block', textAlign: "center"}}>
                                        {
                                            nextState == 'FISCAL' ? 'Se pare că nu ați completat datele de facturare. Fără acestea nu vom putea începe comanda.' :
                                                'Se pare că tranzația nu a fost validată. Te rugăm să faci plata sau să mai aștepți câteva minute pentru validare dacă ai făcut-o deja.'
                                        }
                                    </Typography>
                                    <Button variant="contained"
                                            color={"secondary"}
                                            fullWidth
                                            onClick={() => redirectToPayment()}
                                            component="span">
                                        {nextState == 'FISCAL' ? 'Completare date de facturare' : 'Către plată'}
                                    </Button>
                                </CardContent>
                        }
                    < /Card>

                </Grid>
            </Grid>
        </Grid>

    ];
}
