// material
// components
import * as React from "react";
import InfoCardsPage from "./InfoCardsPage";
import Messages from "../constants/Messages";

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {

    return (
        <InfoCardsPage document={Messages.PRIVACY_POLICY}/>
    );
}
