import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {Button, Card, CardContent, Grid, IconButton, Input, Link, Typography} from '@mui/material';
// utils
import {fDate} from '../../../utils/formatTime';
//
import SvgIconStyle from '../../SvgIconStyle';
import Download from '@mui/icons-material/Download';
import * as LogicService from "../../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../../services/LogicService";
import * as CommunicationService from "../../../services/CommunicationService";
import * as React from "react";
import {useState} from "react";
import * as AlertMapper from "../../../services/AlertMapper";

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
});

const IconButtonAvatar = styled(IconButton)(({theme}) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2)
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
});

// ----------------------------------------------------------------------

OrderDetailsDocument.propTypes = {
    document: PropTypes.object.isRequired,
    index: PropTypes.number
};

export default function OrderDetailsDocument({refreshOrder, orderId, doc, index, gridWidth, orderStarted}) {
    const {name, type, uploadedDateTime, url, hasProblems} = doc;

    const latestPostLarge = false;
    const latestPost = false;


    let documentsSavedDetails = DOCUMENTS_DETAILS[type.toUpperCase()];
    const [downloadEnabled, setDownloadEnabled] = useState(true);
    const [uploadEnabled, setUploadEnabled] = useState(true);


    function downloadDocument() {
        setDownloadEnabled(false);
        setTimeout(() => {
            setDownloadEnabled(true);
        }, 10000)

        CommunicationService.downloadFile(url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = name;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const uploadDocument = (event, objectKey) => {
        setUploadEnabled(false);
        let file = event.target.files[0];
        CommunicationService.addDocumentToOrder(orderId, objectKey, file)
            .then((response) => {
                refreshOrder();
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_UPLOAD);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    let cardSx = {
        position: 'relative'
    }

    if (hasProblems) {
        cardSx.border = "2px solid red";
    }

    let id = `contained-button-file_${index}`;
    let id1 = `contained-button-show-file_${index}`;
    return (
        <Grid item xs={12} sm={gridWidth * 1.5} md={gridWidth}>
            <Card sx={cardSx}>
                <CardMediaStyle>
                    <SvgIconStyle
                        color="paper"
                        src="/static/icons/shape-avatar.svg"
                        sx={{
                            width: 80,
                            height: 36,
                            zIndex: 9,
                            bottom: -15,
                            position: 'absolute',
                            ...((latestPostLarge || latestPost) && {display: 'none'})
                        }}
                    />
                    <IconButtonAvatar aria-label="delete" size="large"
                                      disabled={!downloadEnabled}
                                      onClick={() => downloadDocument()}
                                      sx={{
                                          ...((latestPostLarge || latestPost) && {
                                              zIndex: 9,
                                              top: 24,
                                              left: 24,
                                              width: 40,
                                              height: 40
                                          }),
                                          background: '#3366FF',
                                          color: 'white',
                                          "&:hover": {
                                              color: 'white',
                                              backgroundColor: '#22AAFF'
                                          },
                                      }}>
                        <Download/>
                    </IconButtonAvatar>

                    <CoverImgStyle alt={type.toLowerCase()}
                                   src={"/static/illustrations/" + type.toLowerCase() + ".webp"}/>
                </CardMediaStyle>

                <CardContent
                    sx={{
                        pt: 4,

                        ...((latestPostLarge || latestPost) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute'
                        })
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="caption"
                        sx={{color: 'text.disabled', display: 'block'}}
                    >
                        {fDate(uploadedDateTime)}
                    </Typography>

                    <Typography variant="subtitle1" sx={{height: "70px"}}>{documentsSavedDetails.title}</Typography>

                    <TitleStyle
                        to="#"
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        sx={{
                            color: "darkgray",
                            height: "50px"
                        }}
                    >
                        {name}
                    </TitleStyle>
                    {
                        orderStarted ? null :
                            <label htmlFor={id}>
                                <Input accept="image/*" id={id}
                                       onChange={(event) => uploadDocument(event, type)}
                                       multiple type="file" sx={{display: "none"}}/>
                                {
                                    hasProblems ?
                                        <Button variant="contained"
                                                id={id1}
                                                disabled={!documentsSavedDetails.reUpdatable || !uploadEnabled}
                                                fullWidth
                                                color={hasProblems ? "error" : "success"}
                                                component="span" sx={{marginTop: "10px"}}>
                                            {"Încarcă din nou"}
                                        </Button> : null
                                }
                            </label>
                    }
                </CardContent>
            </Card>
        </Grid>
    );
}
