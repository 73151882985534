import {merge} from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {Box, Card, CardHeader} from '@mui/material';
//
import {BaseOptionChart} from '../../charts';

// ----------------------------------------------------------------------



export default function AppStatsEvolution({title, detailsSuffix = "", labels = [], data = []}) {
    const CHART_DATA = [
        {
            name: 'Stripe',
            type: 'area',
            data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
        },
        {
            name: 'Bank Transfer',
            type: 'area',
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
        },
        {
            name: 'Total',
            type: 'line',
            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
        },
        // {
        //     name: 'Potential',
        //     type: 'line',
        //     data: [30, 75, 36, 36, 45, 35, 64, 52, 59, 36, 39]
        // }
    ];

    data.forEach((ar, index) => CHART_DATA[index].data = ar);
    const chartOptions = merge(BaseOptionChart(), {
        stroke: {width: [2, 2, 4]},
        plotOptions: {bar: {columnWidth: '11%', borderRadius: 4}},
        fill: {type: ['gradient', 'solid', 'solid']},
        labels: labels,
        xaxis: {type: 'datetime'},
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} ${detailsSuffix}`;
                    }
                    return y;
                }
            }
        }
    });

    return (
        <Card>
            <CardHeader title={title}/>
            <Box sx={{p: 3, pb: 1}} dir="ltr">
                <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364}/>
            </Box>
        </Card>
    );
}
