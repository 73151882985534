// material
import {Box, Card, CardContent, CardHeader, Typography} from '@mui/material';
// utils
import TextRowsTimeline from "./TextRowsTimeline";
import TextRowsTimelineV2 from "./TextRowsTimelineV2";

let getSubtitle = (line, noSuppSpaces) => {
    return noSuppSpaces ? <Typography variant="body2" sx={{opacity: 0.72, marginBottom: "10px"}}>
            {line}
        </Typography> :
        <Typography variant="body2" sx={{opacity: 0.72, marginBottom: "10px"}}>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {line}
        </Typography>
}

export default function PageWithTextCard({document}) {
    let ROWS = document.ROWS;
    let noSuppSpaces = document.NO_SUPP_SPACES;
    let rowFontType = document.ROW_FONT_TYPE;
    let titleFontType = document.TITLE_FONT_TYPE;
    return (
        <Card
            sx={{
                '& .MuiTimelineItem-missingOppositeContent:before': {
                    display: 'none'
                }
            }}
        >
            <CardHeader title={document.PAGE_TITLE}/>
            <Box sx={{margin: "10px 25px 0px 25px"}}>
                {
                    document.DESCRIPTION.map(line => getSubtitle(line, noSuppSpaces))
                }
            </Box>
            <CardContent>
                <TextRowsTimelineV2 ROWS={ROWS} noSuppSpaces={noSuppSpaces}
                                  rowFontType={rowFontType} titleFontType={titleFontType}/>
            </CardContent>
        </Card>
    );
}
