import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/Cazier-judiciar-online-Cluj-2.jpg"
import Foto_3 from "./images/Cazier-judiciar-online-Cluj-3.jpg"
import Foto_4 from "./images/Cazier-judiciar-online-Cluj-4.jpg"
import Foto_5 from "./images/Cazier-judiciar-online-Cluj-5.jpg"
import Foto_6 from "./images/Cazier-judiciar-online-Cluj-6.jpg"
import Foto_cta from "./images/Cazier-judiciar-online-Cluj-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageCluj({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier online Cluj",
        "cazier Cluj online",
        "obtinere cazier judiciar online Cluj",
        "eliberare cazire judiciar online Cluj"
    ]

    return (
        <Page title={`Cazier Judiciar Online Cluj`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description"
                      content={"Sunteți din Cluj și aveți nevoie de cazierul judiciar, dar timpul și distanța sunt o problemă? Echipa noastră vă vine în ajutor, oferindu-vă posibilitatea de a obține documentul de care aveți nevoie cu un proces simplificat și eficient."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Cluj - Eficiența obținerii unui cazier la îndemâna dumneavoastră</title>

            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Cluj</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Eficiența obținerii unui cazier, la
                                îndemâna
                                dumneavoastră</p>
                            <p>Sunteți din Cluj și aveți nevoie de cazierul judiciar, dar timpul și distanța sunt o
                                problemă? Echipa noastră vă vine în ajutor, oferindu-vă posibilitatea de a obține
                                documentul de care aveți nevoie cu un proces simplificat și eficient. Grație serviciilor
                                noastre digitale, puteți solicita și primi documentul necesar fără a fi nevoie să vă
                                deplasați. Transformăm ceea ce părea a fi o corvoadă într-un demers rapid și ușor,
                                ajutându-vă să economisiți timp și energie.</p>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Vă ajutăm să obțineți un cazier judiciar
                                online,
                                simplu și rapid!</p>
                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>

                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}
                        ></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Optați pentru un cazier judiciar online în Cluj!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Când e nevoie de un cazier online</h2>
                            <p>Cazierul online pentru cei din Cluj este adesea o condiție prealabilă pentru angajare,
                                studii sau alte proceduri oficiale. Conștientizând această cerință, serviciile noastre
                                vă asigură că puteți accesa acest document vital în orice moment, direct de acasă. </p>

                            <p><strong>Când aveți nevoie de cazier judiciar online:</strong></p>
                            <ul className="checkmarkList">
                                <li><strong>Pentru angajare:</strong> Multe companii solicită cazierul judiciar ca parte
                                    a verificării de fond a potențialului angajat, asigurându-se că acesta nu are
                                    antecedente penale care să contravină cu natura muncii sau cu politicile companiei.
                                </li>
                                <li><strong>Admiterea în instituții de învățământ:</strong> Universitățile și alte
                                    instituții educaționale pot cere prezentarea cazierului judiciar în procesul de
                                    admitere, pentru a confirma că viitorii studenți nu au săvârșit infracțiuni care
                                    i-ar putea exclude din cadrul educațional.
                                </li>
                                <li>
                                    <strong>Solicitarea cetățeniei sau a vizelor:</strong> Când aplicați pentru
                                    cetățenia unei alte țări sau pentru obținerea unei vize de rezidență, este adesea
                                    necesar să furnizați un cazier judiciar curat ca parte a documentației necesare.
                                </li>
                                <li><strong>Înregistrarea în profesii reglementate:</strong> Unele profesii, cum ar fi
                                    practica medicală sau avocatura, pot necesita un cazier judiciar în procesul de
                                    licențiere sau de înregistrare, pentru a asigura integritatea profesională.
                                </li>
                                <li><strong>Afaceri:</strong> În cazul în care doriți să demarați o afacere sau să
                                    accedeți la anumite tipuri de finanțare, poate fi necesar să demonstrați că nu aveți
                                    antecedente penale care ar putea afecta credibilitatea afacerii.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Optați pentru un cazier judiciar online în Cluj!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Avantaje pentru obținerea unui cazier judiciar online în Cluj</h2>
                            <p>Obținerea unui cazier judiciar online pentru Cluj reprezintă o soluție modernă și
                                eficientă pentru cei care au nevoie de acest document esențial, fără a fi nevoie să
                                părăsească confortul propriei case. Acest serviciu oferă o modalitate accesibilă de a
                                rezolva o formalitate necesară în numeroase contexte. </p>

                            <p><strong>De ce tot mai mulți optează pentru cazier judiciar online:</strong></p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt={`Cazier judiciar online Cluj`} className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li><strong>Economisirea timpului:</strong> Nu este necesar să vă deplasați la sediul
                                    poliției sau la alte instituții, evitând astfel cozi lungi și așteptări
                                    interminabile.
                                </li>
                                <li><strong>Confort:</strong> Tot procesul se poate realiza de acasă, folosind un
                                    calculator sau un dispozitiv mobil conectat la internet.
                                </li>
                                <li><strong>Rapiditate în procesare: </strong>Cererile online pentru obținerea
                                    cazierului judiciar pentru Cluj sunt adesea procesate mai rapid decât cele depuse
                                    fizic, astfel că veți intra în posesia cazierului judiciar într-un timp scurt.
                                </li>
                                <li><strong>Accesibilitate non-stop:</strong> Platformele online pentru obținerea
                                    cazierului judiciar online pentru Cluj sunt disponibile 24/7, permițând depunerea
                                    cererii în orice moment al zilei sau al nopții.
                                </li>
                                <li><strong>Siguranță și confidențialitate:</strong> Datele personale sunt protejate
                                    prin protocoale de securitate avansate, astfel încât informațiile dumneavoastră să
                                    rămână confidențiale.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Optați pentru un cazier judiciar online în Cluj!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Cazier Cluj online - ce ne recomandă ca fiind partenerul potrivit pentru
                                dumneavoastră</h2>
                            <p>Obținerea cazierului judiciar poate fi o procedură complicată, dar cu ajutorul nostru,
                                procesul devine rapid și simplificat. Avem experiență în domeniu și suntem dedicați să
                                oferim un serviciu eficient de obținere cazier pentru Cluj, online. Suntem partenerul
                                ideal pentru dumneavoastră pentru că:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt={`Cazier judiciar online Cluj`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li>Înțelegem că timpul dumneavoastră este prețios și, din acest motiv, ne angajăm să
                                    oferim soluții rapide și eficiente pentru obținerea cazierului judiciar online
                                    pentru Cluj.
                                </li>
                                <li>Fiecare client are nevoi specifice, și noi înțelegem acest lucru. Oferim suport
                                    personalizat și suntem întotdeauna pregătiți să răspundem la întrebările
                                    dumneavoastră, să vă ghidăm prin proces și să vă oferim consultanță adaptate
                                    cerințelor personale.
                                </li>
                                <li>Toate tranzacțiile și transferurile de documente necesare pentru a obține un cazier
                                    online pentru locuitorii din Cluj se efectuează prin intermediul unor platforme
                                    securizate, astfel încât toate informațiile dumneavoastră să rămână confidențiale.
                                </li>
                                <li>Oferim un serviciu transparent, fără costuri ascunse sau surprize neplăcute.</li>
                                <li>În urma colaborării cu noi, veți primi documentele solicitate într-o manieră promptă
                                    și precisă.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{pt: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Acte necesare pentru eliberare cazier judiciar online în Cluj </h2>
                            <p>Pentru a începe procesul de eliberare a cazierului judiciar online pentru județul Cluj,
                                sunt necesare următoarele documente:</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ul className="checkmarkList">
                                <li><strong>O copie scanată a actului de identitate: </strong>Pentru a vă identifica și
                                    a verifica eligibilitatea pentru eliberarea cazierului judiciar online pentru Cluj,
                                    este necesar să furnizați o copie clară a unui document de identificare valid.
                                    Aceasta poate fi cartea de identitate sau pașaportul, în cazul cetățenilor români
                                    din străinătate.
                                </li>
                                <li><strong>O fotografie recentă, tip selfie, ținând în mână actul de
                                    identitate:</strong> Această fotografie servește ca un nivel suplimentar de
                                    verificare a identității solicitantului.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Optați pentru un cazier judiciar online în Cluj!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Etapele obținerii cazierului online în Cluj</h2>
                            <p>Pentru a obține cazierul judiciar online pentru Cluj, urmați acești pași simpli:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt={`Cazier judiciar online Cluj`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li>Completați formularul de solicitare online</li>
                                <li>Încărcați documentele necesare</li>
                                <li>Semnați contractul digital</li>
                                <li>Efectuați plata serviciului</li>
                            </ul>
                            <p>După pregătirea și trimiterea acestor documente prin intermediul platformei online,
                                solicitarea dvs. va fi procesată de către echipa noastră. Aceasta se va ocupa de toate
                                procedurile necesare pentru eliberarea cazierului online pentru Cluj. </p>
                            <p>Veți fi informat cu privire la statusul aplicației dumneavoastră prin intermediul
                                notificărilor prin e-mail sau SMS. În cele din urmă, cazierul judiciar va fi trimis în
                                format electronic pentru verificați imediate, urmând ca originalul să fie livrat prin
                                curierat rapid la adresa specificată de dumneavoastră.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt={`Cazier judiciar online Cluj`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Preț cazier judiciar online Cluj</h2>
                            <p>Prețul pentru cazierul judiciar online pentru Cluj este competitiv și reflectă calitatea
                                și eficiența serviciilor pe care le oferim. Detaliile complete despre costurile
                                serviciilor noastre sunt disponibile pe site, permițând clienților să facă o evaluare
                                precisă a investiției necesare pentru obținerea unui cazier judiciar online fără
                                complicații.</p>
                            <p> În plus, oferim transparență totală în structura de costuri, astfel încât clienții să
                                poată înțelege exact pentru ce plătesc și să nu existe taxe ascunse sau neașteptate la
                                finalul procesului.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Optați pentru un cazier judiciar online în Cluj!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de obținerea unui cazier judiciar online în Cluj! Contactați-ne! </h2>
                            <p>Pentru mai multe informații și detalii despre cum să obțineți cazierul judiciar online
                                din Cluj, nu ezitați să ne contactați la adresa <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro </a>. Echipa noastră de
                                profesioniști este gata să vă asiste în întregul proces, garantând o experiență fără
                                stres și eficientă.</p>
                            <p>Alegeți serviciile noastre pentru obținerea cazierului online în Cluj și beneficiați de
                                un partener de încredere care pune accent pe rapiditate, eficiență, suport personalizat,
                                securitate de top și transparență totală! Vom face întregul proces cât mai simplu și mai
                                facil pentru dumneavoastră, asigurându-ne că obțineți în cel mai scurt timp documentele
                                dorite!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
