import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {UserListHead} from '../components/_dashboard/user';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";


export default function Prices({isCountryPrice}) {
    const [objectsRetreived, setObjectsRetreived] = useState(false);
    const [objectInitialState, setObjectInitialState] = useState("");
    const [entityList, setEntityList] = useState([]);

    let TABLE_HEAD = [];
    if (isCountryPrice === true) {
        TABLE_HEAD = [
            {id: 'id', label: 'Id', alignRight: false},
            {id: 'priceOf', label: 'Price of', alignRight: false},
            {id: 'price', label: 'Valoare', alignRight: false},
            {id: 'urgentPrice', label: 'Valoare Urgenta', alignRight: false}
        ];
    } else {
        TABLE_HEAD = [
            {id: 'id', label: 'Id', alignRight: false},
            {id: 'priceOf', label: 'Price of', alignRight: false},
            {id: 'price', label: 'Valoare', alignRight: false},
        ];
    }

    const getEntities = () => {
        CommunicationService.getAllPrices(isCountryPrice)
            .then((response) => {
                setEntityList(response);
                setObjectInitialState(JSON.stringify(response));
                setObjectsRetreived(true);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const update = () => {
        if (JSON.stringify(entityList) == objectInitialState) {
            return LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.NO_MODIFICATIONS);
        }
        let valuesToSend = entityList.map(entity => {
            entity.price = Number(entity.price);
            entity.urgentPrice = Number(entity.urgentPrice);
            return entity;
        })
        CommunicationService.updatePriceBulk(valuesToSend, isCountryPrice)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.SAVE_SUCCES);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const changePrice = (id, value) => {
        let updatedEntityIndex = entityList.findIndex(en => en.id === id);
        let copy = JSON.parse(JSON.stringify(entityList));
        copy[updatedEntityIndex].price = value;
        setEntityList(copy);
    }
    const changeUrgentPrice = (id, value) => {
        let updatedEntityIndex = entityList.findIndex(en => en.id === id);
        let copy = JSON.parse(JSON.stringify(entityList));
        copy[updatedEntityIndex].urgentPrice = value;
        setEntityList(copy);
    }

    const [count, setCount] = React.useState(1);

    useEffect(() => {
        getEntities();
    }, []);


    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {isCountryPrice ? "Translation languages prices" : "Prices"}
                    </Typography>
                    <Button variant="contained"
                            onClick={update}>
                        Save
                    </Button>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table size="small">
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                <TableBody>
                                    {entityList
                                        .map((row) => {
                                            let {id, priceOf, price, urgentPrice} = row;
                                            let color = priceOf.startsWith('CERT') ? 'rgba(0, 0, 255, 0.1)' : 'white';
                                            if (priceOf.startsWith('AUTO')) {
                                                color = 'rgba(255, 0, 0, 0.1)';
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    sx={{
                                                        padding: "0px", margin: "0px",
                                                        background: color
                                                    }}
                                                >
                                                    <TableCell padding="checkbox">
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="checkbox">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {id}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{priceOf}</TableCell>
                                                    <TableCell align="left">
                                                        <TextField
                                                            id="outlined-number"
                                                            type="number"
                                                            sx={{padding: "0px", margin: "0px"}}
                                                            value={price}
                                                            onChange={(event) => changePrice(id, event.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            size="small"
                                                        />
                                                    </TableCell>
                                                    {
                                                        !isCountryPrice ? null :
                                                            <TableCell align="left">
                                                                <TextField
                                                                    id="outlined-number"
                                                                    type="number"
                                                                    sx={{padding: "0px", margin: "0px"}}
                                                                    value={urgentPrice}
                                                                    onChange={(event) => changeUrgentPrice(id, event.target.value)}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
