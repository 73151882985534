// material
import {styled} from '@mui/material/styles';
import {Box, Link, Typography} from '@mui/material';
// utils
import * as React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        height: "100%",
        textAlign: 'left',
        padding: theme.spacing(0, 0),
        color: theme.palette.primary.darker,
    }
}));

const LinkBoxStyle = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // marginLeft: 0,
    [theme.breakpoints.up('lg')]: {
        display: "block",
    }
}));

export default function FooterInfoTwo({title, text, photo}) {

    let margin = "5px 5px 0px 5px";
    return (
        <RootStyle>
            <Typography variant="h5" sx={{marginBottom: "5px"}}>
                Linkuri utile
            </Typography>

            <LinkBoxStyle>
                <Box sx={{display: 'flex', margin: margin}}>
                    <Link href="https://www.baroul-bucuresti.ro/" variant="subtitle1">
                        {'Barou'}
                    </Link>
                </Box>

                <Box sx={{display: 'flex', margin: margin}}>
                    <Link href="https://politiaromana.ro/" variant="subtitle1">
                        {'Politia Română'}
                    </Link>
                </Box>

                <Box sx={{display: 'flex', margin: margin}}>
                    <Link href="https://justitia-romana.org/" variant="subtitle1">
                        {'Parchet'}
                    </Link>
                </Box>

                <Box sx={{display: 'flex', margin: margin}}>
                    <Link href="https://www.diicot.ro/" variant="subtitle1">
                        {'DIICOT'}
                    </Link>
                </Box>

                <Box sx={{display: 'flex', margin: margin}}>
                    <Link href="http://www.anpc.gov.ro/" variant="subtitle1">
                        {'ANPC'}
                    </Link>
                </Box>
            </LinkBoxStyle>
        </RootStyle>
    );
}
