// material
import {styled} from '@mui/material/styles';
import {Button, Grid, Stack, TextField, Toolbar} from '@mui/material';
import * as React from "react";
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ConfirmModal from "./ConfirmModal";
import {DesktopDatePicker} from "@mui/lab";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({theme}) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 1, 1, 1),
    border: "1px solid rgba(0,128,0, 0.6)",
    borderRadius: '16px'
}));

export default function ReportToolbar({isLawyer, handleReportClick, handleAWBClick}) {

    const [startValue, setStartValue] = React.useState(null);
    const [endValue, setEndValue] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [content, setContent] = React.useState("");
    const [fun, setFun] = React.useState(() => {
    });
    const [hideConfirm, setHideConfirm] = React.useState(false);

    let contentTypes = {
        AWB: {
            textAllGood: "Vor fi generate AWB-uri pentru toate orderele eligibile. Această operațiune poate dura până la 30 de secunde.",
            textSemiGood: "Vor fi generate AWB-uri pentru toate orderele eligibile din perioada selectata. Această operațiune poate dura până la 30 de secunde.",
            textAllBad: "Nu se pot genera AWB-uri pentru intervalul dat.",
            function: () => handleAWBClick
        },
        REPORT: {
            textAllGood: "Un raport va fi generat pentru toate orderele pentru care nu ați generat deja un raport. Această operațiune poate dura până la 30 de secunde.",
            textSemiGood: "Un raport va fi generat pentru toate orderele între datele specificate având în vedere și filtrele specificate deja. Această operațiune poate dura până la 30 de secunde.",
            textAllBad: "Nu se poate genera un raport pentru intervalul dat.",
            function: () => handleReportClick
        }
    }

    const computeContent = (contentType) => {
        setFun(contentType.function);
        let currentDate = new Date();
        let text = "";
        if (startValue === null && endValue == null) {
            text = contentType.textAllGood;
            setHideConfirm(false);
        } else if ((startValue === null && endValue < currentDate) || (endValue === null && startValue > currentDate)) {
            text = contentType.textAllBad;
            setHideConfirm(true);
        } else {
            text = contentType.textSemiGood;
            setHideConfirm(false);
        }
        setContent(text);
    }


    return (
        <RootStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ConfirmModal open={modalOpen}
                              handleConfirm={() => {
                                  setModalOpen(false);
                                  fun(startValue, endValue)
                              }}
                              handleClose={() => setModalOpen(false)}
                              title={"Confirmați acțiunea!"}
                              hideConfirm={hideConfirm}
                              content={content}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={10}>
                        <Stack direction="row" alignItems="right" spacing={2}>
                            <DesktopDatePicker
                                label="Start Date"
                                value={startValue}
                                onChange={setStartValue}
                                ampm={false}
                                ampmInClock={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="End Date"
                                value={endValue}
                                onChange={setEndValue}
                                ampm={false}
                                ampmInClock={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Stack direction="row" alignItems="right" spacing={2}>
                            <Button variant="contained"
                                    onClick={() => {
                                        computeContent(contentTypes.REPORT);
                                        setModalOpen(true)
                                    }}
                            >Generare Raport</Button>
                            {
                                isLawyer ? null : <Button variant="contained"
                                                          onClick={() => {
                                                              computeContent(contentTypes.AWB);
                                                              setModalOpen(true)
                                                          }}
                                >Generare AWB-uri</Button>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </RootStyle>
    );
}
