import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Button, Grid, InputAdornment, Stack} from "@mui/material";
import * as Languages from "../../../constants/Languages";
import SearchIcon from "@mui/icons-material/Search";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import AppInfoOne from "./AppInfoOne";
import Constants from "../../../Constants";
import AppInfoTwo from "./AppInfoTwo";
import AppInfoThree from "./AppInfoThree";

export default function AnafSearchFields({getFieldProps, setFieldValue, touched, errors, anafSearchedCompany, setAnafSearchedCompany,
                                             manualInput, setManualInput}) {

    const [searchCount, setSearchCount] = React.useState(0);
    const [disabledButton, setDisabledButton] = React.useState(false);

    const disableTemporarily = (callback) => {
        setDisabledButton(true);
        callback(true);
        setTimeout(() => {
            setDisabledButton(false);
        }, 3000);
    }

    const searchCompanyInAnafDB = () => {
        setSearchCount(searchCount + 1);
        disableTemporarily(()=>{
            if (searchCount == 0) {
                setManualInput(true)
            }
        })
        CommunicationService.getAnafCompany(parseInt(getFieldProps('cui').value))
            .then((response) => {
                setAnafSearchedCompany(response.denumire);
                setFieldValue('firstName', response.denumire)
                setManualInput(false)
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ANAF_CUI_NOT_FOUND)
            });

    }
    // console.log(getFieldProps('cui').value, typeof getFieldProps('cui').value, getFieldProps('cui').value.length)
    return (
        <Stack direction={{xs: 'column', sm: 'column'}} spacing={2}>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={10}>
                    <TextField
                        fullWidth
                        label="CUI"
                        {...getFieldProps('cui')}
                        error={Boolean(touched.cui && errors.cui)}
                        helperText={touched.cui && errors.cui}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Button variant="outlined" startIcon={<SearchIcon/>}
                            sx={{height: '100%', maxHeight: '56px', width: '100%'}}
                            disabled={getFieldProps('cui').value < 100 || disabledButton}
                            onClick={searchCompanyInAnafDB}
                    >
                        Caută
                    </Button>
                </Grid>
            </Grid>
            {
                anafSearchedCompany != null || manualInput == true || getFieldProps('firstName').value.length > 0?
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                        <TextField
                            fullWidth
                            label="Nume Firma"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            // disabled={!manualInput}
                        />
                    </Stack> : null
            }
        </Stack>
    );
}

