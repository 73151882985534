import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
import {DOCUMENTS_DETAILS} from '../../../services/LogicService'
// material
import {Box, Button, Grid, Stack, Typography} from '@mui/material';
import SignaturePad from "signature_pad";
import {isMobile} from "react-device-detect";
import * as AlertMapper from "../../../services/AlertMapper";
// ----------------------------------------------------------------------


export default function OrderSignContract({nrInreg, setAnexNumberBorderColor, translate}) {
    const navigate = useNavigate();
    const ANEXA_38_NUMBER_AND_SIGNATURE_INPUT = LogicService.getFeatureSwitch('ANEXA_38_NUMBER_AND_SIGNATURE_INPUT')?.value;
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 10000);
    }
    let order = LogicService.getOrder();
    let extrasToBeShown = ANEXA_38_NUMBER_AND_SIGNATURE_INPUT === true && order?.userType == "business";

    const myContainer = useRef(null);
    const [finalizeDisabled, setFinalizeDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    let signaturePad = null;

    useEffect(() => {
        signaturePad = new SignaturePad(myContainer.current);
    })

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    let signContract = () => {
        let errorMessage = null;
         if (extrasToBeShown && (nrInreg == undefined || nrInreg == null || nrInreg.length === 0)) {
            errorMessage = (translate ? "You must fill in the registration number!" : "Trebuie să completați numărul de înregistrare!");
            setAnexNumberBorderColor("1px solid red")
        } else if (extrasToBeShown && !isNumeric(nrInreg)) {
            errorMessage = (translate ? "The registration number cannot contain characters other than numbers" : "Numărul de înregistrare nu poate conține alte caractere în afară de numere");
            setAnexNumberBorderColor("1px solid red")
        } else if (signaturePad.isEmpty()) {
             errorMessage = (translate ? "You must sign the document before proceeding!" : "Trebuie să semnați documentul înainte de a continua!");
         }

        if (errorMessage != null) {
            setErrorMessage(errorMessage);
            setFinalizeDisabled(true);
            setTimeout(() => {
                setErrorMessage(null);
                setFinalizeDisabled(false);
            }, 3000)
            return;
        }

        setFinalizeDisabled(true);
        let docKey = DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key;
        let ceva = signaturePad.toDataURL("image/png");
        fetch(ceva)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "signature_for_order_" + order.id + ".png", {type: "image/png"})
                disableTemporarily();

                function addDocument() {
                    CommunicationService.addDocumentToOrder(order.id, docKey, file)
                        .then((response) => {
                            LogicService.addUploadedDocuments({key: docKey, uploaded: true});
                            LogicService.updateOrderState('FISCAL')
                            navigate(translate ? '/en' : '/');
                            setFinalizeDisabled(false);
                        })
                        .catch((error) => {
                            LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
                            setFinalizeDisabled(false);
                        });
                }

                if (extrasToBeShown) {
                    CommunicationService.addRegistrationNumber(order.id, nrInreg)
                        .then((response) => addDocument())
                        .catch((response) => addDocument());
                } else {
                    addDocument();
                }
            })
    }

    let eraseBoard = () => {
        signaturePad.clear();
    }

    let canvasWidth = 500;
    let wordForPointer = translate ? "the cursor" : "cursorul";
    if (isMobile) {
        canvasWidth = 250;
        wordForPointer = translate ? "the finger" : "degetul";
    }

    return (
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="body2" gutterBottom>
                            {translate ? "Draw with" : "Desenează cu"} {wordForPointer} {translate ? "your signature in the box below" : "semnătura ta în chenarul de mai jos"}
                        </Typography>
                        <canvas ref={myContainer} width={canvasWidth} height="300"
                                style={{border: "1px solid green", borderRadius: "2%"}}/>

                        <Box sx={{textAlign: 'center'}}>
                            <Button size="small" variant="contained" color={'secondary'} onClick={eraseBoard}>
                                {translate ? "Reset signature" : "Resetează semnătură"}
                            </Button>
                        </Box>

                        <Typography variant="body2" color={"red"} gutterBottom>
                            {errorMessage}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'primary'}
                    disabled={!buttonEnabled || finalizeDisabled}
                    onClick={signContract}
                >
                    {translate ? "Finish" : "Finalizare"}
                </Button>
            </Stack>
        </Box>
    );
}
