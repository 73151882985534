import * as React from 'react';
import {useEffect, useState} from 'react';

import {Button, Card, Container, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AddCompanyModal from "../components/_dashboard/user/AddCompanyModal";
import {deleteCompany} from "../services/CommunicationService";
import ConfirmOrRejectModal from "../components/_dashboard/user/ConfirmOrRejectModal";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/ContentCopy";

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'type', label: 'Tip', alignRight: false},
    {id: 'value', label: 'Valoare', alignRight: false},
];

export default function Revenue() {
    const [refreshValue, setRefreshValue] = useState(1);
    const [deleteCompanyModalIsOpen, setDeleteCompanyModalIsOpen] = useState(false);
    const [companies, setCompanies] = useState([]);

    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);


    useEffect(() => {
        CommunicationService.getCompanies()
            .then((response) => {
                setCompanies(response);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }, []);


    let years = [2022, 2023, 2024];
    let months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY",
        "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();

    const [year, setYear] = React.useState(currentYear);
    const [month, setMonth] = React.useState(months[currentMonth]);

    const handleYearChange = (event, newVal) => {
        setYear(newVal);
    };

    const handleMonthChange = (event, newVal) => {
        setMonth(newVal);
    };

    const addCompany = (company) => {
        CommunicationService.addCompany(company)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CATEGORY_ADDED);
                let categAux = companies;
                categAux.push(response);
                setCompanies(categAux);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const makeCompanyDefault = (companyId) => {
        CommunicationService.updateDefault(6, companyId)
            .then((response) => {
                let companiesAux = companies;
                companiesAux.map(c => {
                    return {
                        ...c,
                        inUse: false
                    }
                })
                let inUseCompany = companiesAux.findIndex(c => c.id == companyId);
                companiesAux[inUseCompany].inUse = true;
                setCompanies(companiesAux)
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.SAVE_SUCCES);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const deleteCompanyById = (companyId) => {
        CommunicationService.deleteCompany(companyId)
            .then((response) => {
                let companiesAux = companies;
                let inUseCompany = companiesAux.findIndex(c => c.id == companyId);
                companiesAux.splice(inUseCompany, 1);
                setCompanies(companiesAux)
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.SAVE_SUCCES);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container sx={{maxWidth: "2000px !important"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Companies
                    </Typography>
                </Stack>

                <Card sx={{padding: "30px", marginBottom: "20px", textAlign: "left"}}>
                    <Scrollbar>
                        <Stack spacing={1} alignItems="left">
                            <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                   flexWrap={"wrap"} justifyContent={"space-between"} spacing={3}>
                                <AddCompanyModal open={addCategoryModalOpen}
                                                 handleConfirm={(text) => {
                                                     addCompany(text);
                                                     setAddCategoryModalOpen(false);
                                                 }}
                                                 handleClose={() => setAddCategoryModalOpen(false)}
                                                 defaultCompany={companies[0]}

                                />
                                <Button variant="contained" color={'warning'}
                                        onClick={() => setAddCategoryModalOpen(true)}>
                                    {"Add Company"}
                                </Button>
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                   flexWrap={"wrap"} justifyContent={"start"} spacing={13}
                                    sx={{columnGap: '10px', rowGap: '10px'}}
                            >
                                {
                                    companies.map(company => {
                                        let color = company.inUse === true ? "green" : "red"
                                        return <Stack spacing={1} sx={{
                                            border: "3px solid " + color,
                                            borderRadius: "10px",
                                            padding: "10px",
                                            marginLeft: "0 !important"
                                        }}>
                                            <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                                   flexWrap={"wrap"} justifyContent={"space-between"} spacing={3}>
                                                <Typography variant="h6" sx={{marginTop: "5px"}}>
                                                    {company.id}: {company.name}
                                                </Typography>
                                                <ConfirmOrRejectModal open={deleteCompanyModalIsOpen}
                                                                      handleClose={() => {
                                                                          setDeleteCompanyModalIsOpen(false);
                                                                      }}
                                                                      handleConfirm={() => {
                                                                          deleteCompanyById(company.id)
                                                                          setDeleteCompanyModalIsOpen(false);
                                                                      }}
                                                                      title={"Confirmați stergerea companiei?"}
                                                                      content={"Dupa apasarea butonului de refund, compania va fi stearsa definitiv."}
                                                />
                                                <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                                       flexWrap={"wrap"} justifyContent={"flex-end"} spacing={0}>
                                                    <AddCompanyModal open={addCategoryModalOpen}
                                                                     handleConfirm={(text) => {
                                                                         addCompany(text);
                                                                         setAddCategoryModalOpen(false);
                                                                     }}
                                                                     handleClose={() => setAddCategoryModalOpen(false)}
                                                                     defaultCompany={companies[0]}

                                                    />
                                                    <IconButton variant="contained" color={'info'}
                                                                onClick={() => setAddCategoryModalOpen(true)}
                                                    >
                                                        <CopyIcon/>
                                                    </IconButton>
                                                    <IconButton variant="contained" color={'error'} disabled={company.inUse === true}
                                                                onClick={() => setDeleteCompanyModalIsOpen(true)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Stack>

                                            </Stack>
                                            <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                RO: {company.ro} | Reg. com.: {company.rc} | TVA: {company.tvaEligible.toString()}
                                            </Typography>
                                            <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                Adresa: {company.address} | Jud: {company.jud}
                                            </Typography>
                                            <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                Banca: {company.bank} | IBAN: {company.iban}
                                            </Typography>
                                            <Button variant="outlined" color={'info'} disabled={company.inUse === true}
                                                    onClick={() => makeCompanyDefault(company.id)}>
                                                {"Set in use"}
                                            </Button>
                                        </Stack>
                                    })
                                }
                            </Stack>
                        </Stack>
                    </Scrollbar>
                </Card>

                {/*<Card sx={{padding: "30px", textAlign: "center"}}>*/}
                {/*    <Scrollbar>*/}
                {/*        <Stack spacing={1} alignItems="center">*/}
                {/*            <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">*/}
                {/*                <ToggleButtonGroup*/}
                {/*                    value={year} exclusive*/}
                {/*                    onChange={handleYearChange}*/}
                {/*                    aria-label="year-selection"*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        years.map(year => <ToggleButton*/}
                {/*                            value={year}*/}
                {/*                            disabled={year > currentYear}*/}
                {/*                        >{year}</ToggleButton>)*/}
                {/*                    }*/}
                {/*                </ToggleButtonGroup>*/}
                {/*            </Stack>*/}
                {/*            <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">*/}
                {/*                <ToggleButtonGroup*/}
                {/*                    value={month} exclusive*/}
                {/*                    onChange={handleMonthChange}*/}
                {/*                    aria-label="month-selection"*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        months.map(val => <ToggleButton*/}
                {/*                            value={val}*/}
                {/*                            disabled={months.indexOf(val) > currentMonth + 1}*/}
                {/*                        >{val}</ToggleButton>)*/}
                {/*                    }*/}
                {/*                </ToggleButtonGroup>*/}
                {/*            </Stack>*/}

                {/*            /!*<Stack direction={{xs: 'column', sm: 'row'}} alignItems="center" display={"flex"}*!/*/}
                {/*            /!*       flexWrap={"wrap"} >*!/*/}
                {/*            /!*    {*!/*/}
                {/*            /!*        categories.map(val => <DriveCategory*!/*/}
                {/*            /!*            refreshValue={refreshValue}*!/*/}
                {/*            /!*            category={val}*!/*/}
                {/*            /!*            year={year}*!/*/}
                {/*            /!*            month={months.indexOf(month)}*!/*/}
                {/*            /!*            documents={getDocumentsByCategoryAndSelections(val)}*!/*/}
                {/*            /!*            addDocument={(doc) => addDocument(val, doc)}*!/*/}
                {/*            /!*            deleteDocument={(docId) => deleteDocument(val, docId)}*!/*/}
                {/*            /!*        >{val.name}</DriveCategory>)*!/*/}
                {/*            /!*    }*!/*/}
                {/*            /!*</Stack>*!/*/}
                {/*        </Stack>*/}

                {/*    </Scrollbar>*/}
                {/*</Card>*/}
            </Container>
        </Page>
    );
}
