import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {Alert, AlertTitle, Checkbox, FormControlLabel, MenuItem, Select, Switch} from "@mui/material";

export default function AddBannerModal({open, handleConfirm, handleClose}) {

    let [text, setText] = useState(null);
    let [title, setTitle] = useState(null);
    let [severity, setSeverity] = useState("info");

    const returnCompany = () => {
        handleConfirm({
            text: text,
            title: title,
            severity: severity
        });
    }

    let bannerTypes = ["info", "warning", "error", "success"]

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Adăugare banner informare"}
            </DialogTitle>
            <DialogContent>

                <TextField label={"Titlu"} variant="standard" value={title} multiline fullWidth rows={2} defaultValue="" sx={{marginBottom: "20px"}}
                           onChange={(event) => setTitle(event.target.value)}/>

                <TextField label={"Text"} variant="standard" value={text} multiline fullWidth rows={6} defaultValue="" sx={{marginBottom: "20px"}}
                           onChange={(event) => setText(event.target.value)}/>

                <Select
                    label={"few"}
                    value={severity}
                    onChange={(event) => setSeverity(event.target.value)}
                >
                    <MenuItem disabled value="">
                        <em>Severitate</em>
                    </MenuItem>
                    {bannerTypes.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            <Alert severity={name} sx={{width: "500px"}} variant="filled"
                            >
                                <AlertTitle>{"Titlul alertei"}</AlertTitle>
                                {"Textul alertei"}
                            </Alert>
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => returnCompany()} color={"success"}>
                    Adăugare
                </Button>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
