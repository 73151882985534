import {Icon} from '@iconify/react';
import plusFill from '@iconify/icons-eva/refresh-fill';
import {useNavigate} from 'react-router-dom';
// material
import {Button, Container, Grid, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';
//
import * as LogicService from "../services/LogicService";
import {DOCUMENTS_DETAILS, STATUSES} from "../services/LogicService";
import {useEffect, useState} from "react";
import OrderDetailsDocument from "../components/_dashboard/order-details/OrderDetailsDocument";
import * as CommunicationService from "../services/CommunicationService";
import OrderDetailsPostCard from "../components/_dashboard/order-details/OrderDetailsPostCard";
import * as AlertMapper from "../services/AlertMapper";

export default function OrderDetails() {
    const navigate = useNavigate();
    let defaultOrder = {documents: [], status: ''};
    let createdOrder = LogicService.getCreatedOrder() || defaultOrder;
    if (createdOrder == defaultOrder) {
        navigate('/');
    }

    const [refreshButtonEnabled, setRefreshButtonEnabled] = useState(false);

    useEffect(() => {
        if (createdOrder === undefined || createdOrder === null || createdOrder === defaultOrder) {
            navigate('/verificare-comanda');
        }

        let currentDate = new Date();
        let milisecondsDifference = Math.abs(currentDate - new Date(createdOrder.retrievedAt));
        milisecondsDifference += 4 * 1000 * 60;
        let minutesReference = 1000 * 60;

        let differenceBetweenDatesInMinutes =
            Math.ceil((milisecondsDifference) / minutesReference);
        if (differenceBetweenDatesInMinutes > 1) {
            setRefreshButtonEnabled(true);
        } else {
            setTimeout(() => {
                setRefreshButtonEnabled(true);
            }, (minutesReference - milisecondsDifference))
        }
    }, []);

    const refreshOrder = () => {
        setRefreshButtonEnabled(false);
        CommunicationService.getOrderById(createdOrder.id)
            .then((response) => {
                LogicService.setCreatedOrder(response);

                navigate('/detalii-comanda');
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }


    let documentsForDisplay = createdOrder.documents
        .filter((document) => !DOCUMENTS_DETAILS.isPoliceRecord(document.type) && !DOCUMENTS_DETAILS.isPoliceRecordTranslated(document.type));
    let gridWidth = 12 / documentsForDisplay.length;

    let existingStatus = STATUSES[createdOrder.status.toUpperCase()] || {};
    let orderStarted = existingStatus.priority > 5;

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Comanda mea de cazier
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={refreshOrder}
                        disabled={!refreshButtonEnabled}
                        // disabled={!refreshButtonEnabled || createdOrder.documents.length - 1 === documentsForDisplay.length}
                        startIcon={<Icon icon={plusFill}/>}
                    >
                        Actualizare
                    </Button>
                </Stack>

                <Grid container spacing={3}>
                    <OrderDetailsPostCard order={createdOrder}/>
                    {
                        documentsForDisplay
                            .map((document, index) => (
                                <OrderDetailsDocument refreshOrder={refreshOrder} orderId={createdOrder.id} key={index}
                                                      doc={document} index={index} gridWidth={gridWidth}
                                                      orderStarted={orderStarted}/>
                            ))
                    }
                </Grid>
            </Container>
        </Page>
    );
}
