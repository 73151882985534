import {Icon} from '@iconify/react';
// material
import {styled} from '@mui/material/styles';
import {Box, Button, Typography} from '@mui/material';
// utils
import * as React from "react";
import terms from "@iconify/icons-eva/file-text-outline";
import policy from "@iconify/icons-eva/book-open-outline";
import {useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({theme}) => ({
    marginLeft: 0,
    [theme.breakpoints.up('lg')]: {
        height: "100%",
        marginLeft: "80px",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 0),
        color: theme.palette.primary.darker,
    }
}));

export default function FooterInfoThree({title, text, photo}) {
    const navigate = useNavigate();

    return (
        <RootStyle>

            <Box sx={{display: 'flex', justifyContent: "center", marginTop: "30px"}}>
                <Box
                    component="img"
                    alt={"visa-mastercard"}
                    src={"/static/illustrations/mastercard.webp"}
                    sx={{
                        width: 197,
                        height: 58.75,
                        textAlign: 'center',
                        marginTop: '10px',
                        display: "flex",
                        justifyContent: "center"
                    }}
                    onDoubleClick={() => navigate("/admin-login")}
                />
            </Box>
            <Typography sx={{display: 'flex', justifyContent: "center", marginTop: "30px"}} variant="h6" gutterBottom>
                Copyright © by caziere.ro
            </Typography>
        </RootStyle>
    );
}
