import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Divider, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import {changeStatusForOrder, downloadInvoice} from "../../../services/CommunicationService";
import {STATUSES} from "../../../services/LogicService";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Drafts';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import {Icon} from "@iconify/react";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import {fDateTimeForBackend} from "../../../utils/formatTime";

export default function OrderMessageLogsModal({row, open, logs, handleClose}) {


    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Mesajele orderului #" + row.id}
            </DialogTitle>
            <DialogContent sx={{padding: "20px 5px"}}>
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {
                        logs.map(doc => {
                            return <Box>
                                <ListItem sx={{border: "1px solid transparent"}}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {

                                                doc.type === 'EMAIL' ? <EmailIcon/> : <SmsIcon/>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={doc.message}
                                                  secondary={
                                                      <React.Fragment>
                                                          <Typography
                                                              sx={{display: 'inline'}}
                                                              component="span"
                                                              variant="body2"
                                                              color="text.primary"
                                                          >
                                                              {doc.type}
                                                          </Typography>
                                                          {" — "}
                                                          <Typography
                                                              sx={{display: 'inline'}}
                                                              component="span"
                                                              variant="body2"
                                                              color="text.primary"
                                                          >
                                                              <strong> {doc.toWhom} </strong>
                                                          </Typography>
                                                          {" — " + fDateTimeForBackend(doc.createdAt)}
                                                      </React.Fragment>
                                                  }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                            </Box>
                        })
                    }
                </List>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}
