import React from "react";
import Cloud from '@mui/icons-material/Cloud';
import BalanceIcon from '@mui/icons-material/Balance';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CallSplitIcon from '@mui/icons-material/CallSplit';

let ICONS = {
    ONLINE_24_7: "/static/illustrations/24-hour-clock.png",
    DOCUMENTS: "/static/illustrations/documents.png",
    PC: "/static/illustrations/pc.png",
}

let currentPaths = [
    {path: 'cazier-judiciar-online-cluj',              },
    {path: 'cazier-judiciar-online-iasi',              },
    {path: 'cazier-judiciar-online-timisoara',         },
    {path: 'cazier-judiciar-online-arad',              },
    {path: 'cazier-judiciar-online-brasov',              },
    {path: 'cazier-judiciar-online-oradea',              },
    {path: 'cazier-judiciar-online-sibiu',              },
    {path: 'cazier-judiciar-online-constanta',              },
    {path: 'cazier-judiciar-online-bucuresti',         },
    {path: 'cazier-judiciar-online-craiova',           },
    {path: 'cazier-judiciar-online-pret',              },
    {path: 'cazier-judiciar-online-firma',             },
    {path: 'cazier-rutier-online',                     },
    {path: 'certificat-de-integritate-comportamentala',}
]

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export let MENUS = [
    {
        text: "Acasa",
        path: "/"
    },
    {
        text: "Cazier judiciar online",
        values: [
            ... currentPaths.map(p => {
                let name = capitalizeFirstLetter(
                    p.path
                        .replaceAll("-", " ")
                )
                if (name.includes(" judiciar online ")) {
                    let groups = name.split(" judiciar online ");
                    name = groups[0] + " online " + capitalizeFirstLetter(groups[1])
                }
                return {
                    text: name,
                    path: "/servicii/" + p.path,
                    icon: <ChevronRightIcon/>
                }
            })
        ]
    },
    {
        text: "",
        isIcon: true,
        showOn: '/en',
        icon: "/static/illustrations/ro_flag.png",
        path: "/"
    },
    {
        text: "",
        isIcon: true,
        showOn: '/',
        icon: "/static/illustrations/uk_flag.png",
        path: "/en"
    },
    // {
    //     text: "Specializări",
    //     values: [
    //         {
    //             text: "Drept Civil",
    //             path: "/",
    //             icon: <PersonIcon/>
    //         },
    //         {
    //             text: "Drept Comercial",
    //             path: "/products",
    //             icon: <StoreIcon/>
    //         },
    //         {
    //             text: "Drept Penal",
    //             path: "/blog",
    //             icon: <BalanceIcon/>
    //         }
    //     ]
    // },
    // {
    //     text: "Cazuri specifice",
    //     values: [
    //         {
    //             text: "Imobiliare",
    //             path: "/",
    //             icon: <ApartmentIcon/>
    //         },
    //         {
    //             text: "Partaj",
    //             path: "/",
    //             icon: <CallSplitIcon/>
    //         },
    //     ]
    // },
    // {
    //     text: "Contact",
    //     path: "/contact"
    // },
    // {
    //     text: "Admin",
    //     adminOnly: true,
    //     values: [
    //         {
    //             text: "Panel",
    //             path: "/admin",
    //             icon: <Cloud/>
    //         },
    //     ]
    // }
]

let toExport = {
    ICONS: ICONS,
    COLOURS: {
        primary: {
            light: "#42a5f5",
            main: "#1976d2",
            dark: "#1565c0"
        },
        secondary: {
            light: "#ba68c8",
            main: "#9c27b0",
            dark: "#7b1fa2"
        },
        error: {
            light: "#ef5350",
            main: "#d32f2f",
            dark: "#c62828"
        },
        warning: {
            light: "#ff9800",
            main: "#ed6c02",
            dark: "#e65100"
        },
        info: {
            light: "#03a9f4",
            main: "#0288d1",
            dark: "#01579b"
        },
        success: {
            light: "#4caf50",
            main: "#2e7d32",
            dark: "#1b5e20"
        },
    },
    TEXTS: {
        INFO: {
            ONE: {
                title: 'Procedură simplă',
                text: 'Completați informațiile de pe pagina și incarcati documentele necesare, iar procedura este demarata automat in functie de regimul ales.',
                photo: ICONS.ONLINE_24_7,
            },
            TWO: {
                title: 'Transmiteți documente necesare',
                text: 'Trimiteți-ne documentele scanate online. Noi ne ocupăm de toate procedurile de obtinere a unui certificat de cazier judiciar pentru toți cetățenii români dar si straini.',
                photo: ICONS.DOCUMENTS,
            },
            THREE: {
                title: 'Obtinere acte 100% online',
                text: 'Veți intra în posesia cazierului dvs scanat. Mai apoi îl veți primi în original prin curier rapid oriunde în lume, în cel mai scurt timp posibil.',
                photo: ICONS.PC,
            }
        }
    },
    DELIVERY_MANDATORY_ARRAY: [],
    APOSTILA_MANDATORY_ARRAY: [],
};
export default toExport;