import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {UserListHead} from '../components/_dashboard/user';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'type', label: 'Name', alignRight: false},
    {id: 'value', label: 'Valoare', alignRight: false},
];

export default function Defaults() {
    const [objectsRetreived, setObjectsRetreived] = useState(false);
    const [objectInitialState, setObjectInitialState] = useState("");
    const [entityList, setEntityList] = useState([]);

    const getEntities = () => {
        CommunicationService.getAllDefaults()
            .then((response) => {
                setEntityList(response);
                setObjectInitialState(JSON.stringify(response));
                setObjectsRetreived(true);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const update = () => {
        if (JSON.stringify(entityList) == objectInitialState) {
            return LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.NO_MODIFICATIONS);
        }
        CommunicationService.updateDefaultBulk(entityList)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.SAVE_SUCCES);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const changePrice = (id, value) => {
        let updatedEntityIndex = entityList.findIndex(en => en.id === id);
        let copy = JSON.parse(JSON.stringify(entityList));
        copy[updatedEntityIndex].value = value;
        setEntityList(copy);
    }

    useEffect(() => {
            getEntities();
    }, []);


    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Defaults
                    </Typography>
                    <Button variant="contained"
                            onClick={update}>
                        Save
                    </Button>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                <TableBody>
                                    {entityList
                                        .map((row) => {
                                            let {id, type, value} = row;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                >
                                                    <TableCell padding="checkbox">
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="checkbox">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {id}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{type}</TableCell>
                                                    <TableCell align="left">
                                                        <TextField
                                                            disabled={type === "CURRENT_INVOICE_COMPANY"}
                                                            id="outlined-number"
                                                            type="text"
                                                            value={value}
                                                            onChange={(event) => changePrice(id, event.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>

                        </TableContainer>

                    </Scrollbar>


                </Card>
            </Container>
        </Page>
    );
}
