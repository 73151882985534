import React from 'react';

export default function PdfViewerComponent() {

    let docURL = document.location.origin + "/Contract.pdf";
    return (
        <iframe
            title={"Contract PDF"}
            src='/web/viewer.html?file=/Contract.pdf'
            frameBorder="0" height="100%" width="100%">
        </iframe>


        // <iframe
        //     src="https://docs.google.com/viewerng/viewer?url=https://caziere.ro/Contract.pdf&embedded=true"
        //     frameBorder="0" height="100%" width="100%">
        // </iframe>
    );
}