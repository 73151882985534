export const KNOWN_ERRORS = {
    NOT_FOUND: {
        message: 'Hmm, ne pare rău, nu am găsit nimic despre asta ...',
        key: 'not_found',
        code: 404,
        severity: 'error'
    },
    FILE_TOO_LARGE: {
        message: 'Fișierul încărcat nu trebuie să depășească 20MB. Vă rugăm încercați să încărcați un fișier mai mic.',
        key: 'too_large',
        code: 407,
        severity: 'error'
    },
    FORBIDDEN: {
        message: 'Hmm, ne pare rău, nu ai acces la această resursă.',
        key: 'forbidden',
        code: 401,
        severity: 'error'
    },
    UNKNOWN: {
        message: 'Uuups, avem o problemă cu sistemul ... Te rog să încerci mai târziu.',
        key: 'unknown_error',
        code: -1,
        severity: 'error'
    },
    SERVER_OUT_OF_REACH: {
        message: 'Uuups, nu putem contacta serverul ... Te rog să încerci mai târziu.',
        key: 'server_out_of_reach',
        code: -1,
        severity: 'error'
    },
}

function safeJsonParse(str) {
    try {
        return [null, JSON.parse(str)];
    } catch (err) {
        return [err];
    }
}

const findError = (error) => {

    if (error.message) {
        if (typeof error.message !== 'string') {
            return KNOWN_ERRORS.SERVER_OUT_OF_REACH;
        }

        const [err2, result] = safeJsonParse(error.message);
        if (err2) {
            console.log('Failed to parse JSON: ' + err2.message);
        } else {
            error = result;
        }
    }
    let err = Object.values(KNOWN_ERRORS).find(value => value.code === error.code);
    if (error.message && err !== undefined) {
        err = JSON.parse(JSON.stringify(err))
        err.message = error.message;
    }
    let unknownError = KNOWN_ERRORS.UNKNOWN;
    return err === undefined ? {
        ...unknownError,
        message: error.message
    } : err
}

export const KNOWN_SUCCESSES = {
    NO_MODIFICATIONS: {
        message: 'Trebuie să faceți modificari înainte de a salva.',
        key: 'not_modified',
        severity: 'info'
    },
    SAVE_SUCCES: {
        message: 'Valorile au fost salvate cu succes.',
        key: 'save_success',
        severity: 'success'
    },
    REPORT_BEING_CREATED: {
        message: 'Raportul este generat. Vă rog așteptați!',
        key: 'report_being_created',
        severity: 'info'
    },
    REFUND_SUCCESS: {
        message: 'Refound cu succes.',
        key: 'refund_success',
        severity: 'info'
    },
    ORDER_UPDATE_SUCCESS: {
        message: 'Order actualizat cu succes. Va rog sa dati un refresh paginii pentru a vedea si lista actualizata.',
        key: 'refund_success',
        severity: 'info'
    },
    AWB_BEING_CREATED: {
        message: 'AWB-ul este in curs de generare. Vă rog așteptați!',
        key: 'awb_being_created',
        severity: 'info'
    },
    INVOICE_REPORT_BEING_CREATED: {
        message: 'Facturile sunt generate. Vă rog așteptați!',
        key: 'invoice_report_being_created',
        severity: 'info'
    },
    ORDER_IS_COMPUTING: {
        message: 'Se validează datele și se estimează prețul.',
        key: 'order_computing',
        severity: 'info'
    },
    ORDER_ADDING_DELIVERY_INFO: {
        message: 'Adaugam detaliile despre livrare imediat.',
        key: 'order_computing',
        severity: 'info'
    },
    MESSAGE_WAS_SEND: {
        message: 'Mesajul dvs. a fost trimis. Revenim către dumneavoastră cât de repede!',
        key: 'message_send',
        severity: 'info'
    },
    CATEGORY_ADDED: {
        message: 'Categorie adaugată cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    BANNER_ADDED: {
        message: 'Banner adaugat cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    BANNER_UPDATED: {
        message: 'Banner updatat cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    BANNER_DELETED: {
        message: 'Banner sters cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    PAYMENT_LINK_CREATED: {
        message: 'Payment Link creat cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    BLACKLIST_ITEM_CREATED: {
        message: 'Blacklist item creat cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    BLACKLIST_ITEM_DELETED: {
        message: 'Blacklist item sters cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    MESSAGE_QUEUE_ITEM_DELETED: {
        message: 'Mesajul a fost sters cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    COPIED_TO_CLIPBOARD: {
        message: 'Copied to clipboard.',
        key: 'document_upload',
        severity: 'info'
    },
    CANNOT_COPY_TO_CLIPBOARD: {
        message: 'Cannot copy to clipboard.',
        key: 'document_upload',
        severity: 'error'
    },
    DOCUMENT_UPLOAD: {
        message: 'Document încărcat cu succes.',
        key: 'document_upload',
        severity: 'success'
    },
    DOCUMENT_DOWNLOAD: {
        message: 'Document descărcat cu succes.',
        key: 'document_download',
        severity: 'success'
    },
    FILE_DELETED_WITH_SUCCESS: {
        message: 'Fisier sters cu succes.',
        key: 'document_download',
        severity: 'success'
    },
    ALERT_SENT: {
        message: 'Alertă trimisă cu succes.',
        key: 'alert_send',
        severity: 'success'
    },
    ROLE_CHANGED: {
        message: 'Rol schimbat cu succes.',
        key: 'role_changed',
        severity: 'success'
    },
    AUTH_CODE_CHANGED: {
        message: 'Am re-trimis PIN-ul cu succes.',
        key: 'pin_send',
        severity: 'success'
    },
    LATER_DELIVERY_CHANGED: {
        message: 'Later delivery marked as done.',
        key: 'pin_send',
        severity: 'success'
    },
    ANAF_CUI_NOT_FOUND: {
        message: 'CUI-ul nu a fost găsit, verificați detaliile și încercați din nou!',
        key: 'anaf_cui_not_found',
        severity: 'error'
    },

}

export const getErrorMessage = (error) => {
    return findError(error);
}

