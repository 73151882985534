import * as React from 'react';
import {Card, Container, Stack, Typography} from '@mui/material';
import Page from '../components/Page';
import {fDateTimeForBackend} from "../utils/formatTime";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import * as CommunicationService from "../services/CommunicationService";
import InvoicesToolbar from "../components/_dashboard/user/InvoicesToolbar";
import {generatePartners} from "../services/CommunicationService";

export default function Invoices() {

    const generateInvoice = (startDate, endDate, startingNumber, businessOnly, own) => {
        let params = {
            startDate: fDateTimeForBackend(startDate),
            endDate: fDateTimeForBackend(endDate),
            startingNumber: startingNumber,
            businessOnly: businessOnly,
            own: own
        }
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.INVOICE_REPORT_BEING_CREATED);

        CommunicationService.generateInvoices(params)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Invoices-" + Date.now() + ".zip";
                    a.click();

                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    const generatePartnerReportClick = (startDate, endDate, startingNumber) => {
        let params = {
            startDate: fDateTimeForBackend(startDate),
            endDate: fDateTimeForBackend(endDate),
            startingNumber: startingNumber
        }
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.INVOICE_REPORT_BEING_CREATED);

        CommunicationService.generatePartners(params)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Partners-" + Date.now() + ".xls";
                    a.click();

                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Invoices
                    </Typography>
                </Stack>

                <Card>
                    <InvoicesToolbar
                        handleReportClick={(s, e, n, bo) => generateInvoice(s, e, n, bo, true)}
                        handleStripeReportClick={(s, e, n) => generateInvoice(s, e, n, bo, false)}
                        handlePartnerReportClick={(s, e, n) => generatePartnerReportClick(s, e, n)}
                    />
                </Card>
            </Container>
        </Page>
    );
}
