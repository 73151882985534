// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import PageWithTextCard from "../components/_dashboard/app/PageWithTextCard";

// ----------------------------------------------------------------------

export default function InfoCardsPage(document) {

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container maxWidth="xl">
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={12}>
                        <PageWithTextCard {...document}/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
