// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Card, Stack, Typography} from '@mui/material';
// utils
import * as React from "react";
import {useState} from "react";
import {getPublicPrice, getPublicPriceFUll} from "../../../services/LogicService";
import * as LogicService from "../../../services/LogicService";

// ----------------------------------------------------------------------


export default function CompCertificatePrice({regim, legalizedSelected, translationCountry, apostilaSelected, userIsForeign}) {

    let browserData = LogicService.getBrowserData();

    let userOutside = true;
    if (browserData && browserData.country_code === 'RO') {
        userOutside = false;
    }
    let isUrgent = regim === 'urgent';

    let regimPrice = getPublicPrice(userOutside ? 'CERT_REGIME_STANDARD_OC' : 'CERT_REGIME_STANDARD');
    if (isUrgent == true) {
        regimPrice = getPublicPrice(userOutside ? 'CERT_REGIME_URGENT_OC' : 'CERT_REGIME_URGENT');
    }
    debugger
    let translationPrice = !translationCountry ? {urgentPrice: 0, price: 0} : getPublicPriceFUll(translationCountry.label)
    if (translationPrice == undefined || translationPrice == null) {
        translationPrice = {urgentPrice: 0, price: 0}
    }
    let finalTranslationPrice = 0;
    let finalApostilaPrice = 0;
    let finalExpertPrice = 0;
    if (legalizedSelected == true) {
        finalTranslationPrice = isUrgent ? translationPrice.urgentPrice : translationPrice.price;
    }
    if (apostilaSelected == true) {
        finalApostilaPrice = getPublicPrice('CERT_APOSTILA');
    }
    if (userIsForeign == true) {
        finalExpertPrice = getPublicPrice('CERT_EXPERT_VERIFICATION');
    }

    return (
        <Stack direction="column"
               justifyContent="center"
               border={"1px solid rgba(0, 0, 0, 0.15)"}
               padding={"10px"} borderRadius={"10px"}
               sx={{
                   background: "rgba(0, 0, 0, 0.05)"
               }}
        >
            <Typography variant="h6" sx={{display: 'flex', justifyContent: 'flex-end'}}>
                Cost:
                {
                    getPublicPrice('CERT_DELIVERY_ELECTRONIC')
                    + regimPrice + finalTranslationPrice + finalApostilaPrice + finalExpertPrice
                } RON
            </Typography>
        </Stack>
    );
}
