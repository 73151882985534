// material
import {styled} from '@mui/material/styles';
import {Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Toolbar} from '@mui/material';
import * as React from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ConfirmModal from "./ConfirmModal";
import {DesktopDatePicker} from "@mui/lab";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({theme}) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    border: "1px solid rgba(0,128,0, 0.6)",
    borderRadius: '16px'
}));

export default function InvoicesToolbar({handleReportClick, handleStripeReportClick, handlePartnerReportClick}) {

    const [startValue, setStartValue] = React.useState(null);
    const [endValue, setEndValue] = React.useState(null);
    const [numberValue, setNumberValue] = React.useState(0);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [content, setContent] = React.useState("");
    const [hideConfirm, setHideConfirm] = React.useState(false);
    const [businessOnly, setBusinessOnly] = React.useState(false);

    const computeContent = () => {
        let currentDate = new Date();
        let text = "";
        if ((startValue === null && endValue < currentDate) || (endValue === null && startValue > currentDate)) {
            text = "Nu se pot genera facturi pentru intervalul dat."
            setHideConfirm(true);
        } else {
            text = "Această operațiune poate dura până la 30 de secunde."
            setHideConfirm(false);
        }
        setContent(text);
    }

    return (
        <RootStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{display: 'flex', justifyContent: 'space-around'}}>
                {
                    [
                        <ConfirmModal open={modalOpen}
                                      handleConfirmStripe={() => {
                                          setModalOpen(false);
                                          handleStripeReportClick(startValue, endValue, numberValue)
                                      }}
                                      handleConfirm={() => {
                                          setModalOpen(false);
                                          handleReportClick(startValue, endValue, numberValue, businessOnly)
                                      }}
                                      handleClose={() => setModalOpen(false)}
                                      title={"Confirmați acțiunea!"}
                                      hideConfirm={hideConfirm}
                                      content={content}/>,
                        <Stack direction="row" alignItems="left" spacing={2}>
                            <DesktopDatePicker
                                label="Start Date & Time"
                                value={startValue}
                                onChange={setStartValue}
                                ampm={false}
                                ampmInClock={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="End Date & Time"
                                value={endValue}
                                onChange={setEndValue}
                                ampm={false}
                                ampmInClock={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <TextField
                                id="outlined-number"
                                label="Starting number"
                                type="number"
                                defaultValue={numberValue}
                                onChange={(event) => setNumberValue(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <FormControlLabel id={"business-only-checkbox"} control={
                                <Checkbox
                                    value={businessOnly}
                                    onClick={() => setBusinessOnly(!businessOnly)}
                                />
                            } label="Doar PJ"/>
                        </Stack>,

                        <Button variant="contained"
                                disabled={startValue === null && endValue == null}
                                onClick={() => {
                                    computeContent();
                                    setModalOpen(true)
                                }}>
                            Generare Facturi
                        </Button>,

                        // <Button variant="contained"
                        //         disabled={startValue === null && endValue == null}
                        //         onClick={() => {
                        //             handlePartnerReportClick(startValue, endValue);
                        //         }}>
                        //     Generare Raport Parteneri
                        // </Button>
                    ]
                }
            </LocalizationProvider>
        </RootStyle>
    );
}
