import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {InputLabel, MenuItem, Select, TextField} from "@mui/material";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";

export default function OrderAlertModal({row, open, handleClose}) {

    const [alertMessage, setAlertMessage] = useState(null);

    const [documentId, setDocumentId] = React.useState(null);

    const handleSend = () => {
        CommunicationService.alertForDocument(row.id, documentId, {message: alertMessage})
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ALERT_SENT);

                if (documentId === -999) {
                    row.problems = true;
                } else {
                    let documentIndex = row.documents.findIndex(doc => doc.id == documentId);
                    row.documents[documentIndex].hasProblems = true;
                }
                handleClose();
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
            });
    }

    const changeDocumentId = (event) => {
        const {
            target: {value},
        } = event;
        if (value === -999) {
            setAlertMessage("Motivul dumneavoastră nu este unul valid, vă rugăm să oferiți mai multe detalii.");
        } else {
            setAlertMessage("Unul din documentele încărcate are o problemă. Vă rugăm să îl reîncărcați accesând contul dumneavoastră.");
        }
        setDocumentId(value);
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Order #" + row.id + " are o problemă"}
            </DialogTitle>
            <DialogContent>
                <InputLabel id="alert-doc"
                            sx={{margin: "30px 0"}}>Zona cu probleme</InputLabel>
                <Select
                    labelId="alert-doc"
                    value={documentId}
                    label="Zona cu probleme"
                    onChange={changeDocumentId}
                    sx={{marginBottom: "30px", minWidth: "500px"}}
                >
                    <MenuItem
                        key={-999}
                        value={-999}
                    >
                        {"Motiv"}
                    </MenuItem>
                    {row.documents
                        .filter(doc => doc.type !== 'POLICE_RECORD')
                        .filter(doc => !doc.hasProblems).map((document) => {
                            return <MenuItem
                                key={document.id}
                                value={document.id}
                            >
                                {document.type + "(" + document.name + ")"}
                            </MenuItem>
                        })}
                </Select>


                <InputLabel id="alert-doc"
                            sx={{marginTop: "30px"}}>Motivul alertei</InputLabel>
                <TextField
                    margin="dense"
                    id="alert"
                    type="text"
                    multiline
                    maxRows={4}
                    fullWidth
                    variant="standard"
                    disabled={alertMessage == null}
                    value={alertMessage}
                    onChange={(event) => setAlertMessage(event.target.value)}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Close
                </Button>
                <Button onClick={handleSend} disabled={documentId == null}>

                    Send alert
                </Button>
            </DialogActions>
        </Dialog>

    );
}
