// material
// components
import * as React from "react";
import InfoCardsPage from "./InfoCardsPage";
import Messages from "../constants/Messages";

// ----------------------------------------------------------------------

export default function TermsAndConditions() {

    return (
        <InfoCardsPage document={Messages.TERMS_AND_CONDITIONS}/>
    );
}
