import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-timisoara-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-timisoara-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-timisoara-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-timisoara-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-timisoara-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-timisoara-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageTimisoara({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let descriptionList = [
        "",
        ""
    ]
    let keywords = [
        "cazier online Timisoara",
        "cazier Timisoara online",
        "cazier judiciar Timisoara online",
        "eliberare cazier judiciar online Timisoara / eliberare cazier online Timisoara"
    ]

    return (
        <Page title={`Cazier Judiciar Online Timișoara`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <title>Cazier judiciar online Timișoara - Rapid și simplu, fără a mai sta la coadă</title>
                <meta name="description" content={"Când vine vorba despre obținerea unui cazier judiciar în Timișoara sau în alte orașe din țară, eficiența și rapiditatea sunt esențiale."}/>
                <meta name="keywords" content={keywords.join(',')}/>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Timișoara</h1>

                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Rapid și simplu, fără a mai sta la
                                coadă</p>

                            <p>Când vine vorba despre obținerea unui cazier judiciar în Timișoara sau în alte orașe din
                                țară, eficiența și rapiditatea sunt esențiale. Din fericire, acum puteți beneficia de un
                                serviciu online optimizat pentru eliberarea cazierului judiciar, eliminând astfel timpul
                                pierdut cu deplasările și așteptările lungi.</p>
                            <p>Caziere.ro vine în întâmpinarea nevoilor dumneavoastră cu o soluție simplă și rapidă.
                                Prin intermediul platformei noastre, puteți solicita și obține cazierul judiciar online,
                                fără bătăi de cap.</p>

                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>

                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}
                        ></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online în Timișoara, ușor și eficient!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div class="section">
                <div class="container">
                    <div class="row">
                        <div className="col-md-12">
                            <h2>Beneficiile obținerii cazierului judiciar online în Timișoara </h2>
                            <p>Obținerea cazierului judiciar este o necesitate pentru mulți cetățeni, fie că este vorba
                                de
                                angajare, studii sau alte proceduri legale. În Timișoara, procesul de obținere a acestui
                                document esențial este acum mai simplu și mai eficient prin intermediul platformei
                                noastre
                                online.</p>

                            <p><strong>Eliberarea cazierului judiciar online din Timișoara aduce beneficii
                                semnificative, cum ar
                                fi:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Economie de timp: Nu mai stați în cozi pentru depunerea
                                    cererilor.
                                </li>
                                <li dir="ltr" aria-level="1">Accesibilitate: Aplicați oricând și de oriunde, având doar
                                    nevoie
                                    de o conexiune la internet.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditate: Procedurile sunt simplificate, accelerând
                                    procesul de
                                    eliberare.
                                </li>
                                <li dir="ltr" aria-level="1">Siguranță: Datele dumneavoastră sunt tratate cu maximă
                                    confidențialitate.
                                </li>
                                <li> Asistență: Beneficiați de suportul nostru la fiecare pas.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online în Timișoara, ușor și eficient!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>De ce să apelați la caziere.ro pentru obținerea unui cazier online în Timișoara</h2>
                            <p>Optând pentru platformă caziere.ro în vederea obținerii cazierului judiciar online în
                                Timișoara,
                                beneficiați de avantaje deosebite care fac întregul proces mult mai ușor și mai sigur
                                pentru
                                dumneavoastră. </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt={`Cazier judiciar online Timișoara`} className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1"> Simplificăm întregul proces birocratic, astfel încât nu
                                    trebuie să
                                    vă mai faceți griji în legătură cu documentația necesară sau cu pașii procedurali.
                                </li>
                                <li dir="ltr" aria-level="1"> Echipa noastră, care are o vastă experiență în domeniul
                                    obținerii
                                    documentelor legale și oficiale inclusiv a cazierelor judiciare, vă poate ghida
                                    eficient
                                    prin toate etapele necesare.
                                </li>
                                <li dir="ltr" aria-level="1"> Fiabilitatea serviciilor noastre este garantată și ne
                                    asigurăm că
                                    toate documentele sunt eliberate într-un timp optim, eliminând astfel așteptarea
                                    îndelungată
                                    la cozi sau întârzierile neașteptate.
                                </li>
                                <li dir="ltr" aria-level="1"> Suntem disponibili să vă oferim asistență la fiecare pas,
                                    fiind
                                    pregătiți să răspundem la orice întrebare sau nelămurire pe care o puteți avea pe
                                    parcursul
                                    procesului.
                                </li>
                                <li dir="ltr" aria-level="1"> Vă ținem constant la curent cu privire la statutul
                                    solicitării
                                    dumneavoastră, utilizând canale moderne de comunicare cum ar fi emailul și sms-ul.
                                    Acest
                                    nivel de transparență și actualizare continuă vă asigură că sunteți informați în
                                    fiecare
                                    moment despre progresul obținerii cazierului dumneavoastră judiciar.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-12 order-md-1">
                            <p>Vă ajutăm să treceți cu ușurință prin orice obstacole administrative. Indiferent dacă
                                sunteți în Timișoara sau în orice altă locație, platforma noastră vă stă la dispoziție
                                pentru a vă simplifica obținerea cazierului judiciar online.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online în Timișoara, ușor și eficient!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Acte necesare pentru obținerea cazierului online din Timișoara </h2>
                            <p>Pentru a începe procesul de obținere a cazierului judiciar online pentru locuitorii din
                                orașul
                                Timișoara, va fi nevoie de:</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Copie după actul de identitate.
                                </li>
                                <li dir="ltr" aria-level="1">O fotografie recentă, în care țineți actul de identitate în
                                    mână.
                                </li>
                                <li dir="ltr" aria-level="1">Semnarea electronică a contractului prin care ne
                                    împuterniciți să
                                    acționăm în numele dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Etapele obținerii unui cazier judiciar online în Timișoara</h2>
                            <p>Procesul de obținere a cazierului judiciar online în Timișoara prin caziere.ro este
                                conceput
                                pentru a fi cât mai simplu și eficient posibil, eliminând necesitatea deplasărilor la
                                ghișeele
                                de eliberare. În acest scop, următorii pași sunt necesari pentru completarea și
                                finalizarea
                                cererii de cazier judiciar:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt={`Cazier judiciar online Timișoara`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Înregistrarea cererii - se face prin accesarea platformei
                                    online
                                    caziere.ro și completarea formularului dedicat. Va trebui să furnizați informații
                                    personale
                                    de bază și să alegeți tipul de serviciu dorit.
                                </li>
                                <li dir="ltr" aria-level="1">Încărcarea documentelor necesare&nbsp; - Va trebui să
                                    încărcați o
                                    copie scanată a actului de identitate și o fotografie gen selfie care dovedește
                                    autenticitatea acestuia.
                                </li>
                                <li dir="ltr" aria-level="1">Semnarea contractului de împuternicire - Este un pas
                                    crucial,
                                    deoarece conferă echipei caziere.ro autoritatea de a acționa în numele dumneavoastră
                                    pentru
                                    procurarea cazierului judiciar. Contractul poate fi semnat electronic, fără a fi
                                    necesară
                                    prezența fizică, facilitând procesul pentru cei care locuiesc atât în România, cât
                                    și în
                                    străinătate.
                                </li>
                                <li dir="ltr" aria-level="1">Procesarea și finalizarea cererii - Noi vom urmări evoluția
                                    cererii
                                    și vom rezolva eventualele impedimente întâlnite pe parcurs, asigurându-ne că
                                    întregul
                                    proces decurge fără probleme și în cel mai scurt timp posibil, adică între 1-5 zile
                                    lucrătoare, în funcție de regimul ales de dvs.
                                </li>
                                <li>Livrarea cazierului direct la adresa dumneavoastră - După obținerea cazierului
                                    judiciar
                                    pentru Timișoara online, acesta va fi scanat și trimis spre vizualizare prin email.
                                    Ulterior, documentul original va fi livrat prin curier rapid la adresa specificată
                                    de
                                    dumneavoastră, fie că este în Timișoara, în altă parte a țării sau chiar în
                                    străinătate.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online în Timișoara, ușor și eficient!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt={`Cazier judiciar online Timișoara`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <h2>Eliberare cazier judiciar online Timișoara</h2>
                            <p>Pentru toți locuitorii orașului Timișoara și nu numai, obținerea cazierului judiciar nu a
                                fost
                                niciodată mai simplă. Folosind platforma caziere.ro, eliberarea cazierului judiciar
                                online
                                pentru Timișoara se face rapid, fără deplasări și fără timp pierdut așteptând la
                                cozi.</p>

                            <p>Pentru a demara procesul de eliberare cazier judiciar online pentru Timișoara trebuie să
                                încărcați online documentele necesare, să completați informațiile solicitate și, în
                                scurt timp,
                                veți primi cazierul judiciar direct la adresa dumneavoastră, prin curier rapid. Această
                                procedură online elimină complet necesitatea prezenței fizice în fața autorităților și
                                vă ajută
                                să economisiți timp prețios, permiţându-vă să vă concentrați pe aspectele mai importante
                                ale
                                vieții dumneavoastră.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt={`Cazier judiciar online Timișoara`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online Timișoara - preț</h2>
                            <p>Prețul pentru obținerea cazierului judiciar online pentru Timișoara este accesibil și
                                justificat
                                de beneficiile pe care le aveți apelând la acest serviciu. Detaliile privind prețurile
                                pot fi
                                găsite pe platforma noastră în momentul solicitării cazierului, iar plata se poate
                                efectua sigur
                                și convenabil online. Astfel, evitați deplasările inutile și timpul pierdut în așteptare
                                la
                                ghișeele instituțiilor, economisind nu doar timp, ci și bani. </p>

                            <p>Obținerea unui cazier judiciar online pentru Timișoara se face la un preț rezonabil și,
                                totodată,
                                beneficiați de un proces simplificat și rapid, totul fiind gestionat eficient de la
                                distanță. De
                                asemenea, plata este securizată, oferindu-vă încrederea că tranzacțiile dumneavoastră
                                sunt
                                protejate și că detaliile financiare sunt în siguranță.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online în Timișoara, ușor și eficient!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de detalii despre procesul de obținere a cazierului judiciar online în
                                Timișoara?
                                Nu ezitați să ne contactați!</h2>
                            <p>Pentru orice întrebări sau nelămuriri legate de procedura de obținere a cazierului
                                judiciar
                                online în Timișoara, echipa noastră este la dispoziția dumneavoastră. Scrieți-ne la
                                adresa de
                                email <a href="mailto:contact@caziere.ro">contact@caziere.ro </a> și vă vom oferi toate
                                informațiile necesare pentru a demara procesul.</p>

                            <p>Nu lăsați ca demersurile birocratice să vă îngreuneze viața! Alegeți soluția simplă și
                                eficientă:
                                obținerea cazierului judiciar online în Timișoara prin intermediul caziere.ro.
                                Contactați-ne
                                astăzi pentru a iniția procesul și a vă asigura că beneficiați de cea mai rapidă soluție
                                de
                                eliberare a documentelor de care aveți nevoie.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
