import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-bucuresti-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-bucuresti-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-bucuresti-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-bucuresti-5.jpg"
import Foto_cta from "./images/cazier-judiciar-online-bucuresti-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageBucuresti({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let descriptionList = [
        "",
        ""
    ]
    let keywords = [
        "cazier judiciar online București",
        "obtinere cazier judiciar online Bucuresti",
        "eliberare cazier judiciar online Bucuresti",
        "cerere cazier judiciar online București",
        "cazier judiciar online sector 5",
        "cazier judiciar online sector 6",
        "cazier judiciar online sector 4",
        "cazier judiciar online sector 3",
        "cazier judiciar online sector 2",
        "cazier judiciar online sector 1",
        "cazier judiciar online Bucuresti Pret"
    ]

    return (
        <Page title={`Cazier Judiciar Online București`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Obținerea unui cazier judiciar online în București este acum o procedură ușoară și confortabilă"}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online București - Îl puteți obține în maximum 5 zile pentru orice sector</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online București</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Îl puteți obține în maximum 5 zile pentru
                                orice
                                sector</p>
                            <p>Obținerea unui cazier judiciar online în București este acum o procedură ușoară și
                                confortabilă.
                                Folosind
                                platforma noastră, acum aveți posibilitatea de a solicita și primi cazierul judiciar
                                online, fără a
                                pierde timpul în cozi interminabile.</p>
                            <p>Indiferent de sectorul din București în care vă aflați, puteți apela la serviciile
                                noastre serviciu
                                rapide, eficiente și sigure pentru a obține cazierul judiciar!</p>
                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>


                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online din București prin doar câteva click-uri!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_3} alt="Cazier judiciar online București"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Avantajele obținerii cazierului judiciar online în București</h2>
                            <p>Obținerea cazierului judiciar online din București reprezintă o soluție modernă și
                                eficientă pentru
                                economisirea timpului prețios, eliminând necesitatea deplasărilor inutile și așteptarea
                                în cozi
                                obositoare. Comoditatea de a face toate demersurile necesare din confortul propriei
                                locuințe face
                                acest serviciu extrem de atractiv pentru toți cei care valorizează timpul și
                                eficiența. </p>

                            <p>Disponibilitatea acestui serviciu oricând și oriunde, precum și securitatea informațiilor
                                personale,
                                împreună cu posibilitatea de a primi cazierul în format electronic oriunde pe glob,
                                adaugă un plus
                                de valoare inestimabil acestui proces simplificat.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>De ce să apelați la serviciul de obținere cazier judiciar online din București prin
                                caziere.ro</h2>
                            <p>Obținerea unui certificat de cazier judiciar din București poate fi o sarcină dificilă și
                                consumatoare de
                                timp, însă cu serviciul online oferit de caziere.ro, tot procesul devine simplu și
                                eficient. Prin
                                această platformă, de oriunde ați fi, puteți accesa un serviciu profesional și acreditat
                                pentru
                                eliberarea rapidă și fără bătăi de cap a cazierului judiciar. În plus, beneficiați de
                                flexibilitate
                                în
                                obținere și livrare, transparență totală în costuri și procedură, precum și asistență
                                continuă pe
                                parcursul întregului proces. </p>

                            <p><strong>Motive pentru care să apelați la serviciile noastre:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Avem o echipă cu experiență în obținerea cazierului
                                    judiciar online din
                                    București.
                                </li>
                                <li dir="ltr" aria-level="1">Reacționăm imediat la solicitarea dvs. și începem procesul
                                    de obținere
                                    a
                                    cazierului fără întârziere.
                                </li>
                                <li dir="ltr" aria-level="1">Cazierul dvs. judiciar este disponibil atât în format
                                    digital, cât și
                                    fizic, cu opțiuni de livrare flexibile în toată lumea.
                                </li>
                                <li dir="ltr" aria-level="1">Vă oferim informații detaliate și transparente despre
                                    costurile și
                                    procedura de obținere a cazierului pentru a ști exact ce aşteptări să aveți.
                                </li>
                                <li dir="ltr" aria-level="1">Oferim suport constant și asistență pe întreaga durată a
                                    procesului de
                                    obținere a certificatului dvs. de cazier judiciar din București.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online din București prin doar câteva click-uri!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Când este necesară eliberarea cazierului judiciar online din București </h2>
                            <p>Orice moment din viață în care aveți nevoie de dovada unui istoric curat sau pentru
                                proceduri
                                oficiale, cum ar fi angajarea într-o nouă poziție de muncă sau aplicarea pentru vize și
                                cetățenie
                                străină. Eliberarea cazierului judiciar online din București este esențială atunci când
                                doriți să vă
                                înscrieți la o facultate sau să participați la programe educaționale care solicită
                                verificarea
                                antecedentelor personale. </p>
                            <p>De asemenea, cazierul judiciar online poate fi solicitat de către angajatori atunci când
                                doriți să
                                dovediți integritatea dumnevoastră profesională în cadrul unui proces de recrutare.</p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Cerere pentru un cazier judiciar online în București - acte necesare</h2>


                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_4} alt="Cazier judiciar online București"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Caziere.ro vă permite să economisiți timp prețios prin gestionarea tuturor procedurilor
                                necesare în
                                numele
                                dumneavoastră pentru cererea unui cazier judiciar online din București. Pentru a demara
                                acest proces,
                                sunt
                                indispensabile următoarele acte: </p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Copia actului de identitate sau a pașaportului pentru cei
                                    aflați în afara
                                    țării, astfel încât identitatea dumneavoastră să poată fi verificată fără echivoc.
                                </li>
                                <li dir="ltr" aria-level="1">Selfie ținând în mâna actul de identitate, pentru a dovedi
                                    că cererea este
                                    făcută de persoana în cauză.
                                </li>
                                <li dir="ltr" aria-level="1">Contract semnat electronic pentru a conferi echipei
                                    caziere.ro autoritatea
                                    legală de a obține cazierul în numele dumneavoastră, fără necesitatea prezenței
                                    fizice din partea
                                    dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online din București prin doar câteva click-uri!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Etapele obținerii cazierului online în Cluj</h2>
                            <p>Pentru a obține cazierul judiciar online pentru Cluj, urmați acești pași simpli:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Cazier judiciar online sector 1</h3>
                            <p>Rezidenții din Bucureștii Noi, Aviației sau Dorobanți, și din alte zone ale sectorului 1
                                precum Victoriei
                                sau Plevnei, se pot bucura de ușurința serviciului de cazier judiciar online din
                                sectorul 1. Oferind
                                acces simplu și rapid, acest serviciu este ideal și pentru cei care locuiesc în Grivița
                                sau Pajura și
                                doresc evitarea cozilor interminabile. De asemenea, indiferent dacă sunteți din
                                Floreasca sau Romana,
                                acum puteți obține documentația judiciară necesară fără a părăsi confortul casei
                                dvs.</p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Cazier judiciar online sector 2</h3>
                            <p>Cei care locuiesc în cartierele Colentina, Moşilor sau Pantelimon, au acum opțiunea de a
                                acces serviciul
                                de cazier judiciar online din sectorul 2. Acesta este extrem de convenabil și pentru
                                rezidenții din
                                Iancului, Obor sau Tei care doresc să economisească timp. Totodată, serviciul online
                                este disponibil și
                                pentru cei din Ștefan cel Mare, Făinari sau Floreasca, facilitând astfel obținerea
                                documentelor legale
                                necesare.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Cazier judiciar online sector 3</h3>
                            <p>Bucureștenii care au reședința pe străzile Alba Iulia, Prevederii sau Unirii, pot acum să
                                profite de
                                serviciul de cazier judiciar online pentru sectorul 3. Acest serviciu este disponibil și
                                pentru cei din
                                Sălăjan, Vitan sau Universității, care doresc să scape de drumurile la poliție. De
                                asemenea, este ideal
                                pentru rezidenții din Rosetti, Victoriei sau Chişinău, care pot acum să obțină
                                documentele necesare
                                direct de acasă.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Cazier judiciar online sector 4</h3>
                            <p>Rezidenții bucureșteni care locuiesc în Tineretului, Olteniţei sau Brancoveanu, se bucură
                                de accesul la
                                serviciul de cazier judiciar online pentru sectorul 4. Pentru cei din Filaret sau
                                Apărătorii Patriei,
                                acest serviciu înseamnă obținerea documentației necesare fără deplasări inutile. Obregia
                                sau Secuilor
                                sunt alte zone unde cetățenii pot beneficia de confortul acestui serviciu
                                electronic.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Cazier judiciar online sector 5</h3>
                            <p>Serviciul de obținere online a cazierului judiciar pentru sectorul 5, București,
                                simplifică demersurile
                                pentru cei care locuiesc în zonele Rahova, Ferentari, Alexandriei sau 13 Septembrie. Nu
                                mai este nevoie
                                să faceți naveta la sediile poliției nici dacă locuiți în Panduri, Sălaj sau
                                Viilor.<br/>
                                <br/>
                                Chiar și cei din Năsăud, Viilor sau zona Unirii pot acum să obțină online acest
                                document important.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Cazier judiciar online sector 6</h3>
                            <p>Locuitorii sectorului 6, indiferent dacă sunt din Drumul Taberei, Militari sau Crângaşi,
                                pot acum să
                                beneficieze de serviciul de cazier judiciar online. Acesta este un serviciu eficient și
                                confortabil este
                                util și pentru cei aflați în Plevnei, Lujerului sau Uverturii. De asemenea, locuitorii
                                din zonele
                                Compozitorilor, Prelungirea Ghencea sau Valea Argeșului sunt acum mai aproape ca oricând
                                de rezolvarea
                                formalităților necesare pentru obținerea cazierului judiciar online din sectorul 6.</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online din București prin doar câteva click-uri!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_5} alt="Cazier judiciar online București"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Prețul pentru un cazier judiciar online din București </h2>
                            <p>Prețul pentru eliberarea unui cazier judiciar online din București se calculează în
                                funcție de mai
                                multe
                                criterii, inclusiv dacă solicitantul este persoană fizică sau juridică, și dacă are
                                nevoie de
                                eliberare
                                în regim de urgență sau este dispus să aștepte până la 5 zile lucrătoare.</p>

                            <p>Alți factori care influențează costul sunt necesitatea unei traduceri legalizate, limba
                                în care
                                trebuie
                                făcută traducerea, precum și adăugarea apostilei Haga, dacă este necesar. În plus,
                                modalitatea de
                                livrare, fie ea electronică sau prin curier la o adresă specificată, poate modifica
                                prețul final al
                                serviciului de emitere cazier judiciar online din București.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți cazierul judiciar online din București prin doar câteva click-uri!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de un cazier judiciar online din București? Contactați-ne pentru
                                detalii!</h2>
                            <p>Dacă vreți să obțineți cazierul judiciar online din București, platforma caziere.ro este
                                gata să vă
                                asiste în tot procesul. Expertiza noastră este la doar un email distanță, asigurându-vă
                                că orice
                                nelămurire legată de obținerea cazierului judiciar online va fi clarificată prompt și
                                profesional. </p>

                            <p>Este timpul să vă simplificați viața: contactați-ne la <a
                                href="mailto:contact@caziere.ro">contact@caziere.ro </a>
                                și descoperiți cea mai convenabilă și sigură metodă de a obține cazierul judiciar
                                online, indiferent
                                unde vă aflați în București!</p>
                        </div>
                    </div>
                </div>
            </div>

        </Page>
    );
}
