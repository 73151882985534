import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {InputLabel, MenuItem, Select} from "@mui/material";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";

export default function OrderTransactionStatusModal({row, open, handleClose}) {

    const [orderStatus, setOrderStatus] = React.useState(row.transactionVerified);

    const handleSend = () => {
        CommunicationService.changeTransactionStatusForOrder(row.id, orderStatus)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ALERT_SENT);
                row.transactionVerified = orderStatus;
                handleClose();
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
            });
    }

    const changeOrderStatus = (event) => {
        const {
            target: {value},
        } = event;
        setOrderStatus(value);
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Schimbare status tranzactie pentru Order #" + row.id}
            </DialogTitle>
            <DialogContent>
                <InputLabel id="alert-doc"
                            sx={{marginTop: "30px", marginBottom: "10px"}}>Status</InputLabel>
                <Select
                    labelId="alert-doc"
                    value={orderStatus}
                    label="Status"
                    onChange={changeOrderStatus}
                    sx={{marginBottom: "30px", minWidth: "500px"}}
                >
                    <MenuItem key={true} value={true}>
                        {"Payment done"}
                    </MenuItem>

                    <MenuItem key={false} value={false}>
                        {"Payment pending"}
                    </MenuItem>

                </Select>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Close
                </Button>
                <Button onClick={handleSend} disabled={orderStatus == null}>
                    Change status
                </Button>
            </DialogActions>
        </Dialog>

    );
}
