// material
import {Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import * as CommunicationService from "../services/CommunicationService";
import {paymentLinkOrder} from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import {DOCUMENTS_DETAILS} from "../services/LogicService";

// ----------------------------------------------------------------------


export default function PaymentLinkComplete() {
    let {orderId, shareCode} = useParams();

    const navigate = useNavigate();
    let [confirmedAlready, setConfirmedAlready] = React.useState(false);
    let [text, setText] = React.useState("Se valideaza integritatea datelor...");

    function paymentLinkOrder(orderId, shareCode) {
        CommunicationService.paymentLinkOrder(orderId, shareCode)
            .then((response) => {
                if (response.active === false) {
                    return setText("Linkul nu mai este activ.");
                }
                CommunicationService.setToken(response.jwtResponse);
                let docKey = DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key;
                LogicService.addUploadedDocuments({key: docKey, uploaded: true});

                LogicService.setOrder(response.orderId, response.price, true,
                    response.paymentCode, response.necessaryDocuments, 'PERSON', undefined, true, response.active, response.fiscalDone, 'FISCAL');
                setConfirmedAlready(true);
                navigate('/');
            })
            .catch((error) => {
                setText(JSON.parse(error.message).message);
            });
    }

    if (!confirmedAlready) {
        paymentLinkOrder(orderId, shareCode);
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container maxWidth="xl">
                <Grid container spacing={5} align={"center"}>


                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component="div" variant="h3">
                            {text}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Page>
    );
}
