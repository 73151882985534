import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-firma-2.jpg"
import Foto_3 from "./images/cazier-judiciar-firma-3.jpg"
import Foto_4 from "./images/cazier-judiciar-firma-4.jpg"
import Foto_5 from "./images/cazier-judiciar-firma-5.jpg"
import Foto_6 from "./images/cazier-judiciar-firma-6.jpg"
import Foto_cta from "./images/cazier-judiciar-firma-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageFirma({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "",
        "cazier judiciar firma online",
        "acte necesare cazier judiciar firma",
        "acte cazier judiciar firma",
        "documente cazier judiciar firma",
        "cazier judiciar firma acte necesare",
        "cazier judiciar online persoane juridice",
        "cazier judiciar persoane juridice online",
        "cazier firma / cazier judiciar al firmei",
        "certificat cazier judiciar persoane juridice online",
        "cazier judiciar online firma pret"
    ]

    return (
        <Page title={`Cazier Judiciar Online firma`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={keywords.join(',')}/>
                <meta name="keywords" content={"Acum, cu serviciul nostru online optimizat, obținerea unui cazier judiciar pentru firma dumneavoastră este mai simplă și mai accesibilă ca niciodată."}/>
                <title>Cazier judiciar firmă - Obțineți acum rapid cazierul judiciar</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar firmă </h1>
                            <p>În lumea afacerilor, un cazier judiciar curat este esențial pentru succesul și
                                credibilitatea firmei
                                dumneavoastră. Realizarea tranzacțiilor comerciale, participarea la licitații sau
                                extinderea pe piețele
                                internaționale, toate acestea necesită prezentarea unui cazier judiciar imaculat.</p>
                            <p> Acum, cu serviciul nostru online optimizat, obținerea unui cazier judiciar pentru firma
                                dumneavoastră
                                este mai simplă și mai accesibilă ca niciodată.</p>
                            <a href={window.location.origin + "?person-type=business"}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>


                        <div className="col-md-6 bg-image" style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Solicitați rapid cazierului judiciar pentru firme!</h2>
                    <a href={window.location.origin + "?person-type=business"}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Obțineți online cazierul judiciar pentru firmă </h2>
                            <p>Trecând peste barierele birocratice, serviciile online de obținere a cazierului judiciar
                                al firmei
                                transformă un proces anevoios într-o sarcină simplă și rapidă. Mai mult decât atât,
                                serviciul de
                                obținere a cazierului judiciar online pentru firmă minimizează și riscul erorilor umane,
                                asigurând o
                                acuratețe sporită a datelor, fapt esențial pentru reputația juridică a companiei. În
                                plus, cu
                                ajutorul platformei digitale, puteți urmări în timp real stadiul solicitării și ați fi
                                notificați
                                imediat după finalizarea procesului, eliminând astfel incertitudinea așteptării. </p>
                            <p><strong>Beneficiile demersului online pentru obținerea cazierului judiciar al unei firme
                                sunt
                                următoarele: </strong></p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Economie de timp: Procesul online vă ajută să economisiți
                                    timp prețios
                                    și să nu mai fie necesare drumurile la instituții.
                                </li>
                                <li dir="ltr" aria-level="1">Confort: Puteți obțineți online cazierul judiciar al firmei
                                    de oriunde
                                    vă aflați.
                                </li>
                                <li dir="ltr" aria-level="1">Raport preț-calitate: Serviciile online au costuri
                                    competitive.
                                </li>
                                <li dir="ltr" aria-level="1">Accesibilitate: Indiferent de locația firmei, serviciul
                                    este la un
                                    click distanță.
                                </li>
                                <li>Siguranță: Informațiile dumneavoastră rămân confidențiale și sunt procesate cu
                                    maximă atenție.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Solicitați rapid cazierului judiciar pentru firme!</h2>
                    <a href={window.location.origin + "?person-type=business"}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Eliberare cazier judiciar online pentru persoane juridice prin caziere.ro - ce ne
                                recomandă</h2>
                            <p>Caziere.ro înțelege importanța rapidității în procesul de afaceri, iar serviciile noastre
                                de eliberare online
                                a cazierului judiciar pentru persoane juridice sunt special concepute pentru a sprijini
                                eficiența
                                corporativă și conformitatea legală. Cu o platformă ușor de utilizat și o comunicare
                                eficientă, ne asigurăm
                                că procesul de obținere a cazierului nu aduce perturbări fluxului de lucru al companiei
                                dumneavoastră,
                                oferind o soluție sigură și de încredere pentru toate necesitățile firmei legate de
                                verificările
                                judiciare.</p>

                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar firmă" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">

                            <p><strong>Caziere.ro se distinge pe piața serviciilor de obținere a cazierului judiciar
                                online pentru persoane
                                juridice printr-o reputație solidă și prin rezultatele remarcabile, fiind recomandată
                                de:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Experiență: Avem o perioadă îndelungată de servicii
                                    impecabile și feedback
                                    pozitiv de la clienți.
                                </li>
                                <li dir="ltr" aria-level="1">Profesionalism: Dispunem de o echipă calificată și dedicată
                                    obținerii în timp
                                    rapid al cazierului judiciar online pentru persoane juridice.
                                </li>
                                <li dir="ltr" aria-level="1">Flexibilitate: Avem servicii personalizate adaptate
                                    nevoilor fiecărei firme,
                                    inclusiv traduceri, legalizări și apostilări.
                                </li>
                                <li>Transparență: Practicăm prețuri transparente, fără taxe ascunse.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Solicitați rapid cazierului judiciar pentru firme!</h2>
                    <a href={window.location.origin + "?person-type=business"}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Când este necesar un cazier judiciar persoane juridice </h2>

                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <p>Când firma dumneavoastră aspiră la noi oportunități, fie că este vorba de atragerea de
                                noi clienți
                                sau parteneri de afaceri, participarea la evenimente și licitații, sau pur și simplu de
                                consolidarea
                                reputației, prezentarea unui cazier judiciar al firmei devine un document de încredere
                                esențial.</p>

                            <p>Un cazier judiciar curat poate oferi un avantaj competitiv în procesul de licitare,
                                demonstrând că
                                antecedentele legale ale companiei sunt fără pată și că respectă normele etice și de
                                integritate. De
                                asemenea, în cazul în care sunt necesare certificări sau autorizații de la stat,
                                prezentarea unui
                                cazier judiciar poate facilita și accelera procesul de obținere a acestora. În plus, în
                                contextul
                                expansiunii internaționale, companiile străine pot solicita adesea un cazier judiciar
                                actualizat al
                                partenerilor de afaceri pentru a se asigura de fiabilitatea și legalitatea
                                operațiunilor.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Acte necesare pentru obținerea cazierului judiciar pentru firmă</h2>
                            <p>Primul pas pentru obținerea cazierului judiciar al firmei dumneavoastră este pregătirea
                                documentației
                                necesare.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar firmă" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <p>De asemenea, este important să vă asigurați că toate documentele sunt actualizate și
                                conforme cu ultimele
                                reglementări oficiale. Este esențial de menționat că, pentru un proces fără probleme,
                                toate copiile
                                documentelor trebuie să fie clar lizibile și complete, pentru a evita posibilele
                                întârzieri sau
                                solicitări de documente suplimentare. Documentele necesare pentru obținerea unui cazier
                                online pentru
                                firmă sunt:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">O copie a actului care atestă identitatea reprezentantului
                                    legal.
                                </li>
                                <li dir="ltr" aria-level="1">Dovada calității de persoană juridică / Certificatul de
                                    înregistrare al
                                    firmei.
                                </li>
                                <li dir="ltr" aria-level="1">Împuternicire legală - un document care atestă dreptul
                                    reprezentantului de
                                    a acționa în numele firmei.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Solicitați rapid cazierului judiciar pentru firme!</h2>
                    <a href={window.location.origin + "?person-type=business"}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>Etapele obținerii unui cazier pentru firmă</h2>
                            <p>Procesul de obținere a unui cazier online pentru firma dumneavoastră este optimizat
                                pentru a asigura
                                eficiența și a reduce la minimum timpul de așteptare, permițându-vă să vă continuați
                                operațiunile fără
                                întreruperi.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar firmă" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>De asemenea, serviciul nostru asigură confidențialitatea completă a datelor firmei,
                                oferind liniștea
                                necesară în gestionarea tuturor aspectelor legale. Procesul de obținere a cazierului
                                firmei prin
                                platforma caziere.ro implică următorii pași simpli:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Completarea formularului de pe site și încărcarea
                                    documentelor necesare
                                </li>
                                <li dir="ltr" aria-level="1">Semnarea contractului care este acordul prin care
                                    caziere.ro este
                                    împuternicit să acționeze în numele dumneavoastră.
                                </li>
                                <li dir="ltr" aria-level="1">Procesarea cererii - etapa în care caziere.ro îndeplinește
                                    toate
                                    procedurile administrative.
                                </li>
                                <li dir="ltr" aria-level="1">Primirea cazierului în format electronic sau prin curier,
                                    în funcție de
                                    opțiunile selectate de dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt="Cazier judiciar firmă" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online pentru firmă - preț</h2>
                            <p>Cazier.ro practică o grilă de prețuri transparentă pentru obținerea unui cazier judiciar
                                online pentru o
                                firmă, acestea fiind calculate în funcție de nevoile specifice ale firmei dumneavoastră.
                                Astfel, costul
                                pentru obținerea certificatului de cazier judiciar online pentru o firmă poate varia,
                                asigurându-vă că
                                plătiți exact pentru ceea ce aveți nevoie, fără costuri ascunse sau neașteptate.</p>

                            <p>Indiferent dacă doriți eliberarea rapidă în regim de urgență sau standard, cu traduceri
                                legalizate în
                                diferite limbi și apostilare, caziere.ro se adaptează cerințelor specifice ale afacerii
                                dumneavoastră.
                                Vă oferim flexibilitatea de a alege cum doriți să primiți documentele: electronic sau
                                prin curier, în
                                România sau în străinătate, în cel mai eficient și economic mod posibil.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Solicitați rapid cazierului judiciar pentru firme!</h2>
                    <a href={window.location.origin + "?person-type=business"}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de cazier judiciar online pentru persoane juridice? Contactați-ne pentru
                                detalii! </h2>
                            <p>Pentru orice nelămuriri sau asistență suplimentară în procesul de obținere a cazierului
                                judiciar
                                pentru persoane juridice online, echipa noastră este disponibilă la adresa de email <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro </a>. Suntem aici pentru a face
                                procesul
                                cât mai simplu și mai accesibil pentru dumneavoastră.</p>

                            <p>Eliminați astfel birocrația și timpul irosit cu deplasările! Încredințați-ne
                                responsabilitatea
                                obținerii cazierului judiciar online pentru firma dumneavoastră și veți beneficia de o
                                experiență
                                fără griji, putând să vă concentrați pe ceea ce este cu adevărat important pentru
                                afacerea
                                dumneavoastră!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
