import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContactCard from "../../../pages/ContactCard";
import MessageForm from "../../authentication/order/MessageForm";
import ReportMessageForm from "../../authentication/order/ReportMessageForm";
import DragAndDropFileUploader from "../app/DragAndDropFileUploader";
import {Stack} from "@mui/material";

export default function ReportProblemModal({open, handleConfirm, handleClose, title, content}) {

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb: 5}}>
                    {content}
                </DialogContentText>
                <ReportMessageForm handleConfirm={handleConfirm}/>
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={handleConfirm} color={"success"}>*/}
            {/*        Trimite mesaj*/}
            {/*    </Button>*/}
            {/*    <Button onClick={handleClose} color={"error"} autoFocus>*/}
            {/*        Închide*/}
            {/*    </Button>*/}
            {/*</DialogActions>*/}
        </Dialog>

    );
}
