import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import "./AccessSelect.css"

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};


function getStyles(id, persons, theme) {
    return {
        fontWeight:
            persons.find(op => op.id === id) === undefined
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function AccessSelect({options, selected, onChange}) {
    const theme = useTheme();
    const optionsMapped = {};
    options.forEach(op => optionsMapped[op.id] = op);
    const [persons, setPersons] = React.useState(selected || []);

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        let existingOptions = persons.slice();
        let newOptionIndex = value.find(v => typeof v === 'number')
        let optionAlreadyExist = existingOptions.find(op => op.id === newOptionIndex);
        if (optionAlreadyExist !== undefined) {
            existingOptions = existingOptions.filter(op => op !== optionAlreadyExist)
            onChange(newOptionIndex, 'deny');
        } else {
            existingOptions.push(optionsMapped[newOptionIndex])
            onChange(newOptionIndex, 'grant')
        }

        setPersons(existingOptions);
    };

    return (
        <div>
            <FormControl sx={{m: 1, width: 150}}>
                <InputLabel id="demo-multiple-chip-label">Lawyer</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={persons}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                    renderValue={(selected) => {
                        return <Box sx={{display: 'flex', padding: '1px', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value.id} label={value.name}/>
                            ))}
                        </Box>
                    }}
                    MenuProps={MenuProps}
                    sx={{padding: '1px'}}
                >
                    {options.map((lawyer) => {
                        return <MenuItem
                            key={lawyer.id}
                            value={lawyer.id}
                            style={getStyles(lawyer.id, persons, theme)}
                        >
                            {lawyer.name}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    );
}