// material
import {Alert, AlertTitle, Button, Card, Container, Grid} from '@mui/material';
// components


import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {Helmet} from "react-helmet-async";
import Foto_2 from "./images/cazier-judiciar-online-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-6.jpg"
import Foto_7 from "./images/cazier-judiciar-online-7.jpg"
import Foto_cta from "./images/cazier-judiciar-online-cta.jpg"
import Page from "../../components/Page";
import "./style2.css"
import OrderRegisterCard from "../../components/_dashboard/app/OrderRegisterCard";
import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import PdfViewerComponent from "../PdfViewerComponent";
import {isMobile} from "react-device-detect";

// ----------------------------------------------------------------------

// <MainInfoPage/>
export default function MainPageV2_en({browserDataCheck}) {

    let order = LogicService.getOrder();
    let documents = LogicService.getUploadedDocuments();

    let signOrder = order !== null && order !== undefined
        && (documents.length === order.necessaryDocuments.length && documents.find(doc => doc.key === DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key) === undefined);

    // const [INTERFACE_V2_FORM_POSITION_LEFT, SET_INTERFACE_V2_FORM_POSITION_LEFT] = useState(true);

    let topLeftSpace = 7;
    let topRightSpace = 5;


    if (signOrder) {
        topLeftSpace = 6;
        topRightSpace = 6;
    }

    useEffect(() => {
        browserDataCheck();

        // LogicService.getFeatureSwitchCallback('INTERFACE_V2_FORM_POSITION_LEFT', (data, err) => {
        //     if (err) {
        //         return console.error(err);
        //     }
        //     if (data != null) {
        //         let interfaceFormPositionLeft = data.value;
        //         SET_INTERFACE_V2_FORM_POSITION_LEFT(interfaceFormPositionLeft);
        //     }
        // });

        const accordionHeaders = document.querySelectorAll('.accordion-header');

        accordionHeaders.forEach(header => {
            header.addEventListener('click', function () {
                const accordionContent = this.nextElementSibling;

                // Close all accordion items except the one being clicked
                document.querySelectorAll('.accordion-content').forEach(content => {
                    if (content !== accordionContent) {
                        content.style.display = 'none';
                    }
                });

                // Toggle the display of the clicked accordion item
                if (accordionContent.style.display === 'block') {
                    accordionContent.style.display = 'none';
                } else {
                    accordionContent.style.display = 'block';
                }
            });
        });

        // Show the content of the first accordion item by default
        const firstAccordionContent = document.querySelector('.accordion-content');
        firstAccordionContent.classList.add('show');
    }, []);

    let keywords = [
        "cazier online",
        "cazier judiciar",
        "eliberare cazier judiciar online",
        "cerere cazier judiciar online",
        "obtinere cazier judiciar online",
        "certificat de cazier judiciar",
        "cazier judiciar persoane juridice online",
        "cazier judiciar persoane fizice online"
    ]

    let containerStyle = {marginBottom: '40px', padding: '0 15%'};

    if (isMobile) {
        containerStyle.padding = '0 5px';
    }
    const myRef = useRef(null)
    const executeScroll = () => {
        window.scrollTo(0, myRef.current.offsetTop - 200);
        // myRef.current.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        // })
    }

    let infoCard = <Grid item xs={12} md={6} lg={topRightSpace}>
        {
            signOrder ?
                <Card className={"container-info-name"} sx={{height: "700px"}}>
                    <PdfViewerComponent/>
                </Card> :
                <div className="section" style={{backgroundColor: '#f8f8f8', padding: 0}}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="text-center padding-more hp-bg-image"
                                 style={{backgroundImage: `url(${Foto_2})`}}>
                                <div className="text">
                                    <h1>Online Criminal Record</h1>
                                    <p style={{fontWeight: 'bold', color: '#00ab55'}}>Fast and hassle-free criminal
                                        record issuance</p>
                                    {
                                        isMobile ? null :
                                            <p>Obtaining a criminal record for both individuals and legal entities is
                                                now simpler than ever. Whether you need this document for employment,
                                                studies, or international formalities, the request and issuance process
                                                has become much more efficient and accessible.</p>
                                    }
                                    <p>Through caziere.ro, you avoid trips to the counters and wasting precious time,
                                        benefiting from an optimized and secure platform for obtaining an online
                                        criminal record. By completing the online request, you will quickly receive the
                                        documents you need.</p>
                                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get
                                        a criminal record
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        }
    </Grid>;
    return (
        <Page title={`Cazier judiciar online - Eliberare cazier rapid și fără deplasări`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description"
                      content={"Obținerea cazierului judiciar online reprezintă soluția ideală pentru toți cei care au nevoie de acest document esențial în cel mai scurt timp posibil."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Online Criminal Record - Quick record release with no travel required</title>
                <link rel="stylesheet" href="./style2.css"/>

                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            <Container maxWidth="xxl" style={
                containerStyle
            }>
                {/*{*/}
                {/*    INTERFACE_V2_FORM_POSITION_LEFT ?*/}
                {/*        <Grid container spacing={5}>*/}
                {/*            <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">*/}
                {/*                <div ref={myRef}></div>*/}
                {/*                <OrderRegisterCard ref={myRef} />*/}
                {/*            </Grid>*/}
                {/*            {infoCard}*/}
                {/*        </Grid>*/}
                {/*        :*/}
                {/*        <Grid container spacing={5}>*/}
                {/*            {infoCard}*/}
                {/*            <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">*/}
                {/*                <div ref={myRef}></div>*/}
                {/*                <OrderRegisterCard/>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*}*/}


                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">
                        <div ref={myRef}></div>
                        <OrderRegisterCard ref={myRef} translate={true} />
                    </Grid>
                    {infoCard}
                </Grid>

            </Container>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Turn to caziere.ro for fast online criminal record issuance!</h2>

                    {
                        isMobile ?
                            <p>Obtaining a criminal record for both individuals and legal entities is now simpler than
                                ever. Whether you need this document for employment, studies, or international
                                formalities, the request and issuance process has become much more efficient and
                                accessible.</p> : null
                    }

                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get a criminal
                        record
                    </button>
                </div>

            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Quickly get an online criminal record</h2>
                            <p>Obtaining an online criminal record is the ideal solution for anyone who needs this
                                essential document as quickly as possible. Through our platform, the request process
                                becomes accessible and efficient, eliminating the need for physical trips and waiting in
                                long lines.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <h2>Benefits and advantages of an online criminal record</h2>
                            <p>In the digital age, access to public services is becoming increasingly simplified, and
                                obtaining an online criminal record proves to be an efficient and advantageous solution
                                for those looking to manage their time and resources intelligently.</p>

                            <p><strong>By opting to obtain the criminal record online, you will benefit from several
                                advantages such as:</strong></p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Time-saving - Eliminate the need for trips and waiting
                                    periods at counters.
                                </li>
                                <li dir="ltr" aria-level="1">Accessibility - The process can be started from anywhere,
                                    requiring only an internet connection.
                                </li>
                                <li dir="ltr" aria-level="1">Security - All your information is processed securely.</li>
                                <li dir="ltr" aria-level="1">Speed - Obtaining the online criminal record is fast,
                                    sometimes even on the same day.
                                </li>
                                <li>Convenience - The entire process can be completed from the comfort of your own home
                                    or office.
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Call caziere.ro to quickly obtain your criminal record online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get a criminal record
                    </button>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Situations where a criminal record is required</h2>
                            <p>The criminal record is an essential tool in evaluating character and history
                                one
                                individuals by various entities or institutions. It plays a crucial role in
                                ensuring
                                compliance with the legal norms in force.</p>

                            <p><strong>There are multiple situations in which the presentation of a criminal record is
                                mandatory:</strong>
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Employment - Many companies require a clean criminal record
                                    for us
                                    employees.
                                </li>
                                <li dir="ltr" aria-level="1">Studies - Enrollment in certain educational programs can
                                    require
                                    presentation of the criminal record.
                                </li>
                                <li dir="ltr" aria-level="1">Commercial activities - Obtaining licenses or
                                    authorizations of
                                    operation.
                                </li>
                                <li dir="ltr" aria-level="1">Legal actions - In adoption processes or in
                                    the procedure of
                                    marriage.
                                </li>
                                <li>Obtain citizenship - Applying for citizenship in another country may require
                                    OVERVIEW
                                    the criminal record.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Call caziere.ro to quickly obtain your criminal record online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get record
                        judicial
                    </button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Why use caziere.ro for online criminal record release</h2>
                            <p>At Caziere.ro, we are dedicated to providing a safe and effective method for release
                                record
                                judicial online, without leaving your home. With a simplified process and support
                                dedicated, us
                                we ensure that obtaining a criminal record is easier than ever. </p>
                            <p><strong>Choosing to turn to us, you benefit from:</strong></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Expertise - We have experience in this type of service.
                                </li>
                                <li dir="ltr" aria-level="1">Security - We protect all personal data against
                                    access
                                    unauthorized.
                                </li>
                                <li dir="ltr" aria-level="1">Support - Dedicated assistance throughout the process of
                                    to the team
                                    our.
                                </li>
                                <li dir="ltr" aria-level="1">Efficiency - Online criminal record release process
                                    is optimized
                                    for speed and simplicity.
                                </li>
                                <li>Flexibility - We offer solutions tailored to your needs, including translations and
                                    legalizations.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Online criminal record request</h3>
                            <p>Getting a criminal record online has become a simple and efficient process, saving you
                                of
                                unnecessary trips and lost time in public institutions. </p>
                            <p>Requesting a criminal record online can be done comfortably from home, without
                                the need for presence
                                physical, and the result - the scanned official document and, subsequently, the original
                                by courier
                                - it is yours
                                delivered directly. This modern, effective and uncomplicated method transforms
                                COLLECTION
                                criminal record in an easy task, adapted to the individual needs of each
                                people.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Steps required to obtain a criminal record online</h3>
                            <p>The procedure for obtaining a criminal record online begins by filling in the form,
                                being followed by
                                uploading scans of required documents, including a copy of your ID and
                                a
                                photo of you holding the document.</p>
                            <p>Once started, your application is processed quickly, with the possibility of receiving
                                the record
                                right in
                                the same day, depending on the options selected. The criminal record certificate will be
                                yours
                                SENT
                                digitally, followed by the physical version by express courier.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Call caziere.ro to quickly obtain your criminal record online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get record
                        judicial
                    </button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Certificate of criminal record online - required documents</h3>
                            <p>To quickly and easily obtain a criminal record certificate online is
                                necessary to
                                provide a specific set of documents to initiate the process. They include a copy
                                clear a
                                your ID or passport if you live outside the country or have
                                obtained citizenship
                                there, as well as a selfie photo of you holding the document of
                                identity.</p>

                            <p>You will also be required to complete and electronically sign a contract that us
                                legally authorize us to obtain the criminal record certificate on behalf
                                your. The process
                                it is simplified and efficient, allowing the documents to be obtained 100% online,
                                without the need
                                MOVEMENT
                                at the institutions' headquarters.</p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Criminal record of legal entities online</h3>
                            <p>Issuing the criminal record for legal entities can be done online,
                                simplifying
                                considerable process for companies. The legal representative of the entity had to
                                show
                                power of attorney by presenting proof of legal entity and a copy of its deed
                                of
                                identity, but now, all these procedures can be managed through
                                the platform
                                caziere.ro.</p>

                            <p>The service offered by cazier.ro requires submitting the application and submitting the
                                documents
                                necessary in the mod
                                electronically, eliminating the need for physical travel. This online system
                                streamline the process,
                                allowing legal entities to quickly obtain the necessary documentation.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Criminal record of natural persons online</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_6} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Obtaining a criminal record certificate for individuals is now simpler
                                and
                                accessible through the caziere.ro online service, which allows the submission and
                                processing
                                requests without the need to travel. Users just need to submit an online o
                                copy of the act
                                ID and a photo of it, after which an online contract will be signed
                                for
                                authorizing the service to obtain the record.</p>

                            <p>The issuing procedure can take between 1-5 working days, and the certificate obtained
                                will
                                be sent
                                initially in scanned format, to arrive later in original by express courier,
                                regardless of
                                applicant's location. This modern method saves time and reduces red tape,
                                By
                                the process of obtaining a criminal record quickly and efficiently for Romanian
                                citizens,
                                wherever it is
                                they find out.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_7} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Criminal record online - price</h2>
                            <p>Price for online criminal record includes all related fees and can be found in
                                the final step
                                of the order, it being calculated according to the desired specifications for the record
                                judicial.</p>
                            <p>Payment for criminal record release services can be made online via
                                miscellaneous
                                methods, including bank cards such as Visa, Mastercard or bank transfer,
                                thus facilitating
                                quick access to the necessary documents. Once payment is made, customers are notified
                                by email and
                                sms about the status of their order, benefiting from an efficient and hassle-free
                                service
                                complications.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Call caziere.ro to quickly obtain your criminal record online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get record
                        judicial
                    </button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 style={{textAlign: 'center'}}>Getting a criminal record online - questions and
                                answers</h2>

                            <div className="accordion">
                                <div className="accordion-item">
                                    <div className="accordion-header">I need to travel in person for
                                        obtaining the record
                                        judicial?
                                    </div>
                                    <div className="accordion-content show">
                                        <p>No, it is not necessary. The online services offered by caziere.ro make it
                                            possible
                                            COLLECTION
                                            criminal record certificate online without the need for physical travel
                                            at the headquarters
                                            public institutions or embassies/consulates. All you have to do
                                            is to
                                            submit the required documents online and pay the fee
                                            services
                                            desired.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">What documents are needed to obtain
                                        criminal record
                                        online through caziere.ro?
                                    </div>
                                    <div className="accordion-content">
                                        <p>To get your criminal record online, you need a copy of
                                            Articles
                                            your identity card (or passport, for Romanians abroad),
                                            a photograph
                                            (selfie) holding the deed and electronically signing a contract by
                                            which
                                            caziere.ro is authorized to obtain the certificate on its behalf
                                            you.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">How long does it take to get a criminal record
                                        online?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Average processing time varies between 1 and 5 business days, depending on
                                            REGIME
                                            chosen and the correct and timely completion of all documents
                                            necessary. In those
                                            in several cases, it can be obtained on the same day.</p>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <div className="accordion-header">Can get a criminal record online and for
                                        people
                                        legal?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Yes, our services facilitate obtaining the criminal record not only
                                            for people
                                            individuals, but also for legal entities. The process is simplified and not
                                            more
                                            require
                                            travel to the police units.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">How to pay for services to obtain a
                                        record
                                        online court and how safe are the payment methods?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Payment for services can be made online using secure methods, including
                                            bank cards
                                            (VISA, Mastercard, American Express, Discover, etc.), or by transfer
                                            classic banking.
                                            The process is safe and efficient and the electronic invoice is sent
                                            automatically
                                            by email
                                            after making the payment.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Call caziere.ro to quickly obtain your criminal record online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Get record
                        judicial
                    </button>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Do you need your criminal record online? Contact us for details!</h2>
                            <p>For online criminal records and personalized consultations, feel free to
                                contact us
                                by e-mail at <a href="mailto:contact@caziere.ro">contact@caziere.ro</a>. Team
                                ours is
                                ready to assist you and answer all your questions. Safety and fairness
                                dates
                                you are our priorities and the process of obtaining a criminal record
                                by
                                through our online service is fast and efficient, eliminating the need
                                moving to
                                the counters of state institutions! </p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
