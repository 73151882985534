import * as React from 'react';
import {useEffect, useState} from 'react';

import {
    Box,
    Button,
    Card,
    Container, Grid,
    IconButton,
    Stack, Tab,
    Table,
    TableContainer, Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell';
import AppCurrentVisits from "../components/_dashboard/app/AppCurrentVisits";
import {AppConversionRates, AppTrafficBySite, AppWebsiteVisits, AppWeeklySales} from "../components/_dashboard/app";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import AppStatsEvolution from "../components/_dashboard/app/AppStatsEvolution";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
export default function Statistics() {
    const [stats, setStats] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = React.useState(0);


    let [countPaidStripeArr, setCountPaidStripeArr] = useState([]);
    let [countPaidBankingTransferArr, setCountPaidBankingTransferArr] = useState([]);

    let [countPaidStripeArrCaziereRo, setCountPaidStripeArrCaziereRo] = useState([]);
    let [countPaidBankingTransferArrCaziereRo, setCountPaidBankingTransferArrCaziereRo] = useState([]);
    let [countPaidTotalArrCaziereRo, setCountPaidTotalArrCaziereRo] = useState([]);


    let [countPaidStripeArrCazierOnlineRo, setCountPaidStripeArrCazierOnlineRo] = useState([]);
    let [countPaidBankingTransferArrCazierOnlineRo, setCountPaidBankingTransferArrCazierOnlineRo] = useState([]);
    let [countPaidTotalArrCazierOnlineRo, setCountPaidTotalArrCazierOnlineRo] = useState([]);

    let [countPaidArr , setCountPaidArr] = useState([]);
    let [countAllArr , setCountAllArr] = useState([]);
    let [revenuePaidStripeArr, setRevenuePaidStripeArr] = useState([]);
    let [revenuePaidBankingTransferArr , setRevenuePaidBankingTransferArr] = useState([]);
    let [revenuePaidArr , setRevenuePaidArr] = useState([]);
    let [revenueAllArr, setRevenueAllArr] = useState([]);
    let [labels, setLabels] = useState([]);

    let computeArray = (arr) => {
        let dates = [];
        let countPaidStripeArr = [];
        let countPaidBankingTransferArr = [];

        let countPaidStripeArrCaziereRo = [];
        let countPaidBankingTransferArrCaziereRo = [];
        let countPaidTotalArrCaziereRo = [];


        let countPaidStripeArrCazierOnlineRo = [];
        let countPaidBankingTransferArrCazierOnlineRo = [];
        let countPaidTotalArrCazierOnlineRo = [];

        let countPaidArr = [];
        let countAllArr = [];
        let revenuePaidStripeArr = [];
        let revenuePaidBankingTransferArr = [];
        let revenuePaidArr = [];
        let revenueAllArr = [];

        arr.forEach(el => {
            dates.push(el.date)
            countPaidStripeArr.push(el.countPaidStripe)
            countPaidBankingTransferArr.push(el.countPaidBankingTransfer)

            countPaidStripeArrCaziereRo.push(el.countPaidStripeCaziere)
            countPaidBankingTransferArrCaziereRo.push(el.countPaidBankingTransferCaziere)
            countPaidTotalArrCaziereRo.push(el.countPaidStripeCaziere + el.countPaidBankingTransferCaziere)

            countPaidStripeArrCazierOnlineRo.push(el.countPaidStripeCazierOnline)
            countPaidBankingTransferArrCazierOnlineRo.push(el.countPaidBankingTransferCazierOnline)
            countPaidTotalArrCazierOnlineRo.push(el.countPaidStripeCazierOnline + el.countPaidBankingTransferCazierOnline)

            countPaidArr.push(el.countPaid)
            countAllArr.push(el.countAll)
            revenuePaidStripeArr.push(el.revenuePaidStripe)
            revenuePaidBankingTransferArr.push(el.revenuePaidBankingTransfer)
            revenuePaidArr.push(el.revenuePaid)
            revenueAllArr.push(el.revenueAll)
        })

        setLabels(dates);
        setCountPaidStripeArr(countPaidStripeArr);
        setCountPaidBankingTransferArr(countPaidBankingTransferArr);

        setCountPaidStripeArrCaziereRo(countPaidStripeArrCaziereRo);
        setCountPaidBankingTransferArrCaziereRo(countPaidBankingTransferArrCaziereRo);
        setCountPaidTotalArrCaziereRo(countPaidTotalArrCaziereRo);

        setCountPaidStripeArrCazierOnlineRo(countPaidStripeArrCazierOnlineRo);
        setCountPaidBankingTransferArrCazierOnlineRo(countPaidBankingTransferArrCazierOnlineRo);
        setCountPaidTotalArrCazierOnlineRo(countPaidTotalArrCazierOnlineRo);

        setCountPaidArr(countPaidArr);
        setCountAllArr(countAllArr);
        setRevenuePaidStripeArr(revenuePaidStripeArr);
        setRevenuePaidBankingTransferArr(revenuePaidBankingTransferArr);
        setRevenuePaidArr(revenuePaidArr);
        setRevenueAllArr(revenueAllArr);

    }

    useEffect(() => {
        CommunicationService.getStatsLastSevenDays()
            .then((response) => {
                setStats(response);
                computeArray(response);
                setDates(response.map((r, index) => index))
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }, []);

    const handleChange = (event, newValue) => {
        setSelectedDate(newValue);
    };

    let getRow = (data, index) => {
        return <TableRow
            key={data.date}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="center" component="th" scope="row">
                {data.date}
            </TableCell>
            <TableCell sx={{background: '#AFEEEE'}} align="center">{data.countPaidStripe}</TableCell>
            <TableCell sx={{background: '#AFEEEE'}} align="center">{data.countPaidBankingTransfer}</TableCell>

            <TableCell sx={{background: '#9bcccc'}} align="center">{data.countPaidStripeCaziere}</TableCell>
            <TableCell sx={{background: '#9bcccc'}} align="center">{data.countPaidBankingTransferCaziere}</TableCell>
            <TableCell sx={{background: '#AFEEEE'}} align="center">{data.countPaidStripeCazierOnline}</TableCell>
            <TableCell sx={{background: '#AFEEEE'}} align="center">{data.countPaidBankingTransferCazierOnline}</TableCell>

            <TableCell sx={{background: '#40E0D0'}} align="center">{data.countPaid}</TableCell>
            <TableCell sx={{background: '#40E0D0'}} align="center">{data.countNotPaid}</TableCell>
            <TableCell sx={{background: '#40E0D0'}} align="center">{((data.countPaid / data.countAll) * 100).toFixed(2)} %</TableCell>
            <TableCell sx={{background: '#40E0D0'}} align="center">{data.countAll}</TableCell>
            <TableCell sx={{background: '#F5DEB3'}} align="center">{data.revenuePaidStripe}</TableCell>
            <TableCell sx={{background: '#F5DEB3'}} align="center">{data.revenuePaidBankingTransfer}</TableCell>
            <TableCell sx={{background: '#FAEBD7'}} align="center">{data.revenuePaid}</TableCell>
            <TableCell sx={{background: '#FAEBD7'}} align="center">{data.revenueNotPaid}</TableCell>
            <TableCell sx={{background: '#FAEBD7'}} align="center">{data.revenueAll}</TableCell>
        </TableRow>
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container sx={{maxWidth: "2000px !important"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Statistics
                    </Typography>
                </Stack>

                <Card sx={{padding: "30px", marginBottom: "20px", textAlign: "left"}}>
                    <Scrollbar>
                        <Stack spacing={1} alignItems="left">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}} colSpan={6}>Order count</TableCell>
                                            <TableCell align="center" sx={{background: '#40E0D0'}} colSpan={4}>Totals Count</TableCell>
                                            <TableCell align="center" sx={{background: '#F5DEB3'}} colSpan={2}>Revenue sum</TableCell>
                                            <TableCell align="center" sx={{background: '#FAEBD7'}} colSpan={3}>Totals Revenue (LEI)</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}} colSpan={2}></TableCell>
                                            <TableCell align="center" sx={{background: '#9bcccc'}} colSpan={2}>Caziere.ro</TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}} colSpan={2}>Cazier-online.ro</TableCell>
                                            <TableCell align="center" sx={{background: '#40E0D0'}} colSpan={4}></TableCell>
                                            <TableCell align="center" sx={{background: '#F5DEB3'}} colSpan={2}></TableCell>
                                            <TableCell align="center" sx={{background: '#FAEBD7'}} colSpan={3}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Date</TableCell>

                                            <TableCell align="center" sx={{background: '#AFEEEE'}}>Stripe</TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}}>Banking Transfer</TableCell>

                                            <TableCell align="center" sx={{background: '#9bcccc'}}>Stripe</TableCell>
                                            <TableCell align="center" sx={{background: '#9bcccc'}}>Banking Transfer</TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}}>Stripe</TableCell>
                                            <TableCell align="center" sx={{background: '#AFEEEE'}}>Banking Transfer</TableCell>

                                            <TableCell align="center" sx={{background: '#40E0D0'}}>Paid</TableCell>
                                            <TableCell align="center" sx={{background: '#40E0D0'}}>Non-paid</TableCell>
                                            <TableCell align="center" sx={{background: '#40E0D0'}}>Conv. Rate</TableCell>
                                            <TableCell align="center" sx={{background: '#40E0D0'}}>Total</TableCell>
                                            <TableCell align="center" sx={{background: '#F5DEB3'}}>Stripe</TableCell>
                                            <TableCell align="center" sx={{background: '#F5DEB3'}}>Banking Transfer</TableCell>
                                            <TableCell align="center" sx={{background: '#FAEBD7'}}>Paid</TableCell>
                                            <TableCell align="center" sx={{background: '#FAEBD7'}}>Non-paid</TableCell>
                                            <TableCell align="center" sx={{background: '#FAEBD7'}}>Potential</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats.map((row, index) => getRow(row, index))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Stack>
                    </Scrollbar>
                </Card>


                <Grid container spacing={5}>
                    <Grid item xs={6} sm={6} md={6}>
                        <AppStatsEvolution title={"Orders count"}
                            labels={labels} data={[countPaidStripeArr, countPaidBankingTransferArr, countPaidArr]}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <AppStatsEvolution title={"Orders revenue"} detailsSuffix={"lei"}
                                           labels={labels} data={[revenuePaidStripeArr, revenuePaidBankingTransferArr, revenuePaidArr]}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={5} sx={{marginTop: '5px'}}>
                    <Grid item xs={6} sm={6} md={6}>
                        <AppStatsEvolution title={"Caziere.ro count"}
                            labels={labels} data={[countPaidStripeArrCaziereRo, countPaidBankingTransferArrCaziereRo, countPaidTotalArrCaziereRo]}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <AppStatsEvolution title={"Cazier-online.ro count"} detailsSuffix={"lei"}
                                           labels={labels} data={[countPaidStripeArrCazierOnlineRo, countPaidBankingTransferArrCazierOnlineRo, countPaidTotalArrCazierOnlineRo]}
                        />
                    </Grid>
                </Grid>

                {/*<Card sx={{padding: "30px", textAlign: "center"}}>*/}
                {/*    <Scrollbar>*/}
                {/*        <Stack spacing={1} alignItems="center">*/}
                {/*            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                {/*                <Tabs value={selectedDate} onChange={handleChange} aria-label="basic tabs example">*/}
                {/*                    <Tab label="Item One" />*/}
                {/*                    <Tab label="Item Two" />*/}
                {/*                    <Tab label="Item Three"  />*/}
                {/*                </Tabs>*/}
                {/*            </Box>*/}
                {/*            <TabPanel value={selectedDate} index={0}>*/}
                {/*                <Stack direction={{xs: 'column', sm: 'row'}}>*/}
                {/*                    <AppCurrentVisits/>*/}
                {/*                    <AppWebsiteVisits/>*/}
                {/*                </Stack>*/}
                {/*            </TabPanel>*/}
                {/*            <TabPanel value={selectedDate} index={1}>*/}
                {/*                Item Two*/}
                {/*            </TabPanel>*/}
                {/*            <TabPanel value={selectedDate} index={2}>*/}
                {/*                Item Three*/}
                {/*            </TabPanel>*/}

                {/*        </Stack>*/}

                {/*    </Scrollbar>*/}
                {/*</Card>*/}
            </Container>
        </Page>
    );
}
