import * as Yup from 'yup';
import * as React from 'react';
import {useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
// material
import {Button, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import CountrySelect from "./CountrySelect";
import * as Countries from "../../../constants/Countries";
import APIConstants from "../../../constants/APIConstants";
import * as AlertMapper from "../../../services/AlertMapper";
import DragAndDropFileUploader from "../../_dashboard/app/DragAndDropFileUploader";
import {sendMessageWithFile} from "../../../services/CommunicationService";

// ----------------------------------------------------------------------


export default function ReportMessageForm({handleConfirm}) {
    const navigate = useNavigate();

    let [files, setFiles] = React.useState([]);

    let browserData = LogicService.getBrowserData();


    let RegisterSchema = Yup.object().shape({
        reason: Yup.string().required('Mesajul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Mesajulul este invalid'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            reason: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });


    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 5000);
    }

    const buttonPress = () => {
        if (Boolean(touched.email && errors.email)
            || Boolean(touched.reason && errors.reason)) {
            return;
        }

        let ceva = []
        for (var i = 0; i < localStorage.length; i++){
            ceva.push(localStorage.key(i) + ": " + localStorage.getItem(localStorage.key(i)).toString())
        }

        let clientMessage = {}
        clientMessage = {
            email: getFieldProps('email').value,
            message: getFieldProps('reason').value,
            browserData: browserData,
            currentURL: window.location.href,
            localStrg: ceva
        }
        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);

        let file = files.length === 0 ? undefined : files[0];

        CommunicationService.sendMessageWithFile(clientMessage, file)
            .then((response) => {
                handleConfirm();
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    const processReasonChange = (event) => {
        let newText = event.target.value;
        setFieldValue('reason', newText)
    }

    let semiPhaseOne = <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{mt: 2}}>
            <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
            />

            <TextField
                fullWidth
                label="Descrierea problemei"
                multiline
                rows={4}
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                onChange={processReasonChange}
                helperText={touched.reason && errors.reason}
            />

            <Typography variant={"body1"} justifyContent={"center"} align={"center"}>
                Încarcă și o captură de ecran care să clarifice problema ta
            </Typography>

            <Stack direction={{xs: 'column', sm: 'row', marginTop: "5px"}}
                   sx={{margin: "0% 1%"}}
                   spacing={0} alignSelf={"center"}>
                <DragAndDropFileUploader setFiles={setFiles}/>
            </Stack>

            <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={buttonPress}
                disabled={
                    !buttonEnabled
                    || !touched.email || Boolean(touched.email && errors.email)
                    || !touched.reason || Boolean(touched.reason && errors.reason)
                    || files.length === 0
                }
            >
                Trimiteți mesajul
            </Button>
        </Stack>
    </Form>;


    return (
        <FormikProvider value={formik}>
            {
                semiPhaseOne
            }
        </FormikProvider>
    );
}
