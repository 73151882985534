import React from 'react'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {Box, Button, IconButton, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {bindHover, bindPopover, usePopupState,} from 'material-ui-popup-state/hooks'
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const PositionedMenu = ({menu}) => {
    const navigate = useNavigate();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoPopover',
    })

    const handleRedirect = (path) => {
        // console.log(path)
        navigate(path)
    }

    let simplePath = menu.values === undefined || menu.values === null || menu.values.length === 0;

    let mainClickAction = () => {
    }
    if (simplePath) {
        mainClickAction = () => handleRedirect(menu.path)
    }

    return (
        <div>
            {
                // menu.isIcon ? getLangButton(menu) :
                    <Button variant="text" {...bindHover(popupState)}
                            endIcon={simplePath ? null : <KeyboardArrowDownIcon/>}
                            onClick={mainClickAction}>
                        {menu.text}
                    </Button>
            }
            {
                simplePath ? null : <HoverPopover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {
                        menu.values.map((v, i) =>
                            <MenuItem key={`key-p--m-${i}`} onClick={() => handleRedirect(v.path)}
                                      sx={{margin: "10px"}}>
                                <ListItemIcon>
                                    {v.icon}
                                </ListItemIcon>
                                <ListItemText>{v.text}</ListItemText>
                            </MenuItem>)
                    }
                </HoverPopover>
            }
        </div>
    )
}

export default PositionedMenu