// material
import {Alert, AlertTitle, Button, Card, Container, Grid} from '@mui/material';
// components


import * as React from "react";
import {useEffect, useRef, useState} from "react";

import {Helmet} from "react-helmet-async";
import Foto_2 from "./images/cazier-judiciar-online-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-6.jpg"
import Foto_7 from "./images/cazier-judiciar-online-7.jpg"
import Foto_cta from "./images/cazier-judiciar-online-cta.jpg"
import Page from "../../components/Page";
import "./style2.css"
import OrderRegisterCard from "../../components/_dashboard/app/OrderRegisterCard";
import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import PdfViewerComponent from "../PdfViewerComponent";
import {isMobile} from "react-device-detect";

// ----------------------------------------------------------------------

// <MainInfoPage/>
export default function MainPageV2({browserDataCheck}) {

    let order = LogicService.getOrder();
    let documents = LogicService.getUploadedDocuments();

    let signOrder = order !== null && order !== undefined
        && (documents.length === order.necessaryDocuments.length && documents.find(doc => doc.key === DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key) === undefined);

    // const [INTERFACE_V2_FORM_POSITION_LEFT, SET_INTERFACE_V2_FORM_POSITION_LEFT] = useState(true);

    let topLeftSpace = 7;
    let topRightSpace = 5;


    if (signOrder) {
        topLeftSpace = 6;
        topRightSpace = 6;
    }

    useEffect(() => {
        browserDataCheck();

        // LogicService.getFeatureSwitchCallback('INTERFACE_V2_FORM_POSITION_LEFT', (data, err) => {
        //     if (err) {
        //         return console.error(err);
        //     }
        //     if (data != null) {
        //         let interfaceFormPositionLeft = data.value;
        //         SET_INTERFACE_V2_FORM_POSITION_LEFT(interfaceFormPositionLeft);
        //     }
        // });

        const accordionHeaders = document.querySelectorAll('.accordion-header');

        accordionHeaders.forEach(header => {
            header.addEventListener('click', function () {
                const accordionContent = this.nextElementSibling;

                // Close all accordion items except the one being clicked
                document.querySelectorAll('.accordion-content').forEach(content => {
                    if (content !== accordionContent) {
                        content.style.display = 'none';
                    }
                });

                // Toggle the display of the clicked accordion item
                if (accordionContent.style.display === 'block') {
                    accordionContent.style.display = 'none';
                } else {
                    accordionContent.style.display = 'block';
                }
            });
        });

        // Show the content of the first accordion item by default
        const firstAccordionContent = document.querySelector('.accordion-content');
        firstAccordionContent.classList.add('show');
    }, []);

    let keywords = [
        "cazier online",
        "cazier judiciar",
        "eliberare cazier judiciar online",
        "cerere cazier judiciar online",
        "obtinere cazier judiciar online",
        "certificat de cazier judiciar",
        "cazier judiciar persoane juridice online",
        "cazier judiciar persoane fizice online"
    ]

    let containerStyle = {marginBottom: '40px', padding: '0 15%'};

    if (isMobile) {
        containerStyle.padding = '0 5px';
    }
    const myRef = useRef(null)
    const executeScroll = () => {
        window.scrollTo(0, myRef.current.offsetTop - 200);
        // myRef.current.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        // })
    }

    let infoCard = <Grid item xs={12} md={6} lg={topRightSpace}>
        {
            signOrder ?
                <Card className={"container-info-name"} sx={{height: "700px"}}>
                    <PdfViewerComponent/>
                </Card> :
                <div className="section" style={{backgroundColor: '#f8f8f8', padding: 0}}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="text-center padding-more hp-bg-image"
                                 style={{backgroundImage: `url(${Foto_2})`}}>
                                <div className="text">
                                    <h1>Cazier judiciar online</h1>
                                    <p style={{fontWeight: 'bold', color: '#00ab55'}}>Eliberare cazier rapid și fără
                                        deplasări</p>
                                    {
                                        isMobile ? null : <p>Obținerea unui cazier judiciar atât pentru persoane fizice cât și pentru persoane
                                            juridice, este
                                            acum mai simplă ca oricând. Fie că aveți nevoie de acest document pentru
                                            angajare,
                                            studii sau
                                            formalități internaționale, procesul de solicitare și eliberare a devenit mult
                                            mai
                                            eficient și
                                            mai accesibil.</p>
                                    }

                                    <p>Prin intermediul caziere.ro, evitați drumurile la ghișee și pierderea de timp
                                        prețios,
                                        beneficiind de o platformă optimizată și sigură pentru eliberarea unui cazier
                                        judiciar online.
                                        Completând cererea online, veți intra rapid în posesia documentelor de care
                                        aveți
                                        nevoie.</p>
                                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        }
    </Grid>;
    return (
        <Page title={`Cazier judiciar online - Eliberare cazier rapid și fără deplasări`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description"
                      content={"Obținerea cazierului judiciar online reprezintă soluția ideală pentru toți cei care au nevoie de acest document esențial în cel mai scurt timp posibil."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online - Eliberare cazier rapid și fără deplasări</title>
                <link rel="stylesheet" href="./style2.css"/>

                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>

            <Container maxWidth="xxl" style={
                containerStyle
            }>
                {/*{*/}
                {/*    INTERFACE_V2_FORM_POSITION_LEFT ?*/}
                {/*        <Grid container spacing={5}>*/}
                {/*            <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">*/}
                {/*                <div ref={myRef}></div>*/}
                {/*                <OrderRegisterCard ref={myRef} />*/}
                {/*            </Grid>*/}
                {/*            {infoCard}*/}
                {/*        </Grid>*/}
                {/*        :*/}
                {/*        <Grid container spacing={5}>*/}
                {/*            {infoCard}*/}
                {/*            <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">*/}
                {/*                <div ref={myRef}></div>*/}
                {/*                <OrderRegisterCard/>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*}*/}


                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={topLeftSpace} className="orderRegisterCard">
                        <div ref={myRef}></div>
                        <OrderRegisterCard ref={myRef} />
                    </Grid>
                    {infoCard}
                </Grid>

            </Container>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>

                    {
                        isMobile ? <p>Obținerea unui cazier judiciar atât pentru persoane fizice cât și pentru persoane
                            juridice, este
                            acum mai simplă ca oricând. Fie că aveți nevoie de acest document pentru
                            angajare,
                            studii sau
                            formalități internaționale, procesul de solicitare și eliberare a devenit mult
                            mai
                            eficient și
                            mai accesibil.</p> : null
                    }

                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Obține rapid un cazier judiciar online</h2>
                            <p>Obținerea cazierului judiciar online reprezintă soluția ideală pentru toți cei care au
                                nevoie de
                                acest document esențial în cel mai scurt timp posibil. Prin intermediul platformei
                                noastre, procesul
                                de solicitare devine accesibil și eficient, eliminând necesitatea deplasărilor fizice și
                                așteptarea
                                la cozi lungi.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <h2>Beneficiile și avantajele unui cazier judiciar online</h2>
                            <p>În era digitală accesul la serviciile publice se simplifică tot mai mult, iar obținerea
                                cazierului
                                judiciar online se dovedește a fi o soluție eficientă și avantajoasă pentru cei care
                                doresc să-și
                                gestioneze timpul și resursele într-un mod inteligent.</p>

                            <p><strong>Optând pentru obținerea cazierului online, veți beneficia de mai multe avantaje
                                precum:</strong></p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Economie de timp - Eliminați necesitatea deplasărilor și a
                                    perioadelor
                                    de așteptare la ghișee.
                                </li>
                                <li dir="ltr" aria-level="1">Accesibilitate - Procesul poate fi demarat de oriunde,
                                    având nevoie
                                    doar de o conexiune la internet.
                                </li>
                                <li dir="ltr" aria-level="1">Securitate - Toate informațiile dumneavoastră sunt
                                    procesate în mod
                                    sigur.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditate - Obținerea cazierului online este rapidă,
                                    uneori chiar în
                                    aceeași zi.
                                </li>
                                <li>Confort - Tot procesul se poate finaliza din confortul propriului cămin său birou.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Situațiile în care este necesar un cazier judiciar</h2>
                            <p>Cazierul judiciar este un instrument esențial în evaluarea caracterului și a istoricului
                                unei
                                persoane de către diverse entități sau instituții. Acesta joacă un rol crucial în
                                asigurarea
                                conformității cu normele legale în vigoare.</p>

                            <p><strong>Există multiple situații în care prezentarea unui cazier judiciar este
                                obligatorie:</strong>
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Angajare - Multe companii solicită un cazier judiciar curat
                                    pentru noii
                                    angajați.
                                </li>
                                <li dir="ltr" aria-level="1">Studii - Înscrierea la anumite programe educaționale poate
                                    necesita
                                    prezentarea cazierului judiciar.
                                </li>
                                <li dir="ltr" aria-level="1">Activități comerciale - Obținerea licențelor sau
                                    autorizațiilor de
                                    funcționare.
                                </li>
                                <li dir="ltr" aria-level="1">Demersuri juridice - În procesele de adopție sau în
                                    procedura de
                                    căsătorie.
                                </li>
                                <li>Obținere cetățenie - Solicitarea cetățeniei într-o altă țară poate necesita
                                    prezentarea
                                    cazierului judiciar.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>De ce să apelați la caziere.ro pentru eliberare de cazier judiciar online</h2>
                            <p>La Caziere.ro, suntem dedicați să oferim o metodă sigură și eficientă pentru eliberare
                                cazier
                                judiciar online, fără a vă deplasa de acasă. Cu un proces simplificat și asistență
                                dedicată, ne
                                asigurăm că obținerea cazierului judiciar este mai ușoară ca niciodată. </p>
                            <p><strong>Alegând să apelați la noi, beneficiați de:</strong></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Expertiză - Avem experiență în acest tip de servicii.
                                </li>
                                <li dir="ltr" aria-level="1">Siguranță - Protejăm toate datele personale împotriva
                                    accesului
                                    neautorizat.
                                </li>
                                <li dir="ltr" aria-level="1">Suport - Asistență dedicată pe tot parcursul procesului de
                                    către echipa
                                    noastră.
                                </li>
                                <li dir="ltr" aria-level="1">Eficiență - Procesul de eliberare cazier judiciar online
                                    este optimizat
                                    pentru rapiditate și simplitate.
                                </li>
                                <li>Flexibilitate - Oferim soluții adaptate nevoilor dvs, inclusiv traduceri și
                                    legalizări.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Cerere cazier judiciar online</h3>
                            <p>Obținerea unui cazier judiciar online a devenit un proces simplu și eficient, scutindu-vă
                                de
                                deplasări inutile și de timp pierdut în cadrul instituțiilor publice. </p>
                            <p>Cererea unui cazier judiciar online se poate efectua confortabil de acasă, fără
                                necesitatea prezenței
                                fizice, iar rezultatul - documentul oficial scanat și, ulterior, originalul prin curier
                                - vă este
                                livrat direct. Această metodă modernă, eficientă și lipsită de complicații transformă
                                obținerea
                                cazierului judiciar într-o sarcină ușoară, adaptată nevoilor individuale ale fiecărei
                                persoane.
                            </p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Etapele necesare pentru obținerea unui cazier judiciar online</h3>
                            <p>Procedura obținerii cazierului judiciar online începe prin completarea formularului,
                                fiind urmată de
                                încărcarea scanărilor documentelor necesare, inclusiv o copie a actului de identitate și
                                o
                                fotografie în care țineți documentul în mână.</p>
                            <p>Odată demarată, cererea dvs. este procesată rapid, cu posibilitatea de a primi cazierul
                                chiar în
                                aceeași zi, în funcție de opțiunile selectate. Certificatul de cazier judiciar vă va fi
                                trimis
                                digital, urmat de versiunea fizică prin curier rapid.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Certificat de cazier judiciar online - acte necesare</h3>
                            <p>Pentru obținerea rapidă și ușoară a unui certificat de cazier judiciar online este
                                necesar să
                                furnizați un set specific de documente pentru a iniția procesul. Acestea includ o copie
                                clară a
                                actului dvs. de identitate sau a pașaportului, dacă locuiți în afara țării sau ați
                                obținut cetățenia
                                acolo, precum și o fotografie tip selfie în care țineți în mână documentul de
                                identitate.</p>

                            <p>De asemenea, va fi necesar să completați și să semnați electronic un contract care să ne
                                împuternicească legal să obținem certificatul de cazier judiciar în numele
                                dumneavoastră. Procesul
                                este simplificat și eficient, permițând obținerea actelor 100% online, fără necesitatea
                                deplasării
                                la sediile instituțiilor.</p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Cazier judiciar persoane juridice online</h3>
                            <p>Eliberarea cazierului judiciar pentru persoane juridice poate fi efectuată online,
                                simplificând
                                considerabil procesul pentru companii. Reprezentantul legal al entității trebuia să
                                demonstreze
                                împuternicirea prin prezentarea dovezii de persoană juridică și a copiei după actul său
                                de
                                identitate, dar acum, toate aceste proceduri pot fi gestionate prin intermediul
                                platformei
                                caziere.ro.</p>

                            <p>Serviciul oferit de cazier.ro necesită depunerea cererii și transmiterea documentelor
                                necesare în mod
                                electronic, eliminând necesitatea deplasărilor fizice. Acest sistem online
                                eficientizează procesul,
                                permiţând persoanelor juridice să obțină rapid documentația necesară.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Cazier judiciar persoane fizice online</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_6} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Obținerea unui certificat de cazier judiciar pentru persoane fizice este acum mai simplă
                                și
                                accesibilă prin intermediul serviciului online caziere.ro, care permite depunerea și
                                procesarea
                                cererilor fără necesitatea deplasării. Utilizatorii trebuie doar să trimită online o
                                copie a actului
                                de identitate și o fotografie cu acesta, după care un contract online va fi semnat
                                pentru
                                împuternicirea serviciului de obținere a cazierului.</p>

                            <p>Procedura de eliberare poate dura între 1-5 zile lucrătoare, iar certificatul obținut va
                                fi trimis
                                inițial în format scanat, urmând să sosească mai apoi în original prin curier rapid,
                                indiferent de
                                locația solicitantului. Această metodă modernă economisește timp și reduce birocrația,
                                făcând
                                procesul de obținere a cazierului judiciar rapid și eficient pentru cetățenii români,
                                oriunde s-ar
                                afla ei.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_7} alt="Cazier judiciar online" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online - preț</h2>
                            <p>Prețul pentru cazierul judiciar online include toate taxele aferente și poate fi aflat în
                                pasul final
                                al comenzii, acesta fiind calculat în funcție de specificațiile dorite pentru cazierul
                                judiciar.</p>
                            <p>Plata pentru serviciile de eliberare a cazierului judiciar poate fi efectuată online prin
                                diverse
                                metode, inclusiv prin carduri bancare precum Visa, Mastercard sau transfer bancar,
                                facilitând astfel
                                accesul rapid la documentele necesare. Odată efectuată plata, clienții sunt notificați
                                prin email și
                                sms despre stadiul comenzii lor, beneficiind de un serviciu eficient și lipsit de
                                complicații.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 style={{textAlign: 'center'}}>Obținere cazier judiciar online - întrebări și
                                răspunsuri</h2>

                            <div className="accordion">
                                <div className="accordion-item">
                                    <div className="accordion-header">Este necesar să mă deplasez personal pentru
                                        obținerea cazierului
                                        judiciar?
                                    </div>
                                    <div className="accordion-content show">
                                        <p>Nu, nu este necesar. Serviciile online oferite de caziere.ro, fac posibilă
                                            obținerea
                                            certificatului de cazier judiciar online fără necesitatea deplasării fizice
                                            la sediile
                                            instituțiilor publice sau la ambasade/consulate. Tot ce trebuie să faceți
                                            este să
                                            trimiteți documentele necesare online și să plătiți contravaloarea
                                            serviciilor
                                            dorite.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">Ce documente sunt necesare pentru a obține
                                        cazierul judiciar
                                        online prin caziere.ro?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Pentru obținerea cazierului judiciar online, aveți nevoie de o copie a
                                            actului
                                            dumneavoastră de identitate (sau pașaport, pentru românii din străinătate),
                                            o fotografie
                                            (selfie) ținând actul în mână și semnarea electronică a unui contract prin
                                            care
                                            caziere.ro este împuternicit să obțină certificatul în numele
                                            dumneavoastră.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">Cât durează obținerea cazierului judiciar
                                        online?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Timpul mediu de procesare variază între 1 și 5 zile lucrătoare, în funcție de
                                            regimul
                                            ales și de completarea corectă și în timp util a tuturor documentelor
                                            necesare. În cele
                                            mai multe cazuri, se poate obține chiar în aceeași zi.</p>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <div className="accordion-header">Se poate obține cazier judiciar online și pentru
                                        persoane
                                        juridice?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Da, serviciile noastre facilitează obținerea cazierului judiciar nu numai
                                            pentru persoane
                                            fizice, ci și pentru persoane juridice. Procesul este simplificat și nu mai
                                            necesită
                                            deplasarea la unitățile de poliție.</p>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header">Cum se face plata pentru serviciile de obținere a
                                        cazierului
                                        judiciar online și cât sunt de sigure metodele de plată?
                                    </div>
                                    <div className="accordion-content">
                                        <p>Plata serviciilor se poate efectua online prin metode sigure, inclusiv
                                            carduri bancare
                                            (VISA, Mastercard, American Express, Discover etc.), sau prin transfer
                                            bancar clasic.
                                            Procesul este sigur și eficient, iar factura electronică se trimite automat
                                            pe email
                                            după efectuarea plății.</p>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la caziere.ro pentru obținerea rapidă a cazierului judiciar online!</h2>
                    <button onClick={executeScroll}><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de cazierul judiciar online? Contactaţi-ne pentru detalii!</h2>
                            <p>Pentru obținerea cazierului judiciar online și consultații personalizate, nu ezitați să
                                ne contactați
                                prin e-mail la <a href="mailto:contact@caziere.ro">contact@caziere.ro</a>. Echipa
                                noastră este
                                pregătită să vă asiste și să răspundă tuturor întrebărilor. Siguranța și corectitudinea
                                datelor
                                dumneavoastră sunt prioritățile noastre iar procesul de obținere a cazierului judiciar
                                prin
                                intermediul serviciului nostru online este rapid și eficient, eliminând necesitatea
                                deplasării la
                                ghișeele instituțiilor statului! </p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
