import {Icon} from '@iconify/react';
import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component, useEffect} from 'react';
// material
import {
    Box, Button,
    Card,
    Checkbox,
    Container, Grid,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AccessSelect from "./AccessSelect";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import * as Languages from "../constants/Languages";
import * as Countries from "../constants/Countries";
import ReportToolbar from "../components/_dashboard/user/ReportToolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fDateTimeForBackend} from "../utils/formatTime";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import DocumentUploader from "../components/authentication/order/DocumentUploader";
// ----------------------------------------------------------------------


export default class TestingBench extends Component {

    constructor() {
        super()
    }
    stringifyEvent(e) {
        const arrData = [];
        for (let i=0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            const obj = {
                lastModified: file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type,
                webkitRelativePath: file.webkitRelativePath,
            }
            arrData.push( obj );
        }
        return JSON.stringify(arrData);
    }

    render() {

        return (
            <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">

                <Container maxWidth={"1600px"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Testing bench
                        </Typography>
                    </Stack>

                    <Card sx={{padding: "10px"}}>
                        <Box>

                            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}} gutterBottom>
                                    Test inital:
                                </Typography>

                                <input accept="image/*, application/pdf"
                                       onChange={(event) => {
                                           alert(this.stringifyEvent(event));
                                           // setDisableButton(true);
                                       }}
                                       multiple type="file"
                                />
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}} gutterBottom>
                                    Test environment:
                                </Typography>

                                <input accept="image/*, application/pdf"
                                       capture="environment"
                                       onChange={(event) => {
                                           alert(this.stringifyEvent(event));
                                       }}
                                       multiple type="file"
                                />
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}} spacing={2} alignSelf={"center"}>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}} gutterBottom>
                                    Test 3:
                                </Typography>

                                <p>
                                    <label htmlFor="cameraFile">Take a picture:</label>
                                    <input name="cameraFile" type="file" capture="environment" accept="image/*"

                                           onChange={(event) => {
                                               alert(this.stringifyEvent(event));
                                           }}
                                    />
                                </p>
                                <p>
                                    <label htmlFor="storageFile">Choose existing file:</label>
                                    <input name="storageFile" type="file" accept="image/*"
                                           onChange={(event) => {
                                               alert(this.stringifyEvent(event));
                                           }}
                                    />
                                </p>
                            </Stack>

                        </Box>
                    </Card>
                </Container>
            </Page>
        );
    }

}
