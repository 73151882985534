import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Stack} from "@mui/material";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size="5rem" variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Stack direction={{xs: 'column', sm: 'row'}} display={"block"} spacing={0} justifyContent={"center"}>
                    <Typography variant="caption" component="div" color="text.secondary">
                        <b>{`${Math.round(props.value)}%`}</b>
                    </Typography>
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round((600000 - Math.round(props.remaining)) / 1000)} sec`}
                    </Typography>
                </Stack>

            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircularStatic({toDate, machineLocalTime}) {
    const [progress, setProgress] = React.useState(100);
    const [remaining, setRemaining] = React.useState(100);

    // console.log(machineLocalTime, new Date())

    React.useEffect(() => {
        const timer = setInterval(() => {
            let timeBetween = new Date() - new Date(toDate);
            let remaining = 100 - Math.round(timeBetween / 6000);
            setRemaining(timeBetween);
            if (progress == 0) {
                clearInterval(timer);
                window.location.reload();
            }
            setProgress((prevProgress) => (remaining <= 0 ? 0 : remaining));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return <CircularProgressWithLabel value={progress} remaining={remaining} />;
}