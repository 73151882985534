import {Icon} from '@iconify/react';
import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component, useEffect} from 'react';
// material
import {
    Box,
    Card,
    Checkbox,
    Container, Grid,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AccessSelect from "./AccessSelect";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import * as Languages from "../constants/Languages";
import * as Countries from "../constants/Countries";
import ReportToolbar from "../components/_dashboard/user/ReportToolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fDateTimeForBackend} from "../utils/formatTime";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import * as PropTypes from "prop-types";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'createdAt', label: 'Creat', alignRight: false},
    {id: 'type', label: 'Tip', alignRight: false},
    {id: 'orderId', label: 'Email', alignRight: false},
    {id: 'toWhom', label: 'First Name', alignRight: false},
    {id: 'response', label: 'Last name', alignRight: false},
    {id: 'subject', label: 'CUI', alignRight: false},
    {id: 'message', label: 'Telefon', alignRight: false},
    {id: 'reason', label: 'Motiv', alignRight: false}
];

export default class FingerprintsTable extends React.Component {
    render() {
        return <Card sx={{}}>
            <Scrollbar>
                <TableContainer sx={{minWidth: 800}}>
                    <Table>
                        <UserListHead
                            order={this.props.order}
                            orderBy={this.props.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={this.props.rowCount}
                            numSelected={this.props.selected.length}
                            onRequestSort={this.props.onRequestSort}
                            onSelectAllClick={this.props.onSelectAllClick}
                        />
                        <TableBody>
                            {this.props.filteredUsers.map((row) => this.mapOrderTo(row, this.props.selected))}
                        </TableBody>
                        {this.props.userNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                        <SearchNotFound searchQuery={this.props.searchQuery}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={this.props.rowCount}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                onPageChange={this.props.onPageChange}
                onRowsPerPageChange={this.props.onRowsPerPageChange}
            />
        </Card>;
    }

    mapOrderTo(row, selected) {
        const {
            id, email, name, createdAt, orderId, type, toWhom, response, subject, message, firstName, lastName, cui, phoneNumber, reason
        } = row;

        const isItemSelected = selected.indexOf(name) !== -1;


        return this.getOrderRow(id, isItemSelected, email, name, createdAt, orderId, type, toWhom, response, subject, message, firstName, lastName, cui, phoneNumber, reason);
    }

    getOrderRow(id, isItemSelected, email, name, createdAt, orderId, type, toWhom, response, subject, message, firstName, lastName, cui, phoneNumber, reason) {
        return (
            <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
            >

                <TableCell align="left"></TableCell>
                <TableCell align="left">{id}</TableCell>
                <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
                <TableCell align="left">{type}</TableCell>
                <TableCell align="left">{email}</TableCell>
                <TableCell align="left">{firstName}</TableCell>
                <TableCell align="left">{lastName}</TableCell>
                <TableCell align="left">{cui}</TableCell>
                <TableCell align="left">{phoneNumber}</TableCell>
                <TableCell align="left">{reason}</TableCell>
            </TableRow>
        );
    }
}

