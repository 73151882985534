import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-oradea-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-oradea-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-oradea-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-oradea-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-oradea-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-oradea-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageOradea({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier online Oradea",
        "eliberare cazier Oradea online",
        "cazier Oradea online",
        "cazier judiciar Oradea online",
        "obtinere cazier judiciar online Oradea",
        "cazier judiciar online Oradea pret"
    ]

    return (
        <Page title={`Cazier judiciar online Oradea - Acum mult mai simplu de obținut`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Prin serviciul nostru optimizat, obținerea cazierului judiciar online din Oradea devine o experiență simplă și eficientă."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Oradea - Acum mult mai simplu de obținut</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Oradea</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Acum mult mai simplu de obținut</p>
                            <p>Înțelegem nevoia dumneavoastră de a obține rapid și fără bătăi de cap documente
                                esențiale, precum
                                cazierul judiciar necesar în diverse situații. Prin serviciul nostru optimizat,
                                obținerea cazierului
                                judiciar online din Oradea devine o experiență simplă și eficientă. Nu mai este nevoie
                                să pierdeți timp
                                așteptând la coadă la ghișee pentru că totul se rezolvă cu doar câteva clicuri!
                            </p>
                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>


                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Faceți primul pas către obținerea rapidă a cazierului judiciar online din Oradea!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Cazier judiciar online Oradea - obținere rapidă pe platforma caziere.ro</h2>
                            <p>Obținerea unui cazier judiciar online din Oradea nu a fost niciodată mai simplă.
                                Serviciul nostru vă
                                permite să economisiți timp prețios, eliminând necesitatea deplasărilor. Alegând
                                serviciul nostru
                                pentru eliberarea cazierului judiciar din Oradea online, veți beneficia de:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Profesionalism: Echipa noastră are experiență vastă în
                                    domeniu.
                                </li>
                                <li dir="ltr" aria-level="1">Eficiență: Procesăm cererile rapid și eficient.
                                </li>
                                <li dir="ltr" aria-level="1">Flexibilitate: Oferim soluții adaptate nevoilor
                                    dumneavoastră.
                                </li>
                                <li dir="ltr" aria-level="1">Transparență: Costurile sunt comunicate clar și nu există
                                    taxe ascunse.
                                </li>
                                <li>Flexibilitate: Opțiunea de a alege între diferite metode de plată online, inclusiv
                                    card bancar
                                    sau transfer, pentru a facilita procesul și a-l face cât mai convenabil pentru
                                    dumneavoastră.
                                </li>
                            </ul>
                            <p>Aceste avantaje demonstrează angajamentul nostru de a oferi un serviciu de calitate,
                                adaptat nevoilor
                                cetățenilor din Oradea, și de a simplifica procedurile administrative, permițându-vă să
                                vă
                                concentrați pe ceea ce este cu adevărat important pentru dumneavoastră.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Faceți primul pas către obținerea rapidă a cazierului judiciar online din Oradea!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Avantajele solicitării online a cazierului din Oradea</h2>
                            <p>O cerere online pentru un cazier judiciar din orașul Oradea nu numai că simplifică
                                procesul, dar
                                aduce și o serie de beneficii semnificative pentru solicitant. În era digitală, accesul
                                la servicii
                                online eficiente și sigure este esențial, iar serviciul de eliberare a cazierului
                                judiciar online
                                din Oradea răspunde acestei nevoi cu profesionalism și atenție la detalii.</p>

                            <p><strong>
                                Avantajele solicitării online a cazierului din Oradea includ:</strong></p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online Oradea"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Economia de timp: Evitați deplasările și așteptarea la
                                    cozi,
                                    economisind ore prețioase care pot fi folosite în alte scopuri productive.
                                </li>
                                <li dir="ltr" aria-level="1">Confortul: Procesul se poate realiza de acasă sau de la
                                    birou, fără a
                                    fi nevoie să vă întrerupeți activitățile zilnice.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditatea: Obținerea documentului se face într-un timp
                                    scurt, uneori
                                    chiar în aceeași zi, dacă toate documentele sunt în ordine.
                                </li>
                                <li dir="ltr" aria-level="1">Securitatea datelor personale: Datele dumneavoastră sunt
                                    protejate.
                                </li>
                                <li>Accesibilitate: Serviciul este disponibil 24/7, permițându-vă să faceți solicitarea
                                    în orice
                                    moment, fără a fi constrânși de programul instituțiilor publice. Totodată, serviciul
                                    este
                                    disponibil pentru toți cetățenii, indiferent de locație
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Faceți primul pas către obținerea rapidă a cazierului judiciar online din Oradea!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Situații în care este necesar cazierul judiciar în Oradea </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online Oradea"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <p>Cazierul judiciar este solicitat în diverse situații, cum ar fi angajarea într-un nou loc
                                de muncă,
                                înscrierea la cursuri sau obținerea cetățeniei unei alte țări. De asemenea, cazierul
                                judiciar este
                                indispensabil atunci când o persoană dorește să își înceapă propria afacere sau să
                                obțină licențe și
                                autorizații specifice de la autoritățile competente.</p>
                            <p>În contextul adopțiilor sau al custodiei minorilor, autoritățile pot solicita prezentarea
                                cazierului
                                judiciar pentru a asigura un mediu sigur și stabil pentru copii. Nu în ultimul rând,
                                pentru cei care
                                doresc să participe la licitații publice sau să acceseze anumite funcții publice,
                                prezentarea unui
                                cazier judiciar curat este adesea o condiție obligatorie.</p>
                            <p>Astfel, eliberarea online a cazierului judiciar din Oradea devine un serviciu esențial
                                pentru
                                eficientizarea și simplificarea demersurilor legale și administrative necesare în aceste
                                situații.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>De ce acte aveți nevoie pentru emiterea online unui cazier judiciar în Oradea</h2>
                            <p>Procesul de obținere a cazierului judiciar din Oradea a fost simplificat prin
                                posibilitatea de a-l
                                solicita online, eliminând astfel nevoia deplasării fizice și așteptarea la cozi. Acest
                                serviciu
                                facilitează accesul la documentele necesare într-un mod rapid și eficient, oferind o
                                soluție
                                convenabilă pentru toți cei care au nevoie de el.</p>

                            <p><strong>Pentru a obține cazierul judiciar online din Oradea, aveți nevoie de următoarele
                                acte:</strong></p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Copie a actului de identitate, care să fie clară și
                                    lizibilă, pentru a
                                    asigura verificarea identității dumneavoastră fără probleme.
                                </li>
                                <li dir="ltr" aria-level="1">Fotografie tip selfie cu actul de identitate în mână,
                                    pentru a confirma
                                    că persoana care solicită documentul este aceeași cu cea din actul de identitate.
                                </li>
                                <li dir="ltr" aria-level="1">Contract semnat online, prin care ne împuterniciți să
                                    acționăm în
                                    numele dumneavoastră. Acest contract este esențial pentru a ne permite să gestionăm
                                    procedura de
                                    solicitare și obținere a cazierului judiciar în mod legal și eficient.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Faceți primul pas către obținerea rapidă a cazierului judiciar online din Oradea!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Timp de soluționare a cererii pentru un cazier judiciar online din Oradea</h2>
                            <p>Timpul de soluționare a cererii pentru cazierul judiciar online din Oradea variază între
                                1 și 5 zile
                                lucrătoare, în funcție de serviciile solicitate. Această variație este determinată de
                                complexitatea
                                serviciilor adiționale cerute, cum ar fi apostilarea, supralegalizarea sau traducerea
                                documentelor.
                                Pentru a accelera procesul, este recomandat să furnizați toate documentele necesare
                                într-un format
                                clar și complet de la început.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{paddingTop: '10px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Ce etape trebuie parcurse pentru a obține un cazier judiciar online din Oradea</h2>
                            <p>Procesul de obținere a cazierului judiciar online din Oradea este acum mai simplu și mai
                                accesibil
                                decât oricând, eliminând necesitatea deplasărilor fizice și așteptarea în cozi lungi la
                                instituțiile
                                statului. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar online Oradea"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Acest serviciu este conceput pentru a veni în întâmpinarea nevoilor cetățenilor, oferind
                                o modalitate
                                eficientă și rapidă de a obține documentele necesare direct de acasă.</p>
                            <p><strong>Acesta implică următorii pași:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Completarea formularului de comandă pe site-ul nostru.
                                </li>
                                <li dir="ltr" aria-level="1">Trimiterea documentelor necesare.
                                </li>
                                <li dir="ltr" aria-level="1">Procesarea cererii de către echipa noastră.
                                </li>
                                <li dir="ltr" aria-level="1">Primirea cazierului judiciar online.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt="Cazier judiciar online Oradea"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online Oradea - preț</h2>
                            <p>Costul obținerii cazierului judiciar online din Oradea variază în funcție de mai mulți
                                factori,
                                inclusiv regimul de urgență, necesitatea traducerii și legalizării, precum și
                                modalitatea de
                                livrare. Pentru a obține cazierul judiciar online cu regim de urgență, costurile pot fi
                                mai
                                ridicate, dar acest serviciu asigură primirea documentului într-un timp mult mai scurt
                                comparativ cu
                                procedura standard.</p>
                            <p>Dacă este necesară traducerea și legalizarea documentului pentru utilizare în afara
                                țării, aceste
                                servicii suplimentare vor adăuga costuri adiționale la prețul final. De asemenea,
                                modalitatea de
                                livrare aleasă, fie că este vorba despre primirea documentului prin email în format
                                digital sau prin
                                curierat rapid pentru versiunea fizică, influențează costul total al serviciului.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Faceți primul pas către obținerea rapidă a cazierului judiciar online din Oradea!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de informații despre procedura de obținere a unui cazier judiciar online
                                din Oradea?
                                Contactați-ne!</h2>
                            <p>Pentru orice întrebări sau nelămuriri legate de procesul de obținere a cazierului
                                judiciar online din
                                Oradea, echipa noastră este la dispoziția dumneavoastră. Ne puteți contacta la adresa de
                                email <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro</a>.</p>

                            <p>Prin serviciul nostru, obținerea cazierului judiciar online din Oradea devine o
                                experiență simplă și
                                accesibilă. Economisiți timp și efort, apelând la profesioniștii noștri pentru a vă
                                asigura că
                                procesul se desfășoară fără probleme.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
