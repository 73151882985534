import {filter} from 'lodash';
import {useEffect, useState} from 'react';
// material
import {
    Avatar, Button,
    Card,
    Container, IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserMoreMenu} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import {mockImgAvatar} from "../utils/mockImages";
import RoleSelect from "./RoleSelect";
import {
    createBlacklistItem,
    deleteBlacklistItem,
    getAllBlacklist,
    updateDefault
} from "../services/CommunicationService";
import AddPaymentLinkModal from "../components/_dashboard/user/AddPaymentLinkModal";
import * as React from "react";
import AddBlacklistItemModal from "../components/_dashboard/user/AddBlacklistItemModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../components/_dashboard/user/ConfirmModal";
import ConfirmOrRejectModal from "../components/_dashboard/user/ConfirmOrRejectModal";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'field', label: 'Field', alignRight: false},
    {id: 'value', label: 'Value', alignRight: false},
    {id: 'createdBy', label: 'Created by', alignRight: false},
    {id: 'createdAt', label: 'Created at', alignRight: false},
    {id: 'hits', label: 'Hits', alignRight: false}
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Blacklist() {
    const [usersRetrieved, setUsersRetrieved] = useState(false);
    const [USERLIST, SET_USERLIST] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [deleteItemOpen, setDeleteItemOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const mapUser = (user) => {
        return user;
    }

    const getUsers = () => {
        CommunicationService.getAllBlacklist()
            .then((response) => {
                SET_USERLIST(response.map(u => mapUser(u)))
                setUsersRetrieved(true);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    useEffect(() => {
            getUsers();
    }, []);

    const addBlacklistItem = (obj) => {
        CommunicationService.createBlacklistItem(obj)
            .then((response) => {
                location.reload();
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.BLACKLIST_ITEM_CREATED);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const deleteBlacklistItem = (id) => {
        CommunicationService.deleteBlacklistItem(id)
            .then((response) => {
                location.reload();
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.BLACKLIST_ITEM_DELETED);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Blacklist
                    </Typography>
                </Stack>

                <Card>
                    <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">
                        <Button variant="contained" color={'warning'} sx={{margin: "25px"}}
                                onClick={() => setCreateModalIsOpen(true)}>
                            {"Create"}
                        </Button>
                    </Stack>
                    <AddBlacklistItemModal open={createModalIsOpen}
                                         handleConfirm={(obj) => {
                                             addBlacklistItem(obj);
                                             setCreateModalIsOpen(false)
                                         }}
                                         handleClose={() => setCreateModalIsOpen(false)}
                    />

                    <ConfirmOrRejectModal open={deleteItemOpen}
                                          handleClose={() => {
                                              setDeleteItemOpen(false)
                                          }}
                                          handleConfirm={() => {
                                              deleteBlacklistItem(selectedItem);
                                              setDeleteItemOpen(false);
                                          }}
                                          title={"Confirmați stergerea itemului de Blacklist?"}
                                          content={"Dupa apasarea butonului de confirm, itemul va fi sters definitiv."}
                    />



                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {id, field, value, createdByUsername, createdAt, hits} = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell align="left">{id}</TableCell>
                                                    <TableCell align="left">{field}</TableCell>
                                                    <TableCell align="left">{value}</TableCell>
                                                    <TableCell align="left">{createdByUsername}</TableCell>
                                                    <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
                                                    <TableCell align="left">{hits}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton color="error" component="label" onClick={() => {
                                                            setSelectedItem(id);
                                                            setDeleteItemOpen(true);
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={USERLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
