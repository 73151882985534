let identifier = '356565ck';
let region = 'eu-west-1';
let deployObject = {
    apiGateway: {
        REGION: region,
        URL: `https://${identifier}.execute-api.${region}.amazonaws.com/dev/`
    },
    cognito: {
        REGION: region,
        USER_POOL_ID: `${region}_kjytj454`,
        APP_CLIENT_ID: '',
        DOMAIN: `${identifier}.auth.${region}.amazoncognito.com`,
        SCOPE: ['email', 'openid'],
        REDIRECT_SIGN_IN: `https://ceva-bucket.s3-${region}.amazonaws.com/index.html`,
        REDIRECT_SIGN_OUT: `https://ceva-bucket.s3-${region}.amazonaws.com/index.html`,
        RESPONSE_TYPE: 'code'
    },
    server: {
        // AWS: `https://${identifier}.execute-api.${region}.amazonaws.com/Stage`,
        LOCAL: `http://localhost:8666/api`,
        TEST: `https://52.212.242.49:8666/api`,
        AWS: `https://caziere.ro/api`
    }
}

export default deployObject;