import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, Card, Container, Link, Typography} from '@mui/material';
// layouts
// components
import Page from '../components/Page';
import {MHidden} from '../components/@material-extend';
import {RegisterForm} from '../components/authentication/register';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({theme}) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
    return (
        <RootStyle title="Register | Minimal-UI">


            <MHidden width="mdDown">
                <SectionStyle>
                    <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                        Înregistrează-te
                    </Typography>
                    <img alt="register" src="/static/illustrations/illustration_register.png"/>
                </SectionStyle>
            </MHidden>

            <Container>
                <ContentStyle>
                    <Box sx={{mb: 5}}>
                        <Typography variant="h4" gutterBottom>
                            Introdu datele personale
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>
                            O analiză a administratorilor va fi necesară pentru a avea acces la sistem
                        </Typography>
                    </Box>

                    <RegisterForm/>

                    <Typography variant="body2" align="center" sx={{color: 'text.secondary', mt: 3}}>
                        Prin înregistrare afirm că sunt de acord cu &nbsp;
                        <Link underline="always"
                              component={RouterLink}
                              to="/terms-and-conditions"
                              sx={{color: 'text.primary'}}>
                            Termenii & Condițiile
                        </Link>
                        &nbsp;și&nbsp;
                        <Link underline="always"
                              component={RouterLink}
                              to="/privacy-policy"
                              sx={{color: 'text.primary'}}>
                            Politica de confidențialitate
                        </Link>
                        .
                    </Typography>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
