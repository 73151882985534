import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextRowsTimeline from "./TextRowsTimeline";
import "./MainInfoPage.css";
import {Timeline} from "@mui/lab";
import Messages from "../../../constants/Messages";
import TextRowsTimelineV2 from "./TextRowsTimelineV2";

const RootStyle = styled(Card)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    }
}));

const BoxStyle = styled(Box)(({theme}) => ({

    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        width: '65%',
    }
}));

const SubBoxStyle = styled(Box)(({theme}) => ({

    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        marginTop: "5%"
    }
}));

const TextRowsTimelineStyle = styled(Timeline)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '50%'
    }
}));

const CardMediaStyled = styled(CardMedia)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '35%'
    }
}));

export default function MainInfoPage() {
    return (
        <RootStyle>
            <CardMediaStyled
                component="img"
                image="/static/illustrations/eliberare-cazier-judiciar-online.webp"
                alt="cazier judiciar online fără deplasări"
            />
            <BoxStyle>
                <CardContent sx={{flex: '1 0 auto', textAlign: 'center'}}>
                    <Typography component="div" variant="h3" id={"keepStyles1"}>
                        Eliberare Certificat De Cazier Judiciar Online
                    </Typography>
                    <SubBoxStyle>
                        {/*{*/}
                        {/*    Messages.MAIN_PAGE_CONTENT.map((message, index) =>*/}
                        {/*        <TextRowsTimelineStyle key={index}>*/}
                        {/*            <TextRowsTimeline ROWS={message.ROWS}*/}
                        {/*                              noSuppSpaces={true}*/}
                        {/*                              rowFontType={'body1'}/>*/}
                        {/*        </TextRowsTimelineStyle>*/}
                        {/*    )*/}
                        {/*}*/}

                        {
                            Messages.MAIN_PAGE_CONTENT.map((message, index) =>
                                <TextRowsTimelineStyle key={index}>
                                    <TextRowsTimelineV2 ROWS={message.ROWS}
                                                      noSuppSpaces={true}
                                                      rowFontType={'body1'}/>
                                </TextRowsTimelineStyle>
                            )
                        }
                    </SubBoxStyle>
                </CardContent>
            </BoxStyle>
        </RootStyle>
    );
}
