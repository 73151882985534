import * as Yup from 'yup';
import * as React from 'react';
import {useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
// material
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import CountrySelect from "./CountrySelect";
import * as Countries from "../../../constants/Countries";
import APIConstants from "../../../constants/APIConstants";
import * as AlertMapper from "../../../services/AlertMapper";

// ----------------------------------------------------------------------


export default function MessageForm() {
    const navigate = useNavigate();
    let browserData = LogicService.getBrowserData();

    let countryToChooseAsDefault = Countries.getCountryByName('Romania');
    if (!APIConstants.DEV_MODE) {
        if (browserData === undefined || browserData === null ||
            browserData.country_name === undefined || browserData.country_name === null) {
        } else {
            countryToChooseAsDefault = Countries.getCountryByName(browserData.country_name);
        }
    }

    const [country, changeCountry] = useState(countryToChooseAsDefault);

    let RegisterSchema = Yup.object().shape({
        lastName: Yup.string().required('Prenumele este necesar').matches(/.*/, 'Prenumele este invalid').min(3, 'Prea scurt').max(20, 'Prea lung'),
        firstName: Yup.string().required('Numele este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(20, 'Prea lung'),
        phoneNumber: Yup.string().required('Numărul de telefon este necesar').min(6, 'Prea scurt').max(40, 'Prea lung'),
        reason: Yup.string().required('Mesajul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Mesajulul este invalid'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            reason: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });


    const normalizePhoneNumber = (prefix, phoneNo) => {
        if (prefix === undefined || prefix === null) {
            return phoneNo;
        }

        let prefixLen = prefix.length;
        for (let i = 0; i < prefixLen; i++) {
            let subStrToVerify = prefix.substring(i, prefixLen);
            if (phoneNo.startsWith(subStrToVerify)) {
                return '00' + prefix.substring(0, i) + phoneNo;
            }
        }
        return '00' + prefix + phoneNo;
    }

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 5000);
    }

    const buttonPress = () => {
        if (Boolean(touched.email && errors.email)
            || country === null || country === undefined
            || Boolean(touched.phoneNumber && errors.phoneNumber)
            || Boolean(touched.firstName && errors.firstName)
            || Boolean(touched.lastName && errors.lastName)
            || Boolean(touched.reason && errors.reason)) {
            return;
        }

        if (country.code === 'RO' && getFieldProps('phoneNumber').value.match(/^[0]{0,1}7\d{8}$/) === null) {
            setFieldError('phoneNumber', 'Numărul de telefon nu este valid.', false);
            return;
        }

        let clientMessage = {}
        clientMessage = {
            email: getFieldProps('email').value,
            phoneNumber: normalizePhoneNumber(country.phone, getFieldProps('phoneNumber').value),
            country: country.label,
            firstName: getFieldProps('firstName').value,
            lastName: getFieldProps('lastName').value,
            message: getFieldProps('reason').value,
            browserData: browserData
        }
        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);

        CommunicationService.sendMessage(clientMessage)
            .then((response) => {
                navigate('/');
                window.location.reload();
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    const processReasonChange = (event) => {
        let newText = event.target.value;
        setFieldValue('reason', newText)
    }

    let semiPhaseOne = <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
            <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
            />

            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <CountrySelect defaultValue={country} selectOption={(option) => {
                    changeCountry(option);
                }}/>
                <TextField
                    fullWidth
                    autoComplete="phoneNumber"
                    label="Număr de telefon"
                    {...getFieldProps('phoneNumber')}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    disabled={country === null || country === undefined}
                    InputProps={{
                        startAdornment:
                            (country === null || country === undefined) ? null :
                                <InputAdornment position="start">+{country.phone}</InputAdornment>,
                    }}
                />
            </Stack>

            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <TextField
                    fullWidth
                    label="Nume"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                />

                <TextField
                    fullWidth
                    label="Prenume"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                />
            </Stack>

            <TextField
                fullWidth
                label="Mesajul dumneavoastră"
                multiline
                rows={4}
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                onChange={processReasonChange}
                helperText={touched.reason && errors.reason}
            />

            <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={buttonPress}
                disabled={
                    !buttonEnabled
                    || Boolean(country === null || country === undefined)
                    || !touched.email || Boolean(touched.email && errors.email)
                    || !touched.phoneNumber || Boolean(touched.phoneNumber && errors.phoneNumber)
                    || Boolean(touched.firstName && errors.firstName)
                    || Boolean(touched.lastName && errors.lastName)
                    || !touched.reason || Boolean(touched.reason && errors.reason)
                }
            >
                Trimiteți mesajul
            </Button>
        </Stack>
    </Form>;


    return (
        <FormikProvider value={formik}>
            {
                semiPhaseOne
            }
        </FormikProvider>
    );
}
