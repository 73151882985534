import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import "../components/_dashboard/app/MainInfoPage.css";
import MessageForm from "../components/authentication/order/MessageForm";

const RootStyle = styled(Card)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    }
}));

const BoxStyle = styled(Box)(({theme}) => ({

    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        width: '65%',
    }
}));

const SubBoxStyle = styled(Box)(({theme}) => ({

    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        // display: 'flex',
        marginTop: "5%"
    }
}));


const CardMediaStyled = styled(CardMedia)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '35%'
    }
}));


export default function ContactCard() {

    return (
        <RootStyle>
            <CardMediaStyled
                component="img"
                image="/static/illustrations/lawyer_justice.webp"
                alt="cazier judiciar online fără deplasări"
            />
            <BoxStyle>
                <CardContent sx={{flex: '1 auto', textAlign: 'center'}}>
                    <Typography component="div" variant="h4">
                        Cum vă pot ajuta?
                    </Typography>
                    <SubBoxStyle>
                        <MessageForm/>
                    </SubBoxStyle>
                </CardContent>
            </BoxStyle>
        </RootStyle>
    );
}
