import * as Yup from 'yup';
import * as React from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {Box, Button, Grid, Stack, TextField, ToggleButton, Typography} from '@mui/material';
import {ToggleButtonGroup} from '@mui/lab';
import CountrySelect from "./CountrySelect";
import * as Countries from "../../../constants/Countries";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import {updateOrderById} from "../../../services/CommunicationService";
import {useNavigate} from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import * as Languages from "../../../constants/Languages";
import "./OrderFormFinished.css"
import ConfirmOrRejectModal from "../../_dashboard/user/ConfirmOrRejectModal";
import AddDeliveryInfoModal from "../../_dashboard/user/AddDeliveryInfoModal";

// ----------------------------------------------------------------------


export default function OrderFormFinished({order}) {
    const {
        id,
        regime,
        translationLanguage,
        legalized,
        apostila,
        user,
        address,
        reason,
        problems,
        createdAt
    } = order;
    const navigate = useNavigate();
    const isForeignUser = user != undefined && user != null && user.fatherName != null && user.motherName != null;
    const isFieldEmpty = (field) => {
        return field === undefined || field === null;
    }

    let _30DaysAgo = new Date().getTime() - (90 * 24 * 60 * 60 * 1000);
    let createdDate = new Date(createdAt).getTime();
    let isOlderThan30Days = createdDate < _30DaysAgo;

    let country = null;
    let translationCountry = null;
    let toUseAddress = {};
    let toUseUser = {};

    const [reasonField, setReasonField] = React.useState(reason);

    if (!isFieldEmpty(address)) {
        country = Countries.getCountryByName(address.country);
        toUseAddress = address
    }
    if (!isFieldEmpty(translationLanguage)) {
        // translationCountry = Countries.getCountryByName(translationLanguage);
        translationCountry = Languages.getLanguageByName(translationLanguage)
    }
    if (user !== null && user !== undefined) {
        toUseUser = user
    }

    let RegisterSchema = Yup.object().shape({});

    const formik = useFormik({
        initialValues: {
            firstName: toUseUser.firstName,
            lastName: toUseUser.lastName,
            cui: toUseUser.cui,
            district: toUseAddress.district,
            city: toUseAddress.city,
            details: toUseAddress.details,
            postalCode: toUseAddress.postalCode,
            reason: reason
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });
    const {handleSubmit, getFieldProps, touched} = formik;

    const updateOrder = () => {
        CommunicationService.updateOrderById(order.id, reasonField)
            .then((response) => {
                order.problems = false;
                LogicService.setCreatedOrder(order);
                navigate('/detalii-comanda');
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const getToggleButton = (text, value, key, icon, changeVariable, changeMethod) => {
        return <ToggleButton value={value} key={key} selected={changeVariable} disabled>
            <Box component="img" src={icon} sx={{width: 34}}/>
            <Typography variant={key} sx={{padding: '0 15px', marginTop: '10px'}} gutterBottom>{text}</Typography>
        </ToggleButton>
    }


    const children = [
        getToggleButton('Persoană fizică', 'person', 'person', "/static/illustrations/businessman.png"),
        getToggleButton('Persoană juridică', 'business', 'business', "/static/illustrations/online-shop.png")
    ];

    const travelSelect = [
        getToggleButton('Electronic', 'electronic', 'electronic', "/static/illustrations/email.png"),
        getToggleButton('Electronic & Livrare la adresă', 'address', 'address', "/static/illustrations/delivery-truck.png")
    ];

    let workingDays = regime === 'ADDRESS_FOREIGN_UE' ? '20' : '5';

    const regimSelect = [
        getToggleButton('Urgent (1 zi lucrătoare)', 'urgent', 'urgent', "/static/illustrations/calendar_1_day.png"),
        getToggleButton(`Standard (${workingDays} zile lucrătoare)`, 'standard', 'standard', `/static/illustrations/calendar_${workingDays}_day.png`)
    ];

    const travelTypeControls = {
        value: order.delivery?.toLowerCase(),
        exclusive: true,
    };

    let selectedValue = regime === 'ADDRESS_FOREIGN_UE' ? 'standard' : regime?.toLowerCase();

    const regimTypeControls = {
        value: selectedValue,
        exclusive: true,
    };

    const legalizedSelected = legalized;
    const apostilaSelected = apostila;
    const deliverySelected = address != null;


    const personTypeControls = {
        value: toUseUser.type,
        exclusive: true,
    };

    const [infoPopupOpen, setInfoPopupOpen] = React.useState(false);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} id={"orderFinished"}>
                <AddDeliveryInfoModal open={infoPopupOpen}
                                      id={id}
                                      handleClose={() => {
                                          setInfoPopupOpen(false)
                                      }}
                                      handleConfirm={() => {
                                          setInfoPopupOpen(false);
                                      }}
                />
                <Stack spacing={3}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>

                        <Grid container spacing={3}>
                            {
                                country == null ? null :
                                    <Grid item xs={12} sm={12} md={12}>
                                        <CountrySelect selectOption={() => {
                                        }} defaultValue={country} disabled/>
                                    </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="Motivul solicitării (scris pe cazier)"
                                    onChange={(event) => setReasonField(event.target.value)}
                                    value={reasonField}
                                    sx={!problems ? {} : {color: "white"}}
                                    inputProps={!problems ? {} : {
                                        style: {
                                            border: "3px solid red",
                                            borderRadius: "5px",
                                            margin: "1px"
                                        }
                                    }}
                                    InputLabelProps={!problems ? {} : {style: {top: "-10px", color: "red"}}}
                                    disabled={!problems}
                                />
                                {
                                    !problems ? null :
                                        <Button variant="contained"
                                                color={"error"}
                                                fullWidth
                                                disabled={reason.length == reasonField.length}
                                                onClick={() => updateOrder()}
                                                sx={{margin: "5px 0"}}
                                                component="span">
                                            Update
                                        </Button>
                                }
                            </Grid>

                        </Grid>

                    </Stack>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                        <ToggleButtonGroup size="large" {...personTypeControls}>
                            {children}
                        </ToggleButtonGroup>
                    </Stack>

                    {
                        Boolean(personTypeControls.value !== 'person') ? null :
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Nume"
                                    {...getFieldProps('firstName')}
                                    disabled
                                />

                                <TextField
                                    fullWidth
                                    label="Prenume"
                                    {...getFieldProps('lastName')}
                                    disabled
                                />
                            </Stack>
                    }

                    {
                        Boolean(personTypeControls.value !== 'business') ? null :
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Numele firmei"
                                    {...getFieldProps('firstName')}
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    label="CUI"
                                    {...getFieldProps('cui')}
                                    disabled
                                />
                            </Stack>
                    }
                    {
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} justifyContent="space-between"
                               border={"1px solid rgba(0, 0, 0, 0.15)"} padding={"10px"} borderRadius={"10px"}>
                            {
                                getToggleButton('Apostilă Haga', 'apostila', 'apostila',
                                    "/static/illustrations/stamp.png", apostilaSelected)
                            }
                            {
                                getToggleButton('Traducere legalizată', 'legalized', 'legalized',
                                    "/static/illustrations/legalized.png", legalizedSelected)
                            }
                            {
                                <Stack width={{xs: '100%', sm: '40%'}} alignSelf={"center"}>
                                    <LanguageSelect selectOption={() => {
                                    }}
                                                    defaultValue={translationCountry}
                                                    disabled
                                                    label={"Limba traducerii"} disablePrefix
                                    />
                                </Stack>
                            }

                        </Stack>
                    }
                    {
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                            <ToggleButtonGroup size="large" {...regimTypeControls}>
                                {regimSelect}
                            </ToggleButtonGroup>
                        </Stack>
                    }
                    {
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                            <ToggleButtonGroup size="large" {...travelTypeControls}>
                                {travelSelect}
                            </ToggleButtonGroup>
                        </Stack>
                    }
                    {
                        Boolean(deliverySelected) || isOlderThan30Days ? null :
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                                <Button variant="contained"
                                        color={"secondary"}
                                        size={"large"}
                                        fullWidth
                                        onClick={() => setInfoPopupOpen(true)}
                                        component="span">
                                    {'Solicită livrare prin curier'}
                                </Button>
                            </Stack>
                    }
                    {
                        Boolean(deliverySelected) && address.laterAdded == true && address.country != 'Romania' ?
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                                <Button variant="contained"
                                        color={"error"}
                                        size={"large"}
                                        fullWidth
                                        onClick={() => window.open('https://buy.stripe.com/14k00WglYeny6Gc7T43m')}
                                        component="span">
                                    {'Plătește transport DHL'}
                                </Button>
                            </Stack> : null
                    }
                    {
                        Boolean(deliverySelected) && address.laterAdded == true && address.country != 'Romania'  ?
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                                <Typography gutterBottom variant="h6"
                                            sx={{color: 'red', display: 'block', textAlign: "center", padding: '0 0%'}}>
                                    {
                                       'Dacă ai făcut plata deja, te rugăm să aștepți confirmarea și să nu plătești încă o dată!'
                                    }
                                </Typography>
                            </Stack> : null
                    }
                    {
                        Boolean(!deliverySelected) ? null :
                            <Stack spacing={3}>

                                {
                                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Judet"
                                            {...getFieldProps('district')}
                                            disabled
                                        />
                                        <TextField
                                            fullWidth
                                            label="Localitate"
                                            {...getFieldProps('city')}
                                            disabled
                                        />
                                    </Stack>

                                }

                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Cod poștal"
                                        {...getFieldProps('postalCode')}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="Detalii (scara, etaj, număr)"
                                        {...getFieldProps('details')}
                                        disabled
                                    />
                                </Stack>
                            </Stack>
                    }

                </Stack>
            </Form>
        </FormikProvider>
    );
}
