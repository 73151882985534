import PropTypes from 'prop-types';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object
};

export default function Logo({sx}) {
    return <Box component="img" src="/static/illustrations/law.png" sx={{width: 80, height: 80, ...sx}}/>;
}
