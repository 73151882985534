// material
import {styled} from '@mui/material/styles';
import {Box, Button, Card, Typography} from '@mui/material';
// utils
import * as LogicService from "../../../services/LogicService";
import * as React from "react";
import {useState} from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
    textAlign: 'center',
    padding: theme.spacing(0, 0),
    color: theme.palette.secondary,

}));

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '0%',
    alignItems: 'center',

    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
}));

// ----------------------------------------------------------------------

export default function DocumentUploader(props) {
    let uploadedDocuments = LogicService.getUploadedDocuments();
    let currentDocument = uploadedDocuments.find(doc => props.document.key === doc.key);

    let downloadAnexa38 = () => {
        let file_name = "Anexa_38.pdf";
        let documentToDownload = "https://caziere.ro/" + file_name;
        let a = document.createElement('a');
        a.target = "_blank";
        a.href = documentToDownload;
        a.download = file_name;
        a.click();
    }

    let exampleButton = props.document.key === 'srl_anexa_38' ?
        <Button variant="contained" color={"secondary"}
                id={`contained-button-download-example-file_${props.document.key}`}
                onClick={downloadAnexa38}
                component="span" disabled={props.disabled || currentDocument}>
            {"Model"}
        </Button> : null;
    return (
        <RootStyle sx={{boxShadow: 0, borderRadius: "1%"}}>
            <IconWrapperStyle>
                <Box component="img" sx={{borderRadius: "10%", width: 220}}
                     src={`/static/illustrations/${props.document.key}.webp`}/>
            </IconWrapperStyle>
            <Box className="uploadTitle" sx={{height: "60px"}}>
                <Typography variant="subtitle1" sx={{width: "90%", margin: "auto"}}>{props.document.title}</Typography>
            </Box>

            <Box
                sx={{display: "flex", justifyContent: "space-evenly", marginTop: (props.isBussines ? "20px" : "10px")}}>
                <label htmlFor={`contained-button-file_${props.document.key}`}>
                    <input accept="image/*, application/pdf" id={`contained-button-file_${props.document.key}`}
                           onChange={(event) => {
                               props.document.onChange(event);
                               // setDisableButton(true);
                           }}
                           multiple type="file"
                           style={{display: 'none'}}
                    />
                    <Button variant={props.disableAll ? "outlined" : "contained"} id={`contained-button-show-file_${props.document.key}`}
                            component="span" disabled={props.disabled || currentDocument || props.disableAll}>
                        {props.disabled || currentDocument ? "Succes" : props.disableAll ? "Se încarcă" : "Incarcă"}
                    </Button>

                </label>
                {exampleButton}
            </Box>
        </RootStyle>)
}
