import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {Checkbox, FormControlLabel} from "@mui/material";

export default function AddCompanyModal({open, handleConfirm, handleClose, defaultCompany}) {
    if (defaultCompany === undefined || defaultCompany === null) {
        defaultCompany = {};
    }
    let [text, setText] = useState(defaultCompany.name);
    let [ro, setRo] = useState(defaultCompany.ro);
    let [rc, setRc] = useState(defaultCompany.rc);
    let [address, setAddress] = useState(defaultCompany.address);
    let [judet, setJudet] = useState(defaultCompany.jud);
    let [iban, setIban] = useState(defaultCompany.iban);
    let [bank, setBank] = useState(defaultCompany.bank);
    let [cod, setCode] = useState(null);
    let [accessTokenAnaf, setAccessTokenAnaf] = useState(null);
    let [refreshTokenAnaf, setRefreshTokenAnaf] = useState(null);
    let [tvaEligible, setTvaEligible] = useState(defaultCompany.tvaEligible);
    let subjectText = "companie";

    const returnCompany = () => {
        handleConfirm({
            name: text,
            ro: ro,
            rc: rc,
            address: address,
            jud: judet,
            iban: iban,
            bank: bank,
            invoiceSeries: cod,
            accessTokenAnaf: accessTokenAnaf,
            refreshTokenAnaf: refreshTokenAnaf,
            tvaEligible: tvaEligible
        });
        setText(null);
        setRo(null);
        setRc(null);
        setAddress(null);
        setJudet(null);
        setIban(null);
        setBank(null);
        setCode(null);
        setAccessTokenAnaf(null);
        setRefreshTokenAnaf(null);
        setTvaEligible(null);
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Adăugare " + subjectText}
            </DialogTitle>
            <DialogContent>
                <TextField label={"Nume " + subjectText} variant="standard" value={text} fullWidth
                           onChange={(event) => setText(event.target.value)}/>
                <TextField label={"RO " + subjectText} variant="standard" value={ro} fullWidth
                           onChange={(event) => setRo(event.target.value)}/>
                <TextField label={"Reg Com " + subjectText} variant="standard" value={rc} fullWidth
                           onChange={(event) => setRc(event.target.value)}/>
                <TextField label={"Adresa " + subjectText} variant="standard" value={address} fullWidth
                           onChange={(event) => setAddress(event.target.value)}/>
                <TextField label={"Judet " + subjectText} variant="standard" value={judet} fullWidth
                           onChange={(event) => setJudet(event.target.value)}/>
                <TextField label={"Iban " + subjectText} variant="standard" value={iban}  fullWidth
                           onChange={(event) => setIban(event.target.value)}/>
                <TextField label={"Banca " + subjectText} variant="standard" value={bank} fullWidth
                           onChange={(event) => setBank(event.target.value)}/>
                <TextField label={"Serie factura (ex. SVRON)"} variant="standard" value={cod} fullWidth
                           onChange={(event) => setCode(event.target.value)}/>
                <TextField label={"Access Token ANAF (E-Factura)"} variant="standard" value={accessTokenAnaf} fullWidth
                           onChange={(event) => setAccessTokenAnaf(event.target.value)}/>
                <TextField label={"Refresh Token ANAF (E-Factura)"} variant="standard" value={refreshTokenAnaf} fullWidth
                           onChange={(event) => setRefreshTokenAnaf(event.target.value)}/>
                {
                        <FormControlLabel
                            control={
                                <Checkbox checked={tvaEligible}
                                          onChange={() => setTvaEligible(!tvaEligible)}
                                          sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                />}

                            label={"Firma platitoare de TVA"}>
                        </FormControlLabel>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => returnCompany()} color={"success"}>
                    Adăugare
                </Button>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
