import {format, formatDistanceToNow} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
    return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateISO(date) {
    return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeForBackend(date) {
    if (date === undefined || date === null) {
        return date;
    }
    // let a = formatDate(date);
    let date1 = new Date(date);
    date1.setHours(5);
    return date1.toISOString();
}

function formatDate(date) {
    let str = (new Date(date)).toLocaleString()
    let strings = str.split(', ');
    let arr = strings[0].split('/');
    let newFormatDate = arr[2] + "-" + arr[1] + "-" + arr[0];
    return newFormatDate + "T" + strings[1] + ".000Z";
}


export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}
