// material
// components
import * as React from "react";
import Page from "../components/Page";
import {Container, Grid} from "@mui/material";
import ContactCard from "./ContactCard";

// ----------------------------------------------------------------------

export default function Contact() {

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container maxWidth="xl">
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={12}>
                        <ContactCard/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
