import {default as languageFile} from './languages.json'
import {default as languageFileEn} from './languages_en.json'

let o = languageFile.languages;
let o_en = languageFileEn.languages;

function getLanguageArray(lang) {
    return Object.keys(lang)
        .map(languageKey => {
            return {
                code: languageKey,
                label: lang[languageKey].name,
                phone: '',
            }
        })
        .sort((k1, k2) => k1.label.localeCompare(k2.label));
}

const languageArray = getLanguageArray(o)
const languageEnArray = getLanguageArray(o_en)


const getLanguageByName = (name) => {
    if (name === undefined || name === null || name.length === 0) {
        return null;
    }
    let find = languageArray.find(c => c.label.toLowerCase() === name.toLowerCase());
    return find === undefined ? null : find;
}


const getLanguageByKey = (name) => {
    if (name === undefined || name === null || name.length === 0) {
        return null;
    }
    let find = languageArray.find(c => c.code.toLowerCase() === name.toLowerCase());
    return find === undefined ? null : find;
}

const getLanguageEnByName = (name) => {
    if (name === undefined || name === null || name.length === 0) {
        return null;
    }
    let find = languageEnArray.find(c => c.label.toLowerCase() === name.toLowerCase());
    return find === undefined ? null : find;
}

const getLangKeyByLabel = (name) => {
    if (name === undefined || name === null) {
        return null;
    }
    let find = languageArray.find(c => c.label.toLowerCase() === name.toLowerCase());
    if (find) {
        return find.code;
    }
    find = languageEnArray.find(c => c.label.toLowerCase() === name.toLowerCase());
    if (find) {
        return find.code;
    }
    return null;
}


export {
    languageArray,
    languageEnArray,
    getLanguageEnByName,
    getLanguageByKey,
    getLanguageByName,
    getLangKeyByLabel
}