// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import DocumentMeta from 'react-document-meta';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
const meta = {
    title: 'Cazier judiciar online - Eliberare cazier rapid și fără deplasări',
    description: 'Obținerea cazierului judiciar online reprezintă soluția ideală pentru toți cei care au nevoie de acest document esențial în cel mai scurt timp posibil.',
    canonical: 'https://caziere.ro/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'cazier online,cazier judiciar,eliberare cazier judiciar online,cerere cazier judiciar online,obtinere cazier judiciar online,certificat de cazier judiciar,cazier judiciar persoane juridice online,cazier judiciar persoane fizice online'
        }
    }
};

ReactDOM.render(
    <DocumentMeta {...meta}>
        <HelmetProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </HelmetProvider>
    </DocumentMeta>,
    document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
