import {default as judeteNoi} from './judete-situra.json'

let jud = judeteNoi;



const getOrasByJudetAndName = (judet, name) => {
    return name;
}

const getOraseByJudet = (judet) => {
    return jud[judet];
}

let judList = null;

const getJudete = () => {
   return Object.keys(jud);
}

const getJudetByName = (name) => {
    return name;
}

export {
    getJudetByName,
    getOraseByJudet,
    getOrasByJudetAndName,
    getJudete
}