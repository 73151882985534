import * as React from 'react';
import {TextField} from "@mui/material";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {Controller, useForm} from "react-hook-form";

export default function TextSelectInputV2({
                                              name,
                                              rules,
                                              label,
                                              options,
                                              supOnChange,
                                              disabled,
                                              setManuallyCompletedAddress
                                          }) {

    const {control} = useForm();


    return (
        <Controller
            disabled={disabled}
            name={name}
            fullWidth
            control={control}
            rules={rules}
            defaultValue={null}
            render={({
                         field: {ref, ...field},
                         fieldState: {error, invalid}
                     }) => {
                return (
                    <Autocomplete
                        {...field}
                        freeSolo
                        disabled={disabled}
                        fullWidth
                        handleHomeEndKeys
                        options={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                inputRef={ref}
                                error={invalid}
                                helperText={error?.message}
                            />
                        )}
                        onChange={(e, value) => {
                            field.onChange(value)
                            setManuallyCompletedAddress(false);
                            supOnChange(value)
                        }}
                        onInputChange={(_, data) => {
                            if (data) {
                                field.onChange(data);
                                setManuallyCompletedAddress(true);
                                supOnChange(data)
                            }
                        }}
                    />
                );
            }}
        />
    );

}

