import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-arad-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-arad-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-arad-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-arad-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-arad-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-arad-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageArad({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "eliberare cazier Arad online",
        "cazier online Arad",
        "cazier Arad online",
        "obtinere cazier judiciar online Arad",
        "cazier judiciar persoane fizice online Arad",
        "cazier judiciar persoane juridice online Arad",
        "cazier judiciar online Arad pret"
    ]

    return (
        <Page title={`Cazier Judiciar Online Arad`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Sunteți în căutarea unui mod simplu și rapid de a obține cazierul judiciar online din Arad? Pe caziere.ro vă oferim o soluție digitală eficientă și accesibilă."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Arad - Obțineți simplu și rapid de oriunde v-ați afla</title>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Arad</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Obțineți simplu și rapid de oriunde v-ați
                                afla</p>
                            <p>Sunteți în căutarea unui mod simplu și rapid de a obține cazierul judiciar online din
                                Arad? Pe
                                caziere.ro vă oferim o soluție digitală eficientă și accesibilă, asigurându-vă toate
                                informațiile și
                                asistența de care aveți nevoie.</p>
                            <p> Astfel, puteți obține mult mai simplu și mai rapid acest document. Eliberarea cazierului
                                judiciar
                                online în Arad nu a fost niciodată mai ușoară!</p>
                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>

                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre pentru obținerea cazierului judiciar online din Arad!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Obținerea cazierului online din Arad - o procedură avantajoasă</h2>
                            <p>Eliberarea online a cazierului judiciar din Arad este un proces confortabil și ușor de
                                gestionat.
                                Profitați de acest mod eficient de a obține documentele de care aveți nevoie și veți
                                avea
                                următoarele beneficii: </p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Evitați deplasările inutile și cozile interminabile.
                                </li>
                                <li dir="ltr" aria-level="1">Este un proces simplificat și accesibil din confortul
                                    propriului cămin.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditate în obținerea documentelor, economisind timp
                                    prețios.
                                </li>
                                <li dir="ltr" aria-level="1">Asistență profesională pentru orice întrebare sau
                                    nelămurire.
                                </li>
                                <li dir="ltr" aria-level="1">Securitate sporită în gestionarea datelor personale și
                                    procesare
                                    transparentă.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre pentru obținerea cazierului judiciar online din Arad!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Alegeți caziere.ro pentru eliberarea cazierului judiciar online în Arad</h2>
                            <p>Când vine vorba despre obținerea cazierului judiciar online din Arad, este esențial să
                                alegeți un
                                partener de încredere precum caziere.ro. </p>

                            <p><strong>Suntem un partener de încredere pentru că:</strong></p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online Arad" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Avem experiență în domeniul obținerii cazierelor judiciare
                                    din Arad,
                                    legalizarea, traducerea și apostilarea acestora.
                                </li>
                                <li dir="ltr" aria-level="1">Oferim un serviciu client personalizat și orientat către
                                    soluții.
                                </li>
                                <li dir="ltr" aria-level="1">Procesele de pe platforma noastră sunt optimizate pentru a
                                    reduce
                                    timpul de așteptare și vă garantăm obținerea unui certificat de cazier judiciar
                                    eliberat din
                                    Arad conform standardelor legale.
                                </li>
                                <li dir="ltr" aria-level="1">Practicăm prețuri corecte și transparente și punem un
                                    accent deosebit
                                    pe protecția datelor personale, asigurându-vă o confidențialitate totală.
                                </li>
                                <li dir="ltr" aria-level="1">Asistența noastră este disponibilă pentru a răspunde
                                    oricăror întrebări
                                    sau nelămuriri pe care le-ați putea avea în procesul de eliberare a cazierului.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre pentru obținerea cazierului judiciar online din Arad!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Situații în care aveți nevoie de un cazier judiciar online în Arad </h2>
                            <p>Sunt mai multe situații în care aveți nevoie urgentă de un certificat de cazier judiciar
                                online din
                                Arad. Acest document este cerut adesea atunci când candidați pentru un nou loc de muncă,
                                dacă
                                urmează să studiați în străinătate devine realitate sau în procedura de obținere a unei
                                vize.
                                Prezentarea unui cazier judiciar în Arad este necesară și dacă sunteți implicat în
                                înființarea unei
                                noi afaceri sau în proceduri legale.</p>

                            <p>Obținerea online a cazierului judiciar din Arad simplifică procesul, oferind o modalitate
                                rapidă și
                                eficientă de a obține acest important document, evitând astfel deplasările la sediile
                                instituțiilor
                                statului și aglomerația.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online Arad" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>De ce acte aveți nevoie pentru obţinerea unui cazier online din Arad</h2>
                            <p>Obținerea unui cazier judiciar este un pas important pentru multe demersuri legale și
                                administrative.
                                Pentru a obține în cel mai scurt timp cazierul judiciar online din Arad, este esențial
                                să ne
                                furnizați:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">O copie a actului de identitate sau a pașaportului.
                                </li>
                                <li dir="ltr" aria-level="1">O poză tip selfie cu actul de identitate în mână.
                                </li>
                                <li dir="ltr" aria-level="1">Contract de reprezentare de către caziere.ro semnat
                                    digital.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre pentru obținerea cazierului judiciar online din Arad!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Etapele obținerii cazierului judiciar din Arad</h2>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar online Arad" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Dacă locuiți în Arad și aveți nevoie de un certificat de cazier judiciar, procesul poate
                                fi
                                simplificat considerabil prin intermediul serviciilor online. Fără a vă deplasa personal
                                la
                                instituțiile relevante, puteți obține documentația necesară cu doar câteva click-uri.
                                Principalele
                                etape pe care va trebui să le urmați sunt:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Completați formularul de cerere cazier online
                                </li>
                                <li dir="ltr" aria-level="1">Încărcați documentele necesare
                                </li>
                                <li dir="ltr" aria-level="1">Semnați digital contractul de prestări servicii. Acesta vă
                                    va autoriza
                                    legal să beneficiați de serviciile de obținere a cazierului judiciar prin
                                    reprezentare.
                                </li>
                                <li dir="ltr" aria-level="1">Faceți plata serviciilor prin card bancar sau transfer
                                    bancar.
                                    Procedura va fi demarată după confirmarea plății.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h2>Cazier judiciar pentru persoane fizice în Arad</h2>
                            <p>Obținerea cazierului judiciar pentru persoanele fizice din Arad se poate realiza acum
                                mult mai simplu
                                prin intermediul serviciului online oferit de caziere.ro. Indiferent dacă locuiți în
                                orașul Arad sau
                                în împrejurimi, nu mai este necesar să vă deplasați personal la sediile instituțiilor de
                                poliție
                                pentru acest demers.</p>
                            <p>Procesul se demarează rapid, online, prin trimiterea actelor necesare, reducând
                                semnificativ timpul
                                de așteptare. Primiți cazierul judiciar scanat și ulterior prin curier rapid, direct la
                                adresa
                                dorită.</p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar pentru persoane juridice în Arad</h2>
                            <p>În Arad, persoanele juridice care necesită eliberarea unui certificat de cazier judiciar
                                pot
                                beneficia de serviciile online eficiente oferite de caziere.ro, evitând astfel
                                formalitățile
                                laborioase și deplasările inutile. Procesul simplificat presupune transmiterea
                                electronică a
                                documentelor necesare, inclusiv dovada calității de persoană juridică și o copie a
                                actului de
                                identitate al reprezentantului legal.</p>
                            <p>Cu ajutorul acestei platforme, reprezentanții legali ai firmelor din Arad pot obține
                                rapid și fără
                                efort cazierul judiciar pentru entitatea pe care o gestionează, asigurându-se că
                                îndeplinesc
                                cerințele legale pentru diverse tranzacții sau încheierea de contracte.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">

                    <div className="row" style={{textAlign: 'center'}}>
                        <div className="col-md-12 order-md-1">
                            <h2>Cazier judiciar online Arad - preţ</h2>
                            <p>Prețul cazierului judiciar online din Arad este accesibil și se adaptează nevoilor
                                fiecărui client,
                                garantând astfel că nu veți plăti pentru servicii de care nu aveți nevoie. Vă asigurăm
                                transparență
                                totală în calculul costurilor, oferindu-vă posibilitatea de a alege pachetul de servicii
                                care se
                                potrivește cel mai bine cerințelor dumneavoastră.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt="Cazier judiciar online Arad" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <p>Indiferent dacă sunteți persoană fizică în căutarea unui serviciu rapid și credibil
                                pentru obținerea
                                cazierului judiciar sau o companie care necesită mai multe caziere pentru angajații săi,
                                suntem aici
                                pentru a vă facilita procesul la cele mai competitive prețuri.</p>

                            <p>Vă oferim flexibilitate maximă, costul fiind personalizat după:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Tipul solicitantului (persoană fizică sau juridică)
                                </li>
                                <li dir="ltr" aria-level="1">Servicii standard cu livrare în 5 zile sau de urgență cu
                                    livrare într-o
                                    zi
                                </li>
                                <li dir="ltr" aria-level="1">Traducerea legalizată a cazierului judiciar online din Arad
                                </li>
                                <li dir="ltr" aria-level="1">Livrare în format electronic sau la cea prin curier
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre pentru obținerea cazierului judiciar online din Arad!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Pentru detalii referitoare la cazierul judiciar eliberat online din Arad,
                                contactați-ne!</h2>
                            <p>Aveți întrebări privind cazierul judiciar online din județul Arad? Echipa noastră este
                                disponibilă
                                pentru a vă asista. Contactați-ne la adresa de e-mail <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro </a>
                                și lăsați experții noștri să vă ghideze prin proces.</p>

                            <p>Cu noi, obținerea cazierului judiciar online din Arad devine un proces simplu și lipsit
                                de griji,
                                economisind timp prețios. În plus, garantăm discreția și confidențialitatea datelor
                                personale,
                                astfel încât să puteți avea încredere totală în serviciile noastre!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
