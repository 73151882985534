import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {InputAdornment} from "@mui/material";
import * as Countries from "../../../constants/Countries";
import {useState} from "react";

export default function CountrySelect({sx, selectOption, defaultValue, disabled, label, disablePrefix}) {
    const [selectedOption, setSelectedOption] = useState(null);
    if (disabled === undefined || disabled === null) {
        disabled = false;
    }
    if (label === undefined || label === null) {
        label = "Țara";
    }
    if (disablePrefix === undefined || disablePrefix === null) {
        disablePrefix = false;
    }

    const getOptionText = (option) => {
        let defText = option.label + " (" + option.code + ")";
        if (!disablePrefix) {
            defText += " +" + option.phone;
        }
        return defText;
    }

    return (
        <Autocomplete
            className={"country-selector"}
            sx={sx}
            disabled={disabled}
            fullWidth
            options={Countries.countries}
            autoHighlight
            defaultValue={defaultValue}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {getOptionText(option)}
                </Box>
            )}
            renderInput={(params) => {
                let option = Countries.getCountryByName(params.inputProps.value);
                if (selectedOption !== option) {
                    setSelectedOption(option);
                    selectOption(option);
                }

                if (option === null || option === undefined) {
                    return <TextField
                        {...params} label={label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />;
                }
                return <TextField
                    {...params}
                    label={label}
                    className={"country-selector-text-field"}
                    inputProps={{
                        ...params.inputProps,
                        value: (option.label + ' (' + option.code + ')'),
                        option: option,
                        autoComplete: 'new-password',
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{marginLeft: "5px"}}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            }}
        />
    );
}

