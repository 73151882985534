import {Icon} from '@iconify/react';
import {sentenceCase} from 'change-case';
import * as React from 'react';
import {Component, useEffect} from 'react';
// material
import {
    Box,
    Card,
    Checkbox,
    Container, Grid,
    IconButton,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    ToggleButton,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import deletedFileOutlined from "@iconify/icons-ant-design/delete-row-outlined";
import * as LogicService from "../services/LogicService";
import {STATUSES} from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AccessSelect from "./AccessSelect";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import OrderMoreMenu from "../components/_dashboard/user/OrderMoreMenu";
import * as Languages from "../constants/Languages";
import * as Countries from "../constants/Countries";
import ReportToolbar from "../components/_dashboard/user/ReportToolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fDateTimeForBackend} from "../utils/formatTime";
import AppInfoOne from "../components/authentication/order/AppInfoOne";
import Constants from "../Constants";
import AppInfoTwo from "../components/authentication/order/AppInfoTwo";
import AppInfoThree from "../components/authentication/order/AppInfoThree";
import * as PropTypes from "prop-types";
import CommunicationLogTable from "./CommunicationLogsTable";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'createdAt', label: 'Creat', alignRight: false},
    {id: 'orderId', label: 'Order id', alignRight: false},
    {id: 'type', label: 'Tip', alignRight: false},
    {id: 'toWhom', label: 'Catre', alignRight: false},
    {id: 'response', label: 'Raspuns', alignRight: false},
    {id: 'subject', label: 'Subiect', alignRight: false},
    {id: 'message', label: 'Mesaj', alignRight: false}
];

export default class CommunicationLogsEFactura extends Component {

    constructor() {
        super()
        this.state = {
            detailsModalIsOpen: false,
            tableHead: TABLE_HEAD,
            isLawyer: true,
            orders: [],
            urgentOrders: [],
            laterAddedDeliveryOrders: [],
            lawyers: [],
            statuses: [],
            transactions: [
                {
                    label: 'Verified',
                    value: true
                },
                {
                    label: 'Not verified',
                    value: false
                }
            ],
            page: 0,
            order: 'desc',
            selected: [],
            orderBy: 'createdAt',
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null,
            rowsPerPage: 10,
            totalItems: 0,
            totalPages: 0
        }
    }

    setOrders = (val) => {
        this.setState({orders: val})
    }

    setPage = (val) => {
        this.setState({page: val}, () => this.getOrders(true))
    }

    setOrder = (val) => {
        this.setState({order: val}, () => this.getOrders(true))
        // this.setState({order: val})
    }

    setSelected = (val) => {
        this.setState({selected: val})
    }

    setOrderBy = (val) => {
        this.setState({orderBy: val}, () => {
            // this.getOrders()
        })
    }
    setFilterName = (type, val) => {
        this.clearAllFilters(
            () => {
                this.setState({
                    filterBy: type,
                    filterName: val
                }, () => this.getOrders(true, type, val))
            }
        )
    }

    clearAllFilters = (thenMethod) => {
        this.setState({
            filterBy: null,
            filterName: null,
            filterLawyer: null,
            filterStatus: null,
            filterTransaction: null
        }, thenMethod)
    }

    setRowsPerPage = (val) => {
        this.setState({rowsPerPage: val}, () => this.getOrders(true))
    }

    componentDidMount() {
        let Auth = CommunicationService.getUser();
        let userType = CommunicationService.getUserType();
        let isModeratorOrGreater = Auth !== null && (userType === 'ROLE_ADMIN' || userType === 'ROLE_MODERATOR')
        this.setState({
            isLawyer: !isModeratorOrGreater
        }, () => {
            this.getOrders();
        })
    }

    getOrders(force = false) {
        if (force || this.state.orders.length === 0) {
        }
        let filterBy = 'types';
        let filterName = 'E_FACTURA';

        CommunicationService.getCommunicationLogs(this.state.page, this.state.rowsPerPage, this.state.orderBy,
            this.state.order, filterBy, filterName, false)
            .then((response) => {
                this.setState({
                    totalItems: response.totalItems,
                    totalPages: response.totalPages
                })
                this.setOrders(response.logs)
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    render() {
        const ORDERLIST = this.state.orders;
        const page = this.state.page;
        const order = this.state.order;
        const selected = this.state.selected;
        const orderBy = this.state.orderBy;
        const filterName = this.state.filterName;
        const rowsPerPage = this.state.rowsPerPage;

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        };

        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = ORDERLIST.map((n) => n.name);
                this.setSelected(newSelecteds);
                return;
            }
            this.setSelected([]);
        };

        const handleChangePage = (event, newPage) => {
            this.setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            this.setRowsPerPage(parseInt(event.target.value, 10));
            this.setPage(0);
        };

        const handleFilterByName = (text) => {
            this.setFilterName('search', text);
        };

        const filteredUsers = ORDERLIST;

        const isUserNotFound = filteredUsers.length === 0;


        return (
            <CommunicationLogTable order={order} orderBy={orderBy}
                                   headLabel={this.state.tableHead}
                                   rowCount={this.state.totalItems}
                                   selected={selected}
                                   filteredUsers={filteredUsers}
                                   userNotFound={isUserNotFound}
                                   searchQuery={filterName}
                                   rowsPerPage={rowsPerPage}
                                   page={page}
                                   onRequestSort={handleRequestSort}
                                   onSelectAllClick={handleSelectAllClick}
                                   onPageChange={handleChangePage}
                                   onRowsPerPageChange={handleChangeRowsPerPage}/>
        );
    }


}
