import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import * as LogicService from '../../../services/LogicService'

import {Box, Button, Stack, Typography} from '@mui/material';
import PriceOffer from "./PriceOffer";


export default function OrderOffer({translate}) {
    const navigate = useNavigate();

    const toPayment = () => {
        LogicService.verifyTransactionAndReturn();
        navigate(translate ? '/en/complete-offer' : '/complete-offer');
    }

    const back = () => {
        LogicService.undefineMyOrder();
        // LogicService.updateOrderState('FORM_INIT');
        navigate(translate ? '/en' : '/');
    }

    return (
        <Box>
            <Typography variant="subtitle2">
                <PriceOffer/>
            </Typography>

            <Typography variant="h6" sx={{marginBottom: "10px"}}>
                {translate ? "Upload the necessary documents to continue" : "Încarcă documentele necesare pentru a continua"}
            </Typography>
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignSelf={"center"}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={'warning'}
                    onClick={back}
                >
                    {translate ? "Back" : "Inapoi"}
                </Button>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={toPayment}
                >
                    {translate ? "Upload documents" : "Încărcare documente"}
                </Button>
            </Stack>

        </Box>
    );
}
