import {useEffect, useState} from 'react';
import * as React from 'react';

import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, ToggleButton, ToggleButtonGroup,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {UserListHead} from '../components/_dashboard/user';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import {addCategoryToDrive, getDriveScheduler} from "../services/CommunicationService";
import DriveCategory from "./DriveCategory";
import AddCategoryModal from "../components/_dashboard/user/AddCategoryModal";

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'type', label: 'Tip', alignRight: false},
    {id: 'value', label: 'Valoare', alignRight: false},
];

export default function Drive() {
    const [refreshValue, setRefreshValue] = useState(1);
    const [schedule, setSchedule] = useState([]);
    const [categories, setCategories] = useState([]);
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);

    const getSchedule = () => {
        CommunicationService.getDriveScheduler()
            .then((response) => {
                setSchedule(response.schedule)
                setCategories(response.categories)
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    useEffect(() => {
        getSchedule();
    }, []);


    let years = [2022, 2023, 2024];
    let months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY",
        "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();

    const [year, setYear] = React.useState(currentYear);
    const [month, setMonth] = React.useState(months[currentMonth]);

    const handleYearChange = (event, newVal) => {
        setYear(newVal);
    };

    const handleMonthChange = (event, newVal) => {
        setMonth(newVal);
    };

    const isBadItem = (item) => {
        return item == undefined || item == null;
    }

    const getDocumentsByCategoryAndSelections = (category) => {
        if (isBadItem(category) || isBadItem(category.name) || isBadItem(schedule) || isBadItem(year) || isBadItem(month)
        || isBadItem(schedule[year]) || isBadItem(schedule[year][month]) || isBadItem(schedule[year][month][category.name])) {
            return [];
        }
        return schedule[year][month][category.name];
    }

    const addDocument = (category, document) => {
        if (isBadItem(category) || isBadItem(category.name) || isBadItem(schedule) || isBadItem(year) || isBadItem(month)) {
           return;
        }
        let localSchedule = schedule;

        if (isBadItem(localSchedule[year])) {
            localSchedule[year] = {};
        }

        if (isBadItem(localSchedule[year][month])) {
            localSchedule[year][month] = {};
        }

        if (isBadItem(localSchedule[year][month][category.name])) {
            localSchedule[year][month][category.name] = [];
        }

        localSchedule[year][month][category.name].push(document);
        setSchedule(localSchedule);
        setRefreshValue(refreshValue + 1);
    }

    const deleteDocument = (category, documentId) => {
        if (isBadItem(category) || isBadItem(category.name) || isBadItem(schedule) || isBadItem(year) || isBadItem(month)
        || isBadItem(schedule[year]) || isBadItem(schedule[year][month]) || isBadItem(schedule[year][month][category.name])) {
           return;
        }
        let localSchedule = schedule;
        localSchedule[year][month][category.name] = localSchedule[year][month][category.name].filter(d => d.id !== documentId);
        setSchedule(localSchedule);
        setRefreshValue(refreshValue + 1);
    }

    const addCategory = (categoryName) => {
        CommunicationService.addCategoryToDrive(categoryName)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CATEGORY_ADDED);
                let categAux = categories;
                categAux.push({name: categoryName});
                setCategories(categAux);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const generateArchive = () => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.REPORT_BEING_CREATED);
        CommunicationService.generateDriveArchive(null, year, months.indexOf(month) + 1)
            .then((response) => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Drive_Archive_" + year + "_" + month + "_Generated_" + Date.now() + ".zip";
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container sx={{maxWidth: "2000px !important"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Drive
                    </Typography>
                </Stack>

                <Card sx={{padding: "30px", textAlign: "center"}}>
                    <Scrollbar>
                        <Stack spacing={1} alignItems="center">
                            <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">
                                <ToggleButtonGroup
                                    value={year} exclusive
                                    onChange={handleYearChange}
                                    aria-label="year-selection"
                                >
                                    {
                                        years.map(year => <ToggleButton
                                            value={year}
                                            disabled={year > currentYear}
                                        >{year}</ToggleButton>)
                                    }
                                </ToggleButtonGroup>
                            </Stack>
                            <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center">
                                <ToggleButtonGroup
                                    value={month} exclusive
                                    onChange={handleMonthChange}
                                    aria-label="month-selection"
                                >
                                    {
                                        months.map(val => <ToggleButton
                                            value={val}
                                            disabled={months.indexOf(val) > currentMonth + 1}
                                        >{val}</ToggleButton>)
                                    }
                                </ToggleButtonGroup>
                            </Stack>
                            <AddCategoryModal open={addCategoryModalOpen}
                                              handleConfirm={(text) => {
                                                  addCategory(text);
                                                  setAddCategoryModalOpen(false);
                                              }}
                                              handleClose={() => setAddCategoryModalOpen(false)}
                            />
                            <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                   flexWrap={"wrap"} justifyContent={"space-between"} spacing={3}>

                                <Button variant="contained" color={'warning'}
                                        onClick={() => setAddCategoryModalOpen(true)}>
                                    {"Add Category"}
                                </Button>

                                <Button variant="contained" color={'secondary'}
                                        onClick={(event) => generateArchive()}
                                >
                                    {"Archive Month"}
                                </Button>
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} alignItems="center" display={"flex"}
                                   flexWrap={"wrap"} >
                                {
                                    categories.map(val => <DriveCategory
                                        refreshValue={refreshValue}
                                        category={val}
                                        year={year}
                                        month={months.indexOf(month)}
                                        documents={getDocumentsByCategoryAndSelections(val)}
                                        addDocument={(doc) => addDocument(val, doc)}
                                        deleteDocument={(docId) => deleteDocument(val, docId)}
                                    >{val.name}</DriveCategory>)
                                }
                            </Stack>
                        </Stack>

                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
