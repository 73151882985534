import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Button, Grid, InputAdornment, Stack} from "@mui/material";
import * as Languages from "../../../constants/Languages";
import SearchIcon from "@mui/icons-material/Search";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import AppInfoOne from "./AppInfoOne";
import Constants from "../../../Constants";
import AppInfoTwo from "./AppInfoTwo";
import AppInfoThree from "./AppInfoThree";
import TextSelectInputPlainString from "./TextSelectInputPlainString";
import * as Siruta from "../../../constants/Siruta";

export default function AnafFiscalSearchFields(
    {
        getFieldProps, setFieldValue, userDetails, errorObj, setErrorObj, sameData,
        cuiField, setCuiField, firstNameField, setFirstNameField, nrRcField, setNrRcField, anafSearchedCompany, setAnafSearchedCompany, manualInput, setManualInput,
        firmBorder, setFirmBorder
    }) {

    const [searchCount, setSearchCount] = React.useState(0);
    const [disabledButton, setDisabledButton] = React.useState(false);

    const disableTemporarily = (callback) => {
        setDisabledButton(true);
        callback(true);
        setTimeout(() => {
            setDisabledButton(false);
        }, 3000);
    }

    const searchCompanyInAnafDB = () => {
        setSearchCount(searchCount + 1);
        disableTemporarily(() => {
            if (searchCount == 0) {
                setManualInput(true)
            }
        })
        CommunicationService.getAnafCompany(parseInt(cuiField))
            .then((response) => {
                setAnafSearchedCompany(response.denumire);
                setFirstNameField(response.denumire)
                setNrRcField(response.nrRegCom)
                setManualInput(false)
            })
            .catch((error) => {
                setFirmBorder('red');
                setFirstNameField(null)
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ANAF_CUI_NOT_FOUND)
            });
    }

    return (
        <Stack direction={{xs: 'column', sm: 'column'}} spacing={2}>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={10} md={10}>
                    <TextField
                        fullWidth
                        label="CUI"
                        // disabled={(userDetails.cui !== undefined && userDetails.cui !== null && userDetails.cui.length > 1) && sameData}
                        error={errorObj.field === 'cuiField'}
                        helperText={errorObj.field === 'cuiField' ? errorObj.message : null}
                        type="number"
                        onChange={(e) => {
                            setCuiField(e.target.value)
                            setErrorObj({
                                field: '',
                                message: ''
                            })
                        }}
                        value={cuiField}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Button variant="outlined" startIcon={<SearchIcon/>}
                            sx={{height: '100%', maxHeight: '56px', width: '100%'}}
                        // disabled={getFieldProps('cui').value < 100 || disabledButton}
                            disabled={disabledButton}
                            onClick={searchCompanyInAnafDB}
                    >
                        Caută
                    </Button>
                </Grid>
            </Grid>
            {
                anafSearchedCompany != null || manualInput == true ?

                <Stack spacing={3} direction={{xs: 'row', sm: 'row'}}>
                    <TextField

                        fullWidth
                        label="Nume Firma"
                        InputLabelProps={firstNameField == undefined || firstNameField == null || firstNameField.length == 0 ? {} : { shrink: true }}                        error={errorObj.field === 'firstNameField'}
                        helperText={errorObj.field === 'firstNameField' ? errorObj.message : null}
                        onChange={(e) => {
                            setFirstNameField(e.target.value)
                            setErrorObj({
                                field: '',
                                message: ''
                            })
                        }}
                        sx={firmBorder == null ? null : {border: '1px solid red', borderRadius: '10px'}}
                        value={firstNameField}
                    />

                    <TextField
                        fullWidth
                        label="Registrul comerțului"
                        InputLabelProps={nrRcField == undefined || nrRcField == null || nrRcField.length == 0 ? {} : { shrink: true }}
                        error={errorObj.field === 'nrRcField'}
                        helperText={errorObj.field === 'nrRcField' ? errorObj.message : null}
                        onChange={(e) => {
                            setNrRcField(e.target.value)
                            setErrorObj({
                                field: '',
                                message: ''
                            })
                        }}
                        sx={firmBorder == null ? null : {border: '1px solid red', borderRadius: '10px'}}
                        value={nrRcField}
                    />
                </Stack>
                 : null
            }
        </Stack>
    );
}

