import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-craiova-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-craiova-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-craiova-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-craiova-5.jpg"
import Foto_cta from "./images/cazier-judiciar-online-craiova-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageCraiova({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier online Craiova",
        "obtinere cazier online Craiova",
        "cazier judiciar persoane fizice Craiova",
        "cazier judiciar persoane juridice Craiova",
        "cerere cazier judiciar online Craiova",
        "eliberare cazier judiciar online Craiova"
    ]

    return (
        <Page title={`Cazier Judiciar Online Craiova`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Apelând la serviciile noastre, procesul de obținere al unui cazier judiciar online pentru cei care locuiesc în Craiova, este cât se poate de eficient și de simplu"}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Craiova - Salvați timp prețios cu ajutorul serviciului nostru de obținere caziere online</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Craiova</h1>

                            <p>Apelând la serviciile noastre, procesul de obținere al unui cazier judiciar online pentru
                                cei care
                                locuiesc în Craiova, este cât se poate de eficient și de simplu. Indiferent dacă aveți
                                nevoie de un
                                cazier pentru persoane fizice sau juridice, vă stăm la dispoziție cu un serviciu rapid,
                                sigur și
                                accesibil.</p>
                            <p> Salvați timp prețios și evitați deplasările inutile cu ajutorul serviciului nostru de
                                obținere caziere
                                online, optimizat!
                            </p>

                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>


                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obține rapid cazierul judiciar online în Craiova!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Obținerea cazierului online în Craiova - o procedură avantajoasă</h2>
                            <p>Obținerea unui cazier judiciar din Craiova nu a fost niciodată mai simplă. Indiferent
                                dacă sunteți în
                                oraș sau la distanță, procesul poate fi efectuat integral online, cu un efort minim din
                                partea
                                dumneavoastră și câteva avantaje notabile: </p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Rapiditate: Primiți cazierul online dacă sunteți din
                                    Craiova, fără să
                                    pierdeți timp prețios așteptând la cozi.
                                </li>
                                <li dir="ltr" aria-level="1">Comoditate: Tot procesul se desfășoară online, din
                                    confortul casei
                                    dumneavoastră.
                                </li>
                                <li dir="ltr" aria-level="1">Siguranță: Datele dumneavoastră sunt în siguranță și
                                    procesate cu
                                    discreție totală.
                                </li>
                                <li>Flexibilitate: Beneficiați de servicii adaptate nevoilor dumneavoastră, inclusiv
                                    traduceri și
                                    legalizări.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obține rapid cazierul judiciar online în Craiova!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{
                            textAlign:
                                'center'
                        }}>
                            <h2>De ce să apelați la noi pentru eliberare de cazier judiciar online din Craiova</h2>

                            <p>Obținerea unui certificat de cazier judiciar poate fi o procedură dificilă și
                                consumatoare de timp dacă
                                alegeți să o faceți personal, mai ales dacă nu vă aflați în orașul natal.</p>

                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online Craiova" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <p>Aici intervine caziere.ro, oferind o soluție online eficientă și accesibilă pentru
                                oricine are nevoie de un
                                cazier judiciar, chiar și în Craiova. </p>
                            <p><strong>Motivele principale pentru care ar trebui să alegeți serviciile noastre pentru
                                eliberare cazier
                                judiciar online din Craiova:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Avem experiența necesară pentru a gestiona rapid și
                                    eficient cererile.
                                </li>
                                <li dir="ltr" aria-level="1">Vă ajutăm să economisiți timp și bani.
                                </li>
                                <li dir="ltr" aria-level="1">Puteți apela la serviciile noastre 24/ 7, de oriunde v-ați
                                    afla.
                                </li>
                                <li dir="ltr" aria-level="1">Veți obține cazierul în 1-5 zile.
                                </li>
                                <li dir="ltr" aria-level="1">Suntem gata să vă răspundem la orice întrebare legată de
                                    eliberarea cazierului
                                    judiciar online din Craiova.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obține rapid cazierul judiciar online în Craiova!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Cerere pentru cazier judiciar online în Craiova - când este necesară </h2>
                            <p>De la angajare până la demersuri legale sau tranzacții importante, cazierul judiciar
                                online din
                                Craiova este un document indispensabil. Acesta poate fi necesar ca să aplicați pentru un
                                post în
                                domeniul educației, sănătății sau al siguranței publice. De asemenea, este cerut în
                                procedurile de
                                obținere a licențelor profesionale sau în înregistrarea unor activități comerciale. În
                                plus, dacă
                                plănuiți să călătoriți în străinătate, multe țări solicită cazierul judiciar pentru a vă
                                acorda vize
                                de lungă durată sau permise de rezidență.
                            </p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online Craiova"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>De ce acte aveți nevoie pentru a obține un cazier online în Craiova</h2>
                            <p>Obținerea unui cazier judiciar online din Craiova nu a fost niciodată mai simplă și mai
                                rapidă.
                                Pentru a accelera procesul și a ne asigura că procedura de obținere a cazierului online
                                se
                                desfășoară fără probleme am pregătit o listă scurtă cu actele de care aveți nevoie:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Act de identitate valabil (copie și poză cu dumneavoastră
                                    ținând actul)
                                </li>
                                <li dir="ltr" aria-level="1">Contractul semnat (proces simplificat și eficient online)
                                </li>
                                <li dir="ltr" aria-level="1">Cererea tip pentru eliberarea cazierului (noi ne ocupăm de
                                    redactare)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obține rapid cazierul judiciar online în Craiova!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 order-md-1">
                            <h3>Etapele obținerii cazierului judiciar din Craiova</h3>
                            <p>Pentru a obține cazierul judiciar din Craiova, este necesar să urmați câțiva pași simpli.
                                Începeți
                                prin a completa informațiile solicitate și a încărca documentele necesare. Ulterior,
                                semnați
                                contractul de prestări servicii online, acordând astfel reprezentanților site-ului
                                dreptul de a
                                acționa în numele dumneavoastră. Procesul de obținere durează între 1-5 zile lucrătoare,
                                iar
                                cazierul judiciar vă va fi trimis scanat, urmând să primiți documentul original prin
                                curier.</p>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <h3>Cazier judiciar pentru persoanele fizice din Craiova</h3>
                            <p>Indiferent de motivul pentru care aveți nevoie de cazier judiciar pentru persoane fizice
                                din Craiova,
                                noi simplificăm procesul și vă ajutăm să îl obțineți în cel mai scurt timp posibil.
                                Puteți demara
                                procesul de acasă sau chiar în timp ce călătoriți și îl veți primi rapid prin în format
                                electronic
                                sau prin curier.</p>

                            <p>Vă punem la dispoziție o platformă sigură și securizată unde puteți încărca documentele
                                necesare în
                                orice moment al zilei, garantând confidențialitatea datelor dumneavoastră. Vă stăm la
                                dispoziție cu
                                instrucțiuni detaliate pentru a completa corect cererea de cazier judiciar pentru
                                persoane fizice
                                din Craiova, astfel încât să evitați orice întârzieri sau erori în procesare.</p>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h3>Cazier judiciar pentru persoanele juridice din Craiova</h3>
                            <p>Furnizăm servicii de obținere rapidă a cazierului judiciar pentru persoane juridice din
                                Craiova, cu
                                același nivel ridicat de eficiență și profesionalism. Indiferent de mărimea sau domeniul
                                de
                                activitate al companiei dumneavoastră, caziere.ro vă poate ajuta să obțineți documentul
                                necesar în
                                cel mai scurt timp posibil, fără a vă îngreuna activitatea de zi cu zi. </p>

                            <p>Dacă documentul urmează a fi utilizat în afara granițelor României, asigurăm și servicii
                                de
                                apostilare sau supralegalizare pentru caziere judiciare persoane juridice din Craiova.
                                Confidențialitatea și siguranța datelor companiei dumneavoastră sunt prioritare pentru
                                noi, fiind
                                protejate pe întreg parcursul procesului de obținere a cazierului judiciar. </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_5} alt="Cazier judiciar online Craiova"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online Craiova - preț</h2>
                            <p>Prețul pentru eliberarea cazierului judiciar online din Craiova poate varia în funcție de
                                mai multe
                                detalii. Ne adaptăm nevoilor dumneavoastră și oferim transparență totală în privința
                                costurilor.</p>
                            <p>Este important să specificați cât de urgent aveți nevoie de acesta, deoarece eliberarea
                                într-o singură zi
                                va implica un cost suplimentar comparativ cu eliberarea standard în cinci zile
                                lucrătoare. Dacă doriți
                                ca documentul să fie tradus într-o limbă străină și legalizat, acest lucru va adăuga o
                                taxă suplimentară
                                la prețul final.</p>

                            <p>Solicitarea apostilei Haga, necesară pentru folosirea documentului în anumite țări, va
                                implica, de
                                asemenea, costuri adiționale. În plus, dacă alegeți să primiți documentul electronic sau
                                prin curier la
                                o anumită adresă, aceasta va influența prețul final, livrarea prin curier având un cost
                                adițional în
                                funcție de destinația aleasă.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obține rapid cazierul judiciar online în Craiova!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Contactați-ne pentru detalii despre obținere cazier judiciar online din Craiova!</h2>
                            <p>Pentru orice întrebări sau clarificări, scrieți-ne la <a
                                href="mailto:contact@caziere.ro">contact@caziere.ro </a>
                                și echipa noastră vă va asista cu promptitudine în obținerea cazierului judiciar online.
                            </p>

                            <p>Scăpați de griji și lăsați formalitățile în seama noastră. Solicitați cazierul și
                                începeți procesul
                                simplu și transparent de a obține documentul de care aveți nevoie fără deplasări inutile
                                și fără
                                timp pierdut. Alegeți calea cea mai simplă și mai sigură, alegeți caziere.ro pentru
                                cazierul
                                judiciar online din Craiova!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
