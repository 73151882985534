// material
import {styled} from '@mui/material/styles';
import {Box, Button, Card, IconButton, Typography} from '@mui/material';
// utils
import * as LogicService from "../../../services/LogicService";
import * as React from "react";
import Download from "@mui/icons-material/Download";
import Upload from "@mui/icons-material/Upload";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
    textAlign: 'center',
    padding: theme.spacing(0, 0),
    color: theme.palette.secondary,

}));

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '0%',
    alignItems: 'center',

    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
}));

// ----------------------------------------------------------------------

export default function UploadButton(props) {
    return (
        <RootStyle sx={{boxShadow: 0, borderRadius: "1%", margin: "0 5px"}}>

            <Box
                sx={{display: "flex", justifyContent: "space-evenly"}}>
                <label htmlFor={`button-upload-input_${props.maybeId}`}>
                    <input accept="image/*, application/pdf" id={`button-upload-input_${props.maybeId}`}
                           onChange={props.onChange}
                           multiple type="file"
                           style={{display: 'none'}}
                    />
                    <Button variant="outlined" id={`button-upload_${props.maybeId}`}
                            component="span" color={'primary'}
                            disabled={props.disabled}>
                        {"Upload"}
                    </Button>
                </label>
            </Box>
        </RootStyle>)
}
