import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {InputAdornment, Stack, TextField, Typography} from "@mui/material";
import CountrySelect from "../../authentication/order/CountrySelect";
import TextSelectInput from "../../authentication/order/TextSelectInput";
import * as Countries from "../../../constants/Countries";
import TextSelectInputV2 from "../../authentication/order/TextSelectInputV2";
import {useFormik} from "formik";
import * as Reasons from "../../../constants/Reasons";
import * as Yup from "yup";
import {useState} from "react";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import * as CommunicationService from "../../../services/CommunicationService";
import {addLaterDelivery} from "../../../services/CommunicationService";

export default function AddDeliveryInfoModal({open, id, handleConfirm, handleClose}) {


    let RegisterSchema = Yup.object().shape({
        lastName: Yup.string().required('Prenumele este necesar').matches(/.*/, 'Prenumele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        firstName: Yup.string().required('Numele este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        cui: Yup.string().required('CUI-ul este necesar').matches(/.*/, 'CUI-ul este invalid').min(3, 'Prea scurt').max(20, 'Prea lung'),
        phoneNumber: Yup.string().required('Numărul de telefon este necesar').min(5, 'Prea scurt').max(40, 'Prea lung').matches(/\d*/, 'Detaliile sunt invalide'),
        deliveryPhoneNumber: Yup.string().required('Numărul de telefon este necesar').min(5, 'Prea scurt').max(40, 'Prea lung').matches(/\d*/, 'Detaliile sunt invalide'),
        district: Yup.string().required('Județul este necesar').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Județul este invalid'),
        city: Yup.string().required('Localitatea este necesară').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Localitatea este invalidă'),
        str: Yup.string().required('Strada este necesară').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Strada este invalidă'),
        postalCode: Yup.string().required('Codul poștal este necesar').min(3, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Codul poștal este invalid'),
        details: Yup.string().required('Detaliile sunt necesare').min(1, 'Prea scurt').max(40, 'Prea lung').matches(/.*/, 'Detaliile sunt invalide'),
        reason: Yup.string().required('Motivul este necesar').min(3, 'Prea scurt').max(250, 'Prea lung'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),

        fatherName: Yup.string().required('Numele tatalui este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
        motherName: Yup.string().required('Numele mamei este necesar').matches(/.*/, 'Numele este invalid').min(3, 'Prea scurt').max(60, 'Prea lung'),
    });
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            cui: '',
            email: '',
            phoneNumber: '',
            deliveryPhoneNumber: '',
            district: '',
            city: '',
            str: '',
            numar: '',
            scara: '',
            etaj: '',
            apartament: '',
            details: '',
            postalCode: '',
            reason: '',
            fatherName: '',
            motherName: ''
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });
    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    let countryToChooseAsDefault = Countries.getCountryByName('Romania');
    const [country, changeCountry] = useState(countryToChooseAsDefault);

    let changeCountryOption = (value) => {
        changeCountry(value);
    }

    const [selectedDeliveryPhone, setSelectedDeliveryPhone] = React.useState(null);
    const [phoneCountry, changePhoneCountry] = useState(countryToChooseAsDefault);
    const [manuallyCompletedCod, setManuallyCompletedCod] = useState(false);
    let judete = Countries.getJudete();
    const [judet, changeJudet] = useState(null);
    const [localitate, changeLocalitate] = useState(null);
    const changeLoc = (loc) => {
        changeLocalitate(loc);
        Countries.getStreets(judet, loc)
            .then(res => {
                changeStrada(null)
                changeStrazi(res)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const modifyJudet = (judet) => {
        changeJudet(judet);
        changeLoc(null);
    }
    const [strazi, changeStrazi] = useState([]);
    const [strada, changeStrada] = useState(null);
    const [manuallyCompletedStreet, setManuallyCompletedStreet] = useState(false);
    function getCoduriPostale() {
        if (strazi == undefined || strazi == null || strada == undefined || strada == null) {
            return [];
        }
        let find = strazi.find(s => s.name == strada);
        if (find == undefined) {
            return [];
        }
        return find.coduriPostale;
    }

    const [postal, setPostal] = useState(null);
    const [buttonEnabled, setButtonEnabled] = useState(true);

    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 10000);
    }

    const normalizePhoneNumber = (prefix, phoneNo) => {
        if (prefix === undefined || prefix === null) {
            return phoneNo;
        }

        let prefixLen = prefix.length;
        for (let i = 0; i < prefixLen; i++) {
            let subStrToVerify = prefix.substring(i, prefixLen);
            if (phoneNo.startsWith(subStrToVerify)) {
                return '00' + prefix.substring(0, i) + phoneNo;
            }
        }
        return '00' + prefix + phoneNo;
    }

    const handleAddDelivery = () => {
        if (country == null || country === undefined) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Tara de livrare este necesară',
                key: 'invalid_livrare', severity: 'error'
            });
        }
        let countryIsRomania = country.code === 'RO';

        if (getFieldProps('deliveryPhoneNumber').value == null || getFieldProps('deliveryPhoneNumber').value === undefined
            || getFieldProps('deliveryPhoneNumber').value.length < 3) {
            return LogicService.setAlertMessageAndSeverity({
                message: 'Telefonul pentru livrare este necesar',
                key: 'invalid_livrare', severity: 'error'
            });
        }

        if (countryIsRomania) {
            if (judet === undefined || judet == null) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Judetul este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (localitate === undefined || localitate == null) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Orasul este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (strada === undefined || strada == null) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Strada este necesara',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (postal === undefined || postal == null) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Codul postal este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
        } else {
            if (getFieldProps('district').value == null || getFieldProps('district').value === undefined
                || getFieldProps('district').value.length < 3) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Judetul este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (getFieldProps('city').value == null || getFieldProps('city').value === undefined
                || getFieldProps('city').value.length < 3) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Orasul este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (getFieldProps('str').value == null || getFieldProps('str').value === undefined
                || getFieldProps('str').value.length < 3) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Strada este necesara',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
            if (getFieldProps('postalCode').value == null || getFieldProps('postalCode').value === undefined
                || getFieldProps('postalCode').value.length < 3) {
                return LogicService.setAlertMessageAndSeverity({
                    message: 'Codul postal este necesar',
                    key: 'invalid_livrare', severity: 'error'
                });
            }
        }


        let toSendPhoneNumber = normalizePhoneNumber(country.phone, getFieldProps('deliveryPhoneNumber').value);
        let district = null;
        let city = null;
        let postalCode = null;
        let details = getFieldProps('details').value;
        let street = "";
        if (countryIsRomania) {
            district = judet.name;
            city = localitate.name;
            postalCode = postal;
            street = strada;
        } else {
            district = getFieldProps('district').value;
            city = getFieldProps('city').value;
            postalCode = getFieldProps('postalCode').value;
            street = getFieldProps('str').value;
        }
        let address = {
            country: country.label,
            district: district,
            city: city,
            street: street,
            numar: getFieldProps('numar').value,
            scara: getFieldProps('scara').value,
            etaj: getFieldProps('etaj').value,
            apartament: getFieldProps('apartament').value,
            details: getFieldProps('details').value,
            postalCode: postalCode,
            phoneNumber: toSendPhoneNumber,
            manuallyCompleted: (manuallyCompletedStreet || manuallyCompletedCod)
        }

        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ORDER_ADDING_DELIVERY_INFO);
        CommunicationService.addLaterDelivery(id, address)
            .then((response) => {
                LogicService.setCreatedOrder(response);
                handleConfirm();

                if (country != undefined && country != null && country.code != 'RO') {
                    // window.location.assign('https://buy.stripe.com/14k00WglYeny6Gc7T43m');
                    window.open('https://buy.stripe.com/14k00WglYeny6Gc7T43m')
                } else {
                    window.location.reload();
                }
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Adauga detalii de livrare
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Stack spacing={3} mt={'20px'}>
                        {
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                <CountrySelect defaultValue={country} selectOption={(option) => {
                                    changeCountryOption(option);
                                    if (option !== null && option !== undefined && selectedDeliveryPhone !== option) {
                                        setSelectedDeliveryPhone(option);
                                        if (option === phoneCountry) {
                                            setFieldValue('deliveryPhoneNumber', getFieldProps('phoneNumber').value)
                                        } else {
                                            setFieldValue('deliveryPhoneNumber', "")
                                        }
                                    }

                                }}/>
                                <TextField
                                    fullWidth
                                    autoComplete="deliveryPhoneNumber"
                                    label="Număr de telefon livrare"
                                    {...getFieldProps('deliveryPhoneNumber')}
                                    error={Boolean(touched.deliveryPhoneNumber && errors.deliveryPhoneNumber)}
                                    helperText={touched.deliveryPhoneNumber && errors.deliveryPhoneNumber}
                                    InputProps={{
                                        startAdornment:
                                            (country === null || country === undefined) ? null :
                                                <InputAdornment
                                                    position="start">+{country.phone}</InputAdornment>,
                                    }}
                                />
                            </Stack>
                        }
                        {
                            Boolean(country !== null && country !== undefined && country.code === 'RO') ?
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextSelectInput options={judete}
                                                     selectOption={modifyJudet}
                                                     label={"Alege județul"}
                                                     parenthesisFieldName={'code'}
                                                     searchFunction={(judet) => Countries.getJudetByName(judet)}
                                                     disabled={country === null || country === undefined}
                                    />
                                    <TextSelectInput options={Countries.getLocalitati(judet)}
                                                     disabled={judet === null || judet === undefined}
                                                     label={"Alege localitatea"}
                                                     parenthesisFieldName={'comuna'}
                                                     searchFunction={(name) => Countries.getLocalitateByName(judet, name)}
                                                     selectOption={changeLoc}
                                    />
                                </Stack> :
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Judet"
                                        disabled={Boolean(country === null || country === undefined)}
                                        {...getFieldProps('district')}
                                        error={Boolean(touched.district && errors.district)}
                                        helperText={touched.district && errors.district}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Localitate"
                                        disabled={Boolean(country === null || country === undefined)}
                                        {...getFieldProps('city')}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Stack>

                        }
                        {
                            Boolean(country !== null && country !== undefined && country.code === 'RO') ?
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextSelectInputV2
                                        name="Alegestrada"
                                        label="Alege strada"
                                        options={strazi.map(s => s.name)}
                                        supOnChange={(value) => changeStrada(value)}
                                        disabled={localitate === null || localitate === undefined}
                                        setManuallyCompletedAddress={setManuallyCompletedStreet}
                                    />
                                    <TextSelectInputV2
                                        name="Codpostal"
                                        label="Cod poștal"
                                        options={getCoduriPostale()}
                                        supOnChange={(value) => setPostal(value)}
                                        disabled={strada === null || strada === undefined}
                                        setManuallyCompletedAddress={setManuallyCompletedCod}
                                    />
                                </Stack> :
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Stada"
                                        disabled={Boolean(country === null || country === undefined)}
                                        {...getFieldProps('str')}
                                        error={Boolean(touched.str && errors.str)}
                                        helperText={touched.str && errors.str}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Cod poștal"
                                        {...getFieldProps('postalCode')}
                                        error={Boolean(touched.postalCode && errors.postalCode)}
                                        helperText={touched.postalCode && errors.postalCode}
                                    />
                                </Stack>
                        }
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                            <TextField
                                fullWidth
                                label="Număr"
                                disabled={Boolean(country === null || country === undefined)}
                                {...getFieldProps('numar')}
                            />
                            <TextField
                                fullWidth
                                label="Bloc"
                                disabled={Boolean(country === null || country === undefined)}
                                {...getFieldProps('details')}
                            />
                            <TextField
                                fullWidth
                                label="Scara"
                                disabled={Boolean(country === null || country === undefined)}
                                {...getFieldProps('scara')}
                            />
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                            <TextField
                                fullWidth
                                label="Etaj"
                                disabled={Boolean(country === null || country === undefined)}
                                {...getFieldProps('etaj')}
                            />
                            <TextField
                                fullWidth
                                label="Apartament"
                                disabled={Boolean(country === null || country === undefined)}
                                {...getFieldProps('apartament')}
                            />
                        </Stack>
                        {
                            <Stack direction={{xs: 'column', sm: 'column'}} spacing={2} justifyContent={"center"}>
                                <Typography variant="subtitle1" color={'secondary'} sx={{textAlign: "center"}}
                                            padding={"0px 0px 5px 0px"}>
                                    Veți fi notificat de îndată ce cererea de livrare va fi aprobată.
                                </Typography>

                                {
                                    country != undefined && country != null && country.code == 'RO' ?
                                        <Typography variant="subtitle1" color={'error'} sx={{textAlign: "center"}}
                                                    padding={"0px 10px 0px 10px"}>
                                            Livrarea se face prin Fan Courier în regim rapid iar dumneavoastră veți plăti livrarea la primirea plicului.
                                        </Typography> :
                                        <Typography variant="subtitle1" color={'error'} sx={{textAlign: "center"}}
                                                    padding={"0px 10px 0px 10px"}>
                                            Livrarea în afara țării se face prin DHL în regim rapid și se va percepe o extra-taxă de livrare.
                                        </Typography>
                                }
                            </Stack>
                        }
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddDelivery} color={"success"} disabled={!buttonEnabled}>
                    Adaugă detalii pentru livrare
                </Button>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
