// material
import {styled} from '@mui/material/styles';
import {Card, Typography} from '@mui/material';

import * as React from "react"; // Import using relative path
import "./InfoCardMain.css"
import PdfViewerComponent from "../../pages/PdfViewerComponent";
import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({theme}) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(12),
    '& .apexcharts-canvas svg': {
        height: CHART_HEIGHT
    },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible'
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
    }
}));

// ----------------------------------------------------------------------


export default function InfoCardMain() {

    let typoStyle = {
        margin: "60px 50px 10px 50px"
    }

    let order = LogicService.getOrder();
    let documents = LogicService.getUploadedDocuments();

    let signOrder = order !== null && order !== undefined
        && (documents.length === order.necessaryDocuments.length && documents.find(doc => doc.key === DOCUMENTS_DETAILS.SIGNATURE_FOR_LAWYER_CONTRACT.key) === undefined);

    let infoCard = <ChartWrapperStyle dir="ltr" id={"keepStyles2"}>
        <Typography variant="h4" sx={typoStyle}>
            Eliberare cazier judiciar online cu livrare oriunde in tara si strainatate.
        </Typography>
        <Typography variant="h4" sx={typoStyle}>
            Aplici, primesti cazierul judiciar online in format electronic si fizic la adresa.
        </Typography>
        <Typography variant="h4" sx={typoStyle}>
            Rapid. Simplu. Convenabil.
        </Typography>
    </ChartWrapperStyle>;

    let signBox = <PdfViewerComponent/>

    let sx = {};
    let cardToShow = infoCard

    if (signOrder) {
        sx = {height: "700px"};
        cardToShow = signBox;
    }

    return (
        <Card className={"container-info-name"} sx={sx}>
            {cardToShow}
        </Card>
    );
}
