import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-lasi-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-lasi-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-lasi-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-lasi-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-lasi-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-lasi-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageIasi({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let descriptionList = [
        "",
        ""
    ]
    let keywords = [
        "cazier online Iasi",
        "eliberare cazier judiciar online Iasi",
        "cazier Iasi online",
        "cazier judiciar Iasi online",
    ]

    return (
        <Page title={`Cazier Judiciar Online Iași`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Vă putem ajuta să obțineți rapid și fără bătăi de cap cazierul judiciar online necesar în Iași, direct din confortul casei dumneavoastră!"}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Iași - Obțineți acum de la distanță cazierul judiciar de care aveți nevoie</title>

            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Iași</h1>

                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Obțineți acum de la distanță cazierul
                                judiciar de care aveți nevoie</p>

                            <p>Timpul și eficiența sunt aspecte importante și, dacă este necesar să obțineți un cazier
                                judiciar în Iași, acum o puteți face online. Eliberarea cazierului judiciar online este
                                acum un proces rapid și avantajos care necesită doar câteva click-uri, fără a pierde
                                timp prețios la cozi interminabile.</p>
                            <p>Indiferent de motivul pentru care aveți nevoie de cazier, serviciul nostru online
                                simplifică întregul proces, fiind special conceput pentru a reduce timpul și efortul
                                necesare din partea dumneavoastră. Vă putem ajuta să obțineți rapid și fără bătăi de cap
                                cazierul judiciar online necesar în Iași, direct din confortul casei dumneavoastră! </p>

                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>

                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}
                        ></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți acum online cazierul judiciar pentru județul Iași!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Cazier online pentru locuitorii din Iași - avantaje și beneficii</h2>
                            <p>Obținerea cazierului judiciar este o necesitate pentru mulți cetățeni, dar procesul
                                tradițional poate fi adesea anevoios și consumator de timp. Din fericire, serviciul de
                                eliberare a cazierului judiciar online din Iași vine în întâmpinarea nevoilor moderne,
                                oferind o soluție eficientă și la îndemâna oricui. </p>

                            <p><strong>De asemenea, solicitarea cazierului judiciar online din Iași prezintă o serie de
                                avantaje și beneficii semnificative:</strong></p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Economisiți timp: Evitați drumurile și cozile la
                                        instituțiile publice.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Accesibilitate: Oriunde ați fi, puteți demara
                                        procedura de obținere cazier online din iași, fără a fi nevoie să vă deplasați
                                        fizic.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Este un proces simplu și rapid: Procesul este mult
                                        simplificat, cu instrucțiuni clare și suport dedicat.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Securitate: Datele dumneavoastră personale sunt
                                        tratate cu maximă confidențialitate.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Transparență: Sunteți informați despre toate
                                        etapele procedurii de obținere cazier online din Iași și despre stadiul cererii
                                        dumneavoastră.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți acum online cazierul judiciar pentru județul Iași!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>De ce puteți apela cu încredere la caziere.ro pentru eliberarea de cazier judiciar
                                online în Iași </h2>
                            <p>Întrucât soluțiile eficiente sunt la mare căutare, platforma online caziere.ro reprezintă
                                răspunsul
                                profesionist la necesitatea obținerii cazierului judiciar. Serviciul nostru din Iași se
                                distinge prin
                                promptitudine, fiabilitate și securitate, oferind utilizatorilor o experiență optimizată
                                și de încredere
                                pentru eliberarea documentului solicitat. </p>

                            <p>Optând pentru serviciile noastre de eliberare caziere online, veți beneficia de o
                                abordare profesionistă și
                                eficientă, care își propune să elimine barierele geografice și să simplifice procesul de
                                obținere a actelor
                                necesare. </p>

                            <p><strong>Ce vă oferim:</strong></p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt={`Cazier judiciar online Iași`} className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Profesionalism: Experiența vastă a echipei noastre
                                        în gestionarea și
                                        eliberarea cazierelor judiciare online pentru Iași asigură calitatea serviciilor
                                        și respectarea tuturor
                                        normelor legale.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Rapiditate: Înțelegem importanța timpului pentru
                                        clienții noștri și ne
                                        asumăm angajamentul de a procesa solicitările în cel mai scurt timp posibil.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Siguranță: Pentru caziere.ro, confidențialitatea
                                        informațiilor
                                        dumneavoastră este esențială, așa că am implementat măsuri riguroase pentru a
                                        asigura siguranța și
                                        securitatea datelor personale pe tot parcursul procesului.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Asistență: Echipa noastră de experți este
                                        disponibilă pentru a răspunde
                                        la întrebările dumneavoastră și pentru a oferi asistența necesară pe tot
                                        parcursul procesului de
                                        obținere a cazierului judiciar online din Iași, asigurându-vă astfel o
                                        experiență fără probleme.</p>
                                </li>
                                <li>
                                    <p dir="ltr" role="presentation">Flexibilitate: Indiferent de locația dumneavoastră,
                                        fie că vă aflați în
                                        Iași sau în oricare alt colț al lumii, caziere.ro este aici pentru a vă oferi
                                        soluțiile necesare.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți acum online cazierul judiciar pentru județul Iași!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Situații în care este util un cazier online din Iași </h2>
                            <p>De la confirmarea unui istoric judiciar curat pentru angajatori sau vize, la înscrierea
                                în diverse
                                instituții educaționale sau obținerea cetățeniei în altă țară, cazierul obținut online
                                de către locuitorii
                                din Iași, joacă un rol crucial în diverse situații. Cazierul este deosebit de important
                                pentru a valida
                                integritatea și conformitatea legală a unei persoane.</p>

                            <p>Astfel, cazierul judiciar pentru Iași obținut online poate fi solicitat în procesul de
                                adopție, pe măsură
                                ce autoritățile doresc să se asigure că viitorii părinți adoptivi nu au antecedente care
                                i-ar putea face
                                nepotriviți pentru creșterea unui copil. În contextul obținerii anumitor licențe
                                profesionale, cum ar fi
                                cele pentru avocați, medici sau instructori auto, prezentarea unui cazier judiciar curat
                                este un criteriu
                                esențial.</p>

                            <p>Un cazier online din Iași mai poate fi necesar pentru închirierea unei locuințe.
                                Proprietarii pot solicita
                                acest document pentru a se asigura că potențialii chiriași nu au antecedente legate de
                                infracțiuni care ar
                                putea afecta siguranța sau proprietatea.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Acte necesare pentru eliberarea unui cazier judiciar în Iași, online</h2>
                            <p>Pentru a obține cazierul judiciar online din Iași, este important să asigurați pregătirea
                                și trimiterea
                                corectă a următoarelor documente necesare:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt={`Cazier judiciar online Iași`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">O copie clară a actului de identitate sau
                                        pașaportului. Este esențial ca
                                        toate datele din document (nume, prenume, data nașterii) să fie lizibile și
                                        complete, pentru a evita
                                        orice întârzieri sau refuzuri din partea autorităților competente.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">O fotografie recentă în care țineți actul de
                                        identitate. Această
                                        fotografie trebuie să fie de bună calitate, cu fața dvs. bine vizibilă, pentru a
                                        facilita verificarea
                                        identității dvs. de către autorități.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Un contract semnat electronic, care ne permite să
                                        acționăm în numele
                                        dumneavoastră. Acest contract trebuie să fie completat cu atenție, inclusiv cu
                                        toate datele personale
                                        cerute, și să fie semnat conform cu instrucțiunile primite, asigurând astfel
                                        valabilitatea reprezentării
                                        legale în procesul de obținere a cazierului.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți acum online cazierul judiciar pentru județul Iași!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt={`Cazier judiciar online Iași`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <h2>Etape pentru obținerea unui cazier în Iași, online</h2>
                            <p>Procedura de obținere a cazierului judiciar online din Iași este structurată în etape
                                simple:</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Înregistrarea pe platformă, completarea datelor
                                        personale și încărcarea
                                        documentelor necesare.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Înaintarea cererii, urmând ca noi să ne ocupăm de
                                        transmiterea acesteia
                                        către autorități.</p>
                                </li>
                                <li dir="ltr" aria-level="1">
                                    <p dir="ltr" role="presentation">Procesarea și eliberarea - Cazierul pentru Iași
                                        solicitat online este
                                        procesat și apoi trimis către dumneavoastră în format digital urmat de variantă
                                        fizică prin curier.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt={`Cazier judiciar online Iași`}
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Cazier judiciar online Iași - preț</h2>
                            <p>Costul serviciilor de eliberare a unui cazier judiciar online din Iași este transparent
                                și competitiv,
                                asigurând un raport calitate-preț avantajos pentru client. Detaliile complete privind
                                prețurile pentru
                                cazier judiciar online obținut din Iași sunt disponibile pe platforma noastră.</p>
                            <p>În plus, oferim suport clienților în tot acest proces, pentru a răspunde la orice
                                întrebări legate de
                                costuri sau de pașii necesari obținerii documentelor. În cazul în care apar nelămuriri
                                sau dificultăți în
                                utilizarea platformei, echipa noastră de asistență este gata să vă ofere ajutorul
                                necesar pentru a asigura o
                                experiență fără probleme.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Obțineți acum online cazierul judiciar pentru județul Iași!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de un cazier judiciar online în Iași? Apelați cu încredere la serviciile
                                noastre online!</h2>
                            <p>Pentru orice întrebări sau pentru a iniția procesul de obținere a cazierului judiciar
                                online din Iași, nu
                                ezitați să ne contactați la adresa de e-mail <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro </a>.
                                Echipa noastră este gata să vă asiste și să vă faciliteze obținerea documentelor
                                necesare cu eficiență și
                                profesionalism.</p>

                            <p>Apelând la caziere.ro pentru eliberarea cazierului judiciar online din Iași, veți
                                descoperi un partener de
                                încredere, care îmbină profesionalismul cu rapiditatea, siguranța, și suportul dedicat.
                                Astfel, demersurile
                                necesare pentru obținerea documentelor de care aveți nevoie devin mai simple și mai
                                puțin stresante,
                                permițându-vă să vă concentrați pe alte aspecte importante ale vieții dumneavoastră.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
