// material
import {Box, Container, Grid} from '@mui/material';
// components
import Page from '../components/Page';
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import * as CommunicationService from "../services/CommunicationService";
import {Helmet} from "react-helmet-async";
import {useEffect} from "react";
import * as LogicService from "../services/LogicService";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------


export default function OrderDetailsTable() {
    let order = LogicService.getOrder();
    if (order == undefined) {
        order = {details: {userDetails: {}}};
    }

    let details = [
        {
            name: 'Name',
            value: order.details.userDetails.firstName + " " + order.details.userDetails.lastName
        },
        {
            name: 'Email',
            value: order.details.username
        },
        {
            name: 'Telefon',
            value: order.details.phoneNumber
        },
        {
            name: 'Preț',
            value: order.price
        },
        {
            name: 'Regimul comenzii',
            value: order.details.regime.toUpperCase()
        },
        {
            name: 'Verificare expert',
            value: (order.details.expertVerification === true ? 'Da' : 'Nu')
        }
    ]

    if (order.details.legalized === true) {
        details.push(
            {
                name: 'Traducere legalizată',
                value: order.details.translationLanguage
            });
    }

    if (order.details.apostila === true) {
        details.push(
            {
                name: 'Apostilă Haga',
                value: 'Da'
            })
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{
                tablecell: {
                    fontSize: '40pt',
                }
            }}>
                <TableBody>
                    {
                        details.map(d =>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">
                                    <Typography component="div" variant="h6">
                                        {d.name}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography component="div" variant="h6">
                                        {d.value}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    }

                </TableBody>
            </Table>
        </TableContainer>
    );
}
