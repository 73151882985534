import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container, Grid, InputLabel, MenuItem, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {UserListHead} from '../components/_dashboard/user';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import ReportProblemModal from "../components/_dashboard/user/ReportProblemModal";
import FormControl from "@mui/material/FormControl";
import ReportMessageForm from "../components/authentication/order/ReportMessageForm";
import {Form, FormikProvider, useFormik} from "formik";
import DragAndDropFileUploader from "../components/_dashboard/app/DragAndDropFileUploader";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {sendMessageForClientWithFile} from "../services/CommunicationService";
import SearchIcon from "@mui/icons-material/Search";

let prefix = '<h4>Bună ziua,</h4></br><h4>';
let suffix = '</h4></br><h4>Cu stimă, Caziere.ro</h4>';
let MESSAGES = {
    10: {
        SUBJECT: 'Certificat de integritate comportamentala',
        EMAIL_BODY: prefix + 'Regăsiți atașat documentul solicitat' + suffix
    },
    20: {
        SUBJECT: 'Documentul dvs este gata de descărcare',
        EMAIL_BODY: prefix + 'Regăsiți atașat documentul solicitat de dvs.' + suffix
    }
}

export default function EmailSender() {

    let handleConfirm = () => {
        setAge('');
        setFieldValue('reason', '')
        setFieldValue('subject', '')
        setFieldValue('email', '')
    }

    useEffect(() => {
    }, []);

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);

        let message = MESSAGES[event.target.value];
        setFieldValue('subject', message.SUBJECT)
        setFieldValue('reason', message.EMAIL_BODY)
    };

    const navigate = useNavigate();

    let [files, setFiles] = React.useState([]);

    let browserData = LogicService.getBrowserData();


    let RegisterSchema = Yup.object().shape({
        subject: Yup.string().required('Subiectul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Subiectul este invalid'),
        reason: Yup.string().required('Mesajul este necesar').min(3, 'Prea scurt').max(500, 'Prea lung').matches(/.*/, 'Mesajulul este invalid'),
        email: Yup.string().email('Email-ul trebuie să fie valid').required('Email-ul este necesar').max(50, 'Prea lung'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            reason: '',
            subject: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: () => {
        }
    });

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = () => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 5000);
    }

    const buttonPress = () => {
        if (Boolean(touched.email && errors.email)
            || Boolean(touched.reason && errors.reason)
            || Boolean(touched.subject && errors.subject)
        ) {
            return;
        }


        let clientMessage = {}
        clientMessage = {
            email: getFieldProps('email').value,
            body: getFieldProps('reason').value,
            subject: getFieldProps('subject').value,
            currentURL: window.location.href,
        }
        disableTemporarily();
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);

        let file = files.length === 0 ? undefined : files[0];

        CommunicationService.sendMessageForClientWithFile(clientMessage, file)
            .then((response) => {
                handleConfirm();
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

    }

    const {errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError} = formik;

    const processReasonChange = (event) => {
        let newText = event.target.value;
        setFieldValue('reason', newText)
    }
    const processSubjectChange = (event) => {
        let newText = event.target.value;
        setFieldValue('subject', newText)
    }

    let semiPhaseOne = <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{mt: 2}}>
            <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
            />

            <TextField
                fullWidth
                label="Subiect"
                multiline
                rows={1}
                {...getFieldProps('subject')}
                error={Boolean(touched.subject && errors.subject)}
                onChange={processSubjectChange}
                helperText={touched.subject && errors.subject}
            />
            <TextField
                fullWidth
                label="Body"
                multiline
                rows={4}
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                onChange={processReasonChange}
                helperText={touched.reason && errors.reason}
            />

            <Typography variant={"body1"} justifyContent={"center"} align={"center"}>
                Încarcă un document
            </Typography>

            <Stack direction={{xs: 'column', sm: 'row', marginTop: "5px"}}
                   sx={{margin: "0% 1%"}}
                   spacing={0} alignSelf={"center"}>
                <DragAndDropFileUploader setFiles={setFiles}/>
            </Stack>

            <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={buttonPress}
                disabled={
                    !buttonEnabled
                    || !touched.email || Boolean(touched.email && errors.email)
                    // || !touched.reason || Boolean(touched.reason && errors.reason)
                    // || !touched.subject || Boolean(touched.subject && errors.subject)
                }
            >
                Trimiteți mesajul
            </Button>
        </Stack>
    </Form>;

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Email sender
                    </Typography>
                </Stack>

                <Card sx={{padding: '30px', marginBottom: '10px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={2}>
                            <Typography sx={{marginTop: '10px'}} variant="h6" gutterBottom>
                                Subject
                            </Typography>
                        </Grid>
                        <Grid item sx={{marginTop: '10px'}} xs={12} sm={10} md={10}>
                            <div>
                                {getFieldProps('subject').value}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: '10px'}} spacing={2}>
                        <Grid item xs={12} sm={2} md={2}>
                            <Typography variant="h6" gutterBottom>
                                Body
                            </Typography>
                        </Grid>
                        <Grid item sx={{marginTop: '6px'}} xs={12} sm={10} md={10}>
                            <div dangerouslySetInnerHTML={{ __html: getFieldProps('reason').value }} />
                        </Grid>
                    </Grid>
                </Card>

                <Card sx={{padding: '30px'}}>
                    <Box sx={{minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tip email</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Tip email"
                                onChange={handleChange}
                            >
                                <MenuItem value={10}>Certificat de integritate comportamentala</MenuItem>
                                <MenuItem value={20}>Documentul dvs este gata de descărcare</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    <FormikProvider value={formik}>
                        {
                            semiPhaseOne
                        }
                    </FormikProvider>
                </Card>


            </Container>
        </Page>
    );
}
