// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Card, Typography} from '@mui/material';
// utils
import * as React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(10),
    height: theme.spacing(10),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha("#FFF", 1)} 0%, ${alpha(
        "#FFF",
        1
    )} 100%)`
}));

export default function AppInfoTwo({title, text, photo}) {

    return (
        <RootStyle>
            <IconWrapperStyle>
                <Box component="img" src={photo} sx={{width: 45}}/>
            </IconWrapperStyle>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="body2" sx={{opacity: 0.72, margin: "20px 50px 10px 50px"}}>
                {text}
            </Typography>
        </RootStyle>
    );
}
