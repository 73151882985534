import PropTypes from 'prop-types';
// material
import {Typography} from '@mui/material';
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from '@mui/lab';

OrderItem.propTypes = {
    item: PropTypes.object,
    isLast: PropTypes.bool
};


let colors = ['primary.main', 'info.main', 'warning.main', 'success.main', 'error.main']

function getColorFromIndex(index) {
    return colors[index % 5];
}

function getTypographyWithSpaces(line, noSuppSpaces, rowFontType, index) {
    return <p style={{marginBottom: "25px"}}>{line}</p>
}

function OrderItem({item, isLast, index, noSuppSpaces, rowFontType, titleFontType}) {
    const {TITLE, LINES} = item;
    return (
        LINES.map((line, index) => getTypographyWithSpaces(line, noSuppSpaces, rowFontType, index))
    );
}


export default function TextRowsTimelineV2({ROWS, noSuppSpaces, rowFontType, titleFontType, style}) {
    return (
        <div style={{textAlign: 'left'}}>
            {ROWS.map((item, index) => (
                <OrderItem style={{marginBottom: '5px !important'}} key={index} item={item} noSuppSpaces={noSuppSpaces} rowFontType={rowFontType}
                           titleFontType={titleFontType} isLast={index === ROWS.length - 1} index={index}/>
            ))}
        </div>
    );
}
