import * as React from 'react';
import {useEffect, useState} from 'react';

import {
    Alert,
    AlertTitle,
    Button,
    Card,
    Container, FormControlLabel,
    IconButton,
    Stack, Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import AddCompanyModal from "../components/_dashboard/user/AddCompanyModal";
import {deleteBanner, deleteCompany, getBannersAsAdmin} from "../services/CommunicationService";
import ConfirmOrRejectModal from "../components/_dashboard/user/ConfirmOrRejectModal";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/ContentCopy";
import AddBannerModal from "../components/_dashboard/user/AddBannerModal";
import FormControl from "@mui/material/FormControl";

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'type', label: 'Tip', alignRight: false},
    {id: 'value', label: 'Valoare', alignRight: false},
];

export default function Banners() {
    const [companies, setCompanies] = useState([]);
    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);


    useEffect(() => {
        CommunicationService.getBannersAsAdmin()
            .then((response) => {
                setCompanies(response);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }, []);


    const addCompany = (company) => {
        CommunicationService.addBanner(company)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.BANNER_ADDED);
                let categAux = companies;
                categAux.push(response);
                setCompanies(categAux);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const updateBanner = (id, status) => {
        CommunicationService.updateBannerStatus(id, status)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.BANNER_UPDATED);
                let categAux = companies;
                let categIndex = categAux.findIndex(c => c.id === response.id);
                if (categIndex >= 0) {
                    categAux[categIndex] = response;
                    setCompanies(categAux);
                }

            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }
    const deleteBanner = (id) => {
        CommunicationService.deleteBanner(id)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.BANNER_DELETED);
                let categAux = companies;
                let categIndex = categAux.findIndex(c => c.id === id);
                if (categIndex >= 0) {
                    categAux.splice(categIndex, 1);
                    setCompanies(categAux);
                }

            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container sx={{maxWidth: "2000px !important"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Banners
                    </Typography>
                </Stack>

                <Card sx={{padding: "30px", marginBottom: "20px", textAlign: "left"}}>
                    <Scrollbar>
                        <Stack spacing={1} alignItems="left">
                            <Stack direction={{xs: 'column', sm: 'row'}} display={"flex"}
                                   flexWrap={"wrap"} justifyContent={"space-between"} spacing={3}>
                                <AddBannerModal open={addCategoryModalOpen}
                                                 handleConfirm={(text) => {
                                                     addCompany(text);
                                                     setAddCategoryModalOpen(false);
                                                 }}
                                                 handleClose={() => setAddCategoryModalOpen(false)}

                                />
                                <Button variant="contained" color={'warning'}
                                        onClick={() => setAddCategoryModalOpen(true)}>
                                    {"Add Banner"}
                                </Button>
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} sx={{marginTop: "20px !important"}} display={"block"}>
                                {
                                    companies.map(company => {
                                        return <Alert severity={company.severity} sx={{marginBottom: "10px"}} variant="filled"
                                                      action={
                                                          <FormControl>
                                                              <FormControlLabel
                                                                  sx={{
                                                                      display: 'block',
                                                                  }}
                                                                  control={
                                                                      <Switch
                                                                          checked={company.active}
                                                                          onChange={() => updateBanner(company.id, !company.active)}
                                                                          name="loading"
                                                                          color="primary"
                                                                      />
                                                                  }
                                                                  label="Active"
                                                              />
                                                              <Button variant="outlined" color={'warning'} sx={{color: 'white', border: '1px solid white'}} startIcon={<DeleteIcon />}
                                                                onClick={() => deleteBanner(company.id)}
                                                              >
                                                                  Delete
                                                              </Button>
                                                          </FormControl>
                                                      }
                                        >
                                            <AlertTitle>{company.title}</AlertTitle>
                                            {company.text}
                                        </Alert>
                                    })
                                }
                            </Stack>
                        </Stack>
                    </Scrollbar>
                </Card>
            </Container>
        </Page>
    );
}
