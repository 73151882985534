import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-brasov-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-brasov-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-brasov-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-brasov-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-brasov-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-brasov-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageBrasov({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier online Brasov",
        "obtinere cazier judiciar online Brasov",
        "cazier Brasov online",
        "cazier judiciar Brasov online / cazier judiciar online Brașov",
        "cerere cazier online judiciar Brasov",
        "cazier judiciar online Brasov pret"
    ]

    return (
        <Page title={`Cazier judiciar online Brașov - Obțineți rapid cazierul judiciar de care aveți nevoie`}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description" content={"Prin intermediul platformei noastre, puteți obține rapid și fără bătăi de cap cazierul judiciar din Brașov, fără a fi nevoie să vă deplasați din confortul casei."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Brașov - Obțineți rapid cazierul judiciar de care aveți nevoie</title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8'}}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Brașov</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Obțineți rapid cazierul judiciar de care
                                aveți nevoie</p>
                            <p>Știm că timpul dumneavoastră este prețios și că procedurile administrative pot fi adesea
                                lungi și
                                obositoare. Dacă vă aflați în situația de a avea nevoie de un cazier judiciar online din
                                Brașov, vă
                                oferim soluția perfectă pentru a economisi timp și efort.</p>
                            <p> Prin intermediul platformei noastre, puteți obține rapid și fără bătăi de cap cazierul
                                judiciar, fără a
                                fi nevoie să vă deplasați din confortul casei.</p>
                            <a href={window.location.origin}>
                                <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar
                                </button>
                            </a>
                        </div>

                        <div className="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre și obțineți online cazierul judiciar din Brașov!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Obținerea cazierului online în Brașov - avantaje</h2>
                            <p>Solicitarea online a cazierului judiciar din Brașov nu numai că simplifică procesul, dar
                                aduce și o
                                serie de beneficii importante pentru dumneavoastră. Această metodă modernă de obținere a
                                documentelor necesare elimină barierele geografice și birocratice, oferind o soluție
                                eficientă și
                                accesibilă pentru toți.</p>

                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Economisiți timp: Prin solicitarea online a cazierului
                                    judiciar,
                                    evitați deplasările până la sediile instituțiilor relevante și așteptarea în cozi
                                    lungi.
                                </li>
                                <li dir="ltr" aria-level="1">Procedură simplificată: Tot procesul se realizează online,
                                    de la
                                    completarea formularului de cerere până la primirea documentului. Nu este necesar să
                                    vă
                                    deplasați pentru a depune sau ridica documentele, ceea ce face procedura mult mai
                                    accesibilă și
                                    mai puțin stresantă.
                                </li>
                                <li dir="ltr" aria-level="1">Siguranță și confidențialitate: Solicitarea online a
                                    cazierului
                                    judiciar garantează protecția datelor personale.
                                </li>
                                <li>Rapiditate: Procesul online de obținere a cazierului judiciar din Brașov este
                                    semnificativ mai
                                    rapid comparativ cu metoda tradițională.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre și obțineți online cazierul judiciar din Brașov!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Motive pentru care să apelați la caziere.ro pentru un cazier judiciar online din
                                Brașov</h2>
                            <p>Obținerea unui cazier judiciar online din Brașov nu a fost niciodată mai simplă, iar cu
                                ajutorul
                                platformei noastre, acest proces devine nu doar accesibil, dar și eficient. Indiferent
                                dacă vă
                                aflați în confortul propriei case sau sunteți într-o călătorie de afaceri la mii de
                                kilometri
                                distanță, serviciile noastre sunt menite să ofere soluția optimă pentru nevoile
                                dumneavoastră. </p>

                            <p><strong>Oferim servicii profesionale pentru obținerea cazierului judiciar online din
                                Brașov și câteva
                                motive ca să alegeți să apelați la noi:</strong></p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online Brașov"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Experiență și profesionalism - Avem o înțelegere profundă a
                                    procedurilor și legislației în vigoare, ceea ce ne permite să oferim consultanță de
                                    specialitate
                                    și să evităm eventualele întârzieri sau probleme.
                                </li>
                                <li dir="ltr" aria-level="1">Rapiditate - Înțelegem cât de prețios este timpul
                                    dumneavoastră, de
                                    aceea ne angajăm să procesăm cererile în cel mai scurt timp posibil.
                                </li>
                                <li dir="ltr" aria-level="1">Accesibilitate - Platforma noastră este proiectată să fie
                                    accesibilă
                                    oricând și de oriunde, oferindu-vă posibilitatea de a solicita un cazier judiciar
                                    online 24/7.
                                    Aceasta înseamnă că puteți iniția procesul de obținere a cazierului judiciar din
                                    Brașov la orice
                                    oră din zi sau din noapte, fără a fi constrânși de programul de lucru al
                                    instituțiilor publice.
                                </li>
                                <li dir="ltr" aria-level="1">Flexibilitate - Indiferent de complexitatea situației
                                    dumneavoastră sau
                                    de cerințele specifice pe care le aveți, suntem pregătiți să adaptăm serviciile
                                    noastre pentru a
                                    răspunde în mod eficient nevoilor dumneavoastră.
                                </li>
                                <li>Transparență - Credem într-o politică de transparență totală, ceea ce înseamnă că
                                    veți fi
                                    informat în mod clar despre toate costurile implicate în procesul de obținere a
                                    cazierului
                                    judiciar.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre și obțineți online cazierul judiciar din Brașov!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Situații în care este posibil să aveți nevoie de un cazier judiciar în Brașov</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_4} alt="Cazier judiciar online Brașov"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <p> Există multiple situații în care un cazier judiciar din Brașov este necesar. Fie că este
                                vorba
                                despre angajare, înscrierea la cursuri sau obținerea cetățeniei în altă țară, cazierul
                                judiciar este
                                un document esențial în multe procese administrative. </p>

                            <p>De asemenea, acesta poate fi solicitat de către proprietarii sau autoritățile competente
                                pentru
                                închirierea unei locuințe sau pentru obținerea unor licențe și autorizații specifice. În
                                contextul
                                participării la licitații publice sau în procesul de adopție a unui copil, prezentarea
                                unui cazier
                                judiciar curat poate fi o condiție obligatorie.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{paddingTop: '5px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Emitere cazier judiciar Brașov online - acte necesare </h2>
                            <p>Procesul de obținere cazier judiciar online din Brașov este simplificat și eficient,
                                permițându-vă să
                                economisiți timp și efort. Serviciul nostru online facilitează accesul la cazierul
                                necesar fără a fi
                                nevoie să vă deplasați fizic. Pentru a obține cazierul judiciar online din Brașov, este
                                necesar să
                                furnizați următoarele documente.</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Copie a actului de identitate.
                                </li>
                                <li dir="ltr" aria-level="1">Poza (selfie) cu actul de identitate în mână.
                                </li>
                                <li dir="ltr" aria-level="1">Contract semnat online, prin care ne împuterniciți să
                                    acționăm în
                                    numele dumneavoastră.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre și obțineți online cazierul judiciar din Brașov!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Timpul de soluționare pentru o cerere de cazier online judiciar, din Brașov</h2>
                            <p>Procesul de soluționare a cererii dumneavoastră pentru cazier online judiciar din Brașov
                                este rapid
                                și eficient. Echipa noastră se ocupă de toate detaliile necesare pentru a vă asigura că
                                primiți
                                cazierul judiciar în cel mai scurt timp posibil. Timpul de procesare poate varia între 1
                                și 5 zile
                                lucrătoare, în funcție de modul în care a fost completat formularul de comandă și de
                                promptitudinea
                                cu care ne furnizați documentele necesare. Pentru a evita întârzierile, este important
                                să completați
                                formularul cu atenție.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" style={{paddingTop: '10px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{textAlign: 'center'}}>
                            <h2>Ce etape trebuie parcurse pentru a obține un cazier judiciar online din Brașov</h2>
                            <p>Obținerea unui cazier judiciar online din Brașov este un proces care elimină necesitatea
                                deplasărilor
                                fizice și așteptarea în cozi lungi. Ajută la economisirea de timp și de resurse și este
                                deosebit de
                                util pentru cei care nu sunt țară sau în Brașov, sau au un program încărcat. Pentru a
                                obține un
                                cazier judiciar online din Brașov, trebuie să urmați următorii pași:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={Foto_5} alt="Cazier judiciar online Brașov"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Completați formularul de cerere online, asigurându-vă că
                                    introduceți
                                    toate informațiile solicitate corect și complet.
                                </li>
                                <li dir="ltr" aria-level="1">Încărcați copiile scanate ale documentelor necesare (copia
                                    actului de
                                    identitate și o fotografie recentă în care țineți actul de identitate în mână).
                                </li>
                                <li dir="ltr" aria-level="1">Semnați electronic contractul de prestări servicii, prin
                                    care vă dați
                                    acordul ca procedurile de obținere a cazierului judiciar să fie efectuate în numele
                                    dumneavoastră.
                                </li>
                                <li dir="ltr" aria-level="1">Efectuați plata serviciului online, folosind metoda de
                                    plată preferată
                                    (card bancar, transfer bancar etc.).
                                </li>
                                <li dir="ltr" aria-level="1">Așteptați procesarea cererii și verificarea documentelor de
                                    către
                                    echipa platformei.
                                </li>
                                <li dir="ltr" aria-level="1">Primirea cazierului judiciar scanat prin e-mail, urmată de
                                    livrarea
                                    documentului original prin curier rapid, direct la adresa specificată în formular.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section" style={{backgroundColor: "#fbfbfb"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <img src={Foto_6} alt="Cazier judiciar online Brașov"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-2">
                            <h2>Prețul pentru serviciul de obținere cazier judiciar online din Brașov</h2>
                            <p>Prețul obținerii cazierului judiciar online din Brașov variază în funcție de mai mulți
                                factori,
                                inclusiv tipul solicitantului (persoană fizică sau juridică), regimul de urgență,
                                necesitatea
                                traducerii legalizate și modalitatea de livrare. Vă garantăm transparență totală în ceea
                                ce privește
                                costurile. </p>

                            <p>Pentru a vă beneficia de un calcul cât mai exact pentru un cazier judiciar online din
                                Brașov, vă
                                invităm să completați formularul de comandă de pe site. În plus, echipa noastră de
                                suport este
                                întotdeauna gata să răspundă la întrebările dumneavoastră și să vă ajute să alegeți cea
                                mai
                                convenabilă opțiune pentru nevoile dumneavoastră.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div className="container">
                    <h2>Apelați la serviciile noastre și obțineți online cazierul judiciar din Brașov!</h2>
                    <a href={window.location.origin}>
                        <button><i className="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Aveți nevoie de un cazier judiciar online din Brașov? Vă putem ajuta!</h2>
                            <p>Pentru orice întrebări sau nelămuriri legate de procesul de obținere a cazierului
                                judiciar online din
                                Brașov, echipa noastră este gata să vă asiste. Nu ezitați să ne contactați la adresa de
                                email <a
                                    href="mailto:contact@caziere.ro">contact@caziere.ro</a> pentru a beneficia de
                                suportul
                                nostru.</p>

                            <p>Vă putem ghida pas cu pas prin întregul proces, astfel încât obținerea cazierului
                                judiciar din Brașov
                                să fie cât se poate de simplă și de rapidă. Apelați la acest serviciu și uitați de
                                stresul
                                birocrației și a cozilor interminabile pe la ghișee!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
