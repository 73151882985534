import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
// material
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = '', ...other}, ref) => (
    <Box ref={ref} {...other}>
        <Helmet>
            <title>{title}</title>
        
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
        {children}
    </Box>
));

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;
