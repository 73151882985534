import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function TextSelectInputPlainString({
                                            options,
                                            selectOption,
                                            disabled,
                                            label,
                                            parenthesisFieldName,
                                            searchFunction,
                                            defaultValue
                                        }) {
    const [selectedOption, setSelectedOption] = useState(selectOption);


    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            defaultValue={defaultValue}
            options={options}
            autoHighlight
            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
            renderOption={(props, option) => {

                return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}
                            key={option}>
                    {option}
                </Box>
            }}
            renderInput={(params) => {
                let option = searchFunction(params.inputProps.value);
                let toCompareA = typeof selectedOption === 'object' ? JSON.stringify(selectedOption) : selectedOption
                let toCompareB = typeof option === 'object' ? JSON.stringify(option) : option
                if (toCompareA !== toCompareB) {
                    setSelectedOption(option);
                    selectOption(option);
                }

                if (option === null || option === undefined) {
                    return <TextField
                        {...params}
                        label={label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />;
                }

                return <TextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        value: option,
                        option: option,
                        autoComplete: 'new-password',
                    }}
                />
            }}
        />
    );

}

