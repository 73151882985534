import {Icon} from '@iconify/react';
import * as React from 'react';
import {useRef, useState} from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import paperplaneoutline from '@iconify/icons-eva/paper-plane-outline';
import alertFill from '@iconify/icons-eva/alert-triangle-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import paperplane from '@iconify/icons-eva/paper-plane-fill';
import rewindRight from '@iconify/icons-eva/rewind-right-fill';
import truckDelivery from '@iconify/icons-eva/checkmark-square-outline';
import invoiceIcon from '@iconify/icons-eva/file-text-fill';
import listIcon from '@iconify/icons-eva/list-fill';
import undoIcon from '@iconify/icons-eva/undo-fill';
import shuffleIcon from '@iconify/icons-eva/shuffle-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack
} from '@mui/material';
import OrderDetailsModal from "./OrderDetailsModal";
import OrderAlertModal from "./OrderAlertModal";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import OrderStatusModal from "./OrderStatusModal";
import OrderInvoiceModal from "./OrderInvoiceModal";
import ConfirmOrRejectModal from "./ConfirmOrRejectModal";
import OrderTransactionStatusModal from "./OrderTransactionStatusModal";
import OrderDocumentsModal from "./OrderDocumentsModal";
import {markLaterDeliveryDone} from "../../../services/CommunicationService";
import OrderMessageLogsModal from "./OrderMessageLogsModal";

// ----------------------------------------------------------------------
export default function OrderMoreMenu({row, isLawyer, invoiceOnly}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
    const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
    const [laterAddressIsOpen, setLaterAddressIsOpen] = useState(false);
    const [transactionStatusModalIsOpen, setTransactionStatusModalIsOpen] = useState(false);
    const [invoiceModalIsOpen, setInvoiceModalIsOpen] = useState(false);
    const [documentsModalIsOpen, setDocumentsModalIsOpen] = useState(false);
    const [logsModalIsOpen, setLogsModalIsOpen] = useState(false);
    const [refundIsOpen, setRefundIsOpen] = useState(false);
    const [messageLogs, setMessageLogs] = useState([]);


    const generateAwb = () => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.AWB_BEING_CREATED);
        CommunicationService.generateAWB(row.id)
            .then((response) => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "AWB-" + Date.now() + ".pdf";
                    a.click();
                    row.awbGenerated = true;
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });

                setIsOpen(false);
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
            });
    }

    let isManuallyCompleted = false;

    if (row != undefined && row.address != undefined && row.address.manuallyCompleted != undefined) {
        isManuallyCompleted = row.address.manuallyCompleted;
    }

    function triggerMessageLogs() {
        setMessageLogs(row.communicationLogs);
        setLogsModalIsOpen(true);
    }

    return (
        <>
            <OrderDetailsModal order={row} open={detailsModalIsOpen} isLawyer={isLawyer}
                               handleClose={() => {
                                   setDetailsModalIsOpen(false);
                                   setIsOpen(false);
                               }}/>
            <OrderAlertModal row={row} open={alertModalIsOpen}
                             handleClose={() => {
                                 setAlertModalIsOpen(false);
                                 setIsOpen(false);
                             }}/>
            <OrderStatusModal row={row} open={statusModalIsOpen}
                              handleClose={() => {
                                  setStatusModalIsOpen(false);
                                  setIsOpen(false);
                              }}/>

            <ConfirmOrRejectModal open={laterAddressIsOpen}
                                  handleClose={() => {
                                      setLaterAddressIsOpen(false);
                                      setIsOpen(false);
                                  }}
                                  handleConfirm={() => {
                                      CommunicationService.markLaterDeliveryDone(row.id, false)
                                          .then((response) => {
                                              setLaterAddressIsOpen(false);
                                              setIsOpen(false);
                                              LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.LATER_DELIVERY_CHANGED)
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

                                  }}
                                  title={"Confirmati marcarea livrarii tarzii?"}
                                  content={"Dupa apasarea butonului de confirm, livrarea va fi marcata ca fiind terminata si nu va veti putea intoarce la starea initiala."}
            />



            <OrderTransactionStatusModal row={row} open={transactionStatusModalIsOpen}
                              handleClose={() => {
                                  setTransactionStatusModalIsOpen(false);
                                  setIsOpen(false);
                              }}/>
            <OrderInvoiceModal row={row} open={invoiceModalIsOpen}
                               invoiceOnly={invoiceOnly}
                               handleClose={() => {
                                   setInvoiceModalIsOpen(false);
                                   setIsOpen(false);
                               }}/>
            <OrderDocumentsModal row={row} open={documentsModalIsOpen}
                               handleClose={() => {
                                   setDocumentsModalIsOpen(false);
                                   setIsOpen(false);
                               }}/>
            <OrderMessageLogsModal row={row} open={logsModalIsOpen}
                                   logs={messageLogs}
                               handleClose={() => {
                                   setLogsModalIsOpen(false);
                                   setIsOpen(false);
                               }}/>


            <ConfirmOrRejectModal open={refundIsOpen}
                                  handleClose={() => {
                                      setRefundIsOpen(false);
                                      setIsOpen(false);
                                  }}
                                  handleConfirm={() => {
                                      CommunicationService.refundOrder(row.id)
                                          .then((response) => {
                                              LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.REFUND_SUCCESS);
                                              row.transactionVerified = false;
                                              row.status = "REFUNDED";
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
                                      setRefundIsOpen(false);
                                      setIsOpen(false);
                                  }}
                                  title={"Confirmați refund-ul orderului?"}
                                  content={"Dupa apasarea butonului de refund, orderul nu mai poate trece în starea inițială."}
            />

            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'}
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >

                {
                    invoiceOnly === true ? null : <MenuItem sx={{color: 'text.secondary'}} onClick={() => setAlertModalIsOpen(true)}>
                        <ListItemIcon>
                            <Icon icon={alertFill} width={24} height={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Alert" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                }
                {
                    invoiceOnly === true ? null : <MenuItem sx={{color: 'text.secondary'}} onClick={() => setDetailsModalIsOpen(true)}>
                        <ListItemIcon>
                            <Icon icon={editFill} width={24} height={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Details" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                }
                {
                    invoiceOnly === true || isLawyer || row.delivery === undefined || row.delivery === null || row.delivery !== 'ADDRESS'
                    || row.address === undefined || row.address === null || row.transactionVerified != true || row.refunded ? null :
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => generateAwb()}>
                            <ListItemIcon>
                                <Icon icon={paperplaneoutline} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary={row.awbGenerated ? "Descarcare AWB" : "Generare AWB"}
                                          style={isManuallyCompleted ? {background: "red", color: "white"} : {}}
                                          primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    !isLawyer && row.delivery == "ADDRESS" &&
                    (row.address != undefined && row.address != null && row.address.laterAdded)
                        ?
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => setLaterAddressIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={truckDelivery} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Later Delivery Done" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem> : null
                }
                {
                    isLawyer ? null :
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => setStatusModalIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={rewindRight} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Schimbare status" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    isLawyer
                    || row.documents === undefined || row.documents === null || row.documents.length === 0
                        ? null :
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => setTransactionStatusModalIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={shuffleIcon} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Status tranzacție" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    isLawyer || row.fiscalDone !== true || row.transactionVerified !== true
                        ? null :
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => setInvoiceModalIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={invoiceIcon} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Factura" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    isLawyer ? null :
                        <MenuItem sx={{color: 'text.secondary'}}
                                  disabled={row == undefined || row == null || row.documents == undefined || row.documents == null || row.documents.length == 0}
                                  onClick={() => setDocumentsModalIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={listIcon} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Documente" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    invoiceOnly === true || isLawyer || row.transactionVerified === undefined || row.transactionVerified === null
                    || row.transactionVerified === false ? null :
                        <MenuItem sx={{color: 'text.secondary'}} onClick={() => setRefundIsOpen(true)}>
                            <ListItemIcon>
                                <Icon icon={undoIcon} width={24} height={24}/>
                            </ListItemIcon>
                            <ListItemText primary="Refund" primaryTypographyProps={{variant: 'body2'}}/>
                        </MenuItem>
                }
                {
                    invoiceOnly === true || isLawyer ? null : <MenuItem sx={{color: 'text.secondary'}}
                                                                        onClick={triggerMessageLogs}>
                        <ListItemIcon>
                            <Icon icon={paperplane} width={24} height={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Message Logs" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                }
                {
                    invoiceOnly === true || isLawyer ? null : <MenuItem disabled sx={{color: 'text.secondary'}}>
                        <ListItemIcon>
                            <Icon icon={trash2Outline} width={24} height={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Delete" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem>
                }
            </Menu>
        </>
    );
}
