import * as Yup from 'yup';
import * as React from 'react';
import {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {Link, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    let LoginSchema = Yup.object().shape({});
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const disableTemporarily = (timeout) => {
        setButtonEnabled(false);
        setTimeout(() => {
            setButtonEnabled(true);
        }, 5000);
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            authCode: ''
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            let authDetails = {
                username: getFieldProps('email').value,
                authCode: getFieldProps('authCode').value,
            }
            disableTemporarily();
            CommunicationService.getOrderByAuthDetails(authDetails)
                .then((response) => {
                    CommunicationService.setToken(response.jwtResponse);
                    LogicService.setCreatedOrder(response.order);
                    navigate('/detalii-comanda');
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }
    });

    const forgotCode = () => {
        let username = getFieldProps('email').value;
        if (username === undefined || username === null || username.length < 5) {
            return LogicService.setAlertMessageAndSeverity({
                message: "Introduceți adresa de email pentru a re-trimite PIN-ul",
                key: "unknown_error",
                severity: "error"
            });
        }
        let authDetails = {
            username: username
        }
        disableTemporarily(100000);
        CommunicationService.requestNewAuthCode(authDetails)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.AUTH_CODE_CHANGED)
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const {errors, touched, handleSubmit, getFieldProps} = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete="authCode"
                        type={'text'}
                        label="Cod autentificare SMS"
                        {...getFieldProps('authCode')}
                        error={Boolean(touched.authCode && errors.authCode)}
                        helperText={touched.authCode && errors.authCode}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>

                    <Link component={RouterLink} variant="subtitle2" to="#" onClick={forgotCode}>
                        Ati uitat codul de acces?
                    </Link>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={!buttonEnabled}
                >
                    Obține detalii
                </LoadingButton>

            </Form>
        </FormikProvider>
    );
}
