import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-rutier-online-2.jpg"
import Foto_3 from "./images/cazier-rutier-online-3.jpg"
import Foto_4 from "./images/cazier-rutier-online-4.jpg"
import Foto_cta from "./images/cazier-rutier-online-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";
import * as AlertMapper from "../../services/AlertMapper";
import UnsupportedOrderTypeModal from "../../components/_dashboard/user/UnsupportedOrderTypeModal";
import {Button} from "@mui/material";
import Router from "../../routes";
import MainPage from "../MainPage";
import MainPageCluj from "./MainPageCluj";
import MainPageIasi from "./MainPageIasi";
import MainPageTimisoara from "./MainPageTimisoara";
import MainPageArad from "./MainPageArad";
import MainPageBucuresti from "./MainPageBucuresti";
import MainPageCraiova from "./MainPageCraiova";
import MainPagePret from "./MainPagePret";
import MainPageFirma from "./MainPageFirma";
import MainPageIntegritateComportamentala from "./MainPageIntegritateComportamentala";

export default function MainPageListing() {



    return (
        <Page title={`Cazier Judiciar Online`}>
            <Helmet>
                <meta name="googlebot" content="noindex"/>
                <meta name="robots" content="noindex"/>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div className="section" style={{backgroundColor: '#f8f8f8', display: 'flex', flexWrap: 'wrap'}}>

                {
                    [
                        // {element: <Navigate to="/" replace/>},
                        {path: 'cazier-judiciar-online-cluj',              },
                        {path: 'cazier-judiciar-online-iasi',              },
                        {path: 'cazier-judiciar-online-timisoara',         },
                        {path: 'cazier-judiciar-online-arad',              },
                        {path: 'cazier-judiciar-online-brasov',              },
                        {path: 'cazier-judiciar-online-oradea',              },
                        {path: 'cazier-judiciar-online-sibiu',              },
                        {path: 'cazier-judiciar-online-constanta',              },
                        {path: 'cazier-judiciar-online-bucuresti',         },
                        {path: 'cazier-judiciar-online-craiova',           },
                        {path: 'cazier-judiciar-online-pret',              },
                        {path: 'cazier-judiciar-online-firma',             },
                        // {path: 'cazier-online-rutier',                     },
                        {path: 'certificat-de-integritate-comportamentala',}
                    ].map(link => {
                        return <a href={window.location.origin + "/servicii/" + link.path} style={{marginRight: '10px'}}>
                            <button><i className="fas fa-download custom-icon"></i> {link.path}</button>
                        </a>
                    })
                }

            </div>



        </Page>
    );
}
