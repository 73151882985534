import {Icon} from '@iconify/react';
import {useRef, useState} from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Button, Divider, IconButton, MenuItem, Typography} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import * as CommunicationService from "../../services/CommunicationService";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    // {
    //     label: 'Home',
    //     icon: homeFill,
    //     linkTo: '/'
    // },
    // {
    //     label: 'Profile',
    //     icon: personFill,
    //     linkTo: '#'
    // },
    // {
    //     label: 'Settings',
    //     icon: settings2Fill,
    //     linkTo: '#'
    // },
    {
        label: 'Admin',
        icon: settings2Fill,
        linkTo: '/admin'
    }
];

// ----------------------------------------------------------------------

export default function MainPageAccountPopover() {
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        CommunicationService.setToken(null);
        navigate('/');
    };

    let Auth = CommunicationService.getUser();

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar src={account.photoURL} alt="photoURL"/>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{width: 280}}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="body1" sx={{color: 'text.secondary'}} noWrap>
                        {Auth.username}
                    </Typography>
                </Box>

                <Divider sx={{my: 1}}/>

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{typography: 'body2', py: 1, px: 2.5}}
                    >
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{p: 2, pt: 1.5}}>
                    <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
