import * as React from 'react';
import {useState} from 'react';

import {Box, Button, IconButton, Stack, Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import UploadButton from "../components/authentication/order/UploadButton";
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import Download from "@mui/icons-material/Download";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmOrRejectModal from "../components/_dashboard/user/ConfirmOrRejectModal";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            fontSize: "15px",
            bgcolor: stringToColor(name),
        },
        children: `${name}`,
    };
}

export default function DriveCategory({refreshValue, category, year, month, documents, addDocument, deleteDocument}) {
    const [docId, setDocId] = useState(null);
    const [downloadEnabled, setDownloadEnabled] = useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const uploadDocument = (event, cat) => {
        let file = event.target.files[0];
        CommunicationService.addDocumentToDrive(file, cat.name, year, month + 1)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_UPLOAD);
                addDocument(response);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const generateArchive = (cat) => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.REPORT_BEING_CREATED);
        CommunicationService.generateDriveArchive(cat.name, year, month + 1)
            .then((response) => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = "Drive_Archive_" + cat.name + "_" + year + "_" + (month + 1) + "_Generated_" + Date.now() + ".zip";
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const deleteDoc = (docId) => {
        CommunicationService.deleteDocumentToDrive(docId)
            .then((response) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.FILE_DELETED_WITH_SUCCESS);
                deleteDocument(docId);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    function downloadDocument(doc) {
        setDownloadEnabled(false);
        setTimeout(() => {
            setDownloadEnabled(true);
        }, 10000)

        CommunicationService.downloadFile(doc.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc.originalName;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Stack spacing={1} alignItems="center" sx={{
            border: "2px solid green",
            minHeight: "550px !important",
            minWidth: "300px !important",
            borderRadius: "10px",
            margin: "20px",
            padding: "5px"
        }}>
            <ConfirmOrRejectModal open={deleteModalOpen}
                                  handleConfirm={() => {
                                      deleteDoc(docId);
                                      setDeleteModalOpen(false);
                                  }}
                                  handleClose={() => setDeleteModalOpen(false)}
                                  title={"Confirmați ștergerea fisierului!"}
                                  content={"Dupa apasarea butonului de ștergere, fișierul nu mai poate fi recuperat."}
            />

            <Box
                sx={{display: "flex", justifyContent: "space-between", margin: "10px 0", minWidth: "300px !important"}}>
                <Typography variant="h5" sx={{marginTop: "5px"}}>
                    {category.name}
                </Typography>
                <Box sx={{display: "flex", justifyContent: "space-between", textAlign: "right"}}>
                    <UploadButton disabled={false}
                                  onChange={(event) => uploadDocument(event, category)}
                                  maybeId={"category" + "_" + category.name}/>
                    <Button variant="outlined" component="span" color={'secondary'} disabled={documents.length < 2}
                            onClick={(event) => generateArchive(category)}
                    >
                        {"Archive"}
                    </Button>
                </Box>
            </Box>

            <List sx={{width: '100%', maxWidth: 450, bgcolor: 'background.paper', maxHeight: 500, overflow: 'auto'}}>
                {
                    documents
                        .sort((d1, d2) => d2.id - d1.id)
                        .map(doc => {
                            let nameGroup = doc.originalName.split('\.')
                            let ext = nameGroup[nameGroup.length - 1];
                            return <ListItem>
                                <ListItemAvatar>
                                    <Avatar {...stringAvatar(ext)} />
                                </ListItemAvatar>
                                <ListItemText primary={doc.originalName}/>
                                <IconButton variant="outlined" component="span" color={'warning'}
                                            onClick={() => downloadDocument(doc)}
                                >
                                    <Download/>
                                </IconButton>
                                <IconButton variant="outlined" component="span" color={'error'}
                                            onClick={() => {
                                                setDocId(doc.id);
                                                setDeleteModalOpen(true);
                                            }}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItem>;
                        })
                }


            </List>
        </Stack>
    );
}
