// material
import {alpha, styled} from '@mui/material/styles';
import {Box, Card, Typography} from '@mui/material';
// utils
import * as LogicService from "../../../services/LogicService";
import * as React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({theme}) => ({
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: theme.palette.secondary,
    backgroundColor: theme.palette.secondary
}));

const IconWrapperStyle = styled('div')(({theme}) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(18),
    height: theme.spacing(18),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
        theme.palette.primary.dark,
        0.24
    )} 100%)`
}));

// ----------------------------------------------------------------------

export default function PriceOffer() {

    let order = LogicService.getOrder();
    return (
        <RootStyle sx={{margin: "0px 40px 10px 40px", boxShadow: 0, borderRadius: "1%"}}>
            <IconWrapperStyle>
                <Box component="img" src={"/static/illustrations/discount.png"} sx={{width: 90}}/>
            </IconWrapperStyle>
            <Typography variant="h2">{order.price} RON</Typography>
            {/*<Typography variant="h6">TVA 19% = {parseFloat(order.price - (order.price / 1.19)).toFixed(2)} RON INCLUS</Typography>*/}
        </RootStyle>
    );
}
