import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";

import * as LogicService from "../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../services/LogicService";
import Foto_2 from "./images/cazier-judiciar-online-sibiu-2.jpg"
import Foto_3 from "./images/cazier-judiciar-online-sibiu-3.jpg"
import Foto_4 from "./images/cazier-judiciar-online-sibiu-4.jpg"
import Foto_5 from "./images/cazier-judiciar-online-sibiu-5.jpg"
import Foto_6 from "./images/cazier-judiciar-online-sibiu-6.jpg"
import Foto_cta from "./images/cazier-judiciar-online-sibiu-cta.jpg"
import * as Countries from "../../constants/Countries";
import "./style1.css"
import {Helmet} from "react-helmet-async";

export default function MainPageSibiu({browserDataCheck}) {

    useEffect(() => {
        browserDataCheck();
    }, []);

    let keywords = [
        "cazier online Sibiu",
        "cazier judiciar Sibiu online",
        "obtinere cazier judicira online Sibiu",
        "cerere cazier judiciar online Sibiu",
        "eliberare cazier judiciar online Sibiu",
        "cazier judiciar online Sibiu pret",
    ]

    return (
        <Page title={`Cazier judiciar online Sibiu - Îl puteți obține din confortul propriei locuințe `}>
            <Helmet>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
                <meta name="description"
                      content={"Prin intermediul platformei noastre, puteți obține rapid și fără bătăi de cap cazierul judiciar din Brașov, fără a fi nevoie să vă deplasați din confortul casei."}/>
                <meta name="keywords" content={keywords.join(',')}/>
                <title>Cazier judiciar online Sibiu - Îl puteți obține din confortul propriei locuințe </title>
            
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-840657989"></script>
                <script src="./gtag.js" type="text/javascript"></script>
            </Helmet>
            <div class="section" style={{backgroundColor: '#f8f8f8'}}>
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6 text-center padding-more">
                            <h1>Cazier judiciar online Sibiu</h1>
                            <p style={{fontWeight: 'bold', color: '#00ab55'}}>Îl puteți obține din confortul propriei
                                locuințe</p>
                            <p>Conștienți de valoarea timpului dumneavoastră și de complexitatea procedurilor
                                administrative, vă oferim
                                o soluție eficientă pentru a obține cazierul judiciar online dacă locuiți în Sibiu, fără
                                a fi necesar să
                                ieșiți din propria locuință. Platforma noastră vă permite să accesați rapid și ușor
                                cazierul judiciar,
                                eliminând necesitatea deplasărilor fizice.</p>
                            <a href={window.location.origin}>
                                <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                            </a>
                        </div>


                        <div class="col-md-6 bg-image"
                             style={{backgroundImage: `url(${Foto_2})`}}></div>
                    </div>
                </div>
            </div>


            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Beneficiați de serviciile noastre pentru a obține cazierul judiciar online din Sibiu!</h2>
                    <a href={window.location.origin}>
                        <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Avantajele obținerii cazierului judiciar online din Sibiu</h2>
                            <p>Solicitarea cazierului judiciar online din Sibiu aduce o serie de beneficii
                                semnificative,
                                simplificând procesul și eliminând barierele birocratice. Această metodă modernă de
                                accesare a
                                documentelor necesare vă oferă o soluție rapidă și eficientă, indiferent de locația
                                dumneavoastră.</p>
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Evitați deplasările și așteptarea în cozi lungi prin
                                    solicitarea online
                                    a cazierului judiciar din Sibiu.
                                </li>
                                <li dir="ltr" aria-level="1">De la completarea formularului până la primirea
                                    documentului, totul se
                                    desfășoară online, fără necesitatea deplasărilor fizice.
                                </li>
                                <li dir="ltr" aria-level="1">Datele dumneavoastră personale sunt protejate pe parcursul
                                    întregului
                                    proces.
                                </li>
                                <li dir="ltr" aria-level="1">Obținerea cazierului judiciar online din Sibiu este mult
                                    mai rapidă
                                    decât metoda tradițională.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                <div class="container">
                    <h2>Beneficiați de serviciile noastre pentru a obține cazierul judiciar online din Sibiu!</h2>
                    <a href={window.location.origin}>
                        <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                    </a>
                </div>
            </div>


            <div class="section" style={{backgroundColor: "#fbfbfb"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" style={{textAlign: 'center'}}>
                            <h2>De ce să alegeți caziere.ro pentru obținerea cazierului judiciar online din Sibiu</h2>
                            <p>Platforma noastră facilitează procesul de obținere a cazierului judiciar online din
                                Sibiu,
                                oferindu-vă o soluție accesibilă și eficientă. Fie că vă aflați sau nu în țară,
                                serviciile noastre
                                sunt concepute pentru a satisface nevoile dumneavoastră. Motive ca să apelați la noi:
                            </p>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-6 order-md-2">
                            <img src={Foto_3} alt="Cazier judiciar online Sibiu"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <ul className="checkmarkList">
                                <li dir="ltr" aria-level="1">Suntem experți în legislația și procedurile actuale, și vă
                                    putem oferi consultanță de specialitate.&nbsp;
                                </li>
                                <li dir="ltr" aria-level="1">Știm că timpul dumneavoastră este valoros și ne angajăm să
                                    procesăm cererile rapid.
                                </li>
                                <li dir="ltr" aria-level="1">Platforma este disponibilă non-stop, permițându-vă să
                                    solicitați cazierul judiciar din Sibiu la orice oră.
                                </li>
                                <li dir="ltr" aria-level="1">Ne adaptăm serviciile la cerințele dumneavoastră,
                                    indiferent de
                                    complexitate.
                                </li>
                                <li dir="ltr" aria-level="1">Vă informăm clar despre toate costurile implicate.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>


                <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                    <div class="container">
                        <h2>Beneficiați de serviciile noastre pentru a obține cazierul judiciar online din Sibiu!</h2>
                        <a href={window.location.origin}>
                            <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                        </a>
                    </div>
                </div>


                <div class="section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12" style={{textAlign: 'center'}}>
                                <h2>Etapele obținerii cazierului judiciar online din Sibiu
                                </h2>
                                <p>Știm cât de valoros este timpul dumneavoastră și cât de complicate pot părea uneori
                                    demersurile
                                    birocratice. Dacă aveți nevoie de un cazier judiciar online din Sibiu, vă oferim
                                    soluția
                                    ideală
                                    pentru a economisi timp și efort, fără a fi nevoiți să părăsiți confortul propriei
                                    locuințe.
                                    Serviciul nostru online vă permite să obțineți cazierul judiciar rapid și simplu,
                                    eliminând
                                    necesitatea deplasărilor la instituțiile statului. Acum, obținerea documentelor
                                    necesare
                                    este mai
                                    accesibilă și mai puțin stresantă, indiferent de locul în care vă aflați.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 order-md-1">
                                <img src={Foto_4} alt="Cazier judiciar online Sibiu"
                                     class="img-fluid"/>
                            </div>
                            <div class="col-md-6 order-md-2">
                                <p> Procesul este simplu și eficient, eliminând necesitatea deplasărilor și așteptărilor
                                    lungi. Urmăriți
                                    pașii simpli pentru a obține cazierul judiciar online:</p>
                                <ul className="checkmarkList">
                                    <li dir="ltr" aria-level="1">Completați formularul online cu informațiile
                                        solicitate.
                                    </li>
                                    <li dir="ltr" aria-level="1">Încărcați documentele necesare.
                                    </li>
                                    <li dir="ltr" aria-level="1">Semnați contractul de prestări servicii electronic.
                                    </li>
                                    <li dir="ltr" aria-level="1">Efectuați plata serviciului online.
                                    </li>
                                    <li dir="ltr" aria-level="1">Așteptați procesarea cererii și verificarea
                                        documentelor.
                                    </li>
                                </ul>
                                <p>În maximum 5 zile de la primirea și semnarea documentelor, veți primi cazierul
                                    judiciar
                                    online din
                                    Sibiu prin e-mail și livrarea documentului original prin curier.</p>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="section" style={{paddingTop: '5px'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Când este necesar un cazier online pentru locuitorii din Sibiu</h2>
                                <p>Cazierul judiciar este un document esențial în diverse situații, de la angajare și
                                    înscrierea la
                                    cursuri, până la obținerea cetățeniei în altă țară. Este, de asemenea, solicitat în
                                    procesul de
                                    închiriere a unei locuințe, obținerea licențelor sau participarea la licitații
                                    publice. </p>
                                <p>
                                    Cazierul online pentru locuitorii din Sibiu poate fi, de asemenea, cerut de către
                                    autoritățile
                                    competente pentru verificarea istoricului judiciar în cazul adopțiilor sau pentru
                                    validarea
                                    integrității
                                    personale în diverse contexte profesionale. De asemenea, un cazier judiciar curat
                                    este
                                    adesea o condiție
                                    prealabilă pentru implicarea în activități comunitare sau voluntariat, subliniind
                                    importanța
                                    acestui
                                    document în susținerea unui profil personal și profesional integru.
                                </p>
                            </div>
                        </div>

                    </div>

                    <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                        <div class="container">
                            <h2>Beneficiați de serviciile noastre pentru a obține cazierul judiciar online din
                                Sibiu!</h2>
                            <a href={window.location.origin}>
                                <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                            </a>
                        </div>
                    </div>


                    <div class="section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12" style={{textAlign: 'center'}}>
                                    <h2>Acte necesare pentru emiterea cazierului judiciar online din Sibiu</h2>
                                    <p>Obținerea cazierului judiciar online din Sibiu este acum mai accesibilă ca
                                        niciodată,
                                        eliminând
                                        necesitatea deplasărilor și a așteptării la cozi. Serviciul nostru vă permite să
                                        finalizați întregul
                                        proces din confortul propriei locuințe, asigurându-vă că obțineți documentul
                                        necesar
                                        rapid și fără
                                        complicații. Acte pe care trebuie să pregătiți pentru a demara procedura:</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 order-md-2">
                                    <img src={Foto_5} alt="Cazier judiciar online Sibiu"
                                         class="img-fluid"/>
                                </div>
                                <div class="col-md-6 order-md-1">
                                    <ul className="checkmarkList">
                                        <li dir="ltr" aria-level="1">Copie a actului de identitate: Este necesară pentru
                                            a
                                            vă
                                            verifica
                                            identitatea și pentru a asigura că cererea este legitimă.
                                        </li>
                                        <li dir="ltr" aria-level="1">Fotografie (selfie) cu actul de identitate în mână:
                                            Aceasta
                                            ajută la
                                            confirmarea identității solicitantului și la verificarea autenticității
                                            documentului.
                                        </li>
                                        <li>Contract semnat electronic: Prin semnarea acestui contract, ne oferiți
                                            împuternicirea de a
                                            acționa în numele dumneavoastră, facilitând procesul de obținere a
                                            cazierului
                                            judiciar.
                                        </li>
                                    </ul>
                                    <p>Aceste documente sunt esențiale pentru a asigura un proces fluent și pentru a
                                        evita
                                        orice
                                        întârzieri
                                        sau probleme în obținerea cazierului judiciar online din Sibiu.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section" style={{paddingTop: '10px'}}>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12" style={{textAlign: 'center'}}>
                                    <h2>Cât durează obținerea cazierului judiciar online în Sibiu</h2>
                                    <p>Procesăm cererile într-un interval de 1 până la 5 zile lucrătoare, în funcție de
                                        completarea corectă
                                        a formularului și promptitudinea furnizării documentelor necesare.</p>

                                    <p>Pentru a asigura o obținere rapidă a cazierului judiciar online din Sibiu, este
                                        esențial
                                        să acordați
                                        atenție detaliilor atunci când completați formularul de cerere și să vă
                                        asigurați că
                                        toate
                                        documentele necesare sunt furnizate în mod prompt și corect. Astfel, colaborarea
                                        eficientă cu echipa
                                        noastră poate reduce semnificativ timpul de așteptare, permițându-vă să primiți
                                        documentul în cel
                                        mai scurt timp posibil.</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="section" style={{backgroundColor: "#fbfbfb"}}>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 order-md-1">
                                    <img src={Foto_6} alt="Cazier judiciar online Sibiu"
                                         class="img-fluid"/>
                                </div>
                                <div class="col-md-6 order-md-2">
                                    <h2>Obținere cazier judiciar online în Sibiu - preț</h2>
                                    <p>Prețul pentru obținerea unui cazier judiciar online din Sibiu variază în funcție
                                        de
                                        mai
                                        mulți
                                        factori. Acesta poate fi influențat de urgența cu care aveți nevoie de document,
                                        precum
                                        și de
                                        opțiunile suplimentare alese, cum ar fi traducerea legalizată sau livrarea
                                        fizică a
                                        documentului.</p>

                                    <p>Oferim transparență totală în privința costurilor, astfel încât să puteți lua cea
                                        mai
                                        informată
                                        decizie în funcție de necesitățile dumneavoastră. Pentru un calcul exact, vă
                                        invităm
                                        să
                                        completați
                                        formularul de comandă. Echipa noastră vă stă la dispoziție pentru orice
                                        întrebări.</p>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="cta" style={{backgroundImage: `url(${Foto_cta})`}}>
                        <div class="container">
                            <h2>Beneficiați de serviciile noastre pentru a obține cazierul judiciar online din
                                Sibiu!</h2>
                            <a href={window.location.origin}>
                                <button><i class="fas fa-download custom-icon"></i> Obțineți cazier judiciar</button>
                            </a>
                        </div>
                    </div>

                    <div class="section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>Aveți nevoie de informații suplimentare pentru un cazier judiciar online din
                                        Sibiu?
                                        Contactați-ne!</h2>
                                    <p>Pentru detalii despre obținerea cazierului judiciar online din Sibiu,
                                        contactați-ne
                                        la
                                        adresa de
                                        email contact@caziere.ro. Echipa noastră vă va ghida prin întregul proces,
                                        asigurându-vă
                                        o
                                        experiență simplă și rapidă.</p>

                                    <p>Beneficiați de expertiza și suportul nostru pentru a obține cazierul judiciar
                                        online
                                        din
                                        Sibiu fără a
                                        pierde timp prețios în așteptări la ghișee sau în deplasări inutile. Cu ajutorul
                                        nostru,
                                        veți avea
                                        la dispoziție o soluție eficientă și accesibilă, care vă permite să vă
                                        concentrați
                                        pe
                                        ceea ce este
                                        cu adevărat important pentru dumneavoastră. Alegeți serviciul nostru și evitați
                                        complicațiile
                                        birocratice!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Page>
);
}
