import config from './config';

let AUTHORIZATION = '';

function getAllOrders(pageNo = "0", pageSize = "10", sortBy = "createdAt", sortDirection = "desc", filterBy = null, filterName = null, autoGenerated = false) {
    return `/order/all?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&filterBy=${filterBy}&filterValue=${filterName}&autoGenerated=${autoGenerated}`;
}

function getFingerprints(pageNo = "0", pageSize = "10", sortBy = "createdAt", sortDirection = "desc") {
    return `/fingerprint/all?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
}

function getAllCommunicationLogs(pageNo = "0", pageSize = "10", sortBy = "createdAt", sortDirection = "desc", filterBy = null, filterName = null) {
    return `/communicationLog/all?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&filterBy=${filterBy}&filterValue=${filterName}`;
}
function getEFacturaMessages(pageNo = "0", pageSize = "10", sortBy = "createdAt", sortDirection = "desc", filterBy = null, filterName = null) {
    return `/e-factura/messages?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&filterBy=${filterBy}&filterValue=${filterName}`;
}

function reportMethod(startDate, endDate, sortBy = "createdAt", sortDirection = "desc", filterBy = null, filterName = null) {
    let defaultPath = `/report/orders?sortBy=${sortBy}&sortDirection=${sortDirection}&filterBy=${filterBy}&filterValue=${filterName}`
    if (isNotBad(startDate)) {
        defaultPath += `&startDate=${startDate}`
    }
    if (isNotBad(endDate)) {
        defaultPath += `&endDate=${endDate}`
    }
    return defaultPath;
}

function addDocumentToDrive(categoryName, year, month) {
    let defaultPath = `/drive/add-document?categoryName=${categoryName}`
    if (isNotBad(year)) {
        defaultPath += `&year=${year}`
    }
    if (isNotBad(month)) {
        defaultPath += `&month=${month}`
    }
    return defaultPath;
}

function archive(categoryName, year, month) {
    let defaultPath = `/drive/archive/${categoryName}`
    if (isNotBad(year)) {
        defaultPath += `&year=${year}`
    }
    if (isNotBad(month)) {
        defaultPath += `&month=${month}`
    }
    return defaultPath;
}

function awbAllMethod(startDate, endDate) {
    let defaultPath = `/courier/generateAWB`
    let symbol = '?'
    if (isNotBad(startDate)) {
        defaultPath += symbol + `startDate=${startDate}`
        symbol = "&";
    }
    if (isNotBad(endDate)) {
        defaultPath += symbol + `endDate=${endDate}`
    }
    return defaultPath;
}

function invoicesMethod(startDate, endDate, startingNumber, businessOnly, own) {
    if (own) {
        return `/invoice/generate/own?startDate=${startDate}&endDate=${endDate}&businessOnly=${businessOnly}`;
    }
    return `/invoice/generate?startDate=${startDate}&endDate=${endDate}&startingNumber=${startingNumber}`;
}

function isNotBad(field) {
    return field !== undefined && field !== null;
}

const DEV_MODE = false;
const TEST_ENV = false;

const API = {
    HOST_AND_PORT: config.server.AWS,
    PATHS: {
        DRIVE: {
            SCHEDULE: `/drive/schedule`,
            ADD_CATEGORY: (categoryName) => `/drive/add-category/${categoryName}`,
            ARCHIVE: (categoryName, year, month) => `/drive/archive/${categoryName}/${year}/${month}`,
            UPLOAD_DOCUMENT: (categoryName, year, month) => addDocumentToDrive(categoryName, year, month),
            DELETE_DOCUMENT: (documentId) => `/drive/document/${documentId}`
        },
        COMPANY: {
            GET: `/company/`,
            ADD: `/company/`,
            DELETE: (id) => `/company/${id}`,
        },
        BANNER: {
            GET_AS_ADMIN: `/banner/allAsAdmin`,
            GET: `/banner/`,
            ADD: `/banner/`,
            UPDATE_STATUS: (id, isActive) => `/banner/${id}/active/${isActive}`,
            DELETE: (id) => `/banner/${id}`,
        },
        PAYMENT_LINKS: {
            CREATE: `/payment-links/create`,
            ORDER: (orderId, code) => `/payment-links/order/${orderId}/${code}`,
        },
        FEATURE_SWITCHES: {
            ALL: `/feature-switch/all`,
            ALL_PUBLIC: `/feature-switch/all/public`,
            UPDATE: (id) => `/feature-switch/${id}`
        },
        PRICES: {
            ALL: `/price/all`,
            ALL_PUBLIC: `/price/all/public`,
            UPDATE: (id) => `/price/${id}`,
            UPDATE_BULK: `/price/bulk`
        },
        PRICES_COUNTRY: {
            ALL: `/price/country/all`,
            UPDATE: (id) => `/price/country/${id}`,
            UPDATE_BULK: `/price/country/bulk`
        },
        DEFAULTS: {
            ALL: `/default/all`,
            COMPANY_IN_USE: `/default/companyInUse`,
            UPDATE: (id) => `/default/${id}`,
            UPDATE_BULK: `/default/bulk`
        },
        BLACKLIST: {
            ALL: `/blacklist/all`,
            DELETE: (id) => `/blacklist/${id}`,
            CREATE: `/blacklist/`
        },
        MESSAGE_QUEUE: {
            ALL: `/message-queue/all`,
            DELETE: (id) => `/message-queue/${id}`,
        },
        ANAF: {
            DETAILS: (id) => `/anaf/${id}`,
        },
        REPORT: {
            ORDER: ({
                        startDate,
                        endDate,
                        sortBy,
                        sortDirection,
                        filterBy,
                        filterName
                    }) => reportMethod(startDate, endDate, sortBy, sortDirection, filterBy, filterName)
        },
        INVOICES: {
            GENERATE: ({
                           startDate,
                           endDate,
                           startingNumber,
                           businessOnly,
                           own
                       }) => invoicesMethod(startDate, endDate, startingNumber, businessOnly, own),
            GENERATE_PARTNERS: ({startDate, endDate}) => `/invoice/partners?startDate=${startDate}&endDate=${endDate}`
        },
        COURIER: {
            STREETS: '/courier/streets',
            GENERATE_AWB_FOR: (orderId) => `/courier/generateAWB/${orderId}`,
            GENERATE_AWB_FOR_ALL: ({startDate, endDate}) => awbAllMethod(startDate, endDate)
        },
        MESSAGE: {
            SEND: '/message/send',
            SEND_WITH_ATTACHMENT_TO_CLIENT: '/message/send-attachment-to-client',
            SEND_WITH_ATTACHMENT: '/message/send-attachment',
            SEND_WITH_ATTACHMENT_UNKNOWN_DOC: '/message/send-attachment-unknown-doc',
            SEND_WITH_ATTACHMENT_DUMB: '/message/send-attachment-dumb',
        },
        ORDER: {
            PLACE: '/order/place',
            MESSAGE_LOGS: (orderId) => `/order/${orderId}/message-logs`,
            INVOICE_DETAILS: '/order/invoiceDetails',
            INVOICE_DOWNLOAD: (orderId) => `/order/${orderId}/invoice`,
            INVOICE_SEND: (orderId) => `/order/${orderId}/invoiceAndSend`,
            ALL: (pageNo, pageSize, sortBy, sortDirection, filterBy, filterName, autoGenerated) => getAllOrders(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName, autoGenerated),
            GET: '/order/',
            STATS_LAST_SEVEN_DAYS: '/order/statisticsLastDays',
            REQUEST_NEW_AUTH_CODE: '/order/request-auth-code',
            REQUEST_NEW_AUTH_CODE_AS_ADMIN: '/order/request-auth-code/admin',
            GET_STATUSES: '/order/statuses',
            UPDATE: (orderId) => `/order/${orderId}/update`,
            ADD_LATER_DELIVERY: (orderId) => `/order/${orderId}/add-delivery-request`,
            GET_BY_ID: (orderId) => `/order/${orderId}`,
            MARK_LATER_DELIVERY_DONE: (orderId, laterDeliveryState) => `/order/${orderId}/markLaterDeliveryDone/${laterDeliveryState}`,
            GET_BY_ID_WITH_FULL_DETAILS: (orderId) => `/order/${orderId}/fullDetails`,
            CONFIRM_PAYMENT: (orderId, code) => `/order/confirm/${orderId}/${code}`,
            ALERT_DOCUMENT: (orderId, documentId) => `/order/${orderId}/documents/${documentId}/alert`,
            CHANGE_ACCESS: (orderId, operation, userId) => `/order/${orderId}/${operation}/${userId}`,
            CHANGE_STATUS: (orderId, newStatus) => `/order/${orderId}/changeStatus/${newStatus}`,
            CHANGE_TRANSACTION_STATUS: (orderId, newStatus) => `/order/${orderId}/transactionState/${newStatus}`,
            ADD_DOCUMENT: (orderId, type) => `/order/${orderId}/add-document?documentType=${type}`,
            REFUND: (orderId) => `/order/${orderId}/refund`,
            EDIT: (orderId) => `/order/${orderId}/edit`,
            ERROR: (orderId) => `/order/${orderId}/error`,
            ADD_REGISTRATION_NUMBER: (orderId, regNumber) => `/order/${orderId}/add-registration-number/${regNumber}`
        },
        COMMUNICATION_LOG: {
            ALL: (pageNo, pageSize, sortBy, sortDirection, filterBy, filterName) => getAllCommunicationLogs(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName),
        },
        FINGERPRINTS: {
            ALL: (pageNo, pageSize, sortBy, sortDirection) => getFingerprints(pageNo, pageSize, sortBy, sortDirection),
            PLACE: '/fingerprint/place',
        },
        E_FACTURA: {
            ALL: (pageNo, pageSize, sortBy, sortDirection, filterBy, filterName) => getEFacturaMessages(pageNo, pageSize, sortBy, sortDirection, filterBy, filterName),
            PROBLEMATIC_ORDERS: '/e-factura/problematic-orders',
            FORCE_SEND: '/e-factura/force-send',
            FORCE_REFRESH: '/e-factura/force-refresh',
            DOWNLOAD: (cif, id) => `/e-factura/download/${cif}/${id}`,
        },
        AUTH: {
            LOGIN: '/auth/signin',
            REGISTER: '/auth/signup',
            GET_USERS: '/auth/user/all',
            GET_LAWYERS: '/auth/user/lawyers',
            ROLE_CHANGE: '/auth/roleChange',
        }
    }
}

if (DEV_MODE) {
    API.HOST_AND_PORT = config.server.LOCAL
}
if (TEST_ENV) {
    API.HOST_AND_PORT = config.server.TEST
}

let toExport = {
    API, AUTHORIZATION,
    DEV_MODE: DEV_MODE
};
export default toExport;