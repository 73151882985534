import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmModal({open, handleConfirmStripe, handleConfirm, handleClose, title, content, hideConfirm}) {

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    hideConfirm || handleConfirmStripe === undefined ? null : <Button onClick={handleConfirmStripe} color={"success"}>
                        Generare Stripe
                    </Button>
                }
                {
                    hideConfirm ? null : <Button onClick={handleConfirm} color={"success"}>
                        {handleConfirmStripe === undefined ? "Generare" : "Generare DB"}
                    </Button>
                }
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
