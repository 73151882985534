import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import {changeStatusForOrder, downloadInvoice} from "../../../services/CommunicationService";
import {STATUSES} from "../../../services/LogicService";

export default function OrderInvoiceModal({row, open, handleClose, invoiceOnly}) {

    function downloadInvoice() {
        return response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Invoice-" + Date.now() + ".pdf";
                a.click();

                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                handleClose();
            });
        };
    }

    const handleDownloadInvoice = () => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.INVOICE_REPORT_BEING_CREATED);

        CommunicationService.downloadInvoice(row.id)
            .then(downloadInvoice())
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const handleDownloadInvoiceAndSendToClient = () => {
        LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.INVOICE_REPORT_BEING_CREATED);

        CommunicationService.downloadInvoiceAndSend(row.id)
            .then(downloadInvoice())
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Factura pentru Order #" + row.id}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" align="center">
                    Puteti sa descarcati factura doar pentru dumneavoastra sau sa o si trimiteti la client.
                </Typography>

                <Box sx={{display: "flex", justifyContent: "space-around", marginTop: "20px"}}>
                    <Button variant={"outlined"} color={"secondary"} onClick={handleDownloadInvoice}>
                        Descarca
                    </Button>

                    <Button variant={"outlined"} color={"secondary"} disabled={invoiceOnly === true} onClick={handleDownloadInvoiceAndSendToClient}>
                        Descarca si trimite clientului
                    </Button>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}
