import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import * as CommunicationService from '../../../services/CommunicationService'
import * as LogicService from '../../../services/LogicService'
import {DOCUMENTS_DETAILS} from '../../../services/LogicService'
// material
import {Box, Button, CardHeader, Grid, Stack, TextField, Typography} from '@mui/material';
import SignaturePad from "signature_pad";
import {isMobile} from "react-device-detect";
import * as AlertMapper from "../../../services/AlertMapper";
import {fDate, fDateISO} from "../../../utils/formatTime";
import * as Yup from "yup";
import {useFormik} from "formik";
// ----------------------------------------------------------------------
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import {addRegistrationNumber} from "../../../services/CommunicationService";

let border = '1px solid #ced4da';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: border,
        fontSize: 16,
        width: '50px',
        padding: '0',
        margin: '0px 5px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default function Anexa38Number({nrInreg, setNrInreg, b, translate}) {
    border = b;
    const ANEXA_38_NUMBER_AND_SIGNATURE_INPUT = LogicService.getFeatureSwitch('ANEXA_38_NUMBER_AND_SIGNATURE_INPUT')?.value;
    let order = LogicService.getOrder();
    let extrasToBeShown = ANEXA_38_NUMBER_AND_SIGNATURE_INPUT === true && order?.userType == "business";

    let subHeader = <Typography variant="body2" sx={{margin: "10px 25px 0px 25px", color: "darkslategray"}}>
        {translate ? 'This power of attorney gives us your consent to release the record'
            : 'Această împuternicire ne oferă acordul dvs. pentru a putea elibera cazierul'}
    </Typography>;
    let subHeaderWithAnexa38FSon = <Typography variant="body2" sx={{margin: "10px 25px 0px 25px", color: "darkslategray"}}>
        {translate ? 'This authorization gives us your consent to be able to release the record, and will be registered internally with the number'
            : 'Această împuternicire ne oferă acordul dvs. pentru a putea elibera cazierul, și va fi înregistrată intern cu numărul'}

        <BootstrapInput id="bootstrap-input"
                        type={"number"}
                        value={nrInreg}
                        onChange={(e) => setNrInreg(e.target.value)}
                        error

        />
        din data de <strong>{fDateISO(new Date())}</strong>
    </Typography>

    return (
        <Box>
            {extrasToBeShown == true ? subHeaderWithAnexa38FSon: subHeader}
        </Box>
    );
}
