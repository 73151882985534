import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Checkbox, FormControlLabel, FormGroup, Stack, TextField, ToggleButton, Typography} from "@mui/material";
import LanguageSelect from "../../authentication/order/LanguageSelect";
import {useEffect, useState} from "react";
import * as Languages from "../../../constants/Languages";
import CountrySelect from "../../authentication/order/CountrySelect";
import * as Countries from "../../../constants/Countries";
import * as CommunicationService from "../../../services/CommunicationService";
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import ConfirmOrRejectModal from "./ConfirmOrRejectModal";
import {
    editOrder,
    getOrderWithFullDetailsById,
    requestNewAuthCodeAsAdmin
} from "../../../services/CommunicationService";
import Chip from "@mui/material/Chip";
import {Icon} from "@iconify/react";
import invoiceIcon from "@iconify/icons-eva/copy-fill";

export default function OrderDetailsModal({order, isLawyer, open, handleClose}) {


    let [orderFetched, setOrderFetched] = React.useState(false);
    if (order.communicationEmail == undefined || order.communicationEmail == null) {
        order.communicationEmail = order.email;
    }
    let [row, setOrder] = React.useState(order);
    let [state, setState] = React.useState('Edit');
    let address = row.address || {};

    useEffect(() => {
        if (open && !orderFetched) {
            CommunicationService.getOrderWithFullDetailsById(order.id)
                .then((response) => {
                    setOrderFetched(true);
                    setOrder({
                        ...row,
                        authCode: response.authCode,
                        awb: response.awb,
                    });
                })
                .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
        }
    })

    let toggleEdit = () => {
        if (state === 'Edit') {
            setState('Save');
        } else if (state === 'Save') {
            setConfirmSave(true);
        }
    }

    let languageToChooseAsDefault = Languages.getLanguageByName(row.translationLanguage === undefined || row.translationLanguage === null ? 'ROMÂNĂ' : row.translationLanguage);
    const [translationCountry, changeTranslationCountry] = useState(languageToChooseAsDefault);

    let countryToChooseAsDefault = Countries.getCountryByName(row.address === undefined || row.address === null ? 'Romania' : row.address.country);
    const [countryField, setCountryField] = useState(countryToChooseAsDefault);

    const [apostilaSelected, setApostilaSelected] = React.useState(false);
    const [legalizedSelected, seLegalizedSelected] = React.useState(false);

    let getButton = (text, value, key, icon, val, setVal) => {

        return <Button
            variant="contained"
            color={val ? 'primary' : 'error'}
            value={val}
            onClick={() => {
                if (state == 'Edit') {
                    return;
                }
                // setVal(!val)
            }}
            sx={{color: "white", padding: "5px 10px"}}
            // sx={{color: (state == 'Edit' ? "gray" : (val ? "white" :  "black" )), opacity: 0.9, padding: "10px 15px", border: (state == 'Edit' ? "2px solid gray" : "none")}}
        >
            <Box component="img" src={icon} alt={text} title={text} sx={{width: 30}}/> &nbsp;&nbsp; {text}
        </Button>

    }

    const [confirmSave, setConfirmSave] = useState(false);

    let isLaterAddressAdded = false;

    if (row.address != undefined && row.address != null && row.address.laterAdded == true) {
        isLaterAddressAdded = true;
    }

    const [laterAddressMarked, setLaterAddressMarked] = useState(isLaterAddressAdded);

    const [confirmChangeCode, setConfirmChangeCode] = useState(false);
    const [changeCodeButtonDisabled, setChangeCodeButtonDisabled] = useState(false);
    const disableTemporarily = (timeout) => {
        setChangeCodeButtonDisabled(true);
        setTimeout(() => {
            setChangeCodeButtonDisabled(false);
        }, 5000);
    }

    let getURlStyled = (text, active) => {
        let boxClick = (e) => {
            navigator.permissions
                .query({name: "clipboard-write"})
                .then((result) => {
                    if (result.state === "granted" || result.state === "prompt") {
                        navigator.clipboard.writeText(text).then(
                            () => {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.COPIED_TO_CLIPBOARD);
                            },
                            () => {
                                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.CANNOT_COPY_TO_CLIPBOARD);
                            }
                        );
                    }
                });
        }
        let sx = {border: "2px solid green", padding: "6px", borderRadius: "5px"};
        if (active === false) {
            sx.border = "2px solid red";
            sx.color = "white";
            sx.background = "rgba(255, 99, 71, 1)";
        }
        return <Box onClick={boxClick} display={"flex"} justifyContent={"space-between"}>
            <Box sx={sx} display={"flex"}>
                <Typography variant="body1">
                    {text}
                </Typography>
                <Icon class="copyIcon" style={{marginTop: '0px', marginLeft: "10px"}} icon={invoiceIcon} width={24}
                      height={24}/>
            </Box>
        </Box>;
    }

    let deliveryAddressButton = getButton('Electronic & Livrare la adresă', 'address', 'address', "/static/illustrations/delivery-truck.png", true, () => {
    });

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <ConfirmOrRejectModal open={confirmSave}
                                  handleClose={() => {
                                      setState('Edit');
                                      setConfirmSave(false);
                                      setOrder(order);
                                  }}
                                  handleConfirm={() => {
                                      let body = {
                                          email: row.communicationEmail,
                                          phoneNumber: row.phoneNumber,
                                          firstName: row.firstName,
                                          lastName: row.lastName,
                                          address: {
                                              ...row.address,
                                              laterAdded: laterAddressMarked
                                          },
                                          reason: row.reason
                                      }
                                      if (countryField !== undefined && countryField !== null && body.address !== undefined && body.address !== null) {
                                          body.address.country = countryField.label;
                                      }
                                      if (translationCountry !== undefined && translationCountry !== null) {
                                          body.translationCountry = translationCountry.label;
                                      }
                                      CommunicationService.editOrder(row.id, body)
                                          .then((response) => {
                                              LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.ORDER_UPDATE_SUCCESS);
                                              setState('Edit');
                                              setConfirmSave(false);
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
                                  }}
                                  title={"Confirmați modificarea orderului?"}
                                  content={"Dupa apasarea butonului de confirm, orderul nu mai poate trece în starea inițială."}
            />

            <ConfirmOrRejectModal open={confirmChangeCode}
                                  handleClose={() => {
                                      setConfirmChangeCode(false);
                                  }}
                                  handleConfirm={() => {
                                      let authDetails = {
                                          username: row.email,
                                          orderId: row.id
                                      }
                                      setChangeCodeButtonDisabled(true);

                                      CommunicationService.requestNewAuthCodeAsAdmin(authDetails)
                                          .then((response) => {
                                              setOrder({
                                                  ...row,
                                                  authCode: response.authCode
                                              });
                                              setConfirmChangeCode(false);
                                              LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.AUTH_CODE_CHANGED)
                                          })
                                          .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));

                                  }}
                                  title={"Confirmati schimbarea codului de autentificare?"}
                                  content={"Dupa apasarea butonului de confirm, codul va fi schimbat, utilizatorul va fi notificat de aceasta schimbare si codul vechi nu va mai putea fi folosit."}
            />

            <DialogTitle id="alert-dialog-title">
                {"Details abour order #" + row.id}
            </DialogTitle>
            <DialogContent sx={{paddingTop: "10px !important"}}>
                <Stack direction={{xs: 'column', sm: 'column'}} spacing={2} sx={{marginBottom: "15px"}}>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "10px",
                        verticalAlign: "center"
                    }}>
                        {
                            isLawyer ? null : getButton(row.price + " Lei", 'price', 'price', "/static/illustrations/discount.png", true, () => {
                            })
                        }
                        {
                            row.userType == 'PF' ? getButton('Persoană fizică', 'person', 'person', "/static/illustrations/businessman.png", true, () => {
                                }) :
                                getButton('Persoană juridică', 'business', 'business', "/static/illustrations/online-shop.png", true, () => {
                                })
                        }

                        {
                            isLawyer ? null : <Box sx={{
                                border: "1px solid green",
                                borderRadius: "15px",
                                padding: "5px 5px",
                                display: 'flex',
                                verticalAlign: "center"
                            }}>
                                <Stack direction={{xs: 'column', sm: 'row'}} spacing={10}
                                       sx={{minWidth: "140px", minHeight: "30px", padding: "5px"}}>
                                    Auth code: &nbsp; <strong>{row.authCode}</strong> &nbsp;
                                </Stack>
                                <Button
                                    variant="contained"
                                    color={'primary'}
                                    disabled={changeCodeButtonDisabled}
                                    onClick={() => {
                                        setConfirmChangeCode(true);
                                    }}
                                    sx={{color: "white", padding: "5px 10px", fontWeight: "normal"}}
                                    // sx={{color: (state == 'Edit' ? "gray" : (val ? "white" :  "black" )), opacity: 0.9, padding: "10px 15px", border: (state == 'Edit' ? "2px solid gray" : "none")}}
                                >
                                    Change
                                </Button>
                            </Box>
                        }
                        {
                            row.awb == null || isLawyer ? null : <Button
                                variant="contained"
                                color={'secondary'}
                                onClick={() => {
                                    let url = "https://www.fancourier.ro/awb-tracking/?metoda=tracking&bar_size=x&limba=romana&awb=" + row.awb;
                                    window.open(url, '_blank', 'noreferrer')
                                }}
                                sx={{color: "white", padding: "5px 10px", fontWeight: "normal"}}
                                // sx={{color: (state == 'Edit' ? "gray" : (val ? "white" :  "black" )), opacity: 0.9, padding: "10px 15px", border: (state == 'Edit' ? "2px solid gray" : "none")}}
                            >
                                AWB: &nbsp; <strong>{row.awb}</strong> &nbsp;
                            </Button>
                        }

                    </Stack>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        {
                            row.regime == "URGENT" ? getButton('Urgent', 'urgent', 'urgent', "/static/illustrations/calendar_1_day.png", true, () => {
                                }) :
                                getButton('Standard', 'standard', 'standard', "/static/illustrations/calendar_5_day.png", true, () => {
                                })
                        }
                        {
                            getButton('Apostilă Haga', 'apostila', 'apostila', "/static/illustrations/stamp.png", apostilaSelected, setApostilaSelected)
                        }
                        {
                            getButton('Traducere', 'legalized', 'legalized', "/static/illustrations/legalized.png", legalizedSelected, seLegalizedSelected)
                        }

                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        {
                            isLawyer ? null : getURlStyled(row.paymentCode, row.transactionVerified != true)
                        }
                    </Stack>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        {
                            isLawyer ? null :
                                row.delivery == "ADDRESS" ? ((row.address != undefined && row.address != null && row.address.laterAdded) ?
                                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{
                                        marginBottom: "15px",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        gap: "10px"
                                    }}>
                                        {
                                            deliveryAddressButton
                                        }
                                        <FormGroup>
                                            <FormControlLabel id={"foreign-checkbox"} control={
                                                <Checkbox
                                                    disabled={state == 'Edit'}
                                                    value={laterAddressMarked}
                                                    onClick={() => setLaterAddressMarked(!laterAddressMarked)}
                                                />
                                            } label="Mark later address as DONE"/>
                                        </FormGroup>
                                    </Stack> : deliveryAddressButton) : getButton('Electronic', 'electronic', 'electronic', "/static/illustrations/email.png", true, () => {
                                })
                        }
                    </Stack>

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                        <TextField
                            disabled={true}
                            fullWidth
                            label={"Original Email"}
                            value={row.email}
                        />
                        <TextField
                            disabled={state == 'Edit'}
                            fullWidth
                            label={"Communication Email"}
                            value={row.communicationEmail}
                            onChange={(e) => {
                                setOrder({
                                    ...row,
                                    communicationEmail: e.target.value
                                });
                            }}
                        />
                    </Stack>
                    <TextField
                        disabled={state == 'Edit'}
                        fullWidth
                        label={"Phone Number"}
                        value={row.phoneNumber}
                        onChange={(e) => {
                            setOrder({
                                ...row,
                                phoneNumber: e.target.value
                            });
                        }}
                    />

                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                        <TextField
                            disabled={state == 'Edit'}
                            fullWidth
                            label={"First name"}
                            value={row.firstName}
                            onChange={(e) => {
                                setOrder({
                                    ...row,
                                    firstName: e.target.value
                                });
                            }}
                        />
                        <TextField
                            disabled={state == 'Edit'}
                            fullWidth
                            label={"Last name"}
                            value={row.lastName}
                            onChange={(e) => {
                                setOrder({
                                    ...row,
                                    lastName: e.target.value
                                });
                            }}
                        />
                    </Stack>

                    {
                        (row.user != undefined && row.user != null &&
                            (row.user.fatherName != undefined || row.user.fatherName != null
                                || row.user.motherName != undefined || row.user.motherName != null)) ?
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label={"Father name"}
                                    value={row.user.fatherName}
                                    // onChange={(e) => {
                                    //     setOrder({
                                    //         ...row,
                                    //         firstName: e.target.value
                                    //     });
                                    // }}
                                />
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label={"Mother name"}
                                    value={row.user.motherName}
                                    // onChange={(e) => {
                                    //     setOrder({
                                    //         ...row,
                                    //         lastName: e.target.value
                                    //     });
                                    // }}
                                />
                            </Stack> : null
                    }

                    {
                        (row.user != undefined && row.user != null &&
                            (row.user.foreignBirthplace != undefined || row.user.foreignBirthplace != null)) ?
                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label={"Locul nasterii a persoanei straine"}
                                    value={row.user.foreignBirthplace}
                                    // onChange={(e) => {
                                    //     setOrder({
                                    //         ...row,
                                    //         firstName: e.target.value
                                    //     });
                                    // }}
                                />
                            </Stack> : null
                    }
                    {
                        row.address === undefined || row.address === null ? null : <>
                            <CountrySelect defaultValue={countryField}
                                           disabled={state == 'Edit'}
                                           label={"Country"}
                                           selectOption={(option) => {
                                               setCountryField(option);
                                           }}/>

                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                <TextField
                                    disabled={state == 'Edit'}
                                    fullWidth
                                    label={"District"}
                                    value={row.address.district}
                                    onChange={(e) => {
                                        setOrder({
                                            ...row,
                                            address: {
                                                ...row.address,
                                                district: e.target.value
                                            }
                                        });
                                    }}
                                />


                                <TextField
                                    disabled={state == 'Edit'}
                                    fullWidth
                                    label={"City"}
                                    value={row.address.city}
                                    onChange={(e) => {
                                        setOrder({
                                            ...row,
                                            address: {
                                                ...row.address,
                                                city: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                {
                                    address.street == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Strada"}
                                        value={row.address.street}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    street: e.target.value
                                                }
                                            });
                                        }}
                                    />

                                }
                                {
                                    address.details == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Bloc"}
                                        value={row.address.details}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    details: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                }
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                {
                                    address.numar == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Numar"}
                                        value={row.address.numar}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    numar: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                }
                                {
                                    address.scara == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Scara"}
                                        value={row.address.scara}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    scara: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                }
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                {
                                    address.apartament == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Apartament"}
                                        value={row.address.apartament}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    apartament: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                }
                                {
                                    address.etaj == null ? null : <TextField
                                        disabled={state == 'Edit'}
                                        fullWidth
                                        label={"Etaj"}
                                        value={row.address.etaj}
                                        onChange={(e) => {
                                            setOrder({
                                                ...row,
                                                address: {
                                                    ...row.address,
                                                    etaj: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                }
                            </Stack>

                            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                                <TextField
                                    disabled={state == 'Edit'}
                                    fullWidth
                                    label={"Postal code"}
                                    value={row.address.postalCode}
                                    onChange={(e) => {
                                        setOrder({
                                            ...row,
                                            address: {
                                                ...row.address,
                                                postalCode: e.target.value
                                            }
                                        });
                                    }}
                                />
                                <TextField
                                    disabled={state == 'Edit'}
                                    fullWidth
                                    label={"Telefon adresa"}
                                    value={row.address.phoneNumber}
                                    onChange={(e) => {
                                        setOrder({
                                            ...row,
                                            address: {
                                                ...row.address,
                                                phoneNumber: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </Stack>
                        </>
                    }
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{marginBottom: "15px"}}>
                        {
                            row.translationLanguage === undefined || row.translationLanguage === null ? null :
                                <LanguageSelect selectOption={changeTranslationCountry}
                                                defaultValue={translationCountry}
                                                disabled={state == 'Edit'}
                                                label={"Limba traducerii"}
                                                disablePrefix
                                />
                        }
                        {
                            row.userType == 'PF' ? null : <TextField
                                disabled={state == 'Edit'}
                                fullWidth
                                label={"Cui"}
                                value={row.cui}
                                onChange={(e) => {
                                    setOrder({
                                        ...row,
                                        cui: e.target.value
                                    });
                                }}
                            />
                        }

                    </Stack>
                    {
                        <TextField
                            disabled={state == 'Edit'}
                            fullWidth
                            label={"Reason"}
                            value={row.reason}
                            onChange={(e) => {
                                setOrder({
                                    ...row,
                                    reason: e.target.value
                                });
                            }}
                        />
                    }

                </Stack>

                {
                    isLawyer || row.createdAt === undefined || row.createdAt === null ? null :
                        <DialogContentText>
                            <b>Creată la data de:</b> {row.createdAt}
                        </DialogContentText>
                }
                {
                    isLawyer || row.browserCountry === undefined || row.browserCountry === null ? null :
                        <DialogContentText>
                            <b>Țară Browser:</b> {row.browserCountry}
                        </DialogContentText>
                }
                {
                    isLawyer || row.browserIP === undefined || row.browserIP === null ? null : <DialogContentText>
                        <b>IP Browser:</b> {row.browserIP}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                {
                    isLawyer ? null : <Button onClick={toggleEdit} autoFocus>
                        {state}
                    </Button>
                }
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
}
