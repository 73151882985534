import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {useState} from "react";

export default function AddCategoryModal({open, handleConfirm, handleClose, subject}) {

    let [text, setText] = useState(null);
    let subjectText = (subject === undefined ? "categorie" : subject)

    return (
        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgb(255, 255, 255)', opacity: 0.3}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Adăugare " + subjectText}
            </DialogTitle>
            <DialogContent>
                <TextField label={"Nume " + subjectText} variant="standard" value={text}
                           onChange={(event) => setText(event.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleConfirm(text);
                    setText(null);
                }} color={"success"}>
                    Adăugare
                </Button>
                <Button onClick={handleClose} color={"error"} autoFocus>
                    Închide
                </Button>
            </DialogActions>
        </Dialog>

    );
}
