import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as LogicService from "../../../services/LogicService";
import * as AlertMapper from "../../../services/AlertMapper";
import * as CommunicationService from "../../../services/CommunicationService";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 171, 85, .3)',
    borderRadius: '10px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "20px 0px 0px 10px"
}));

export default function BankTransferAccordion({expanded, setExpanded, translate}) {

    let order = LogicService.getOrder();
    let companyInUse = LogicService.getCompanyInUse();

    return (
        <Accordion expanded={expanded} sx={{minWidth: "100%"}}>
            <AccordionDetails>
                <Timeline>
                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                {translate? 'Sum:' : 'Suma:'} <strong>{order.price} RON</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                IBAN: <strong>{companyInUse.iban}</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                {translate? 'Beneficiary name:' : 'Nume beneficiar:'} <strong>{companyInUse.name}</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                CUI: <strong>{companyInUse.ro}</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                {translate? 'Bank:' : 'Bancă:'} <strong>{companyInUse.bank}</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem className={"docs-app-timeline-item"}>
                        <TimelineSeparator>
                            <TimelineDot sx={{bgcolor: 'primary.main'}}/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant={"body1"}>
                                {translate? 'Reference number - enter this number in the transfer details:' : 'Număr de referință - introdu acest număr în detaliile transferului:'}
                                <strong>order {order.id}</strong>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </AccordionDetails>
        </Accordion>
    );
}
