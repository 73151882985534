import PropTypes from 'prop-types';
// material
import {styled} from '@mui/material/styles';
import {Box, Button, Card, Grid, Toolbar} from '@mui/material';

import MainPageDashboardNavbar from "./MainPageDashboardNavbar";
import * as React from "react";
import FooterInfoTwo from "../../components/authentication/order/FooterInfoTwo";
import FooterInfoThree from "../../components/authentication/order/FooterInfoThree";
import FooterInfoOne from "../../components/authentication/order/FooterInfoOne";
import {Icon} from "@iconify/react";
import terms from "@iconify/icons-eva/file-text-outline";
import policy from "@iconify/icons-eva/book-open-outline";
import {useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = "100%";
const APPBAR_DESKTOP = "100%";

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
    minHeight: APPBAR_MOBILE,
    height: APPBAR_MOBILE * 7,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 1)
    }
}));

const FooterList = styled(Grid)(({theme}) => ({
    height: "auto",
    display: "block",
    justifyContent: "space-between",
    [theme.breakpoints.up('lg')]: {
        height: "auto",
        display: "flex",
        justifyContent: "space-between"
    }
}));

const FooterCard = styled(Card)(({theme}) => ({
    padding: "10px 30px 10px 30px",
    marginBottom: '10px',
    borderRadius: "0",
    height: "auto",
    [theme.breakpoints.up('lg')]: {
        padding: "10px 30px 30px 30px",
        marginBottom: '10px',
        borderRadius: "0",
        height: "auto"
    }
}));


MainPageDashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function MainPageDashboardFooter() {
    const navigate = useNavigate();
    return (
        <FooterCard>
            <ToolbarStyle>
                <FooterList container spacing={5}>

                    <Grid item xs={12} sm={6} md={4}>
                        {/*<FooterInfoOne/>*/}
                        <Box sx={{padding: "0px 20%"}}>
                            <Box sx={{display: 'flex', marginBottom: "10px"}}>
                                <Button fullWidth size="small" color="inherit" variant="outlined"
                                        startIcon={<Icon icon={terms} color="#1C9CEA" height={24}/>}
                                        onClick={() => navigate('/terms-and-conditions')}
                                >
                                    TERMENI SI
                                    CONDITII &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Button>
                            </Box>
                            <Box sx={{display: 'flex'}}>
                                <Button fullWidth size="small" color="inherit" variant="outlined"
                                        startIcon={<Icon icon={policy} color="#1C9CEA" height={24}/>}
                                        onClick={() => navigate('/privacy-policy')}
                                >
                                    POLITICA DE CONFIDENTIALITATE
                                </Button>
                            </Box>
                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <FooterInfoThree/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{display: "flex", justifyContent: "center"}}>
                        <FooterInfoTwo/>
                    </Grid>
                </FooterList>
            </ToolbarStyle>
        </FooterCard>
    );
}
