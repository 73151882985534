import {filter} from 'lodash';
import {useEffect, useState} from 'react';
// material
import {
    Avatar, Button,
    Card,
    Container, IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserMoreMenu} from '../components/_dashboard/user';
//
import * as CommunicationService from "../services/CommunicationService";
import * as LogicService from "../services/LogicService";
import * as AlertMapper from "../services/AlertMapper";
import {mockImgAvatar} from "../utils/mockImages";
import RoleSelect from "./RoleSelect";
import {
    createBlacklistItem,
    deleteBlacklistItem, deleteMessageQueueItem,
    getAllBlacklist, getAllMessagesFromQueue,
    updateDefault
} from "../services/CommunicationService";
import AddPaymentLinkModal from "../components/_dashboard/user/AddPaymentLinkModal";
import * as React from "react";
import AddBlacklistItemModal from "../components/_dashboard/user/AddBlacklistItemModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../components/_dashboard/user/ConfirmModal";
import ConfirmOrRejectModal from "../components/_dashboard/user/ConfirmOrRejectModal";
import AddCompanyModal from "../components/_dashboard/user/AddCompanyModal";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Download from "@mui/icons-material/Download";
import CircularStatic from "./CircularStatic";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'id', label: 'ID', alignRight: false},
    {id: 'orderId', label: 'Order id', alignRight: false},
    {id: 'phoneNumber', label: 'Phone Number', alignRight: false},
    {id: 'email', label: 'Email', alignRight: false},
    {id: 'message_cases', label: 'Message Type', alignRight: false},
    {id: 'document', label: 'Document', alignRight: false},
    {id: 'createdAt', label: 'Created at', alignRight: false},
    {id: 'remainingTime', label: 'Remaining', alignRight: false}
];
// id, phoneNumber, email, messageCases, document, createdDateTime

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Blacklist() {
    const [usersRetrieved, setUsersRetrieved] = useState(false);

    const [USERLIST, SET_USERLIST] = useState([]);
    const [machineLocalTime, setMachineLocalTime] = useState(new Date());
    const [messageCases, setMessageCases] = useState([]);

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteItemOpen, setDeleteItemOpen] = useState(false);
    const [infoPopupOpen, setInfoPopupOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);
    const [selectedMessageType, setSelectedMessageType] = useState({});

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const mapUser = (user) => {
        return user;
    }

    const getUsers = () => {
        CommunicationService.getAllMessagesFromQueue()
            .then((response) => {
                SET_USERLIST(response.futureMessageList.map(u => mapUser(u)))
                setMachineLocalTime(response.currentMachineHour)
                setMessageCases(response.messageDetails)
                setUsersRetrieved(true);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    useEffect(() => {
        getUsers();
    }, []);

    const deleteBlacklistItem = (id) => {
        CommunicationService.deleteMessageQueueItem(id)
            .then((response) => {
                location.reload();
                LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_QUEUE_ITEM_DELETED);
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }

    const [downloadEnabled, setDownloadEnabled] = useState(true);

    function downloadDocument(doc) {
        setDownloadEnabled(false);
        setTimeout(() => {
            setDownloadEnabled(true);
        }, 10000)

        CommunicationService.downloadFile(doc.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = doc.internalName;
                    a.click();
                    LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.DOCUMENT_DOWNLOAD);
                });
            })
            .catch((error) => LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error)));
    }
    function showInfoPopupFor(key) {
        let selectedKey = messageCases[key];
        let elementsToBeShownAsContent = <Stack direction={{xs: 'column', sm: 'row'}}
                                                display={"block"} spacing={0}>
            <Typography variant="body2" sx={{marginTop: "5px"}}>
                <b>SMS Text</b>: {selectedKey.sms}
            </Typography>
            <Typography variant="body2" sx={{marginTop: "5px"}}>
                <b>Email Subject</b>: {selectedKey.emailSubject}
            </Typography>
            <Typography variant="body2" sx={{marginTop: "5px"}}>
                <b>Email Body</b>: {selectedKey.emailBody}
            </Typography>
        </Stack>;
        setSelectedMessageType(elementsToBeShownAsContent);
        setInfoPopupOpen(true);
    }

    return (
        <Page title="Cazier Judiciar Online – Eliberare Cazier Fara Deplasari">
            <Container maxWidth={"1600px"}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Message Queue
                    </Typography>
                </Stack>

                <Card>

                    <ConfirmOrRejectModal open={deleteItemOpen}
                                          handleClose={() => {
                                              setDeleteItemOpen(false)
                                          }}
                                          handleConfirm={() => {
                                              deleteBlacklistItem(selectedItem);
                                              setDeleteItemOpen(false);
                                          }}
                                          title={"Confirmați stergerea mesajului din queue?"}
                                          content={"Dupa apasarea butonului de confirm, itemul va fi sters definitiv."}
                    />

                    <ConfirmOrRejectModal open={infoPopupOpen}
                                          handleClose={() => {
                                              setInfoPopupOpen(false)
                                          }}
                                          handleConfirm={() => {
                                              setInfoPopupOpen(false);
                                          }}
                                          title={"Mesajul ce urmează să fie trimis conține următoarele texte"}
                                          content={selectedMessageType}
                    />


                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {
                                                id,
                                                orderId,
                                                phoneNumber,
                                                email,
                                                messageCases,
                                                document,
                                                createdDateTime
                                            } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell align="left">{id}</TableCell>
                                                    <TableCell align="left">{orderId}</TableCell>
                                                    <TableCell align="left">{phoneNumber}</TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">
                                                        <Button color={"secondary"} variant="outlined" onClick={() => showInfoPopupFor(messageCases)}>{messageCases}</Button>
                                                    </TableCell>
                                                    <TableCell>

                                                        <Stack spacing={0} direction={{xs: 'row', sm: 'row'}} sx={{
                                                            border: "1px solid blue",
                                                            borderRadius: "10px",
                                                            padding: "10px"
                                                        }}  display={"flex"} justifyContent={"space-between"}>
                                                            <Stack direction={{xs: 'column', sm: 'row'}}
                                                                   display={"block"} spacing={0}>
                                                                <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                                    <b>ID</b>: {document.id}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                                    <b>Type</b>: {document.type}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                                    <b>Name</b>: {document.name}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                                    <b>Internal Name</b>: {document.internalName}
                                                                </Typography>
                                                                <Typography variant="body2" sx={{marginTop: "5px"}}>
                                                                    <b>Uploaded</b>: {new Date(document.uploadedDateTime).toLocaleString()}
                                                                </Typography>
                                                            </Stack>
                                                            <Stack direction={{xs: 'column', sm: 'row'}}
                                                                   display={"block"} spacing={5}>

                                                                <IconButton variant="contained" color="success" sx={{border: "1px solid green"}}
                                                                        onClick={() => downloadDocument(document)}>
                                                                    <Download />
                                                                </IconButton>

                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{new Date(createdDateTime).toLocaleString()}</TableCell>
                                                    <TableCell align="left">
                                                        <CircularStatic toDate={createdDateTime} machineLocalTime={machineLocalTime}/>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton color="error" component="label" onClick={() => {
                                                            setSelectedItem(id);
                                                            setDeleteItemOpen(true);
                                                        }}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                            <SearchNotFound searchQuery={filterName}/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={USERLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
