import PropTypes from 'prop-types';
// material
import {alpha, styled} from '@mui/material/styles';
import {Alert, AlertTitle, AppBar, Box, Button, IconButton, Stack, Toolbar, Typography} from '@mui/material';
// components
//
import MainPageAccountPopover from './MainPageAccountPopover';
import {useLocation, useNavigate} from "react-router-dom";
import * as CommunicationService from "../../services/CommunicationService";
import PositionedMenu from "./PositionedMenu";
import {MENUS} from "../../Constants";
import * as React from "react";
import {isMobile} from "react-device-detect";
import {bindHover} from "material-ui-popup-state/hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

const ButtonsStyle = styled(Box)(({theme}) => ({
    flexWrap: "wrap",
    margin: "0%",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.up('lg')]: {
        width: "20%",
        margin: "0% 0% 0% 20%",
        display: "flex",
        justifyContent: "space-evenly",
    }
}));

const VerificaStatusulComenzii = styled(Button)(({theme}) => ({
    width: "120px",
    height: "80px",
    margin: "10px",
    [theme.breakpoints.up('lg')]: {
        width: "auto",
        height: "auto",
        margin: "10px",
    }
}));

// ----------------------------------------------------------------------

MainPageDashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function MainPageDashboardNavbar({onOpenSidebar}) {
    const navigate = useNavigate();
    const location = useLocation();

    const isEnPage = location.pathname.includes('/en');

    const LogInButton = () => {
        if (location.pathname.includes("/detalii-comanda")) {
            return null;
        }
        return <VerificaStatusulComenzii
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => navigate("/verificare-comanda")}
        >
            Verifică statusul comenzii
        </VerificaStatusulComenzii>
    }

    let Auth = CommunicationService.getUser();
    let userType = CommunicationService.getUserType();
    let showAccount = Auth !== null && (userType === 'ROLE_ADMIN' || userType === 'ROLE_LAWYER')

    const handleRedirect = (path) => {
        // console.log(path)
        navigate(path)
    }

    let getLangButton = (m) => {
        if (isEnPage) {
            if (m.showOn === '/en') {
                return <IconButton onClick={() => handleRedirect(m.path)}>
                    <Box component="img" src={m.icon} sx={{width: 34}}/>
                </IconButton>;
            }
            return null;
        } else {
            if (m.showOn === '/en') {
                return null;
            }
            return <IconButton onClick={() => handleRedirect(m.path)}>
                <Box component="img" src={m.icon} sx={{width: 34}}/>
            </IconButton>;
        }
    }

    return (
        <RootStyle>

            <div className="topbar">
                Cazier judiciar online | Solicitați cazierul rapid și eficient |
                <a href="mailto:contact@caziere.ro"
                   style={{color: '#fff', textDecoration: 'none'}}>
                    <i className="fas fa-envelope" style={{marginLeft: '5px'}}></i> contact@caziere.ro</a>
            </div>

            <ToolbarStyle>

                <Box sx={{px: 2.5, pb: 3, mt: 10, margin: 0, padding: '10px', display: 'flex', alignItems: 'center'}}
                     onClick={() => navigate('/')}
                >
                    <Box
                        component="img"
                        src="/static/illustrations/law.png"
                        sx={{width: 80}}
                    />
                    <div>
                        {
                            window.screen.width >= 768 ?
                                <Typography variant="title" sx={{color: "black", width: "40px", paddingLeft: '10px'}}>
                                    contact@caziere.ro
                                </Typography> :
                                <Box sx={{display: 'grid'}}>
                                    <Typography variant="title" sx={{color: "black", width: "40px", paddingLeft: '10px'}}>
                                        contact@
                                    </Typography>
                                    <Typography variant="title" sx={{color: "black", width: "40px", paddingLeft: '10px'}}>
                                        caziere.ro
                                    </Typography>
                                </Box>
                        }
                    </div>

                </Box>

                <Box sx={{flexGrow: 1}}>
                </Box>

                {
                    isMobile ? null : <ButtonsStyle>
                        {
                            MENUS
                                .map((m, i) => <PositionedMenu key={"p1-" + i} isEnPage={isEnPage} menu={m}/>)
                        }
                    </ButtonsStyle>
                }
                {
                    getLangButton(MENUS[2])
                }
                {
                    getLangButton(MENUS[3])
                }

                <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
                    {showAccount ? <MainPageAccountPopover/> : <LogInButton/>}
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
