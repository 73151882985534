// material
import {
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox, FormControlLabel,
    Stack,
    Step,
    StepIcon,
    StepLabel,
    Stepper, ToggleButton,
    Typography
} from '@mui/material';
import {ToggleButtonGroup} from '@mui/lab';
//
import OrderForm from "../../authentication/order/OrderForm";
import * as LogicService from "../../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../../services/LogicService";
import OrderOffer from "../../authentication/order/OrderOffer";
import OrderComplete from "../../authentication/order/OrderComplete";
import * as React from "react";
import OrderSignContract from "../../authentication/order/OrderSignContract";
import OrderFiscalInfo from "../../authentication/order/OrderFiscalInfo";
import {useNavigate} from "react-router-dom";
import Anexa38Number from "../../authentication/order/Anexa38Number";
import {useEffect, useState} from "react";
import BankTransferAccordion from "./BankTransferAccordion";
import DragAndDropFileUploader from "./DragAndDropFileUploader";
import * as CommunicationService from "../../../services/CommunicationService";
import * as AlertMapper from "../../../services/AlertMapper";
import OrderFiscalInfoV2 from "../../authentication/order/OrderFiscalInfoV2";
import ConfirmOrRejectModal from "../user/ConfirmOrRejectModal";
import ReportProblemModal from "../user/ReportProblemModal";
import {getCompanyInUse} from "../../../services/StorageService";


export default function OrderRegisterCard({translate}) {
    const navigate = useNavigate();
    let order = LogicService.getOrder();
    let paymentMethod = LogicService.getPaymentMethod();

    const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
    const [reportProblemModalEnabled, setReportProblemModalEnabled] = useState(false);

    const redirectToPayment = () => {
        LogicService.resetSession();
        window.location.assign(order.paymentCode);
    }

    const steps = translate ? ['Personal data', 'Options', 'Required documents', 'Payment'] : ['Date personale', 'Opțiuni', 'Documente necesare', 'Plată'];

    let reportProblem = <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{paddingLeft: 3, pb: 3}}>
        <ReportProblemModal open={reportProblemModalEnabled}
                              handleClose={() => {
                                  setReportProblemModalEnabled(false);
                              }}
                              handleConfirm={() => {
                                  setReportProblemModalEnabled(false);
                                  LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);
                              }}
                              title={translate ? "What problem did you encounter?"
                                  : "Ce problemă ați întâmpinat?"}
                              content={translate ? "Please provide us with as much detail as possible so that we can identify and resolve the issue as soon as possible"
                                  : "Vă rugăm să ne oferiți cât mai multe detalii pentru a putea identifica și rezolva problema în cel mai scurt timp"}
        />
        <Typography variant={"body1"}>
            {translate ? "Experiencing technical difficulties?" : "Întâmpini dificultăți tehnice?"}
        </Typography>
        <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}} onClick={() => setReportProblemModalEnabled(true)}>
            {translate ? "click here" : "apasă aici"}
        </Button>
    </Stack>

    let getStepper = (activeStep) =>
        <Stepper activeStep={activeStep}
                 sx={{margin: "15px 15px 0 15px", display: "flex", flexWrap: "wrap", gap: "10px"}}
        >
            {steps.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>

    let [firstStepActive, setFirstStepActive] = React.useState(0);

    let formular = <Card>
        {getStepper(firstStepActive)}
        <CardHeader title={translate ? "Get your criminal record online" : "Obține cazierul online"} subheader={translate ? "Without going to the counter" : "Fără să te deplasezi la ghișeu"}/>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <OrderForm changeStepper={() => setFirstStepActive(1)} setFirstStepActive={setFirstStepActive} translate={translate}/>
        </Box>
        {
            firstStepActive === 1 ? reportProblem : null
        }
    </Card>

    let pret = <Card>
        {getStepper(1)}
        <CardHeader title={translate ? "The price for the selected services is" : "Prețul pentru serviciile selectate este"}/>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <OrderOffer translate={translate}/>
        </Box>
        {reportProblem}
    </Card>

    let incarcareDocumente = <Card>
        {getStepper(2)}
        <CardHeader title={translate ? "The last steps" : "Ultimii pași"}
                    subheader={translate ? "Upload the required documents" : "Încarcă documentele necesare"}/>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <OrderComplete translate={translate}/>
        </Box>
        {reportProblem}
    </Card>

    const [nrInreg, setNrInreg] = useState("");
    const [anexNumberBorderColor, setAnexNumberBorderColor] = useState("1px solid #ced4da");

    let semnatura = <Card>
        {getStepper(2)}
        <CardHeader title={translate ? "We need your signature" : "Avem nevoie de semnătura ta"}/>
        <Anexa38Number nrInreg={nrInreg} setNrInreg={setNrInreg} b={anexNumberBorderColor} translate={translate}></Anexa38Number>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <OrderSignContract nrInreg={nrInreg} setAnexNumberBorderColor={setAnexNumberBorderColor} translate={translate}/>
        </Box>
        {reportProblem}
    </Card>

    const back = () => {
        LogicService.resetSession();
        navigate(translate ? '/en' : '/');
    }

    const backToPaymentMethod = () => {
        LogicService.updateOrderState('PAYMENT_METHOD');
        navigate(translate ? '/en' : '/');
    }

    let dateDeFacturare =
        <Card>
            {getStepper(2)}
            <CardHeader title={translate ? "Billing data" : "Date de facturare"}/>
            <Box sx={{p: 3, pb: 3}} dir="ltr">
                {
                    <OrderFiscalInfoV2 translate={translate}/>
                }
            </Box>
            {reportProblem}
        </Card>

    const [alignment, setAlignment] = React.useState('creditCard');
    const handlePersonTypeChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const personTypeControls = {
        value: alignment,
        onChange: handlePersonTypeChange,
        exclusive: true,
    };

    let getIconForAlignment = (align) => {
        if (alignment == align) {
            return "checked_checkbox.png";
        }
        return "unchecked_checkbox.png"
    }

    let modalitateDePlata =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">
                        {translate ? "Total payment" : "Total de plată"}&nbsp;
                             {order !== undefined && order !== null ? order.price : ""} RON</Typography>
                    <Typography variant="body1">{translate ? "Choose the desired payment method" : "Alege modalitatea de plată dorită"}</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "2% 5%"}}
                       spacing={2} alignSelf={"center"}>

                    <ToggleButtonGroup size="large" {...personTypeControls} fullWidth color="error" variant="outlined"
                                       id={"paymentMethods"}
                    >

                        <ToggleButton value={'creditCard'} key={'creditCard'} alignSelf={"left"} id="paymentCard"
                                      sx={{display: 'flex', justifyContent: "left", verticalAlign: "top"}}
                        >
                            <Box component="img" src={"/static/illustrations/" + getIconForAlignment('creditCard')}
                                 width={{xs: 20, sm: 34}}/>
                            <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                                   sx={{margin: "2% 5%", height: "100%"}}
                                   spacing={2} alignContent={"left"}>
                                <Typography alignSelf={"left"} variant={{xs: 'body1', sm: 'body1'}}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '7%',
                                                textTransform: 'none',
                                                textAlign: 'left',
                                                fontSize: "18px"
                                            }}
                                >{translate ? "Credit or debit card" : "Card de credit sau de debit"}</Typography>
                                <Typography alignSelf={"left"}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '0px',
                                                textTransform: 'none',
                                                textAlign: 'left'
                                            }}
                                >{translate ? "Pay online by card" : "Plătește online, cu cardul"}</Typography>
                                <Typography alignSelf={"left"}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '0px !important',
                                                textTransform: 'none',
                                                textAlign: 'left'
                                            }}
                                >{translate ? "Payment is secured" : "Plata este securizată"}</Typography>

                                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                                       sx={{margin: "2% 5%"}}
                                       spacing={2} alignSelf={"left"}>
                                    <Box component="img" src={"/static/illustrations/visa-mastercard-logo.png"}
                                         width={{xs: 120, sm: 120}}/>
                                </Stack>

                            </Stack>
                        </ToggleButton>

                        <ToggleButton value={'wireTransfer'} key={'wireTransfer'} alignSelf={"left"} id="wireTransfer"
                                      sx={{display: 'flex', justifyContent: "left", verticalAlign: "top"}}
                        >
                            <Box component="img" src={"/static/illustrations/" + getIconForAlignment('wireTransfer')}
                                 width={{xs: 20, sm: 34}}/>
                            <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                                   sx={{margin: "2% 5%", height: "100%"}}
                                   spacing={2} alignContent={"left"}>
                                <Typography alignSelf={"left"} variant={{xs: 'body1', sm: 'body1'}}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '7%',
                                                textTransform: 'none',
                                                textAlign: 'left',
                                                fontSize: "18px"
                                            }}
                                >{translate ? "Bank transfer" : "Transfer bancar"}</Typography>
                                <Typography alignSelf={"left"}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '0px',
                                                textTransform: 'none',
                                                textAlign: 'left'
                                            }}
                                >{translate ? "You pay by bank transfer from your personal account to the Caziere.ro account" : "Plătești prin transfer bancar din contul tău personal în contul Caziere.ro"}</Typography>

                            </Stack>
                        </ToggleButton>

                    </ToggleButtonGroup>


                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={back}
                    >
                        {translate ? "Home" : "Acasă"}
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'secondary'}
                        disabled={alignment === undefined || alignment === null || alignment === ''}
                        onClick={() => {
                            if (alignment === 'creditCard') {
                                return redirectToPayment();
                            }
                            setPaymentMethodSelected(true);
                            LogicService.updateOrderState('BANKING_TRANSFER');
                        }}
                    >
                        {translate ? "To payment" : "Către plată"}
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    let [files, setFiles] = React.useState([]);
    let [filesUploaded, setFilesUploaded] = React.useState(paymentMethod != undefined && paymentMethod != null);

    let uploadPaymentProof = () => {
        let docKey = 'payment_proof';
        let file = files[0];
        CommunicationService.addDocumentToOrder(order.id, docKey, file)
            .then((response) => {
                LogicService.setPaymentMethod(alignment);
                setFilesUploaded(true);
                LogicService.updateOrderState('THANKS');
                navigate('/manualp');
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
            });
    }

    let detaliiTransferBancar =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                {/*<Box sx={{p: 3, pb: 3, textAlign: 'left', margin: "0px 10%"}}>*/}
                {/*    <Typography variant="h5">Vei primi un SMS cu un cod de acces pentru a verifica statusul comenzii de*/}
                {/*        îndată ce efectuezi plata.</Typography>*/}
                {/*</Box>*/}
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">{translate ? "Bank transfer" : "Transfer bancar"}</Typography>
                    <Typography variant="body1">
                        {translate ? "You have selected payment by bank transfer. You can find the details for making the payment below"
                            :  "Ai selectat plata prin transfer bancar. Regăsești mai jos detaliile pentru efectuarea plății"}
                    </Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "10px"}}
                       sx={{margin: "0% 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <BankTransferAccordion expanded={true}
                                           setExpanded={() => {}}
                                           translate={translate}
                    />
                </Stack>


                <Stack direction={{xs: 'column', sm: 'column', marginTop: "0px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="body1">
                        {translate ? "Upload proof of payment in the box below (eg a screenshot of the payment)." : "Încarcă dovada efectuării plății în chenarul de mai jos (ex. o captură de ecran cu plata)."}
                    </Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "5px"}}
                       sx={{margin: "0% 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <DragAndDropFileUploader setFiles={setFiles} translate={translate}/>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'column'}}
                       sx={{margin: "2% 5%", marginTop: "10px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="body1">
                        {translate ? "or send proof of payment to the address" : "sau trimiteți dovada plății pe adresa"} &nbsp;
                        <a href={"mailto:contact@caziere.ro?subject=Dovada platii pentru orderul " + (order?.id)}
                           target={"_blank"}>
                            contact@caziere.ro
                        </a>
                    </Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={backToPaymentMethod}
                    >
                        {translate ? "Back" : "Înapoi"}
                    </Button>

                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'secondary'}
                        disabled={files.length === 0}
                        onClick={uploadPaymentProof}
                    >
                        {translate ? "Upload proof of payment" : "Încarcă dovada plății"}
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    let multumim =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">{translate ? "Thank you!" : "Mulțumim!"}</Typography>
                    <Typography variant="body1">{translate ? "You will receive an SMS with an access code to check the status of your order" : "Vei primi un SMS cu un cod de acces pentru a verifica statusul comenzii tale"}</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={back}
                    >
                        {translate ? "Home" : "Acasă"}
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    if (order !== undefined && order !== null && order.nextState === 'FORM_INIT') {
        return formular;
    } else if (order === undefined || order === null) {
        return formular;
    } else if (order.nextState === 'PRICE') {
        return pret;
    } else if (order.nextState === "DOCUMENTS") {
        return incarcareDocumente;
    } else if (order.nextState === "SIGNATURE") {
        return semnatura;
    } else if (order.nextState === "FISCAL") {
        return dateDeFacturare;
    } else if (order.nextState === "PAYMENT_METHOD") {
        return modalitateDePlata;
    } else if (order.nextState === "BANKING_TRANSFER") {
        return detaliiTransferBancar;
    } else if (order.nextState === "THANKS") {
        return multumim;
    }
}
