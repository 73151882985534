let constantKeys = {
    ORDER_KEY: '70a17ffa722a3985b86d30b034ad06d7',
    CERT_ORDER_KEY: '9857ffa722a3985b86d30b034ad06d7',
    AUTH_KEY: 'fa53b91ccc1b78668d5af58e1ed3a485',
    DOCUMENTS: 'e3e2a9bfd88566b05001b02a3f51d286',
    PAYMENT_METHOD: '6ba408e34a2570d54dcc2b1c81a9a136',
    BROWSER_DATA: '52ecfe57fd55e7c870447f93dc3e6071',
    FEATURE_SWITCHES: '2cc3bd80b9d5d6422ef52b3d5cd1589c',
    PUBLIC_PRICES: '2ckh57j5d6422ef52b3d985cd1589c',
    COMPANY_IN_USE: 'd6ae18917515dfda120df0721e44c4e5',
    CREATED_ORDER: 'd3b5356c1aca6e8e8a53bdb69a58b02c',
    COOKIE_ACCEPTED: 'dc1a125fa3b49980603fff571ebef913',
}

function getObject(key) {
    let obj = localStorage.getItem(key);
    if (obj == null) {
        return undefined;
    }
    return JSON.parse(obj);
}


function setObject(key, object) {
    if (object !== undefined && object !== null) {
        object = JSON.stringify(object);
    }
    //console.log("[LS-Set] [" + key + "] EV: [" + JSON.stringify(getObject(key)) + "], NV: [" + object + "]")
    localStorage.setItem(key, object);
}


export function getOrder() {
    return getObject(constantKeys.ORDER_KEY)
}

export function setOrder(order) {
    return setObject(constantKeys.ORDER_KEY, order)
}

export function getCertificatOrder() {
    return getObject(constantKeys.ORDER_KEY)
}

export function setCertificatOrder(order) {
    return setObject(constantKeys.ORDER_KEY, order)
}

export function getCookiesAcceptedValue() {
    return getObject(constantKeys.COOKIE_ACCEPTED);
}

export function setCookiesAcceptedValue(value) {
    return setObject(constantKeys.COOKIE_ACCEPTED, value)
}

export function getCreatedOrder() {
    return getObject(constantKeys.CREATED_ORDER)
}

export function setCreatedOrder(order) {
    return setObject(constantKeys.CREATED_ORDER, order)
}

export function getToken() {
    return getObject(constantKeys.AUTH_KEY)
}

export function setToken(auth) {
    if (auth !== undefined && auth !== null && Object.keys(auth).length > 0) {
        let increaseValue = 3;
        if (auth.roles !== undefined && auth.roles !== null && auth.roles.length > 0 && auth.roles.indexOf('ROLE_ADMIN') > -1) {
            increaseValue = 24;
        }
        let dt = new Date();
        dt.setHours(dt.getHours() + increaseValue);
        auth.expireDate = JSON.stringify(dt);
    }
    return setObject(constantKeys.AUTH_KEY, auth)
}

export function getUploadedDocuments() {
    return getObject(constantKeys.DOCUMENTS)
}
export function getPaymentMethod() {
    return getObject(constantKeys.PAYMENT_METHOD)
}

export function setUploadedDocuments(docs) {
    return setObject(constantKeys.DOCUMENTS, docs)
}
export function setPaymentMethod(paymentMethod) {
    return setObject(constantKeys.PAYMENT_METHOD, paymentMethod)
}

export function getBrowserData() {
    return getObject(constantKeys.BROWSER_DATA)
}

export function setBrowserData(data) {
    return setObject(constantKeys.BROWSER_DATA, data)
}

export function getFeatureSwitches() {
    return getObject(constantKeys.FEATURE_SWITCHES)
}

export function setPublicPrices(data) {
    return setObject(constantKeys.PUBLIC_PRICES, data)
}

export function getPublicPrices() {
    return getObject(constantKeys.PUBLIC_PRICES)
}

export function setFeatureSwitches(data) {
    return setObject(constantKeys.FEATURE_SWITCHES, data)
}
export function setCompanyInUse(data) {
    return setObject(constantKeys.COMPANY_IN_USE, data)
}
export function getCompanyInUse() {
    return getObject(constantKeys.COMPANY_IN_USE);
}
