// material
import {
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox, FormControlLabel,
    Stack,
    Step,
    StepIcon,
    StepLabel,
    Stepper, ToggleButton,
    Typography
} from '@mui/material';
import {ToggleButtonGroup} from '@mui/lab';
//
import * as LogicService from "../../../services/LogicService";
import {DOCUMENTS_DETAILS} from "../../../services/LogicService";
import OrderOffer from "../../authentication/order/OrderOffer";
import OrderComplete from "../../authentication/order/OrderComplete";
import * as React from "react";
import OrderSignContract from "../../authentication/order/OrderSignContract";
import OrderFiscalInfo from "../../authentication/order/OrderFiscalInfo";
import {useNavigate} from "react-router-dom";
import Anexa38Number from "../../authentication/order/Anexa38Number";
import {useEffect, useState} from "react";
import BankTransferAccordion from "./BankTransferAccordion";
import DragAndDropFileUploader from "./DragAndDropFileUploader";
import * as CommunicationService from "../../../services/CommunicationService";
import * as AlertMapper from "../../../services/AlertMapper";
import OrderFiscalInfoV2 from "../../authentication/order/OrderFiscalInfoV2";
import ConfirmOrRejectModal from "../user/ConfirmOrRejectModal";
import ReportProblemModal from "../user/ReportProblemModal";
import AutoRecordOrderForm from "../../authentication/order/AutoRecordOrderForm";
import AutoOrderComplete from "../../authentication/order/AutoOrderComplete";


export default function AutoRecordRegisterCard() {
    const navigate = useNavigate();
    let order = LogicService.getOrder();
    let paymentMethod = LogicService.getPaymentMethod();

    const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
    const [reportProblemModalEnabled, setReportProblemModalEnabled] = useState(false);

    const redirectToPayment = () => {
        LogicService.resetSession();
        window.location.assign(order.paymentCode);
    }

    const steps = ['Date personale', 'Opțiuni și documente necesare', 'Facturare și plată'];

    let reportProblem = <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{paddingLeft: 3, pb: 3}}>
        <ReportProblemModal open={reportProblemModalEnabled}
                              handleClose={() => {
                                  setReportProblemModalEnabled(false);
                              }}
                              handleConfirm={() => {
                                  setReportProblemModalEnabled(false);
                                  LogicService.setAlertMessageAndSeverity(AlertMapper.KNOWN_SUCCESSES.MESSAGE_WAS_SEND);
                              }}
                              title={"Ce problemă ați întâmpinat?"}
                              content={"Vă rugăm să ne oferiți cât mai multe detalii pentru a putea identifica și rezolva problema în cel mai scurt timp"}
        />
        <Typography variant={"body1"}>
            Întâmpini dificultăți tehnice?
        </Typography>
        <Button size="small" variant="outlined" color={'info'} sx={{pl: 1}} onClick={() => setReportProblemModalEnabled(true)}>
            apasă aici
        </Button>
    </Stack>

    let getStepper = (activeStep) =>
        <Stepper activeStep={activeStep}
                 sx={{margin: "15px 15px 0 15px", display: "flex", flexWrap: "wrap", gap: "10px"}}
        >
            {steps.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>

    let [firstStepActive, setFirstStepActive] = React.useState(0);

    let formular = <Card>
        {getStepper(firstStepActive)}
        <CardHeader title="Obține cazierul rutier online" subheader="Fără să te deplasezi la ghișeu"/>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <AutoRecordOrderForm changeStepper={() => setFirstStepActive(1)} setFirstStepActive={setFirstStepActive}/>
        </Box>
        {
            firstStepActive === 1 ? reportProblem : null
        }
    </Card>

    let pret = <Card>
        {getStepper(1)}
        <CardHeader title="Prețul pentru serviciile selectate este"/>
        pret de sters
        {reportProblem}
    </Card>

    let incarcareDocumente = <Card>
        {getStepper(1)}
        <CardHeader title="Ultimii pași"
                    subheader="Încarcă documentele necesare"/>
        <Box sx={{p: 3, pb: 3}} dir="ltr">
            <AutoOrderComplete/>
        </Box>
        {reportProblem}
    </Card>

    const [nrInreg, setNrInreg] = useState("");
    const [anexNumberBorderColor, setAnexNumberBorderColor] = useState("1px solid #ced4da");

    let semnatura = <Card>
        {getStepper(2)}
        <CardHeader title="Avem nevoie de semnătura ta"/>
        {reportProblem}
    </Card>

    const back = () => {
        LogicService.resetSession();
        navigate('/servicii/cazier-rutier-online');
    }

    const backToPaymentMethod = () => {
        LogicService.updateOrderState('PAYMENT_METHOD');
        navigate('/servicii/cazier-rutier-online');
    }

    let dateDeFacturare =
        <Card>
            {getStepper(2)}
            <CardHeader title="Date de facturare"/>
            <Box sx={{p: 3, pb: 3}} dir="ltr">
                {
                    <OrderFiscalInfoV2 urlPreffix={'/servicii/cazier-rutier-online'}/>
                }
            </Box>
            {reportProblem}
        </Card>

    const [alignment, setAlignment] = React.useState('creditCard');
    const handlePersonTypeChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const personTypeControls = {
        value: alignment,
        onChange: handlePersonTypeChange,
        exclusive: true,
    };

    let getIconForAlignment = (align) => {
        if (alignment == align) {
            return "checked_checkbox.png";
        }
        return "unchecked_checkbox.png"
    }

    let modalitateDePlata =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">Total de
                        plată {order !== undefined && order !== null ? order.price : ""} RON</Typography>
                    <Typography variant="body1">Alege modalitatea de plată dorită</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "2% 5%"}}
                       spacing={2} alignSelf={"center"}>

                    <ToggleButtonGroup size="large" {...personTypeControls} fullWidth color="error" variant="outlined"
                                       id={"paymentMethods"}
                    >

                        <ToggleButton value={'creditCard'} key={'creditCard'} alignSelf={"left"} id="paymentCard"
                                      sx={{display: 'flex', justifyContent: "left", verticalAlign: "top"}}
                        >
                            <Box component="img" src={"/static/illustrations/" + getIconForAlignment('creditCard')}
                                 width={{xs: 20, sm: 34}}/>
                            <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                                   sx={{margin: "2% 5%", height: "100%"}}
                                   spacing={2} alignContent={"left"}>
                                <Typography alignSelf={"left"} variant={{xs: 'body1', sm: 'body1'}}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '7%',
                                                textTransform: 'none',
                                                textAlign: 'left',
                                                fontSize: "18px"
                                            }}
                                >{"Card de credit sau de debit"}</Typography>
                                <Typography alignSelf={"left"}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '0px',
                                                textTransform: 'none',
                                                textAlign: 'left'
                                            }}
                                >{"Plătește online, cu cardul"}</Typography>
                                <Typography alignSelf={"left"}
                                            sx={{
                                                padding: '0 0px',
                                                marginTop: '0px !important',
                                                textTransform: 'none',
                                                textAlign: 'left'
                                            }}
                                >{"Plata este securizată"}</Typography>

                                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                                       sx={{margin: "2% 5%"}}
                                       spacing={2} alignSelf={"left"}>
                                    <Box component="img" src={"/static/illustrations/visa-mastercard-logo.png"}
                                         width={{xs: 120, sm: 120}}/>
                                </Stack>

                            </Stack>
                        </ToggleButton>
                    </ToggleButtonGroup>


                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={back}
                    >
                        Acasă
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'secondary'}
                        disabled={alignment === undefined || alignment === null || alignment === ''}
                        onClick={() => {
                            if (alignment === 'creditCard') {
                                return redirectToPayment();
                            }
                            setPaymentMethodSelected(true);
                            LogicService.updateOrderState('BANKING_TRANSFER');
                        }}
                    >
                        Către plată
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    let [files, setFiles] = React.useState([]);
    let [filesUploaded, setFilesUploaded] = React.useState(paymentMethod != undefined && paymentMethod != null);

    let uploadPaymentProof = () => {
        let docKey = 'payment_proof';
        let file = files[0];
        CommunicationService.addDocumentToOrder(order.id, docKey, file)
            .then((response) => {
                LogicService.setPaymentMethod(alignment);
                setFilesUploaded(true);
                LogicService.updateOrderState('THANKS');
                navigate('/manualp');
            })
            .catch((error) => {
                LogicService.setAlertMessageAndSeverity(AlertMapper.getErrorMessage(error));
            });
    }

    let detaliiTransferBancar =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                {/*<Box sx={{p: 3, pb: 3, textAlign: 'left', margin: "0px 10%"}}>*/}
                {/*    <Typography variant="h5">Vei primi un SMS cu un cod de acces pentru a verifica statusul comenzii de*/}
                {/*        îndată ce efectuezi plata.</Typography>*/}
                {/*</Box>*/}
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">Transfer bancar</Typography>
                    <Typography variant="body1">Ai selectat plata prin transfer bancar. Regăsești mai jos detaliile
                        pentru efectuarea plății</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "10px"}}
                       sx={{margin: "0% 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <BankTransferAccordion expanded={true}
                                           setExpanded={() => {
                                           }}/>
                </Stack>


                <Stack direction={{xs: 'column', sm: 'column', marginTop: "0px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="body1">Încarcă dovada efectuării plății în chenarul de mai jos (ex. o captură
                        de ecran cu plata).</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "5px"}}
                       sx={{margin: "0% 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <DragAndDropFileUploader setFiles={setFiles}/>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'column'}}
                       sx={{margin: "2% 5%", marginTop: "10px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="body1">
                        sau trimiteți dovada plății pe adresa &nbsp;
                        <a href={"mailto:contact@caziere.ro?subject=Dovada platii pentru orderul " + (order?.id)}
                           target={"_blank"}>
                            contact@caziere.ro
                        </a>
                    </Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={backToPaymentMethod}
                    >
                        Înapoi
                    </Button>

                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'secondary'}
                        disabled={files.length === 0}
                        onClick={uploadPaymentProof}
                    >
                        Încarcă dovada plății
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    let multumim =
        <Card>
            {getStepper(3)}
            <Box sx={{margin: "20px 0px 30px 0px"}} dir="ltr">
                <Stack direction={{xs: 'column', sm: 'column', marginTop: "30px"}}
                       sx={{margin: "2% 5%", marginTop: "30px"}}
                       spacing={2} alignSelf={"left"}>
                    <Typography variant="h4">Mulțumim!</Typography>
                    <Typography variant="body1">Vei primi un SMS cu un cod de acces pentru a verifica statusul comenzii
                        tale</Typography>
                </Stack>

                <Stack direction={{xs: 'column', sm: 'row', marginTop: "30px"}}
                       sx={{margin: "0px 5%"}}
                       spacing={2} alignSelf={"center"}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color={'warning'}
                        onClick={back}
                    >
                        Acasă
                    </Button>
                </Stack>
            </Box>
            {reportProblem}
        </Card>

    if (order === undefined || order === null) {
        return formular;
    } else if (order.nextState === 'PRICE') {
        return pret;
    } else if (order.nextState === "DOCUMENTS") {
        return incarcareDocumente;
    } else if (order.nextState === "SIGNATURE") {
        return semnatura;
    } else if (order.nextState === "FISCAL") {
        return dateDeFacturare;
    } else if (order.nextState === "PAYMENT_METHOD") {
        return modalitateDePlata;
    } else if (order.nextState === "BANKING_TRANSFER") {
        return detaliiTransferBancar;
    } else if (order.nextState === "THANKS") {
        return multumim;
    }
}
